import React from 'react';
import { LineItemsProps } from '../../../components/shop/lineItems/lineItems';
import { Alert, Avatar, Button, Empty } from 'antd';
import { useI18n } from '../../../hooks/useI18n';
import { messages } from '../../../messages';
import { useFileUris } from '../../../hooks/useFileUrls/useFileUrls';
import styles from './lineItems.module.scss';
import cc from 'classcat';
import emptyBoxIcon from '../../../components/shop/lineItems/emptyBoxIcon.svg';
import { LoadingIndicator } from '../../../components/loadingIndicator/loadingIndicator';

export const LineItems = (props: LineItemsProps) => {
  const { t } = useI18n();
  const { placeholderImageFileUri, articleImageFileUri } = useFileUris();
  const { onRemove, unavailableArticleIds, isLoading, items } = props;

  return (
    <div style={{ position: 'relative' }}>
      {isLoading && <LoadingIndicator />}
      {!items.length && (
        <Empty
          description={t(messages.shared.noItems)}
          image={<img src={emptyBoxIcon} />}
        />
      )}
      {!!items.length && (
        <div className={styles.rowContainer}>
          <Avatar
            src={
              items[0].hasImage
                ? articleImageFileUri(items[0].articleId)
                : placeholderImageFileUri
            }
            shape="square"
            className={styles.articleImage}
          />
          <div className={styles.articleDetails}>
            <div className={cc([styles.articleDetail, styles.articleNumber])}>
              {items[0].articleNumber}
            </div>
            <div
              className={cc([styles.articleDetail, styles.articleDescription])}
            >
              {items[0].description}
            </div>
          </div>
          {onRemove && (
            <Button
              shape="circle"
              icon="delete"
              onClick={() => onRemove(items[0].articleId)}
            />
          )}
        </div>
      )}
      {!!items.length &&
        unavailableArticleIds?.includes(items[0].articleId) && (
          <Alert
            style={{ marginTop: '15px' }}
            message={t(messages.checkoutAdapter._3M.unavailableProduct)}
            type="error"
            showIcon={true}
          />
        )}
    </div>
  );
};
