import React, { useState, useEffect } from 'react';
import { Radio, Select } from 'antd';
import { useI18n } from '../../hooks/useI18n';
import { messages } from '../../messages';
import { TimePeriodType } from '../../types';

type State = {
  periodType: TimePeriodType;
  selectablePeriods: string[];
  selectedPeriods: string[];
};

export function TimePeriodPicker({
  currentYear,
  selectedTimePeriods,
  selectedTimePeriodsType,
  onChange,
}: {
  currentYear?: number;
  selectedTimePeriods?: string[];
  selectedTimePeriodsType?: TimePeriodType;
  onChange: (timePeriods: { type: TimePeriodType; periods: string[] }) => void;
}) {
  const { t } = useI18n();
  const yearOptions = Array.from(Array(5).keys()).map((k) =>
    (currentYear ?? new Date().getFullYear() - k).toString(),
  );

  const months = Array.from(Array(12).keys()).map((k) =>
    k < 9 ? `0${k + 1}` : (k + 1).toString(),
  );
  const monthOptions = yearOptions.flatMap((y) =>
    months.map((m) => `${y}-${m}`),
  );

  const quarters = Array.from(Array(4).keys()).map((k) => (k + 1).toString());
  const quarterOptions = yearOptions.flatMap((y) =>
    quarters.map((q) => `${y}-${q}`),
  );

  const selectablePeriodsForType: Record<TimePeriodType, string[]> = {
    [TimePeriodType.Yearly]: yearOptions,
    [TimePeriodType.Quarterly]: quarterOptions,
    [TimePeriodType.Monthly]: monthOptions,
  };

  const initialState: State = {
    periodType: TimePeriodType.Yearly,
    selectablePeriods: selectablePeriodsForType[TimePeriodType.Yearly],
    selectedPeriods: selectedTimePeriods ?? [],
  };

  const [state, setState] = useState(initialState);

  useEffect(() => {
    setState({
      ...state,
      selectedPeriods: selectedTimePeriods ?? [],
      periodType: selectedTimePeriodsType ?? TimePeriodType.Yearly, // SelectedTimePeriodsType is a string from the server, convert to type
    });
  }, [selectedTimePeriods]);

  useEffect(() => {
    onChange({ type: state.periodType, periods: state.selectedPeriods });
  }, [state]);

  return (
    <>
      <Radio.Group
        style={{ minWidth: '250px' }}
        onChange={(e) => {
          setState({
            periodType: e.target.value as TimePeriodType,
            selectedPeriods: [],
            selectablePeriods:
              selectablePeriodsForType[e.target.value as TimePeriodType],
          });
        }}
        value={state.periodType}
      >
        <Radio value={TimePeriodType.Yearly}>Year</Radio>
        <Radio value={TimePeriodType.Quarterly}>Quarter</Radio>
        <Radio value={TimePeriodType.Monthly}>Month</Radio>
      </Radio.Group>
      <Select
        mode="multiple"
        style={{ width: '100%', marginTop: '5px' }}
        placeholder={t(messages.reports.timePeriodPlaceholder)}
        defaultValue={[]}
        value={state.selectedPeriods}
        autoClearSearchValue={false}
        onChange={(e: string[]) => {
          setState({ ...state, selectedPeriods: e });
        }}
        showArrow={true}
        allowClear={true}
      >
        {state.selectablePeriods.map((p) => (
          <Select.Option key={p} value={p}>
            {p}
          </Select.Option>
        ))}
      </Select>
    </>
  );
}
