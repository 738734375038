import { TranslateFunction } from '../hooks/useI18n/messageTranslator';
import { OrderProgress } from './orderProgressConverter';
import { messages } from '../messages';

export function orderProgressTranslator(
  t: TranslateFunction,
  orderProgress: OrderProgress,
) {
  switch (orderProgress) {
    case OrderProgress.Draft:
      return t(messages.order.unplaced);
    case OrderProgress.Unconfirmed:
      return t(messages.order.unconfirmed);
    case OrderProgress.AwaitingDelivery:
      return t(messages.order.awaitingDelivery);
    case OrderProgress.PartiallyDelivered:
      return t(messages.order.partiallyDelivered);
    case OrderProgress.Delivered:
      return t(messages.order.delivered);
    default:
      return t(messages.shared.unknown);
  }
}
