import React from 'react';
import { Layout } from 'antd';
import styles from './listLayout.module.scss';
import cc from 'classcat';

function ListLayout({
  children,
  className,
}: {
  className?: string;
  children: React.ReactNode;
}) {
  return <Layout className={cc([styles.page, className])}>{children}</Layout>;
}

function ListLayoutSider({
  width = 260,
  children,
}: {
  width?: number;
  children: React.ReactNode;
}) {
  return (
    <Layout.Sider theme="light" width={width} className={styles.sider}>
      {children}
    </Layout.Sider>
  );
}

function ListLayoutContent({ children }: { children: React.ReactNode }) {
  return <Layout.Content className={styles.content}>{children}</Layout.Content>;
}

function ListLayoutContentHeader({
  title,
  extra,
}: {
  title?: React.ReactNode;
  extra?: React.ReactNode;
}) {
  return (
    <div className={styles.contentHeader}>
      {title && <div className={styles.contentHeaderTitle}>{title}</div>}
      {extra && <div className={styles.contentHeaderExtra}>{extra}</div>}
    </div>
  );
}

function ListLayoutContentSection({ children }: { children: React.ReactNode }) {
  return <div className={styles.contentSection}>{children}</div>;
}

ListLayout.Sider = ListLayoutSider;
ListLayout.Content = ListLayoutContent;
ListLayout.ContentHeader = ListLayoutContentHeader;
ListLayout.ContentSection = ListLayoutContentSection;

export { ListLayout };
