import React, { useRef, useMemo } from 'react';
import Scrollbar from 'react-scrollbars-custom';
import {
  CellSize,
  BaseCellValue,
  RenderCellContent,
  CellLocation,
} from '../types';
import { customIEScroll } from '../helpers';
import styles from './cells.module.scss';
import { Cell } from '../cell/cell';
import { OverflowOverlay } from '../overflowOverlay/overflowOverlay';

interface XKeyMap<T extends BaseCellValue> {
  [xKey: string]: YKeyMap<T>;
}

interface YKeyMap<T extends BaseCellValue> {
  [yKey: string]: T;
}

interface CellsProps<T extends BaseCellValue> {
  xKeys: string[];
  yKeys: string[];
  cellSize: CellSize;
  selectedCell?: CellLocation;
  cellValues: T[];
  renderCellContent: RenderCellContent<T>;
  overflowingLeft: boolean;
  overflowingRight: boolean;
  overflowingTop: boolean;
  overflowingBottom: boolean;
  innerScrollRef: (instance?: Scrollbar) => void;
  onScroll: () => void;
  onScrollStart: () => void;
  onScrollStop: () => void;
  onCellSelect: (cell: CellLocation) => void;
}

function CellsComponent<T extends BaseCellValue>({
  xKeys,
  yKeys,
  cellSize,
  selectedCell,
  cellValues,
  innerScrollRef,
  renderCellContent,
  overflowingLeft,
  overflowingRight,
  overflowingTop,
  overflowingBottom,
  onScroll,
  onScrollStart,
  onScrollStop,
  onCellSelect,
}: CellsProps<T>) {
  const scrollRef = useRef<Scrollbar>();

  const cellValuesMap = useMemo(() => {
    const xKeymap: XKeyMap<T> = {};
    cellValues.forEach((cellValue) => {
      xKeymap[cellValue.xKey] = {
        [cellValue.yKey]: cellValue,
        ...xKeymap[cellValue.xKey],
      };
    });
    return xKeymap;
  }, [cellValues]);

  const getCellValue = ({ xKey, yKey }: CellLocation): T | undefined => {
    const yKeyMap = cellValuesMap[xKey];
    return yKeyMap[yKey];
  };

  return (
    <>
      <Scrollbar
        style={{
          height: '100%',
          width: 'auto',
        }}
        wrapperRenderer={(props: any) => {
          const {
            elementRef,
            overflowingLeft,
            overflowingRight,
            ...restProps
          } = props;
          return (
            <div {...restProps} ref={elementRef}>
              {overflowingLeft && <OverflowOverlay direction="left" />}
              {overflowingRight && <OverflowOverlay direction="right" />}
              {overflowingTop && <OverflowOverlay direction="top" />}
              {overflowingBottom && <OverflowOverlay direction="bottom" />}
              {restProps.children}
            </div>
          );
        }}
        wrapperProps={{
          style: { marginRight: 0, marginBottom: 0 },
          overflowingLeft,
          overflowingRight,
        }}
        trackXProps={{
          style: { zIndex: 100, height: '15px', marginBottom: '-16px' },
        }}
        trackYProps={{
          style: { zIndex: 100, width: '15px', marginRight: '-16px' },
        }}
        ref={(ref) => {
          innerScrollRef(ref ? ref : undefined);
          scrollRef.current = ref ? ref : undefined;
        }}
        onScroll={onScroll}
        onScrollStart={onScrollStart}
        onScrollStop={onScrollStop}
        contentProps={{
          onWheel: customIEScroll(scrollRef.current!),
        }}
        className={styles.cells}
      >
        <div className={styles.cellsRowWrapper}>
          {yKeys.map((yKey) => (
            <div key={yKey} className={styles.cellsRow}>
              {xKeys.map((xKey) => (
                <Cell
                  key={xKey}
                  xKey={xKey}
                  yKey={yKey}
                  cellValue={getCellValue({ xKey, yKey })}
                  renderCellContent={renderCellContent}
                  onSelect={onCellSelect}
                  isSelected={
                    !!selectedCell &&
                    selectedCell.xKey === xKey &&
                    selectedCell.yKey === yKey
                  }
                  cellSize={cellSize}
                />
              ))}
            </div>
          ))}
        </div>
      </Scrollbar>
    </>
  );
}

// Manually typed because of troubles with React.memo and
// generic functions
export const Cells: <T extends BaseCellValue>(
  props: CellsProps<T>,
) => JSX.Element = React.memo(CellsComponent) as any;
