import React, { useMemo } from 'react';
import ReactTable, {
  SortingRule,
  Column as ReactTableColumn,
  RowInfo,
} from 'react-table';
import { Column } from './types';
import styles from './table.module.scss';
import { Icon, Pagination, Skeleton } from 'antd';
import cc from 'classcat';
import { useI18n } from '../../hooks/useI18n';
import { messages } from '../../messages';
import { LoadingIndicator } from '../loadingIndicator/loadingIndicator';
import { EmptyIndicator } from '../emptyIndicator/emptyIndicator';

interface TableProps<T> {
  isLoading?: boolean;
  items: T[];
  columns: Column<T>[];
  style?: React.CSSProperties;
  onSelectItem?: (row: T) => void;
  paged?: PagedOptions;
  sorted?: SortedOptions<T>;
  memoizeRows?: boolean;
}

interface PagedOptions {
  page: number;
  pageSize: number;
  totalItems: number;
  onPageChange: (page: number) => void;
}

interface SortedOptions<T> {
  key: keyof T;
  desc: boolean;
  onSortedChange: (newSorting: { key: keyof T; desc: boolean }) => void;
}

export function Table<T>({
  isLoading,
  paged,
  sorted,
  columns,
  items,
  style,
  memoizeRows = true,
  onSelectItem,
}: TableProps<T>) {
  const reactTableColumns = useMemo(() => {
    return columns.map<ReactTableColumn<T>>((column) => ({
      accessor: column.accessor as string,
      sortable: column.sortable ? true : false,
      show: column.show === undefined ? true : column.show,
      width: column.width,
      maxWidth: column.maxWidth,
      minWidth: column.minWidth,
      Header: column.header,
      headerClassName: column.align === 'right' ? styles.textAlignRight : '',
      className: cc({
        [styles.textAlignRight]: column.align === 'right',
        [column.cellClassName!]: !!column.cellClassName,
      }),
      Cell: column.cell
        ? ({ value, original }: { value: any; original: T }) =>
            column.cell!({ row: original, value })
        : undefined,
      getHeaderProps: ((state: any, _: any, column: any) => ({
        sorting: state.sorted[0],
        column,
      })) as any,
    }));
  }, [columns]);

  const getReactTableTrProps = (_state: any, rowInfo?: RowInfo) => {
    return {
      className: onSelectItem ? styles.rowSelectable : '',
      onClick: (
        _e: React.MouseEvent<HTMLDivElement>,
        handleOriginal?: () => void,
      ) => {
        if (rowInfo && onSelectItem) {
          onSelectItem(rowInfo.original);
        }

        if (handleOriginal) {
          handleOriginal();
        }
      },
    };
  };

  const getReactTableTbodyProps = () => {
    return {
      paged,
      items,
      isLoading,
      memoizeRows,
    };
  };

  const handleSortedChange = (sorting: SortingRule[]) => {
    if (paged) {
      paged.onPageChange(1);
    }
    if (sorted) {
      sorted.onSortedChange({
        key: sorting[0].id as keyof T,
        desc: sorting[0].desc,
      });
    }
  };

  return (
    <ReactTable
      className="-highlight"
      style={style}
      data={items}
      columns={reactTableColumns}
      onSortedChange={handleSortedChange}
      sorted={
        sorted ? [{ id: sorted.key.toString(), desc: sorted.desc }] : undefined
      }
      sortable={!!sorted}
      showPagination={false}
      manual={true}
      resizable={false}
      multiSort={false}
      minRows={0}
      ThComponent={TableHeader}
      TbodyComponent={TableBody}
      getTrProps={getReactTableTrProps}
      getTbodyProps={getReactTableTbodyProps}
      noDataText=""
    />
  );
}

function TableHeader({
  children,
  className,
  sorting,
  column,
  toggleSort,
  ...rest
}: {
  children: React.ReactNode;
  className: string;
  sorting: SortingRule | undefined;
  column: ReactTableColumn<any>;
  toggleSort: (e: React.MouseEvent<HTMLDivElement>) => void;
}) {
  return (
    <div
      className={cc(['rt-th', styles.headerContent, className])}
      onClick={(e) => toggleSort(e)}
      role="columnheader"
      tabIndex={-1}
      {...rest}
    >
      {children}
      {column.sortable && sorting && (
        <div className={styles.headerSorter}>
          <Icon
            type="caret-up"
            theme="filled"
            className={cc([
              styles.headerSorterIconUp,
              {
                [styles.headerSorterIconActive]:
                  sorting.id === column.id && !sorting.desc,
              },
            ])}
          />
          <Icon
            type="caret-down"
            theme="filled"
            className={cc([
              styles.headerSorterIconDown,
              {
                [styles.headerSorterIconActive]:
                  sorting.id === column.id && sorting.desc,
              },
            ])}
          />
        </div>
      )}
    </div>
  );
}

function TableBody({
  children,
  paged,
  className,
  isLoading,
  items,
  memoizeRows,
  ...rest
}: {
  memoizeRows?: boolean;
  children: React.ReactNode;
  paged: PagedOptions;
  className: string;
  isLoading: boolean;
  isPaged: boolean;
  isLastPage: boolean;
  items: any[];
}) {
  const { t } = useI18n();

  const memoChildren = useMemo(() => children, [items]);

  return (
    <div className={cc(['rt-tbody', className])} {...rest}>
      {isLoading &&
        (items.length > 0 ? (
          <LoadingIndicator />
        ) : (
          <Skeleton
            className={styles.skeleton}
            paragraph={{ rows: 5 }}
            title={false}
            active={true}
          />
        ))}
      {items.length === 0 && !isLoading && (
        <div className={styles.empty}>
          <EmptyIndicator description={t(messages.shared.noItems)} />
        </div>
      )}
      {memoizeRows ? memoChildren : children}
      {items.length > 0 && paged && (
        <div className={styles.paginationWrapper}>
          <Pagination
            onChange={(page) => paged.onPageChange(page)}
            current={paged.page}
            total={paged.totalItems}
            pageSize={paged.pageSize}
          />
        </div>
      )}
    </div>
  );
}
