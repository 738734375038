import React from 'react';
import styles from './overflowOverlay.module.scss';
import cc from 'classcat';

export function OverflowOverlay({
  direction,
}: {
  direction: 'left' | 'right' | 'top' | 'bottom';
}) {
  return (
    <div
      className={cc({
        [styles.overlay]: true,
        [styles.overflowRight]: direction === 'right',
        [styles.overflowLeft]: direction === 'left',
        [styles.overflowTop]: direction === 'top',
        [styles.overflowBottom]: direction === 'bottom',
      })}
    ></div>
  );
}
