import React, { useState } from 'react';
import { useI18n } from '../../../hooks/useI18n';
import { messages } from '../../../messages';
import { ArticleCategoryPicker } from '../../pickers/articleCategoryPicker';
import { OrderType, TimePeriodType } from '../../../types';
import { ListLayout } from '../../listLayout/listLayout';
import { Button, Tooltip } from 'antd';
import { useFileUris } from '../../../hooks/useFileUrls/useFileUrls';
import { categoryCodesApi } from '../../../api/categoryCodesApi';
import { TimePeriodPicker } from '../../pickers/timePeriodPicker';
import { OrderTypePicker } from '../../pickers/orderTypePicker';
import { DeliveryStatusPicker } from '../../pickers/deliveryStatusPicker';
import style from './reports.module.scss';

export function CustomerOrdersReport() {
  const { t } = useI18n();
  const { customerOrderReportUri } = useFileUris();

  const [downloadBtnDisabled, setdownloadBtnDisabled] = useState(false);
  const [categoryIds, setCategoryIds] = useState<number[]>([]);
  const [deliveryStatus, setDeliveryStatus] = useState<number>(0);
  const [orderType, setOrderType] = useState<OrderType>(OrderType.Standard);
  const [timePeriods, setTimePeriods] = useState<{
    type: TimePeriodType;
    periods: string[];
  }>({
    type: TimePeriodType.Yearly,
    periods: [],
  });

  function handleSelectedCategoryIds(selectedCategoryIds: number[]) {
    setCategoryIds(selectedCategoryIds.slice(0, 300));
  }

  const selectableOrderTypes: OrderType[] = [
    OrderType.Standard,
    OrderType.Sample,
    OrderType.Forecast,
  ];

  const searchParams = new URLSearchParams({
    categoryIds: categoryIds.join(','),
    deliveryStatus: deliveryStatus.toString(),
    orderType: orderType.toString(),
    timePeriodType: timePeriods.type.toString(),
    timePeriods: timePeriods.periods.join(','),
  });

  const downloadLink = `${customerOrderReportUri}?${searchParams.toString()}`;

  const validInputs = timePeriods.periods.length && categoryIds.length;

  const disableDownloadBtn = async () => {
    setdownloadBtnDisabled(true);
    await new Promise((r) => setTimeout(r, 5000));
    setdownloadBtnDisabled(false);
  };

  return (
    <ListLayout>
      <div className={style.inputRow}>
        <h4>{t(messages.reports.articleCategory)}</h4>
        <ArticleCategoryPicker
          categoryIds={categoryIds}
          onChange={handleSelectedCategoryIds}
          categoryRequestFactory={(_) => categoryCodesApi.getCategories}
        />
      </div>
      <div className={style.inputRow}>
        <h4>{t(messages.order.orderType)}</h4>
        <OrderTypePicker
          orderType={orderType}
          selectableOrderTypes={selectableOrderTypes}
          onChange={setOrderType}
          allowClear={false}
        />
      </div>
      {orderType !== OrderType.Forecast && (
        <div className={style.inputRow}>
          <h4>{t(messages.order.progress)}</h4>
          <DeliveryStatusPicker
            deliveryStatus={deliveryStatus}
            onChange={setDeliveryStatus}
          />
        </div>
      )}
      <div className={style.inputRow}>
        <h4>{t(messages.reports.timePeriod)}</h4>
        <TimePeriodPicker onChange={setTimePeriods} />
      </div>
      <Tooltip
        title={
          validInputs
            ? t(messages.reports.downloadReport)
            : t(messages.reports.customerOrders.validationMessageBrand)
        }
      >
        <Button
          className={style.width100}
          onClick={() => disableDownloadBtn()}
          href={downloadLink}
          target="_blank"
          rel="noopener noreferrer"
          disabled={!validInputs || downloadBtnDisabled}
        >
          {t(messages.reports.downloadReport)}
        </Button>
      </Tooltip>
    </ListLayout>
  );
}
