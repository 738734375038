import React from 'react';
import styles from './userInformation.module.scss';
import { useI18n } from '../../../../hooks/useI18n';
import { useShopSessionStore } from '../../../../hooks/shop/useShopSessionStore';
import { Row, Col } from 'antd';
import { messages } from '../../../../messages';

export const UserInformation = () => {
  const { t } = useI18n();
  const { session } = useShopSessionStore();

  return (
    <Row type='flex' align='middle'>
      <Col span={12} className={styles.welcomeContent}>
        <div className={styles.welcomeLabel}>
          {t(messages.dashboard.welcome)}
        </div>
        <div className={styles.nameLabel}>{session.userName}</div>
      </Col>
      <Col span={12} className={styles.userContent}>
        <div>
          <div className={styles.infoBlock}>
            <div className={styles.infoLabel}>{t(messages.shared.country)}</div>
            <div className={styles.infoContent}>RH {session.branchName}</div>
          </div>
          <div className={styles.infoBlock}>
            <div className={styles.infoLabel}>
              {t(messages.shared.customer)}
            </div>
            <div className={styles.infoContent}>{session.customerName}</div>
          </div>
          <div className={styles.infoBlock}>
            <div className={styles.infoLabel}>{t(messages.shared.project)}</div>
            <div className={styles.infoContent}>{session.projectName}</div>
          </div>
          <div className={styles.infoBlock}>
            <div className={styles.infoLabel}>{t(messages.shared.assortment)}</div>
            <div className={styles.infoContent}>{session.assortmentName}</div>
          </div>
        </div>
      </Col>
    </Row>
  );
};
