import { useApiSettings } from '../useApi/useApiSettings';
import placeholderImageFileUri from '../../images/placeholder.jpg';

export function useFileUris() {
  const apiSettings = useApiSettings();

  return {
    placeholderImageFileUri,
    articleImageFileUri: apiSettings.articleImageUri,
    documentFileUri: apiSettings.documentsDownloadUri,
    internalArticleImageFileUri: apiSettings.internalArticleImageUri,
    internalDocumentFileUri: apiSettings.internalDocumentDownloadUri,
    articleStatisticsReportUri: apiSettings.articleStatisticsReportUri,
    customerOrderReportUri: apiSettings.customerOrdersReportUri,
    internalMissingArticlesExportUri: apiSettings.internalMissingArticlesExportUri,
    internalArticleStatisticsReportUri:
    apiSettings.internalArticleStatisticsReportUri,
    internalCustomerOrderReportUri: apiSettings.internalCustomerOrdersReportUri,
    internalSyncErrorsExportUri: apiSettings.internalSyncErrorsExportUri,
    internalOrdersExportUri: apiSettings.internalOrdersExportUri,
    internalOrderMetaFieldsExportUri:
    apiSettings.internalOrderMetaFieldsExportUri,
    internalAssortmentsExportUri: apiSettings.internalAssortmentsExportUri,
    internalUserCustomerExportUri: apiSettings.internalUserCustomerExportUri,
    internalShortageExportUri: apiSettings.exportInternalShortage,
    downloadInternalProductStatisticsExportUri: apiSettings.downloadInternalProductStatisticsExportUri,
    internalDelayedDeliveriesExportUri: apiSettings.exportInternalDelayedDeliveries,
    internalAwaitingDeliveriesExportUri: apiSettings.exportInternalAwaitingDeliveries,
    downloadCustomerOrdersExportUri: apiSettings.downloadCustomerOrdersStatisticsExportUri,
    downloadCustomerProductStatisticsExportUri: apiSettings.downloadCustomerProductStatisticsExportUri,
    downloadSlowStockExportUri: apiSettings.downloadSlowStockExportUri,
    customerAwaitingDeliveriesExportUri: apiSettings.exportCustomerAwaitingDeliveries,
    downloadCustomerCustomerOrdersReportExportUri: apiSettings.downloadCustomerCustomerOrdersReportExportUri,
  };
}
