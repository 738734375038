import React, { useState } from 'react';
import { TimePeriodPicker } from '../../pickers/timePeriodPicker';
import { ArticleCategoryPicker } from '../../pickers/articleCategoryPicker';
import { useFileUris } from '../../../hooks/useFileUrls/useFileUrls';
import { useI18n } from '../../../hooks/useI18n';
import { messages } from '../../../messages';
import { categoryCodesApi } from '../../../api/categoryCodesApi';
import { Button, Tooltip } from 'antd';
import style from './reports.module.scss';
import { ListLayout } from '../../listLayout/listLayout';
import { TimePeriodType } from '../../../types';

export function ProductStatisticsReport() {
  const { t } = useI18n();
  const { articleStatisticsReportUri } = useFileUris();

  const [downloadBtnDisabled, setdownloadBtnDisabled] = useState(false);
  const [categoryIds, setCategoryIds] = useState<number[]>([]);
  const [timePeriods, setTimePeriods] = useState<{
    type: TimePeriodType;
    periods: string[];
  }>({
    type: TimePeriodType.Yearly,
    periods: [],
  });

  const searchParams = new URLSearchParams({
    categoryIds: categoryIds.join(','),
    timePeriodType: timePeriods.type.toString(),
    timePeriods: timePeriods.periods.join(','),
  });

  const downloadLink = `${articleStatisticsReportUri}?${searchParams.toString()}`;

  const validInputs = timePeriods.periods.length && categoryIds.length;

  const disableDownloadBtn = async () => {
    setdownloadBtnDisabled(true);
    await new Promise((r) => setTimeout(r, 5000));
    setdownloadBtnDisabled(false);
  };

  return (
    <ListLayout>
      <div className={style.inputRow}>
        <h4>{t(messages.reports.articleCategory)}</h4>
        <ArticleCategoryPicker
          categoryIds={categoryIds}
          onChange={setCategoryIds}
          categoryRequestFactory={(_) => categoryCodesApi.getCategories}
        />
      </div>
      <div className={style.inputRow}>
        <h4>{t(messages.reports.timePeriod)}</h4>
        <TimePeriodPicker onChange={setTimePeriods} />
      </div>
      <Tooltip
        title={
          validInputs
            ? t(messages.reports.downloadReport)
            : t(messages.reports.articleStatistics.validationMessageBrand)
        }
      >
        <Button
          className={style.width100}
          onClick={() => disableDownloadBtn()}
          href={downloadLink}
          target="_blank"
          rel="noopener noreferrer"
          disabled={!validInputs || downloadBtnDisabled}
        >
          {t(messages.reports.downloadReport)}
        </Button>
      </Tooltip>
    </ListLayout>
  );
}
