import { Input, Icon } from 'antd';
import React from 'react';

export function SearchInput({
  value,
  placeholder,
  autoFocus,
  onChange,
}: {
  autoFocus?: boolean;
  value: string;
  placeholder: string;
  onChange: (value: string) => void;
}) {
  return (
    <Input
      prefix={<Icon type="search" style={{ color: 'rgba(0,0,0,.25)' }} />}
      placeholder={placeholder}
      allowClear={true}
      autoCorrect="off"
      autoCapitalize="off"
      spellCheck={false}
      value={value}
      autoFocus={autoFocus}
      onChange={(e) => {
        onChange(e.target.value);
      }}
    />
  );
}
