import React, { useEffect, useCallback, useMemo, useState } from 'react';
import { ArticlesList } from './articlesList/articlesList';
import { ArticleFilters } from './articleFilters/articleFilters';
import { ApiErrorDetails } from '../../apiErrorDetails/apiErrorDetails';
import { useApiResource } from '../../../hooks/useApi';
import { ListLayout } from '../../listLayout/listLayout';
import { useQueryParams, serializers } from '../../../hooks/useQueryParams';
import { useArticleCartModalStore } from '../../../hooks/shop/useArticleCartModalStore';
import { useShopSessionStore } from '../../../hooks/shop/useShopSessionStore';
import { Currency } from '../../../types';
import { getExportArticlesUrl } from './excelExport';
import {
  articlesApi,
  ArticleListItem,
  ArticleFilterValues,
} from '../../../api/articlesApi';
import { ArticleListType } from './types';
import { useApiSettings } from '../../../hooks/useApi/useApiSettings';

const pageSize = 28;

export const Articles = () => {
  const articleCartModalStore = useArticleCartModalStore();
  const apiSettings = useApiSettings();
  const { session } = useShopSessionStore();
  const [displayCurrency, setDisplayCurrency] = useState<Currency>(
    session.currency,
  );

  const [queryParams, setQueryParams] = useQueryParams({
    page: serializers.number({ defaultValue: 1 }),
    searchQuery: serializers.string({ defaultValue: '' }),
    assortmentId: serializers.number(),
    sortingDesc: serializers.boolean({ defaultValue: false }),
    sortingKey: serializers.custom<keyof ArticleListItem>({
      defaultValue: 'articleNumber',
      serialize: (v) => v,
      deserialize: (v) => {
        switch (v as keyof ArticleListItem) {
          case 'articleNumber':
            return 'articleNumber';
          case 'description':
            return 'description';
        }
      },
    }),
    listType: serializers.custom<ArticleListType>({
      defaultValue: ArticleListType.Cards,
      serialize: (v) => v.toString(),
      deserialize: (v) => {
        switch (v) {
          case ArticleListType.Rows.toString():
            return ArticleListType.Rows;
          case ArticleListType.Cards.toString():
            return ArticleListType.Cards;
          default:
            return ArticleListType.Cards;
        }
      },
    }),
  });

  const articles = useApiResource(articlesApi.getArticles, {
    emptyValue: {
      totalItems: 0,
      items: [],
    },
    clearDataOnLoad: false,
  });

  const setPage = useCallback(
    (page: number) => {
      setQueryParams({ ...queryParams, page });
    },
    [queryParams],
  );

  const handleChangeArticleFilters = useCallback(
    (filterValues: ArticleFilterValues) => {
      setQueryParams({
        ...queryParams,
        ...filterValues,
        page: 1,
      });
    },
    [queryParams],
  );

  const handleSelectItem = useCallback(
    (item: ArticleListItem) => {
      articleCartModalStore.showArticle(item.id, displayCurrency);
    },
    [displayCurrency],
  );

  const handleChangeListType = useCallback(
    (listType: ArticleListType) => {
      setQueryParams({
        ...queryParams,
        listType,
      });
    },
    [queryParams],
  );

  const handleSortingChange = useCallback(
    ({ key, desc }: { key: keyof ArticleListItem; desc: boolean }) => {
      setQueryParams({
        ...queryParams,
        sortingKey: key,
        sortingDesc: desc,
        page: 1,
      });
    },
    [queryParams],
  );

  const filterValues = useMemo<ArticleFilterValues>(
    () => ({
      searchQuery: queryParams.searchQuery,
      assortmentId: queryParams.assortmentId,
    }),
    [queryParams.searchQuery, queryParams.assortmentId],
  );

  const exportArticlesCurrentBranchUrl = useMemo<string>(
    () =>
      getExportArticlesUrl(apiSettings, {
        sortBy: queryParams.sortingKey,
        sortDesc: queryParams.sortingDesc,
        filterValues,
        displayCurrencyId: displayCurrency.id,
        includeVariants: false,
        includeAllBranches: false,
      }),
    [
      apiSettings,
      queryParams.sortingKey,
      queryParams.sortingDesc,
      filterValues,
      displayCurrency,
    ],
  );
  const exportArticlesAllBranchesUrl = useMemo<string>(
    () =>
      getExportArticlesUrl(apiSettings, {
        sortBy: queryParams.sortingKey,
        sortDesc: queryParams.sortingDesc,
        filterValues,
        displayCurrencyId: displayCurrency.id,
        includeVariants: false,
        includeAllBranches: true,
      }),
    [
      apiSettings,
      queryParams.sortingKey,
      queryParams.sortingDesc,
      filterValues,
      displayCurrency,
    ],
  );
  const exportArticleVariantsCurrentBranchUrl = useMemo<string>(
    () =>
      getExportArticlesUrl(apiSettings, {
        sortBy: queryParams.sortingKey,
        sortDesc: queryParams.sortingDesc,
        filterValues,
        displayCurrencyId: displayCurrency.id,
        includeVariants: true,
        includeAllBranches: false,
      }),
    [
      apiSettings,
      queryParams.sortingKey,
      queryParams.sortingDesc,
      filterValues,
      displayCurrency,
    ],
  );
  const exportArticleVariantsAllBranchesUrl = useMemo<string>(
    () =>
      getExportArticlesUrl(apiSettings, {
        sortBy: queryParams.sortingKey,
        sortDesc: queryParams.sortingDesc,
        filterValues,
        displayCurrencyId: displayCurrency.id,
        includeVariants: true,
        includeAllBranches: true,
      }),
    [
      apiSettings,
      queryParams.sortingKey,
      queryParams.sortingDesc,
      filterValues,
      displayCurrency,
    ],
  );

  useEffect(() => {
    articles.refresh({
      page: queryParams.page,
      pageSize,
      sortBy: queryParams.sortingKey,
      sortDesc: queryParams.sortingDesc,
      filterValues,
      displayCurrencyId: displayCurrency.id,
    });
  }, [
    queryParams.page,
    queryParams.sortingKey,
    queryParams.sortingDesc,
    filterValues,
    displayCurrency,
  ]);

  return (
    <ListLayout>
      <ListLayout.Sider>
        <ArticleFilters
          values={filterValues}
          onChange={handleChangeArticleFilters}
        />
      </ListLayout.Sider>
      <ListLayout.Content>
        {articles.error && (
          <ListLayout.ContentSection>
            <ApiErrorDetails error={articles.error} />
          </ListLayout.ContentSection>
        )}
        <ArticlesList
          displayCurrency={displayCurrency}
          page={queryParams.page}
          pageSize={pageSize}
          isLoading={articles.isLoading}
          sortingKey={queryParams.sortingKey}
          sortingDesc={queryParams.sortingDesc}
          articles={articles.data.items}
          totalArticles={articles.data.totalItems}
          listType={queryParams.listType}
          exportArticlesCurrentBranchUrl={exportArticlesCurrentBranchUrl}
          exportArticlesAllBranchesUrl={exportArticlesAllBranchesUrl}
          exportArticleVariantsCurrentBranchUrl={
            exportArticleVariantsCurrentBranchUrl
          }
          exportArticleVariantsAllBranchesUrl={
            exportArticleVariantsAllBranchesUrl
          }
          onChangeDisplayCurrency={setDisplayCurrency}
          onPageChange={setPage}
          onChangeListType={handleChangeListType}
          onSortedChange={handleSortingChange}
          onSelectItem={handleSelectItem}
        />
      </ListLayout.Content>
    </ListLayout>
  );
};
