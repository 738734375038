import React, { useEffect } from 'react';
import { Card, Skeleton } from 'antd';
import { messages } from '../../../../messages';
import { useI18n } from '../../../../hooks/useI18n';
import { useApiResource } from '../../../../hooks/useApi';
import { ApiErrorDetails } from '../../../apiErrorDetails/apiErrorDetails';
import styles from './newsCard.module.scss';
import { dashboardApi } from '../../../../api/dashboardApi';

export function NewsCard() {
  const { t, formatDate } = useI18n();

  const dashboardData = useApiResource(dashboardApi.getNews);

  useEffect(() => {
    dashboardData.refresh();
  }, []);

  return (
    <Card title={t(messages.dashboard.news)}>
      {!dashboardData.data && !dashboardData.error && (
        <Skeleton title={false} paragraph={{ rows: 5 }} active={true} />
      )}

      {dashboardData.error && <ApiErrorDetails error={dashboardData.error} />}

      {!!dashboardData.data &&
        dashboardData.data.map((item, i) => (
          <div key={i} className={styles.newsItem}>
            <a href={item.link} target="_blank" rel="noopener noreferrer">
              <div className={styles.newsItemTitle}>{item.title}</div>
              <div className={styles.newsItemDate}>
                {formatDate(item.posted)}
              </div>
            </a>
          </div>
        ))}
    </Card>
  );
}
