import { Select } from 'antd';
import React from 'react';
import { DraftOrderFieldsProps } from '../../../checkoutAdapters/types';
import { useI18n } from '../../../hooks/useI18n';
import { messages } from '../../../messages';
import { OrderType } from '../../../types';
import { OrderMetaField } from '../../form/orderMetaField';
import { SelectField } from '../../form/selectField';
import { TextAreaField } from '../../form/textAreaField';
import { TextField } from '../../form/textField';

export function DraftOrderFields({
  orderTypeOptions,
  order,
  metaFields,
}: DraftOrderFieldsProps) {
  const { t } = useI18n();
  return (
    <>
      {order.type !== OrderType.Variable && (
        <TextField
          name="externalOrderNumber"
          label={t(messages.order.referenceOrderNumber)}
        />
      )}
      <TextField name="contactName" label={t(messages.order.contactName)} />
      <TextField name="contactPhone" label={t(messages.order.contactPhone)} />
      <TextField
        name="courierAccount"
        label={t(messages.order.courierAccount)}
      />
      <TextAreaField
        rows={5}
        name="orderMessage"
        label={t(messages.order.message)}
      />
      <SelectField
        label={t(messages.order.orderType)}
        name="orderType"
        style={{ width: '100%' }}
        optionFilterProp="children"
        dropdownMatchSelectWidth={false}
        disabled={order.type === OrderType.Variable}
      >
        {orderTypeOptions.map((orderTypeOption: any) => (
          <Select.Option key={orderTypeOption.key}>
            {orderTypeOption.label}
          </Select.Option>
        ))}
      </SelectField>
      {metaFields
        ?.sort(
          (field1: any, field2: any) => field1.sortOrder - field2.sortOrder,
        )
        .map((field: any) => (
          <OrderMetaField key={field.id} field={field} formPath="metaFields" />
        ))}
    </>
  );
}
