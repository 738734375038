import { ApiRequest } from '../hooks/useApi';
import { Address, PagedResult } from '../types';

export interface Customer {
  id: number;
  branchErpId: string;
  erpId: string;
  name: string;
}

export interface CustomerDetails {
  id: number;
  branchErpId: string;
  erpId: string;
  name: string;
  telephone: string;
  fax: string;
  address: Address;
  reference: string;
  customerType: string;
  currencyCode: string;
  invoiceCustomerId?: number;
  invoiceCustomerErpId: string;
  invoiceCustomerName: string;
  deliveryTermName: string;
  deliveryMethodName: string;
  widgetConfigurations: WidgetConfiguration[];
  customerGeneralSettings: CustomerGeneralSettings;
}

export interface WidgetConfiguration {
  widgetType: CustomerWidgetType;
  disabled: boolean;
}

export enum CustomerWidgetType {
  ProductStatisticsReport,
  AwaitingDeliveries,
  CustomerOrdersReport,
}

export const translateWidgetType = (type: CustomerWidgetType): string => {
  switch (type) {
    case CustomerWidgetType.ProductStatisticsReport:
      return 'Product statistics report';
    case CustomerWidgetType.AwaitingDeliveries:
      return 'Awaiting deliveries';
    case CustomerWidgetType.CustomerOrdersReport:
      return 'Customer orders report';
    default:
      return 'Widget type missing name';
  }
};

export const internalCustomersApi = {
  getCustomer:
    (id: number): ApiRequest<Customer> =>
    async ({ httpClient, apiSettings }) => {
      const response = await httpClient.get(
        apiSettings.internalCustomerUri(id),
      );

      if (response.hasError) {
        return { error: response.error };
      }

      return {
        result: response.data,
      };
    },

  getCustomerDetails:
    (id: number): ApiRequest<CustomerDetails> =>
    async ({ httpClient, apiSettings }) => {
      const response = await httpClient.get(
        apiSettings.internalCustomerDetailsUri(id),
      );

      if (response.hasError) {
        return { error: response.error };
      }

      return {
        result: response.data,
      };
    },

  getCustomersById:
    (customerIds?: number[]): ApiRequest<Customer[]> =>
    async ({ httpClient, apiSettings }) => {
      const response = await httpClient.get(apiSettings.internalCustomersUri, {
        params: { customerIds },
      });

      if (response.hasError) {
        return { error: response.error };
      }

      return {
        result: response.data,
      };
    },

  getCustomers:
    (
      branchId: number,
      includeAllBranches: boolean,
      query: string,
    ): ApiRequest<Customer[]> =>
    async ({ httpClient, apiSettings }) => {
      const response = await httpClient.get(
        apiSettings.internalCustomersSearchUri,
        {
          params: { query, branchId, includeAllBranches },
        },
      );

      if (response.hasError) {
        return { error: response.error };
      }

      return {
        result: response.data,
      };
    },

  getAllCustomers:
    (
      options: GetAllCustomersOptions,
    ): ApiRequest<PagedResult<CustomerListItem>> =>
    async ({ httpClient, apiSettings }) => {
      const response = await httpClient.get(
        apiSettings.internalSimpleCustomersUri,
        {
          params: {
            branchId: options.branchId,
            includeAllBranches: options.includeAllBranches,
            searchQuery: options.searchQuery,
            limit: options.pageSize,
            offset: (options.page - 1) * options.pageSize,
            pageSize: options.pageSize,
            sortDesc: options.sortDesc,
            sortKey: options.sortKey,
          },
        },
      );

      if (response.hasError) {
        return { error: response.error };
      }

      return {
        result: {
          items: response.data.result,
          totalItems: response.data.total,
        },
      };
    },
  disableWidget:
    (customerId: number, widgetType: CustomerWidgetType): ApiRequest =>
    async ({ httpClient, apiSettings }) => {
      const response = await httpClient.post(
        apiSettings.disableCustomerWidget(customerId, widgetType),
      );

      if (response.hasError) {
        return { error: response.error };
      }
      return {
        result: response.data,
      };
    },
  enableWidget:
    (customerId: number, widgetType: CustomerWidgetType): ApiRequest =>
    async ({ httpClient, apiSettings }) => {
      const response = await httpClient.post(
        apiSettings.enableCustomerWidget(customerId, widgetType),
      );

      if (response.hasError) {
        return { error: response.error };
      }
      return {
        result: response.data,
      };
    },

  saveGeneralSettings:
    (customerId: number, settings: CustomerGeneralSettings): ApiRequest =>
    async ({ httpClient, apiSettings }) => {
      const response = await httpClient.post(
        apiSettings.updateCustomerGeneralSettings(customerId),
        {
          data: settings,
        },
      );

      if (response.hasError) {
        return { error: response.error };
      }
      return {
        result: response.data,
      };
    },
};

export interface CustomerGeneralSettings {
  adminCostOnCheckout: boolean;
}

export interface CustomerListItem {
  id: number;
  name: string;
  erpId: string;
}

interface GetAllCustomersOptions {
  searchQuery: string;
  branchId: number;
  includeAllBranches: boolean;
  page: number;
  pageSize: number;
  sortDesc: boolean;
  sortKey: keyof CustomerListItem;
}
