export const routes = {
  dashboard: '/',
  assortments: '/assortments',
  assortmentsBranch: '/assortments/branch/:branchId',
  assortment: '/assortments/:id',
  users: '/users',
  user: '/users/:id',
  userCustomers: '/userCustomers',
  userCustomersBranch: '/userCustomers/branch/:branchId',
  documents: '/documents',
  notifications: '/notifications',
  updateNotification: '/notifications/:id',
  reports: '/reports',
  branches: '/branches',
  branchesBranch: '/branches/:branchId',
  orders: '/orders',
  ordersBranch: '/orders/branch/:branchId',
  order: '/orders/:id',
  uploadDocument: '/documents/upload',
  createNotification: '/notifications/create',
  sync: '/sync',
  checkoutAdapters: '/checkoutAdapters',
  checkoutAdaptersAdapter: '/checkoutAdapters/:adapterId',
  customers: '/customers',
  customersBranch: '/customers/branch/:branchId',
  customer: '/customers/:id',
  widgets: '/widgets',
};
