import { useState, useEffect, useCallback } from 'react';
import querystringify from 'querystringify';
import { QueryStringSerializationMap } from './types';
import { useLocation, useHistory } from 'react-router-dom';

/*
  Serializes/deserializes values to/from the query string with the help of serializer functions.
    - If the value deserializes to an empty value, an optional default value in the serializer function
      may be used
    - Pre-defined serializers can be found in ./serializers.ts
*/
export function useQueryParams<T>(
  serializationMap: QueryStringSerializationMap<T>,
) {
  const history = useHistory();
  const location = useLocation();

  const [values, setValues] = useState<T>(
    deserializeFromQueryString(history.location.search, serializationMap),
  );

  const updateQueryString = useCallback((newValues: T) => {
    history.replace({
      ...history.location,
      search: serializeToQueryString(newValues, serializationMap),
    });
  }, []);

  useEffect(() => {
    setValues(deserializeFromQueryString(location.search, serializationMap));
  }, [location]);

  return [values, updateQueryString] as [T, (values: T) => void];
}

function serializeToQueryString<T>(
  values: T,
  serializationMap: QueryStringSerializationMap<T>,
) {
  const queryParams: { [key: string]: string | undefined } = {};

  for (const key in serializationMap) {
    if (values[key] !== serializationMap[key].defaultValue) {
      queryParams[key] = serializationMap[key].serialize(values[key] as any);
    }
  }

  return querystringify.stringify({
    ...queryParams,
  });
}

function deserializeFromQueryString<T>(
  queryString: string,
  serializationMap: QueryStringSerializationMap<T>,
) {
  const queryStringMap = querystringify.parse(queryString);
  const values: T = {} as T;

  for (const key in serializationMap) {
    const queryStringValue = queryStringMap[key];

    const value = serializationMap[key].deserialize(
      queryStringValue === null || queryStringValue === ''
        ? undefined
        : queryStringValue,
    );

    if (value !== undefined) {
      values[key] = value;
    } else {
      const defaultValue = serializationMap[key].defaultValue;
      if (defaultValue !== undefined) {
        values[key] = defaultValue;
      }
    }
  }

  return values;
}
