import React, { useState, useRef, useEffect, useMemo } from 'react';
import styles from './checkout.module.scss';
import { Row, Col, Divider, Button, Select, Checkbox, Skeleton } from 'antd';
import { useI18n } from '../../../hooks/useI18n';
import { messages } from '../../../messages';
import {
  CustomerType,
  OrderMetaFieldOption,
  OrderMetaFieldType,
  OrderType,
} from '../../../types';
import { useApiRequests, ApiError } from '../../../hooks/useApi';
import { useCartStore } from '../../../hooks/shop/useCartStore';
import { useShopSessionStore } from '../../../hooks/shop/useShopSessionStore';
import {
  usePlaceOrderForm,
  PlaceOrderForm,
  ShippingAddressType,
} from '../../../hooks/shop/usePlaceOrderForm/usePlaceOrderForm';
import { Vendor } from '../../../api/checkoutApi';
import { ordersApi, PlacedOrder, PlaceOrderData } from '../../../api/ordersApi';
import { ApiErrorDetails } from '../../../components/apiErrorDetails/apiErrorDetails';
import { LoadingIndicator } from '../../../components/loadingIndicator/loadingIndicator';
import { SelectField } from '../../../components/form/selectField';
import { TextField } from '../../../components/form/textField';
import { TextAreaField } from '../../../components/form/textAreaField';
import { AddressSelect } from '../../../components/addressSelect/addressSelect';
import { AddressForm } from '../../../components/addressForm/addressForm';
import { AddressDetails } from '../../../components/addressDetails/addressDetails';
import { CartLineItems } from '../../../components/shop/cartLineItems/cartLineItems';
import { Form } from '../../../components/form/form';
import ScrollToTop from '../../../helpers/scrollToTop';
import { OrderMetaFieldName } from '../types';
import { FormItem } from '../../../components/form/formItem';
import { DeliveryDatePicker } from '../../../components/shop/checkout/deliveryDatePicker/deliveryDatePicker';

const formLayout = {
  labelCol: { span: 10 },
  wrapperCol: { span: 14 },
};

export const Checkout = ({
  onPlacedOrder,
}: {
  onPlacedOrder: (id: number) => void;
}) => {
  const { t } = useI18n();

  const isSubmitting = useRef<boolean>(false);
  const cartStore = useCartStore();
  const { session } = useShopSessionStore();
  const placeOrderRequest = useApiRequests(ordersApi.placeOrder);

  const [placeOrderError, setPlaceOrderError] = useState<ApiError>();
  const [disableDatePicker, setDisableDatePicker] = useState(false);

  const handlePlaceOrderSuccess = (placedOrder: PlacedOrder) => {
    cartStore.refreshItems();
    onPlacedOrder(placedOrder.id);
  };

  const handleFormSubmit = (
    values: PlaceOrderForm,
    requestedDeliveryDate?: string,
  ) => {
    if (isSubmitting.current) {
      return;
    }
    isSubmitting.current = true;

    setPlaceOrderError(undefined);
    const orderData: PlaceOrderData = {
      externalOrderNumber: values.externalOrderNumber,
      orderMessage: values.orderMessage,
      orderType: values.orderType.key,
      contactName: values.contactName,
      contactPhone: values.contactPhone,
      courierAccount: values.courierAccount,
      customOrderAddress: values.customAddress
        ? {
            company: values.customAddress.company,
            address1: values.customAddress.address1,
            address2: values.customAddress.address2,
            postalCode: values.customAddress.postalCode,
            city: values.customAddress.city,
            countryId: values.customAddress.country!.key,
          }
        : undefined,
      vendorCustomerId: values.vendorCustomer
        ? parseInt(values.vendorCustomer.key, 10)
        : undefined,
      existingCustomAddressId: values.existingCustomAddressId,
      metaFieldValues: values.metaFields,
      requestedDeliveryDate: disableDatePicker ? '' : requestedDeliveryDate,
    };

    placeOrderRequest
      .create({
        onSuccess: handlePlaceOrderSuccess,
        onError: setPlaceOrderError,
        onResponse: () => {
          isSubmitting.current = false;
        },
      })
      .send(orderData);
  };

  const placeOrderForm = usePlaceOrderForm({
    standardShippingAddress: session.customerAddress,
    standardInvoiceAddress: session.invoiceCustomerAddress ?? session.customerAddress,
    cartStoreItems: cartStore.items,
    onSubmit: handleFormSubmit,
  });

  const metaFields: {
    [key: string]: OrderMetaFieldType | undefined;
  } = (placeOrderForm.metaFields ?? []).reduce((acc, curr) => {
    return {
      ...acc,
      [curr.name]: curr,
    };
  }, {});

  useEffect(() => {
    cartStore.refreshItems();
  }, []);

  useEffect(() => {
    const formik = placeOrderForm.formik;
    const garmentQuantity = parseInt(
      formik.values.metaFields[
        `${metaFields[OrderMetaFieldName.GarmentQuantity]?.id}`
      ],
    );
    const quantity = parseInt(
      formik.values.metaFields[
        `${metaFields[OrderMetaFieldName.Quantity]?.id}`
      ],
    );

    if (!garmentQuantity && !quantity) return;

    if (!garmentQuantity) {
      formik.setFieldValue(
        `metaFields.${metaFields[OrderMetaFieldName.Quantity]?.id}`,
        undefined,
      );
      return;
    }

    formik.setFieldValue(
      `metaFields.${metaFields[OrderMetaFieldName.Quantity]?.id}`,
      Math.ceil(garmentQuantity * 1.05),
    );
  }, [
    placeOrderForm.formik.values.metaFields[
      `${metaFields[OrderMetaFieldName.GarmentQuantity]?.id}`
    ],
  ]);

  const itemsAreInStock = useMemo(() => {
    const formik = placeOrderForm.formik;
    const quantity = parseInt(
      formik.values.metaFields[
        `${metaFields[OrderMetaFieldName.Quantity]?.id}`
      ],
    );

    if (!quantity) return;

    return cartStore.items.every((i) => {
      return i.stockBalance >= quantity;
    });
  }, [
    placeOrderForm.formik.values.metaFields[
      `${metaFields[OrderMetaFieldName.Quantity]?.id}`
    ],
  ]);

  const anyItemHasMultistock = useMemo(() => {
    return cartStore.items.some((i) => i.hasMultiStock);
  }, [cartStore.items]);

  const filterReflectiveProducts = (option: OrderMetaFieldOption) => {
    const parentOptionId =
      parseInt(
        placeOrderForm.formik.values.metaFields[
          `${metaFields[OrderMetaFieldName.Segment]?.id}`
        ],
      ) || undefined;

    return (
      option.parentOptionId === 0 ||
      (parentOptionId && option.parentOptionId === parentOptionId)
    );
  };

  const sortOptions = (a: OrderMetaFieldOption, b: OrderMetaFieldOption) => {
    if (a.sortOrder === b.sortOrder) {
      return a.value.toLowerCase() < b.value.toLowerCase()
        ? -1
        : a.value.toLowerCase() > b.value.toLowerCase()
        ? 1
        : 0;
    } else {
      return a.sortOrder > b.sortOrder ? -1 : 1;
    }
  };

  useEffect(() => {
    placeOrderForm.formik.setFieldValue(
      `metaFields.${metaFields[OrderMetaFieldName.ReflectiveProduct]?.id}`,
      undefined,
    );
  }, [
    placeOrderForm.formik.values.metaFields[
      `${metaFields[OrderMetaFieldName.Segment]?.id}`
    ],
  ]);

  const reflectiveProducts = useMemo(() => {
    return metaFields[OrderMetaFieldName.ReflectiveProduct]?.options
      .filter(filterReflectiveProducts)
      .sort(sortOptions);
  }, [
    placeOrderForm.formik.values.metaFields[
      `${metaFields[OrderMetaFieldName.Segment]?.id}`
    ],
  ]);

  useEffect(() => {
    setDisableDatePicker(
      (!itemsAreInStock && !session.allowAutomaticOrderOnIncomingStock) ||
      !placeOrderForm.deliveryDatesOptions?.deliveryDateAvailable ||
      anyItemHasMultistock ||
      session.customerType === CustomerType.Internal ||
      !session.allowAutomaticOrder ||
      session.useStockLocations,
    );
  }, [
    itemsAreInStock,
    anyItemHasMultistock,
    placeOrderForm.deliveryDatesOptions?.deliveryDateAvailable,
  ]);

  if (placeOrderForm.error) {
    return <ApiErrorDetails error={placeOrderForm.error} />;
  }

  if (placeOrderForm.isInitializing) {
    return <Skeleton paragraph={{ rows: 4 }} active={true} />;
  }

  const DeliveryDatePickerContainer = ({
    children,
  }: {
    children: React.ReactNode;
  }) =>
    session.customerType === CustomerType.Internal ||
    !session.allowAutomaticOrder ||
    session.useStockLocations ||
    OrderType[placeOrderForm.formik.values.orderType.key] !==
      OrderType[OrderType.Forecast] ? (
      <></>
    ) : (
      <>{children}</>
    );

  return (
    <ScrollToTop>
      <div className={styles.content}>
        {placeOrderError && <ApiErrorDetails error={placeOrderError} />}
        {placeOrderRequest.isLoading && <LoadingIndicator />}
        <Form form={placeOrderForm.formik} layout={formLayout}>
          <Row gutter={36}>
            <Col span={11}>
              <Divider orientation="left">{t(messages.shared.details)}</Divider>
              <TextField
                name="externalOrderNumber"
                label={t(messages.checkoutAdapter._3M.order.poNumber)}
              />
              {metaFields[OrderMetaFieldName.Brand] && (
                <TextField
                  name={`metaFields.${
                    metaFields[OrderMetaFieldName.Brand]?.id
                  }`}
                  label={t(messages.checkoutAdapter._3M.order.brand)}
                ></TextField>
              )}
              {metaFields[OrderMetaFieldName.Factory] && (
                <TextField
                  name={`metaFields.${
                    metaFields[OrderMetaFieldName.Factory]?.id
                  }`}
                  label={t(messages.checkoutAdapter._3M.order.factory)}
                ></TextField>
              )}
              {metaFields[OrderMetaFieldName.Segment] && (
                <SelectField
                  label={t(messages.checkoutAdapter._3M.order.segment)}
                  style={{ width: '100%' }}
                  name={`metaFields.${
                    metaFields[OrderMetaFieldName.Segment]?.id
                  }`}
                  labelInValue={false}
                >
                  {metaFields[OrderMetaFieldName.Segment]?.options
                    .sort(sortOptions)
                    .map((o) => (
                      <Select.Option key={o.id} value={o.id.toString()}>
                        {o.value}
                      </Select.Option>
                    ))}
                </SelectField>
              )}
              {metaFields[OrderMetaFieldName.ReflectiveProduct] && (
                <SelectField
                  label={t(
                    messages.checkoutAdapter._3M.order.reflectiveProduct,
                  )}
                  style={{ width: '100%' }}
                  name={`metaFields.${
                    metaFields[OrderMetaFieldName.ReflectiveProduct]?.id
                  }`}
                  labelInValue={false}
                >
                  {reflectiveProducts?.map((o) => (
                    <Select.Option key={o.id} value={o.id.toString()}>
                      {o.value}
                    </Select.Option>
                  ))}
                </SelectField>
              )}
              <TextField
                name="contactName"
                label={t(messages.order.contactName)}
              />
              <TextField
                name="contactPhone"
                label={t(messages.order.contactPhone)}
              />
              <TextField
                name="courierAccount"
                label={t(messages.order.courierAccount)}
              />
              <TextAreaField
                rows={3}
                name="orderMessage"
                label={t(messages.order.message)}
              />

              <Divider orientation="left">
                {t(messages.address.address)}
              </Divider>
              {session.customerType === CustomerType.Brand && (
                <div className={styles.vendorOptionContainer}>
                  <Checkbox
                    checked={placeOrderForm.vendorOptionIsVisible}
                    onChange={(e) =>
                      placeOrderForm.toggleVendorOption(e.target.checked)
                    }
                  >
                    {t(messages.order.placeAsVendor)}
                  </Checkbox>
                  {placeOrderForm.vendorOptionIsVisible && (
                    <div className={styles.vendorOptionPickerWrapper}>
                      <VendorOptionPicker
                        error={placeOrderForm.vendorsError}
                        vendors={placeOrderForm.vendors}
                      />
                    </div>
                  )}
                </div>
              )}
              <Row gutter={18}>
                <Col span={12}>
                  <div className={styles.addressTitleContainer}>
                    <span className={styles.addressTitle}>
                      {t(messages.address.shipping)}
                    </span>
                    <Divider type="vertical" />
                    <AddressSelect
                      existingCustomAddressId={
                        placeOrderForm.formik.values.existingCustomAddressId
                      }
                      addresses={placeOrderForm.customAddresses}
                      addressesError={placeOrderForm.customAddressesError}
                      loadingAddresses={placeOrderForm.loadingCustomAddresses}
                      loadAddresses={placeOrderForm.loadCustomAddresses}
                      onChangeAddressType={
                        placeOrderForm.changeShippingAddressType
                      }
                      shippingAddressType={placeOrderForm.shippingAddressType}
                    />
                  </div>
                  {placeOrderForm.shippingAddressType ===
                    ShippingAddressType.NewCustom && (
                    <AddressForm formPath="customAddress" />
                  )}
                  {!!placeOrderForm.shippingAddress && (
                    <AddressDetails address={placeOrderForm.shippingAddress} />
                  )}
                </Col>
                <Col span={12}>
                  <div className={styles.addressTitleContainer}>
                    <span className={styles.addressTitle}>
                      {t(messages.address.billing)}
                    </span>
                  </div>
                  <AddressDetails address={placeOrderForm.invoiceAddress} />
                </Col>
              </Row>
            </Col>
            <Col span={13}>
              <Divider orientation="left">
                {t(messages.checkoutAdapter._3M.order.producedGarment)}
              </Divider>
              <div className={styles.placeOrderContainer}>
                {metaFields[OrderMetaFieldName.GarmentType] && (
                  <SelectField
                    label={t(messages.checkoutAdapter._3M.order.garmentType)}
                    style={{ width: '100%' }}
                    name={`metaFields.${
                      metaFields[OrderMetaFieldName.GarmentType]?.id
                    }`}
                    labelInValue={false}
                  >
                    {metaFields[OrderMetaFieldName.GarmentType]?.options
                      .sort(sortOptions)
                      .map((o) => (
                        <Select.Option key={o.id} value={o.id.toString()}>
                          {o.value}
                        </Select.Option>
                      ))}
                  </SelectField>
                )}
                {metaFields[OrderMetaFieldName.GarmentQuantity] && (
                  <TextField
                    name={`metaFields.${
                      metaFields[OrderMetaFieldName.GarmentQuantity]?.id
                    }`}
                    type="number"
                    label={t(
                      messages.checkoutAdapter._3M.order.garmentQuantity,
                    )}
                    min={1}
                  />
                )}
              </div>
              <Divider orientation="left">{t(messages.cart.cart)}</Divider>
              <FormItem label="Product">
                <CartLineItems />
              </FormItem>
              <div className={styles.placeOrderContainer}>
                {metaFields[OrderMetaFieldName.Quantity] && (
                  <TextField
                    name={`metaFields.${
                      metaFields[OrderMetaFieldName.Quantity]?.id
                    }`}
                    type="number"
                    label={t(messages.checkoutAdapter._3M.order.quantity)}
                    min={1}
                  />
                )}
              </div>
              <div className={styles.placeOrderContainer}>
                <DeliveryDatePickerContainer>
                  <Divider orientation="left">
                    {t(messages.order.deliveryDate)}
                  </Divider>
                  <FormItem className={styles.floatRight}>
                    <DeliveryDatePicker
                      nonWorkingDates={
                        placeOrderForm.deliveryDatesOptions?.nonWorkingDates ??
                        []
                      }
                      earliestDate={
                        placeOrderForm.deliveryDatesOptions
                          ?.earliestDeliveryDate
                      }
                      name="requestedDeliveryDate"
                      upcomingDeliveries={
                        placeOrderForm.shippingAddress &&
                        placeOrderForm.deliveryDatesOptions?.upcomingDeliveries
                          ? placeOrderForm.deliveryDatesOptions
                              .upcomingDeliveries
                          : []
                      }
                      showToday={false}
                      disabled={disableDatePicker}
                      disabledTooltip={anyItemHasMultistock ?
                        messages.order.deliveryPickerDisabledTooltipMultiStock :
                        messages.order.deliveryPickerDisabledTooltipOutOfStock
                      }
                    />
                  </FormItem>
                </DeliveryDatePickerContainer>
                <Button
                  type="primary"
                  size="large"
                  block={true}
                  disabled={
                    session.locked ||
                    cartStore.isLoading ||
                    !cartStore.items.length ||
                    !!cartStore.unavailableArticleIds.length
                  }
                  onClick={placeOrderForm.formik.submitForm}
                >
                  {t(messages.order.placeOrder)}
                </Button>
              </div>
            </Col>
          </Row>
        </Form>
      </div>
    </ScrollToTop>
  );
};

function VendorOptionPicker({
  error,
  vendors,
}: {
  error?: ApiError;
  vendors?: Vendor[];
}) {
  if (error) {
    return <ApiErrorDetails error={error} />;
  }

  if (vendors === undefined) {
    return <Skeleton paragraph={false} active={true} />;
  }

  return (
    <SelectField
      name="vendorCustomer"
      showSearch={true}
      style={{ width: '100%' }}
      optionFilterProp="children"
      dropdownMatchSelectWidth={false}
      placeholder="Select vendor"
      className={styles.formInputElement}
    >
      {vendors.map((v) => (
        <Select.Option key={v.id}>{v.name}</Select.Option>
      ))}
    </SelectField>
  );
}
