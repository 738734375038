import React, { useMemo } from 'react';
import { useI18n } from '../../hooks/useI18n';
import { messages } from '../../messages';
import { DeliveryStatus } from '../../types';
import { Select } from 'antd';
import style from './picker.module.scss';

export function DeliveryStatusPicker({
  deliveryStatus,
  onChange,
}: {
  deliveryStatus: number;
  onChange: (selectedDeliveryStatus: number) => void;
}) {
  const { t } = useI18n();

  const deliveryStatusOptions = useMemo(
    () => [
      {
        key: DeliveryStatus.Undelivered,
        label: t(messages.order.awaitingDelivery),
      },
      {
        key: DeliveryStatus.PartiallyDelivered,
        label: t(messages.order.partiallyDelivered),
      },
      {
        key: DeliveryStatus.Delivered,
        label: t(messages.order.delivered),
      },
    ],
    [t],
  );

  return (
    <Select
      className={style.width100}
      value={deliveryStatus}
      onChange={onChange}
      notFoundContent={false}
    >
      {deliveryStatusOptions.map((o) => (
        <Select.Option key={o.key} value={o.key}>
          {o.label}
        </Select.Option>
      ))}
    </Select>
  );
}
