import React, {
  useCallback,
  useMemo,
  useRef,
  useEffect,
  useState,
} from 'react';
import {
  RenderCellContent,
  CellLocation,
  RenderHeaderCellContent,
  BaseCellValue,
} from '../../../../matrixTable/types';
import { Icon, Input, Tooltip } from 'antd';
import { MatrixTable } from '../../../../matrixTable/matrixTable';
import { boxIcon } from '../../../../customIcons/boxIcon';
import { coinsIcon } from '../../../../customIcons/coinsIcon';
import { messages } from '../../../../../messages';
import { getTotalAvailable } from '../../../../../helpers/articleStockTransformer';
import { useI18n } from '../../../../../hooks/useI18n';
import { formatNumber } from '../../../../../helpers/formatNumber/formatNumber';
import {
  MatrixVariantSummary,
  ArticlePrice,
} from '../../../../../api/articlesApi';
import { VariantOrderItem } from '../types';
import styles from './matrixOrderTable.module.scss';
import cc from 'classcat';
import { Currency } from '../../../../../types';
import { getquantityTooltip, quantityIsValid } from '../../helpers';

export interface MatrixArticleCellValue extends BaseCellValue {
  quantity?: number;
  originalQuantity?: number;
  variant: MatrixVariantSummary;
}

export function MatrixOrderTable(
  {
    currencyCode,
    currencyPriceDecimalPlaces,
    items,
    selectedVariant,
    displayCurrency,
    articlePrices,
    draftOrder,
    draftQuantityAdjustmentPercent,
    onSelectItem,
    onUpdateItem,
  }: {
    currencyCode: string;
    currencyPriceDecimalPlaces: number;
    items: VariantOrderItem[];
    selectedVariant?: MatrixVariantSummary;
    displayCurrency?: Currency;
    articlePrices?: ArticlePrice[];
    draftOrder: boolean;
    draftQuantityAdjustmentPercent: number;
    onSelectItem: (item: VariantOrderItem) => void;
    onUpdateItem: (item: VariantOrderItem) => void;
  }) {
  const { t } = useI18n();

  useEffect(() => autoSelectFirstItem(), []);

  function autoSelectFirstItem(): void {
    if (items.length <= 0) return;
    onSelectItem(items[0]);
  }

  const cellSize = {
    width: 120,
    height:
      !displayCurrency || displayCurrency.code === currencyCode ? 75 : 100,
  };

  const cellValues = useMemo(() => {
    return items.map<MatrixArticleCellValue>((item) => {
      return {
        xKey: item.variant.sizeCode,
        yKey: item.variant.colorCode,
        variant: item.variant,
        quantity: item.quantity,
        originalQuantity: item.originalQuantity,
      };
    });
  }, [items]);

  const sortVariantBySizeCode = (a: VariantOrderItem, b: VariantOrderItem) => {
    if (a.variant.sizeCode === undefined) return 1;
    if (b.variant.sizeCode === undefined) return -1;

    const sizeCodeA = a.variant.sizeCode.replace(/\s/g, '');
    const sizeCodeB = b.variant.sizeCode.replace(/\s/g, '');
    return sizeCodeA.localeCompare(sizeCodeB);
  };

  const xKeys = useMemo(() => {
    const sizeCodes = new Set<string>();
    items.sort(sortVariantBySizeCode).forEach((item) => {
      const sizeCode = item.variant.sizeCode;
      if (!sizeCodes.has(sizeCode)) {
        sizeCodes.add(sizeCode);
      }
    });
    return Array.from(sizeCodes);
  }, [items]);

  const sortVariantByColorCode = (a: VariantOrderItem, b: VariantOrderItem) => {
    if (a.variant.colorCode === undefined) return 1;
    if (b.variant.colorCode === undefined) return -1;

    const colorCodeA = a.variant.colorCode.replace(/\s/g, '');
    const colorCodeB = b.variant.colorCode.replace(/\s/g, '');
    return colorCodeA.localeCompare(colorCodeB);
  };

  const yKeys = useMemo(() => {
    const colorCodes = new Set<string>();
    items.sort(sortVariantByColorCode).forEach((item) => {
      const colorCode = item.variant.colorCode;
      if (!colorCodes.has(colorCode)) {
        colorCodes.add(colorCode);
      }
    });
    return Array.from(colorCodes);
  }, [items]);

  const xLabels = useMemo(
    () =>
      items.reduce<Record<string, string>>(
        (values, { variant }) => ({
          ...values,
          [variant.sizeCode]: variant.sizeName,
        }),
        {},
      ),
    [items],
  );

  const yLabels = useMemo(
    () =>
      items.reduce<Record<string, string>>(
        (values, { variant }) => ({
          ...values,
          [variant.colorCode]: variant.colorName,
        }),
        {},
      ),
    [items],
  );

  const selectedCell = useMemo(
    () =>
      selectedVariant
        ? {
          xKey: selectedVariant.sizeCode,
          yKey: selectedVariant.colorCode,
        }
        : undefined,
    [selectedVariant],
  );

  const handleCellSelect = useCallback(({ xKey, yKey }: CellLocation) => {
    const variant = items.find(
      ({ variant }) => variant.sizeCode === xKey && variant.colorCode === yKey,
    );
    if (variant) {
      onSelectItem(variant);
    }
  }, []);

  const renderXHeaderCellContent = useCallback<RenderHeaderCellContent>(
    ({ key }) => xLabels[key],
    [xLabels],
  );

  const renderYHeaderCellContent = useCallback<RenderHeaderCellContent>(
    ({ key }) => (
      <>
        {yLabels[key] !== key && <div>{key}</div>}
        <div>{yLabels[key]}</div>
      </>
    ),
    [yLabels],
  );
  const renderCellContent = useCallback<RenderCellContent<MatrixArticleCellValue>>(
    ({ cellValue }) => {
      if (!cellValue) {
        return <EmptyCell />;
      }

      const displayPrice = articlePrices?.find(
        (articlePrice) => articlePrice.articleId === cellValue.variant.id,
      )?.price;

      return (
        <MatrixArticleCell
          cellValue={cellValue}
          currencyCode={currencyCode}
          currencyPriceDecimalPlaces={currencyPriceDecimalPlaces}
          displayCurrency={displayCurrency}
          displayPrice={displayPrice}
          onChangeQuantity={(quantity) =>
            onUpdateItem({ variant: cellValue.variant, quantity })
          }
          draftOrder={draftOrder}
          draftQuantityAdjustmentPercent={draftQuantityAdjustmentPercent}
        />
      );
    },
    [articlePrices],
  );

  return (
    <MatrixTable
      xAxisLabel={t(messages.article.size)}
      yAxisLabel={t(messages.article.color)}
      cellSize={cellSize}
      cellValues={cellValues}
      xKeys={xKeys}
      yKeys={yKeys}
      selectedCell={selectedCell}
      renderXHeaderCellContent={renderXHeaderCellContent}
      renderYHeaderCellContent={renderYHeaderCellContent}
      renderCellContent={renderCellContent}
      onCellSelect={handleCellSelect}
    />
  );
}

function EmptyCell() {
  return (
    <div className={cc([styles.cell, styles.disabledCell])}>
      <Icon type='minus' />
    </div>
  );
}

function MatrixArticleCell(
  {
    cellValue: { variant, quantity, originalQuantity },
    currencyCode,
    currencyPriceDecimalPlaces,
    displayCurrency,
    displayPrice,
    draftOrder,
    draftQuantityAdjustmentPercent,
    onChangeQuantity,
  }: {
    cellValue: MatrixArticleCellValue;
    currencyCode: string;
    currencyPriceDecimalPlaces: number;
    displayCurrency?: Currency;
    displayPrice?: number;
    draftOrder: boolean;
    draftQuantityAdjustmentPercent: number;
    onChangeQuantity: (quantity: number | undefined) => void;
  }) {
  const { t } = useI18n();
  const ref = useRef<Input>(null);
  const [quantityError, setQuantityError] = useState<boolean>(false);

  const quantityTooltip = useMemo(() => {
    if (originalQuantity)
      return getquantityTooltip(
        originalQuantity,
        draftQuantityAdjustmentPercent,
      );
    return '';
  }, []);

  useEffect(() => {
    if (
      draftOrder &&
      quantity !== undefined &&
      originalQuantity !== undefined
    ) {
      setQuantityError(
        quantityIsValid(
          quantity,
          originalQuantity,
          draftQuantityAdjustmentPercent,
        )
          ? false
          : true,
      );
    }
  }, [quantity]);

  const inputQuantity = () => {
    return (
      <Input
        ref={ref}
        size='small'
        className={
          quantityError
            ? styles.quantityError + ' ' + styles.cellInput
            : styles.cellInput
        }
        value={quantity}
        type='number'
        onChange={(e) => {
          const value = parseInt(e.target.value, 10);
          if (Number.isInteger(value)) {
            onChangeQuantity(value);
          } else {
            onChangeQuantity(undefined);
          }
        }}
        disabled={
          draftOrder &&
          (originalQuantity === undefined || originalQuantity === 0)
        }
      />
    );
  };

  function quantityInputTooltip() {
    if (draftOrder && originalQuantity) {
      return <Tooltip title={quantityTooltip}>{inputQuantity()}</Tooltip>;
    } else {
      return inputQuantity();
    }
  }

  if (variant.frozen) {
    return (
      <div className={cc([styles.cell, styles.disabledCell])}>
        <div>
          <Icon type='lock' />
        </div>
        <div>{t(messages.article.frozen)}</div>
      </div>
    );
  }

  return (
    <div
      className={styles.cell}
      onClick={(e) => {
        e.preventDefault();
        ref.current!.focus();
      }}
    >
      <div className={styles.cellItem}>{quantityInputTooltip()}</div>
      <div className={styles.cellItem}>
        <Icon className={styles.cellItemIcon} component={boxIcon} />
        <span className={styles.cellItemLabel}>
          {getTotalAvailable(variant.stockLocations).toFixed(
            variant.quantityDecimals,
          )}
        </span>
      </div>
      <div className={styles.cellItem}>
        <Icon className={styles.cellItemIcon} component={coinsIcon} />
        <span className={styles.cellItemLabel}>
          {formatNumber(variant.displayPrice, currencyPriceDecimalPlaces)}
        </span>
        <span className={styles.cellItemLabel}>{currencyCode}</span>
      </div>
      {!!displayCurrency &&
      displayCurrency.code !== currencyCode &&
      !!displayPrice && (
        <div className={styles.cellItem}>
          <Icon className={styles.cellItemIcon} component={coinsIcon} />
          <span className={styles.cellItemLabel}>
              {formatNumber(displayPrice, displayCurrency.priceDecimalPlaces)}
            </span>
          <span className={styles.cellItemLabel}>{displayCurrency.code}</span>
        </div>
      )}
    </div>
  );
}
