import React, { useState, useEffect } from 'react';
import { Address } from '../../types';
import {
  ShippingAddressType,
  ChangeShippingAddressOption,
} from '../../hooks/shop/usePlaceOrderForm/usePlaceOrderForm';
import { Select, Modal, Radio } from 'antd';
import { LoadingIndicator } from '../loadingIndicator/loadingIndicator';
import styles from './addressSelect.module.scss';
import { AddressDetails } from '../addressDetails/addressDetails';
import { useI18n } from '../../hooks/useI18n';
import { messages } from '../../messages';
import { EmptyIndicator } from '../emptyIndicator/emptyIndicator';
import { ApiError } from '../../hooks/useApi';
import { ApiErrorDetails } from '../apiErrorDetails/apiErrorDetails';

export function AddressSelect({
  disabled,
  existingCustomAddressId,
  addressesError,
  addresses,
  loadingAddresses,
  loadAddresses,
  onChangeAddressType,
  shippingAddressType,
}: {
  disabled?: boolean;
  existingCustomAddressId?: number;
  addresses: Address[] | undefined;
  addressesError?: ApiError;
  loadingAddresses: boolean;
  loadAddresses: () => void;
  onChangeAddressType: (option: ChangeShippingAddressOption) => void;
  shippingAddressType: ShippingAddressType;
}) {
  const { t } = useI18n();
  const [adressModalIsVisible, setAdressModalIsVisible] = useState<boolean>();

  const handleSelectChange = (type: ShippingAddressType) => {
    if (type === ShippingAddressType.Standard) {
      onChangeAddressType({ type: ShippingAddressType.Standard });
    }

    if (type === ShippingAddressType.NewCustom) {
      onChangeAddressType({ type: ShippingAddressType.NewCustom });
    }

    if (type === ShippingAddressType.ExistingCustom) {
      setAdressModalIsVisible(true);
      loadAddresses();
    }
  };

  return (
    <>
      <Select<ShippingAddressType>
        disabled={disabled}
        style={{ flexGrow: 1 }}
        value={shippingAddressType}
        onSelect={handleSelectChange}
        allowClear={false}
      >
        <Select.Option value={ShippingAddressType.Standard}>
          {t(messages.address.shippingTypeStandard)}
        </Select.Option>
        <Select.Option value={ShippingAddressType.ExistingCustom}>
          {t(messages.address.shippingTypeExistingCustom)}
        </Select.Option>
        <Select.Option value={ShippingAddressType.NewCustom}>
          {t(messages.address.shippingTypeNewCustom)}
        </Select.Option>
      </Select>
      {adressModalIsVisible && (
        <AddressModal
          existingCustomAddressId={existingCustomAddressId}
          addressesError={addressesError}
          addresses={addresses}
          loadingAddresses={loadingAddresses}
          onOk={(id) => {
            setAdressModalIsVisible(false);
            onChangeAddressType({
              type: ShippingAddressType.ExistingCustom,
              existingCustomAddressId: id,
            });
          }}
          onClose={() => setAdressModalIsVisible(false)}
        />
      )}
    </>
  );
}

function AddressModal({
  existingCustomAddressId,
  addressesError,
  addresses,
  loadingAddresses,
  onOk,
  onClose,
}: {
  existingCustomAddressId?: number;
  addressesError?: ApiError;
  addresses: Address[] | undefined;
  loadingAddresses: boolean;
  onOk: (id: number) => void;
  onClose: () => void;
}) {
  const { t } = useI18n();
  const [selectedAddressId, setSelectedAddressId] = useState(
    existingCustomAddressId,
  );

  useEffect(() => {
    if (addresses?.length && !selectedAddressId) {
      setSelectedAddressId(addresses[0].id);
    }
  }, [addresses, selectedAddressId]);

  return (
    <Modal
      bodyStyle={{
        maxHeight: '500px',
        overflowY: 'auto',
        position: 'relative',
      }}
      centered={true}
      visible={true}
      closable={false}
      onCancel={onClose}
      okButtonProps={{ disabled: !selectedAddressId }}
      onOk={() => {
        if (selectedAddressId) {
          onOk(selectedAddressId);
        }
      }}
    >
      {loadingAddresses && <LoadingIndicator />}
      {addressesError && <ApiErrorDetails error={addressesError} />}
      {addresses?.length === 0 && (
        <EmptyIndicator description={t(messages.shared.noItems)} />
      )}
      {!!addresses?.length && (
        <div>
          <h2>{t(messages.order.selectAddress)}</h2>
          <Radio.Group
            className={styles.addressRadioGroup}
            value={selectedAddressId}
            onChange={(e) => {
              setSelectedAddressId(e.target.value);
            }}
          >
            {addresses.map((address) => (
              <Radio
                value={address.id}
                key={address.id}
                className={styles.addressRadio}
              >
                <div className={styles.addressRadioValue}>
                  <AddressDetails address={address} />
                </div>
              </Radio>
            ))}
          </Radio.Group>
        </div>
      )}
    </Modal>
  );
}
