import React, { useEffect } from 'react';
import { Skeleton, Icon, Alert, Row, Col, Card } from 'antd';
import { useI18n } from '../../../hooks/useI18n';
import { messages } from '../../../messages';
import { ApiErrorDetails } from '../../apiErrorDetails/apiErrorDetails';
import { useApiResource } from '../../../hooks/useApi';
import { OrderType } from '../../../types';
import { useQueryParams, serializers } from '../../../hooks/useQueryParams';
import { OrderProgressBadge } from '../../orderProgressBadge/orderProgressBadge';
import { DescriptionList } from '../../descriptionList/descriptionList';
import { orderTypeTranslator } from '../../../helpers/orderTypeTranslator';
import { AddressDetails } from '../../addressDetails/addressDetails';
import { OrderRows } from './orderRows/orderRows';
import { formatNumber } from '../../../helpers/formatNumber/formatNumber';
import { DeliveryNoteRows } from './deliveryNoteRows/deliveryNoteRows';
import styles from './order.module.scss';
import { ordersApi } from '../../../api/ordersApi';
import { useApiSettings } from '../../../hooks/useApi/useApiSettings';
import { useShopSessionStore } from '../../../hooks/shop/useShopSessionStore';

export function Order({ id, onGoBack }: { id: number; onGoBack: () => void }) {
  const { t, formatDate } = useI18n();

  const order = useApiResource(ordersApi.getOrder);

  const { orderConfirmationUri, deliveryNoteUri } = useApiSettings();

  const { session } = useShopSessionStore();

  const [queryParams] = useQueryParams({
    deliveryNoteId: serializers.number(),
    isNew: serializers.boolean({ defaultValue: false }),
  });

  useEffect(() => {
    order.refresh(id);
  }, [id]);

  return (
    <div className={styles.page}>
      <div className={styles.navigation} onClick={onGoBack}>
        <Icon type="left" /> {t(messages.order.orders)}
      </div>
      {!order.data && !order.error && (
        <Skeleton paragraph={{ rows: 15 }} active={true} />
      )}

      {order.error && <ApiErrorDetails error={order.error} />}

      {!!order.data && (
        <>
          {queryParams.isNew && (
            <Alert
              className={styles.messageAlert}
              message={t(messages.order.orderPlacedTitle)}
              description={t(messages.order.orderPlacedDescription)}
              type="success"
              showIcon={true}
              closable={true}
            />
          )}
          <div className={styles.header}>
            <div className={styles.headerTitle}>
              <span>{order.data.orderNumber}</span>
              <OrderProgressBadge
                orderStatus={order.data.status}
                deliveryStatus={order.data.deliveryStatus}
              />
            </div>
          </div>
          <Row gutter={15}>
            <Col span={8}>
              <Card
                className={styles.contentBlock}
                title={t(messages.shared.details)}
              >
                <DescriptionList>
                  <DescriptionList.Item title={t(messages.order.orderNumber)}>
                    {order.data.orderNumber}
                  </DescriptionList.Item>
                  {order.data.type !== OrderType.Variable && (
                    <DescriptionList.Item
                      title={t(messages.order.referenceOrderNumber)}
                    >
                      {order.data.externalOrderNumber}
                    </DescriptionList.Item>
                  )}
                  <DescriptionList.Item title={t(messages.order.orderDate)}>
                    {formatDate(order.data.orderDate)}
                  </DescriptionList.Item>
                  <DescriptionList.Item
                    title={t(messages.order.confirmedDeliveryDate)}
                  >
                    {order.data.deliveryDate
                      ? formatDate(order.data.deliveryDate)
                      : order.data.deliveryDateNote}
                  </DescriptionList.Item>
                  <DescriptionList.Item title={t(messages.shared.type)}>
                    {orderTypeTranslator(t, order.data.type)}
                  </DescriptionList.Item>
                  {order.data.metaFields
                    .sort(
                      (field1, field2) => field1.sortOrder - field2.sortOrder,
                    )
                    .map((field) => (
                      <DescriptionList.Item key={field.id} title={field.name}>
                        {field.value}
                      </DescriptionList.Item>
                    ))}
                  {order.data.season && (
                    <DescriptionList.Item title={t(messages.order.season)}>
                      {order.data.season}
                    </DescriptionList.Item>
                  )}
                  <DescriptionList.Divider />
                  <DescriptionList.Item title={t(messages.shared.customer)}>
                    {order.data.customerName}
                  </DescriptionList.Item>
                  <DescriptionList.Item title={t(messages.order.billedTo)}>
                    {order.data.invoiceCustomerName}
                  </DescriptionList.Item>
                  <DescriptionList.Item title={t(messages.shared.project)}>
                    {order.data.projectName}
                  </DescriptionList.Item>
                  <DescriptionList.Item title={t(messages.order.reference)}>
                    {order.data.externalReference}
                  </DescriptionList.Item>
                  <DescriptionList.Item title={t(messages.order.paymentTerms)}>
                    {order.data.paymentTermName}
                  </DescriptionList.Item>
                  <DescriptionList.Item
                    title={t(messages.order.deliveryMethod)}
                  >
                    {order.data.deliveryMethodName}
                  </DescriptionList.Item>
                  <DescriptionList.Item title={t(messages.order.deliveryTerms)}>
                    {order.data.deliveryTermName}
                  </DescriptionList.Item>
                  <DescriptionList.Item title="Download confirmation">
                    <a href={orderConfirmationUri(order.data.id)}>Download</a>
                  </DescriptionList.Item>
                </DescriptionList>
              </Card>
              {order.data.careLabelPos.length > 0 && (
                <Card
                  className={styles.contentBlock}
                  title={t(messages.order.careLabelPos)}
                >
                  <ul className={styles.careLabelPoList}>
                    {order.data.careLabelPos.map((careLabelPo) => (
                      <li key={careLabelPo.po}>
                        <a
                          href={careLabelPo.uri}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {careLabelPo.po}
                        </a>
                      </li>
                    ))}
                  </ul>
                </Card>
              )}
              <Card
                className={styles.contentBlock}
                title={t(messages.order.message)}
              >
                <div className={styles.orderMessage}>
                  {order.data.orderMessage?.length
                    ? order.data.orderMessage
                    : t(messages.order.noMessage)}
                </div>
              </Card>
              <Card
                className={styles.contentBlock}
                title={t(messages.address.addresses)}
              >
                {order.data.type !== OrderType.Forecast && (
                  <div className={styles.contentBlock}>
                    <h3>{t(messages.address.shipping)}</h3>
                    <AddressDetails address={order.data.shippingAddress} />
                  </div>
                )}
                <div className={styles.contentBlock}>
                  <h3>{t(messages.address.billing)}</h3>
                  <AddressDetails address={order.data.invoiceCustomerAddress} />
                </div>
              </Card>
            </Col>
            <Col span={16}>
              <Card
                title={t(messages.article.articles)}
                className={styles.contentBlock}
              >
                <OrderRows
                  rows={order.data.rows}
                  currencyCode={order.data.currencyCode}
                  currencyPriceDecimalPlaces={
                    order.data.currencyPriceDecimalPlaces
                  }
                />
                <Row>
                  <Col span={12} offset={12}>
                    <div className={styles.summary}>
                      <div className={styles.totalPriceLabel}>
                        {t(messages.shared.totalPrice)}
                      </div>
                      <div>
                        {`${formatNumber(order.data.orderSum, 2)}${
                          order.data.currencyCode
                            ? ` (${order.data.currencyCode})`
                            : ''
                        }`}
                      </div>
                    </div>
                  </Col>
                </Row>
              </Card>
              {order.data.deliveryNotes.map((deliveryNote) => (
                <div key={deliveryNote.id} className={styles.contentBlock}>
                  <Card
                    title={`${t(messages.order.deliveryNote)} #${
                      deliveryNote.erpId
                    }`}
                  >
                    <Row>
                      <Col>
                        <DescriptionList>
                          <DescriptionList.Item
                            title={t(messages.order.deliveryDate)}
                          >
                            {deliveryNote.deliveryDate
                              ? formatDate(deliveryNote.deliveryDate)
                              : deliveryNote.deliveryDateNote}
                          </DescriptionList.Item>
                          {deliveryNote.trackingNumber && (
                            <DescriptionList.Item
                              title={t(messages.order.trackingNumber)}
                            >
                              {deliveryNote.trackingNumber}
                            </DescriptionList.Item>
                          )}
                          {deliveryNote.invoiceNumber && (
                            <DescriptionList.Item
                              title={t(messages.order.invoiceNumber)}
                            >
                              {deliveryNote.invoiceNumber}
                            </DescriptionList.Item>
                          )}
                          {!session.branchDeliveryNotePdfDisabled ? (
                            <DescriptionList.Item title="Download delivery note">
                              <a
                                href={deliveryNoteUri(
                                  order.data!.id,
                                  deliveryNote.id,
                                )}
                              >
                                Download
                              </a>
                            </DescriptionList.Item>
                          ) : undefined}
                          <DescriptionList.Divider />
                        </DescriptionList>
                      </Col>
                    </Row>
                    <DeliveryNoteRows rows={deliveryNote.rows} />
                  </Card>
                </div>
              ))}
            </Col>
          </Row>
        </>
      )}
    </div>
  );
}
