import { Locale } from '../hooks/useI18n';

export function localeTranslator(type: Locale) {
  switch (type) {
    case Locale.En:
      return 'English';
    case Locale.ZhCn:
      return '中文';
    default:
      return 'Unkown';
  }
}
