import React, { useEffect } from 'react';
import { useArticleCartModalStore } from '../../../hooks/shop/useArticleCartModalStore';
import { useCartStore } from '../../../hooks/shop/useCartStore';
import { messages } from '../../../messages';
import { useI18n } from '../../../hooks/useI18n';
import { useShopSessionStore } from '../../../hooks/shop/useShopSessionStore';

export function ArticleCartModal() {
  const articleCartModalStore = useArticleCartModalStore();
  const cartStore = useCartStore();
  const { t } = useI18n();
  const {
    checkoutAdapter: { ArticleModal },
  } = useShopSessionStore();

  useEffect(() => {
    const { articleId } = articleCartModalStore;
    if (!articleId) {
      return;
    }

    const unsubscribeFn = cartStore.articleUpdateEvent.subscribe(
      (updatedArticleId) => {
        if (articleId === updatedArticleId) {
          articleCartModalStore.hideArticle();
        }
      },
    );

    return () => {
      unsubscribeFn();
    };
  }, [articleCartModalStore.articleId]);

  if (!articleCartModalStore.articleId) return null;

  const lineItem = cartStore.items.find(
    (i) => i.articleId === articleCartModalStore.articleId,
  );

  return (
    <ArticleModal
      articleId={articleCartModalStore.articleId}
      displayCurrency={articleCartModalStore.displayCurrency}
      lineItemMessage={t(messages.cart.updateCart)}
      lineItem={lineItem}
      onClose={articleCartModalStore.hideArticle}
      draftOrder={false}
      isUpdatingLineItem={cartStore.isLoading}
      onUpdateLineItem={(updatedLineItem) =>
        cartStore.updateLineItem(updatedLineItem)
      }
      onChangeDisplayCurrency={articleCartModalStore.setDisplayCurrency}
    />
  );
}
