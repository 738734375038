import { ApiRequest } from '../../../hooks/useApi';
import { OrderType, TimePeriodType } from '../../../types';

export const customerCustomerOrdersReportWidgetApi = {
  createWidget:
    (request: CreateUpdateCustomerOrdersReportWidgetRequest): ApiRequest =>
    async ({ httpClient, apiSettings }) => {
      const response = await httpClient.post(
        apiSettings.customerCustomerOrdersReportWidgetsUri,
        {
          data: request,
        },
      );

      if (response.hasError) {
        return { error: response.error };
      }
      return {
        result: response.data,
      };
    },
  updateWidget:
    (
      id: number,
      request: CreateUpdateCustomerOrdersReportWidgetRequest,
    ): ApiRequest =>
    async ({ httpClient, apiSettings }) => {
      const response = await httpClient.put(
        apiSettings.customerCustomerOrdersReportWidgetUri(id),
        {
          data: request,
        },
      );

      if (response.hasError) {
        return { error: response.error };
      }
      return {
        result: response.data,
      };
    },
  getWidget:
    (id: number): ApiRequest<CustomerOrdersReportWidget> =>
    async ({ httpClient, apiSettings }) => {
      const response = await httpClient.get(
        apiSettings.customerCustomerOrdersReportWidgetUri(id),
      );

      if (response.hasError) {
        return { error: response.error };
      }
      return {
        result: response.data,
      };
    },
  triggerNewReport:
    (widgetId: number): ApiRequest<void> =>
    async ({ httpClient, apiSettings }) => {
      const response = await httpClient.post(
        apiSettings.triggerNewCustomerCustomerOrdersReportWidgetUri(widgetId),
      );

      if (response.hasError) {
        return { error: response.error };
      }
      return {
        result: response.data,
      };
    },
};

export interface CustomerOrdersReportWidget {
  name: string;
  widgetCategoryId: number;
  color: string | undefined;
  branches: Branch[];
  categoryIds: number[];
  orderType: OrderType;
  deliveryStatus: number;
  timePeriods: string[];
  timePeriodType: TimePeriodType;
  mostRecentSuccessfullyGeneratedReport: CustomerOrdersReport | undefined;
  existsPendingReport: boolean;
  lastGeneratedReportFailed: boolean;
}

export interface CreateUpdateCustomerOrdersReportWidgetRequest {
  name: string;
  widgetCategoryId: number | undefined;
  color: string | undefined;
  categoryIds: number[];
  orderType: OrderType;
  deliveryStatus: number;
  timePeriods: string[];
  timePeriodType: TimePeriodType;
}

export interface Branch {
  id: number;
  name: string;
}

export interface CustomerOrdersReport {
  id: string;
  createdDate: string;
  status: ReportStatus;
}

export enum ReportStatus {
  Pending,
  Finished,
  Failed,
}
