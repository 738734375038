import React from 'react';
import { Spin, Select } from 'antd';
import { useI18n } from '../../../../hooks/useI18n';
import { useShopSessionStore } from '../../../../hooks/shop/useShopSessionStore';
import { messages } from '../../../../messages';
import { Currency } from '../../../../types';
import { LabeledValue } from 'antd/lib/select';

export function CurrencyPicker({
  currency,
  onChange,
}: {
  currency: Currency;
  onChange: (currency: Currency) => void;
}) {
  const { t } = useI18n();
  const shopSessionStore = useShopSessionStore();

  if (!shopSessionStore.currencies || shopSessionStore.isLoadingCurrencies) {
    return <Spin />;
  }

  return (
    <Select<LabeledValue>
      labelInValue={true}
      value={{ key: currency.id.toString(), label: currency.code }}
      dropdownMatchSelectWidth={false}
      optionLabelProp="title"
      style={{ width: '80px' }}
      onChange={({ key }) => {
        if (shopSessionStore.currencies) {
          const currency = shopSessionStore.currencies.find(
            (c) => c.id.toString() === key,
          );
          if (currency) {
            onChange(currency);
          }
        }
      }}
    >
      {shopSessionStore.currencies.map((c) => (
        <Select.Option key={c.id} title={c.code}>
          {c.code}{' '}
          {c.id === shopSessionStore.session.currency.id &&
            ` (${t(messages.shared.orderCurrency)})`}
        </Select.Option>
      ))}
    </Select>
  );
}
