import React from 'react';
import { Select } from 'antd';
import { useI18n } from '../../../../hooks/useI18n';
import { messages } from '../../../../messages';
import { orderProgressTranslator } from '../../../../helpers/orderProgressTranslator';
import { ordersApi } from '../../../../api/ordersApi';

export function OrderProgressPicker({
  onChange,
  orderProgressIds,
}: {
  onChange: (selectedOrderProgresses: number[]) => void;
  orderProgressIds: number[];
}) {
  const { t } = useI18n();
  const { Option } = Select;

  const orderProgresses = ordersApi.getOrderProgresses();

  return (
    <Select
      style={{ width: '100%' }}
      onChange={onChange}
      mode="multiple"
      value={orderProgressIds}
      placeholder={t(messages.order.progresses)}
      showArrow={true}
      allowClear={true}
    >
      {orderProgresses.map((op) => (
        <Option key={op} value={op}>
          {orderProgressTranslator(t, op)}
        </Option>
      ))}
    </Select>
  );
}
