import React, { useEffect } from 'react';
import { Select } from 'antd';
import { customerBranchesApi } from '../../api/customerBranchesApi';
import { useApiResource } from '../../hooks/useApi';

export function CustomerBranchPicker({
  onChange,
  branchIds,
}: {
  onChange: (selectedBranchIds: number[]) => void;
  branchIds: number[];
}) {
  const { Option } = Select;

  const branches = useApiResource(customerBranchesApi.getBranches);

  useEffect(() => {
    branches.refresh();
  }, []);

  return (
    <Select
      style={{ width: '100%' }}
      onChange={onChange}
      mode="multiple"
      value={branchIds}
      showArrow={true}
      allowClear={true}
    >
      {branches.data &&
        branches.data.map((b) => (
          <Option key={b.id} value={b.id}>
            {b.name}
          </Option>
        ))}
    </Select>
  );
}
