import React, { useMemo } from 'react';
import { Button, Alert, Icon } from 'antd';
import { useI18n, TranslateFunction } from '../../../../../hooks/useI18n';
import { useShopSessionStore } from '../../../../../hooks/shop/useShopSessionStore';
import { messages } from '../../../../../messages';
import { Table, Columns } from '../../../../table';
import { getTotalPrice } from '../../../../../helpers/priceCalculator';
import { VariantOrderItem } from '../types';
import { formatNumber } from '../../../../../helpers/formatNumber/formatNumber';
import Decimal from 'decimal.js';
import styles from './matrixOrderSummary.module.scss';

const getColumns = (
  t: TranslateFunction,
  totalPriceByVariantId: { [variantId: string]: number },
  onRemoveItem?: (item: VariantOrderItem) => void,
): Columns<VariantOrderItem> => [
  {
    header: t(messages.article.color),
    cell: ({ row }) => row.variant.colorName,
  },
  {
    header: t(messages.article.size),
    cell: ({ row }) => row.variant.sizeName,
  },
  {
    header: t(messages.shared.quantity),
    accessor: 'quantity',
  },
  {
    header: t(messages.shared.totalPrice),
    cell: ({ row }) => formatNumber(totalPriceByVariantId[row.variant.id], 2),
  },
  {
    header: '',
    width: 45,
    cell: ({ row }) => {
      return (
        <div
          className={styles.actionsCell}
          onClick={(e) => e.stopPropagation()}
        >
          {onRemoveItem && (
            <Button
              shape="circle"
              icon="delete"
              size="small"
              onClick={() => onRemoveItem(row)}
            />
          )}
        </div>
      );
    },
  },
];

export function MatrixOrderSummary({
  items,
  currencyCode,
  lineItemQuantityError,
  onRemoveItem,
  onUpdateCart,
}: {
  items: VariantOrderItem[];
  currencyCode: string;
  lineItemQuantityError?: string;
  onRemoveItem?: (item: VariantOrderItem) => void;
  onUpdateCart: () => void;
}) {
  const { t } = useI18n();
  const { session } = useShopSessionStore();

  const totalPriceByVariantId = useMemo(() => {
    const prices: Record<string, number> = {};

    for (const { variant, quantity } of items) {
      prices[variant.id] = getTotalPrice(variant.displayPrice, quantity ?? 0);
    }

    return prices;
  }, [items]);

  const totalPrice = useMemo(() => {
    let price = new Decimal(0);

    for (const variantId in totalPriceByVariantId) {
      price = price.add(totalPriceByVariantId[variantId]);
    }

    return price.toNumber();
  }, [totalPriceByVariantId]);

  const totalQuantity = useMemo(() => {
    let total = 0;
    items.forEach((orderedCellValue) => {
      total = total + orderedCellValue.quantity!;
    });
    return total;
  }, [items]);

  return (
    <div className={styles.container}>
      <div className={styles.updateCartContainer}>
        {lineItemQuantityError && (
          <Alert
            icon={<Icon type="warning" />}
            message={lineItemQuantityError}
            showIcon={true}
            type="error"
          />
        )}
        <div className={styles.totalSummary}>
          <div className={styles.totalEntry}>
            <div className={styles.totalEntryLabel}>
              {t(messages.shared.totalQuantity)}
            </div>
            <div className={styles.totalEntryValue}>{totalQuantity}</div>
          </div>
          <div className={styles.totalEntry}>
            <div className={styles.totalEntryLabel}>
              {`${t(messages.shared.totalPrice)} (${currencyCode})`}
            </div>
            <div className={styles.totalEntryValue}>
              {formatNumber(totalPrice, 2)}
            </div>
          </div>
        </div>
        <Button
          size="large"
          type="primary"
          block={true}
          onClick={onUpdateCart}
          className={styles.updateCartButton}
          disabled={session.locked}
        >
          {t(messages.shared.update)}
        </Button>
      </div>
      <div className={styles.rows}>
        <Table
          items={items}
          columns={getColumns(t, totalPriceByVariantId, onRemoveItem)}
        />
      </div>
    </div>
  );
}
