import React from 'react';
import { useField } from 'formik';
import TextArea, { TextAreaProps } from 'antd/lib/input/TextArea';
import { FormItem } from './formItem';

export function TextAreaField({
  name,
  label,
  ...rest
}: { name: string; label?: string } & TextAreaProps) {
  const [inputProps, metaProps] = useField(name);
  const showError = metaProps.touched && metaProps.error;

  return (
    <FormItem
      label={label}
      validateStatus={showError ? 'error' : undefined}
      help={showError ? <li>{metaProps.error}</li> : undefined}
    >
      <TextArea {...rest} {...inputProps} />
    </FormItem>
  );
}
