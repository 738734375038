import {
  Address,
  UpdateLineItem,
  OrderType,
  LineItem,
  OrderCareLabelPo,
} from '../types';
import { ApiRequest } from '../hooks/useApi';

export const draftOrderApi = {
  getDraftOrdersCount: (): ApiRequest<number> => async ({
    httpClient,
    apiSettings,
  }) => {
    const response = await httpClient.get(apiSettings.draftOrdersCountUri);

    if (response.hasError) {
      return { error: response.error };
    } else {
      return { result: response.data };
    }
  },

  getDraftOrder: (id: number): ApiRequest<DraftOrderDetails> => async ({
    httpClient,
    apiSettings,
  }) => {
    const response = await httpClient.get(apiSettings.draftOrderUri(id));

    if (response.hasError) {
      return { error: response.error };
    } else {
      return { result: response.data };
    }
  },

  placeDraftOrder: (
    id: number,
    data: PlaceDraftOrderData,
  ): ApiRequest => async ({ httpClient, apiSettings }) => {
    const response = await httpClient.put(apiSettings.draftOrderUri(id), {
      data,
    });

    if (response.hasError) {
      return { error: response.error };
    } else {
      return {
        result: undefined,
      };
    }
  },

  updateLineItem: (
    orderId: number,
    lineItem: UpdateLineItem,
  ): ApiRequest<UpdateDraftLineItemResponse> => async ({
    httpClient,
    apiSettings,
  }) => {
    const response = await httpClient.post(
      apiSettings.draftOrderLineItemsUri(orderId),
      {
        data: lineItem,
      },
    );

    if (response.hasError) {
      return { error: response.error };
    } else {
      return {
        result: {
          ...response.data,
          quantityError: response.headers.quantityvalidationerror,
        },
      };
    }
  },
};

export interface PlaceDraftOrderData {
  externalOrderNumber?: string;
  contactName?: string;
  contactPhone?: string;
  courierAccount?: string;
  orderMessage?: string;
  orderType: OrderType;
  customOrderAddress?: PlaceDraftOrderCustomAddress;
  existingCustomAddressId?: number;
  metaFieldValues?: { [key: number]: string };
  requestedDeliveryDate?: string;
}

export interface PlaceDraftOrderCustomAddress {
  company: string;
  address1: string;
  address2: string;
  postalCode: string;
  city: string;
  countryId: number;
}

export interface DraftOrderDetails {
  id: number;
  orderNumber: string;
  orderDate: string;
  type: OrderType;
  externalOrderNumber: string;
  externalReference: string;
  reference: string;
  shippingAddress: Address;
  paymentTermName: string;
  deliveryTermName: string;
  deliveryMethodName: string;
  customerId: number;
  customerErpId: string;
  customerName: string;
  projectName: string;
  invoiceCustomerId: number;
  invoiceCustomerErpId: string;
  invoiceCustomerName: string;
  invoiceCustomerAddress: Address;
  currencyCode: string;
  orderMessage: string;
  careLabelPos: OrderCareLabelPo[];
  rows: LineItem[];
}

export interface UpdateDraftLineItemResponse {
  quantityError: string;
}
