import querystringify from 'querystringify';
import { ArticleListItem, ArticleFilterValues } from '../../../api/articlesApi';
import { ApiSettings } from '../../../api/apiSettings';

export interface ExportOptions {
  sortDesc: boolean;
  sortBy: keyof ArticleListItem;
  filterValues: ArticleFilterValues;
  displayCurrencyId: number;
  includeVariants: boolean;
  includeAllBranches: boolean;
}

export function getExportArticlesUrl(
  apiSettings: ApiSettings,
  options: ExportOptions,
) {
  const queryString = querystringify.stringify({
    searchQuery: options.filterValues.searchQuery,
    assortmentId: options.filterValues.assortmentId,
    sortBy: options.sortBy,
    sortDesc: options.sortDesc,
    displayCurrencyId: options.displayCurrencyId,
    includeVariants: options.includeVariants,
    includeAllBranches: options.includeAllBranches,
  });

  return `${apiSettings.articlesExportUri}?${queryString}`;
}
