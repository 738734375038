import React, { useState } from 'react';
import { Card, Select } from 'antd';
import { messages } from '../../../../messages';
import { DescriptionList } from '../../../descriptionList/descriptionList';
import { useI18n } from '../../../../hooks/useI18n';
import { Contact, contacts } from './contacts';
import { LabeledValue } from 'antd/lib/select';
import styles from './contactCard.module.scss';

export function ContactCard() {
  const { t } = useI18n();
  const [contact, setContact] = useState<Contact>(contacts[0]);

  return (
    <Card title={t(messages.dashboard.contactRH)}>
      <Select<LabeledValue>
        className={styles.contactSelect}
        dropdownMatchSelectWidth={false}
        labelInValue={true}
        value={{ key: contact.id.toString(), label: contact.name }}
        onChange={({ key }) => {
          const contact = contacts.find((c) => c.id.toString() === key);
          if (contact) {
            setContact(contact);
          }
        }}
      >
        {contacts.map((c) => (
          <Select.Option key={c.id.toString()}>{c.name}</Select.Option>
        ))}
      </Select>
      <DescriptionList>
        {contact.email && (
          <DescriptionList.Item title={t(messages.shared.email)}>
            {contact.email}
          </DescriptionList.Item>
        )}
        <DescriptionList.Item title={t(messages.shared.telephone)}>
          {contact.telephone}
        </DescriptionList.Item>
        {contact.fax && (
          <DescriptionList.Item title={t(messages.shared.fax)}>
            {contact.fax}
          </DescriptionList.Item>
        )}
      </DescriptionList>
    </Card>
  );
}
