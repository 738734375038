import React, { useEffect, useState } from 'react';
import { messages } from '../../../../messages';
import { Modal } from 'antd';
import { useI18n } from '../../../../hooks/useI18n';
import { ListLayout } from '../../../listLayout/listLayout';
import { Columns, Table } from '../../../table';
import {
  CustomerWidget,
  customerWidgetsApi,
} from '../../../../api/widgets/customers/customerWidgetsApi';
import { useApiResource } from '../../../../hooks/useApi';
import { CreateCustomerProductStatisticsReportWidgetModal } from './productStatisticsReport/createCustomerProductStatisticsReportWidgetModal';
import { LoadingIndicator } from '../../../loadingIndicator/loadingIndicator';
import {
  CustomerWidgetType,
  translateWidgetType,
} from '../../../../api/internalCustomerApi';
import { CreateCustomerAwaitingDeliveriesModal } from './awaitingDeliveries/createCustomerAwaitingDeliveriesModal';
import { CreateCustomerOrdersReportWidgetModal } from './customerOrdersReport/createCustomerOrdersReportWidgetModal';

export const SelectWidgetToCreateModal = (
  {
    onClose,
  }: {
    onClose: () => void;
  }) => {
  const { t } = useI18n();

  const [createWidgetModal, setCreateWidgetModal] = useState<CustomerWidgetType | undefined>();

  const widgets = useApiResource(customerWidgetsApi.getAllWidgets, {
    emptyValue: [] as CustomerWidget[],
    clearDataOnLoad: false,
  });

  useEffect(() => {
    widgets.refresh();
  }, []);

  const getColumns = (): Columns<CustomerWidget> => [
    {
      header: t(messages.widgets.widgetType),
      accessor: 'type',
      sortable: true,
      cell: ({ row }) => <>{translateWidgetType(row.type)}</>,
    },
  ];

  const renderCreateWidgetModal = (type: CustomerWidgetType) => {
    switch (type) {
      case CustomerWidgetType.ProductStatisticsReport:
        return <CreateCustomerProductStatisticsReportWidgetModal
          onClose={() => {
            setCreateWidgetModal(undefined);
            onClose();
          }} />;
      case CustomerWidgetType.AwaitingDeliveries:
        return <CreateCustomerAwaitingDeliveriesModal
          onClose={() => {
            setCreateWidgetModal(undefined);
            onClose();
          }} />;
      case CustomerWidgetType.CustomerOrdersReport:
        return <CreateCustomerOrdersReportWidgetModal
          onClose={() => {
            setCreateWidgetModal(undefined);
            onClose();
          }} />;
    }
  };

  return (
    <Modal
      title={t(messages.widgets.create)}
      visible={true}
      closable={false}
      onCancel={onClose}
      okButtonProps={{ hidden: true }}
      width={720}
    >
      {widgets.isLoading && <LoadingIndicator />}
      {!widgets.isLoading &&
      <ListLayout>
        <ListLayout.Content>
          <Table<CustomerWidget>
            columns={getColumns()}
            onSelectItem={(item) => setCreateWidgetModal(item.type)}
            items={widgets.data}
          />
        </ListLayout.Content>
      </ListLayout>
      }
      {createWidgetModal !== undefined && renderCreateWidgetModal(createWidgetModal)}
    </Modal>
  );
};
