import React, { useState, useEffect, useRef, useMemo } from 'react';
import { Input, Button, Alert, Icon, Tooltip } from 'antd';
import styles from './updateArticleLineItem.module.scss';
import { LineItem, UpdateLineItem } from '../../../../types';
import { useI18n } from '../../../../hooks/useI18n';
import { useShopSessionStore } from '../../../../hooks/shop/useShopSessionStore';
import { formatNumber } from '../../../../helpers/formatNumber/formatNumber';
import { getTotalPrice } from '../../../../helpers/priceCalculator';
import { LoadingIndicator } from '../../../loadingIndicator/loadingIndicator';
import { messages } from '../../../../messages';
import { getquantityTooltip, quantityIsValid } from '../helpers';

function clampQuantity(quantity: number) {
  const minValue = 0;
  const maxValue = 999999999;

  return Math.max(minValue, Math.min(quantity, maxValue));
}

function adjustQuantityPerUnit(quantity: number, quantityPerUnit: number) {
  return Math.ceil(quantity / quantityPerUnit) * quantityPerUnit;
}

export const UpdateArticleLineItem = ({
  articleId,
  lineItem,
  isLoading,
  price,
  currencyCode,
  quantityPerUnit,
  lineItemQuantityError,
  draftOrder,
  draftQuantityAdjustmentPercent,
  onUpdateLineItem,
}: {
  articleId: number;
  lineItem?: LineItem;
  isLoading: boolean;
  price: number;
  currencyCode: string;
  quantityPerUnit: string;
  lineItemQuantityError?: string;
  draftOrder: boolean;
  draftQuantityAdjustmentPercent: number;
  onUpdateLineItem: (lineItem: UpdateLineItem) => void;
}) => {
  const { t } = useI18n();
  const { session } = useShopSessionStore();
  const inputRef = useRef<Input>(null);
  const [quantityAdjusted, setQuantityAdjusted] = useState<boolean>(false);
  const [quantity, setQuantity] = useState<number | undefined>(
    lineItem ? lineItem.quantity : undefined,
  );
  const [updateWithEnter, setUpdateWithEnter] = useState<boolean>(false);

  const quantityTooltip = useMemo(() => {
    if (lineItem?.originalQuantity)
      return getquantityTooltip(
        lineItem.originalQuantity,
        draftQuantityAdjustmentPercent,
      );
    return '';
  }, []);

  const saveLineItem = () => {
    onUpdateLineItem({
      articleId,
      quantity: quantity ?? 0,
      quantityIsAdjusted: quantityAdjusted,
    });
  };

  useEffect(() => {
    const currentInputRef = inputRef.current;
    if (currentInputRef) {
      currentInputRef.focus();
    }

    if (lineItem) {
      setQuantityAdjusted(lineItem.quantityIsAdjusted);
    }
  }, []);

  const totalPriceLabel = useMemo(() => {
    if (!quantity) {
      return '-';
    }

    return formatNumber(getTotalPrice(price, quantity), 2);
  }, [price, quantity]);

  const handleQuantityChange = (value: string) => {
    if (value === '') {
      setQuantity(undefined);
      return;
    }

    const parsedValue = parseInt(value, 10);
    if (Number.isInteger(parsedValue)) {
      setQuantity(clampQuantity(parsedValue));
    } else {
      setQuantity(quantity);
    }
  };

  const handleQuantityBlur = (
    value: string,
    callback?: (adjusted: boolean) => void,
  ) => {
    if (!session.useAutomaticQuantityAdjustment) {
      return;
    }

    const parsedValue = parseInt(value, 10);
    const parsedQuantity = parseInt(quantityPerUnit, 10);
    if (
      Number.isInteger(parsedValue) &&
      Number.isInteger(parsedQuantity) &&
      parsedQuantity > 0
    ) {
      const quantity = clampQuantity(parsedValue);
      const adjustedQuantity = adjustQuantityPerUnit(quantity, parsedQuantity);
      setQuantity(adjustedQuantity);
      if (adjustedQuantity !== quantity) {
        setQuantityAdjusted(true);
      }

      if (callback !== undefined) {
        callback(adjustedQuantity !== quantity);
      }
    }
  };

  const [quantityError, setQuantityError] = useState<boolean>(false);

  useEffect(() => {
    if (
      draftOrder &&
      quantity !== undefined &&
      lineItem?.originalQuantity !== undefined
    ) {
      setQuantityError(
        quantityIsValid(
          quantity,
          lineItem.originalQuantity,
          draftQuantityAdjustmentPercent,
        )
          ? false
          : true,
      );
    }

    if (updateWithEnter) {
      saveLineItem();
    }
  }, [quantity]);

  const inputQuantity = () => {
    return (
      <Input
        className={quantityError ? styles.quantityError : ''}
        data-testid="quantity"
        ref={inputRef}
        value={quantity}
        size="large"
        allowClear={true}
        onChange={(e) => handleQuantityChange(e.target.value)}
        onBlur={(e) => handleQuantityBlur(e.target.value)}
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            if (session.useAutomaticQuantityAdjustment) {
              handleQuantityBlur(
                e.currentTarget.value.length > 0 ? e.currentTarget.value : '0',
                function (adjusted) {
                  if (adjusted) {
                    setUpdateWithEnter(true);
                  } else {
                    saveLineItem();
                  }
                },
              );
            } else {
              saveLineItem();
            }
          }
        }}
        disabled={
          draftOrder &&
          (lineItem?.originalQuantity === undefined ||
            lineItem.originalQuantity === 0)
        }
      />
    );
  };

  function quantityInputTooltip() {
    if (draftOrder && lineItem?.originalQuantity) {
      return <Tooltip title={quantityTooltip}>{inputQuantity()}</Tooltip>;
    } else {
      return inputQuantity();
    }
  }

  return (
    <div className={styles.container}>
      {isLoading && <LoadingIndicator />}
      {lineItemQuantityError && (
        <Alert
          icon={<Icon type="warning" />}
          message={lineItemQuantityError}
          showIcon={true}
          type="error"
        />
      )}
      {quantityAdjusted && (
        <Alert
          icon={<Icon type="info-circle" />}
          message={t(messages.shared.quantityAdjusted, {
            qtyPerUnit: quantityPerUnit,
          })}
          showIcon={true}
          type="info"
        />
      )}
      <div className={styles.totalSummary}>
        <div className={styles.totalEntry}>
          <div className={styles.totalEntryLabel}>
            {t(messages.shared.quantity)}
          </div>
          <div className={styles.totalEntryValue}>{quantityInputTooltip()}</div>
        </div>
        <div className={styles.totalEntry}>
          <div className={styles.totalEntryLabel}>
            {`${t(messages.shared.totalPrice)} (${currencyCode})`}
          </div>
          <div className={styles.totalEntryValue} data-testid="totalPrice">
            {totalPriceLabel}
          </div>
        </div>
      </div>
      <Button
        size="large"
        type="primary"
        block={true}
        onClick={saveLineItem}
        disabled={session.locked}
      >
        {t(messages.shared.update)}
      </Button>
    </div>
  );
};
