// These are polyfills and must be imported first
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

import React from 'react';
import ReactDOM from 'react-dom';
import './styles/base.scss';
import './styles/antDesign.less';
import { App } from './components/app/app';
import { OutdatedBrowser } from './components/outdatedBrowser/OutdatedBrowser';
import rg4js from 'raygun4js';
import Bowser from 'bowser';

const browser = Bowser.getParser(window.navigator.userAgent);
const isValidBrowser = browser.satisfies({
  ie: '>=11',
});

// isValidBrowser will be false for outdated browsers, otherwise undefined or true
if (isValidBrowser === false) {
  ReactDOM.render(<OutdatedBrowser />, document.getElementById('root'));
} else {
  ReactDOM.render(<App />, document.getElementById('root'));
}

if (process.env.NODE_ENV === 'production') {
  rg4js('enableCrashReporting', true);
  rg4js('apiKey', 'mKUWotb5c5ssLVISUUvBQ');
  rg4js('options', { ignoreAjaxError: true, ignoreAjaxAbort: true });
  rg4js('setVersion', process.env.REACT_APP_VERSION!);
}
