import { messages } from '../../messages';
import { TranslateFunction } from '../useI18n/messageTranslator';
import { LocaleObject } from 'yup';

type ReqiuredLocaleObject = {
  [P in keyof Required<LocaleObject>]: Required<LocaleObject[P]>;
};

export const getLocale = (t: TranslateFunction): ReqiuredLocaleObject => ({
  mixed: {
    default: () => t(messages.validation.mixed.default),
    required: () => t(messages.validation.mixed.required),
    oneOf: ({ values }) => t(messages.validation.mixed.oneOf, { values }),
    notOneOf: ({ values }) => t(messages.validation.mixed.notOneOf, { values }),
    notType: ({ type }) => t(messages.validation.mixed.notType, { type }),
  },
  string: {
    length: ({ length }) => t(messages.validation.string.length, { length }),
    min: ({ min }) => t(messages.validation.string.min, { min }),
    max: ({ max }) => t(messages.validation.string.max, { max }),
    matches: ({ regex }) =>
      t(messages.validation.string.matches, { regex: regex.toString() }),
    email: () => t(messages.validation.string.email),
    url: () => t(messages.validation.string.url),
    trim: () => t(messages.validation.string.trim),
    lowercase: () => t(messages.validation.string.lowercase),
    uppercase: () => t(messages.validation.string.uppercase),
  },
  number: {
    min: ({ min }) => t(messages.validation.number.min, { min }),
    max: ({ max }) => t(messages.validation.number.max, { max }),
    lessThan: ({ less }) => t(messages.validation.number.lessThan, { less }),
    moreThan: ({ more }) => t(messages.validation.number.moreThan, { more }),
    positive: () => t(messages.validation.number.positive),
    negative: () => t(messages.validation.number.negative),
    integer: () => t(messages.validation.number.integer),
  },
  date: {
    min: ({ min }) => t(messages.validation.date.min, { min: min.toString() }),
    max: ({ max }) => t(messages.validation.date.max, { min: max.toString() }),
  },
  boolean: {},
  object: {
    noUnknown: () => t(messages.validation.object.noUnknown),
  },
  array: {
    min: ({ min }) => t(messages.validation.array.min, { min }),
    max: ({ max }) => t(messages.validation.array.max, { max }),
  },
});
