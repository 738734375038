/* eslint-disable react-hooks/rules-of-hooks */
import { useState, useMemo } from 'react';
import constate from 'constate';
import { Currency } from '../../../types';

function articleCartModalStore() {
  const [articleId, setArticleId] = useState<number>();
  const [displayCurrency, setDisplayCurrency] = useState<Currency>();

  function showArticle(id: number, currency?: Currency) {
    setArticleId(id);
    setDisplayCurrency(currency);
  }

  function hideArticle() {
    setArticleId(undefined);
  }

  // Memoize to only update consumers when specified deps changes
  return useMemo(
    () => ({
      articleId,
      displayCurrency,
      showArticle,
      hideArticle,
      setDisplayCurrency,
    }),
    [articleId, displayCurrency],
  );
}

const [ArticleCartModalStoreProvider, useArticleCartModalStore] = constate(
  articleCartModalStore,
);
export { ArticleCartModalStoreProvider, useArticleCartModalStore };
