import React from 'react';
import { Address } from '../../types';

export function AddressDetails({ address }: { address: Address }) {
  return (
    <>
      <div>{address.company}</div>
      <div>{address.address1}</div>
      <div>{address.address2}</div>
      <div>{address.cityAndPostalCode}</div>
      <div>{address.countryName}</div>
    </>
  );
}
