import { DeliveryStatus } from '../types';
import { TranslateFunction } from '../hooks/useI18n';
import { messages } from '../messages';

export function deliveryStatusTranslator(
  t: TranslateFunction,
  delivery: DeliveryStatus,
) {
  switch (delivery) {
    case DeliveryStatus.Undelivered:
      return t(messages.order.undelivered);
    case DeliveryStatus.PartiallyDelivered:
      return t(messages.order.partiallyDelivered);
    case DeliveryStatus.Delivered:
      return t(messages.order.delivered);
    default:
      return t(messages.shared.unknown);
  }
}
