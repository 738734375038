export const getMaxQuantity = (
  originalQuantity: number,
  draftQuantityAdjustmentPercent: number,
): number => {
  return Math.round(
    originalQuantity +
      (originalQuantity * draftQuantityAdjustmentPercent) / 100,
  );
};

export const getMinQuantity = (
  originalQuantity: number,
  draftQuantityAdjustmentPercent: number,
): number => {
  return Math.round(
    originalQuantity -
      (originalQuantity * draftQuantityAdjustmentPercent) / 100,
  );
};

export const getquantityTooltip = (
  originalQuantity: number,
  draftQuantityAdjustmentPercent: number,
): string => {
  return (
    'Min: ' +
    getMinQuantity(originalQuantity, draftQuantityAdjustmentPercent) +
    ', max: ' +
    getMaxQuantity(originalQuantity, draftQuantityAdjustmentPercent)
  );
};

export const quantityIsValid = (
  quantity: number,
  originalQuantity: number,
  draftQuantityAdjustmentPercent: number,
): boolean => {
  return (
    quantity >= getMinQuantity(originalQuantity, draftQuantityAdjustmentPercent)
    && quantity <= getMaxQuantity(originalQuantity, draftQuantityAdjustmentPercent)
  );
};
