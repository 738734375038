import React, { useEffect } from 'react';
import { ApiErrorDetails } from '../../apiErrorDetails/apiErrorDetails';
import { useApiResource } from '../../../hooks/useApi';
import { List, Icon, Skeleton } from 'antd';
import { useI18n } from '../../../hooks/useI18n';
import { useShopSessionStore } from '../../../hooks/shop/useShopSessionStore';
import { messages } from '../../../messages';
import { EmptyIndicator } from '../../emptyIndicator/emptyIndicator';
import { documentsApi, DocumentListItem } from '../../../api/documentsApi';
import { useFileUris } from '../../../hooks/useFileUrls/useFileUrls';
import { ContentLayout } from '../../contentLayout/contentLayout';

export const Documents = () => {
  const documents = useApiResource(documentsApi.getDocuments);
  const { t } = useI18n();
  const { session } = useShopSessionStore();

  useEffect(() => {
    documents.refresh();
  }, []);

  if (documents.error) {
    return <ApiErrorDetails error={documents.error} />;
  }

  if (!documents.data) {
    return <Skeleton paragraph={{ rows: 8 }} active={true} />;
  }

  const generalDocuments = documents.data.filter(
    (item) => !item.branchId && !item.projectId,
  );

  const branchDocuments = documents.data.filter(
    (item) => !!item.branchId && !item.projectId,
  );

  const yourDocuments = documents.data.filter((item) => !!item.projectId);

  return (
    <ContentLayout title={t(messages.documents.documents)}>
      {!!generalDocuments.length && (
        <>
          <h3>{t(messages.documents.generalDocuments)}</h3>
          <DocumentsList items={generalDocuments} />
        </>
      )}
      {!!branchDocuments.length && (
        <>
          <h3>
            {t(messages.documents.branchDocuments, {
              branch: `RH - ${session.branchName}`,
            })}
          </h3>
          <DocumentsList items={branchDocuments} />
        </>
      )}
      {!!yourDocuments.length && (
        <>
          <h3>{t(messages.documents.yourDocuments)}</h3>
          <DocumentsList items={yourDocuments} />
        </>
      )}
      {!documents.data.length && (
        <EmptyIndicator description={t(messages.shared.noItems)} />
      )}
    </ContentLayout>
  );
};

function DocumentsList({ items }: { items: DocumentListItem[] }) {
  const { documentFileUri } = useFileUris();

  return (
    <List
      style={{ marginBottom: '15px' }}
      size="small"
      dataSource={items}
      renderItem={(item) => (
        <List.Item>
          <List.Item.Meta
            title={
              <a
                href={documentFileUri(item.id)}
                target="_blank"
                rel="noopener noreferrer"
              >
                <Icon type="file" /> {item.name}
              </a>
            }
            description={item.description}
          />
        </List.Item>
      )}
    />
  );
}
