import React from 'react';
import { Layout, PageHeader } from 'antd';

export function ContentLayout({
  children,
  title,
}: {
  className?: string;
  children: React.ReactNode;
  title: string;
}) {
  const { Content } = Layout;

  return (
    <Layout className="layout">
      <Content
        style={{
          margin: 'auto',
          width: '700px',
          background: '#fff',
          minHeight: '280px',
        }}
      >
        <PageHeader title={title} />
        <div style={{ padding: '0 24px 24px 24px' }}>{children}</div>
      </Content>
    </Layout>
  );
}
