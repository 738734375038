import React, { ReactElement, useEffect, useState } from 'react';
import { Button, Card, Divider, Icon, Skeleton, Tag, Tooltip } from 'antd';
import {
  ApiError,
  useApiRequests,
  useApiResource,
} from '../../../../../hooks/useApi';
import { customerProductStatisticsReportWidgetApi } from '../../../../../api/widgets/customers/customerProductStatisticsReportWidgetApi';
import { messages } from '../../../../../messages';
import { DateFormat, useI18n } from '../../../../../hooks/useI18n';
import { useNotificationStore } from '../../../../../hooks/useNotificationStore/notificationStore';
import {
  Branch,
  ReportStatus,
} from '../../../../../api/widgets/internalProductStatisticsReportWidgetApi';
import Moment from 'moment';
import { ApiErrorDetails } from '../../../../apiErrorDetails/apiErrorDetails';
import FileSaver from 'file-saver';
import { useFileUris } from '../../../../../hooks/useFileUrls/useFileUrls';
import styles from '../styling/widgetStyling.module.scss';
import { useInterval } from '../../../../../hooks/useInterval/useInterval';
import { UpdateCustomerProductStatisticsReportWidgetModal } from './updateCustomerProductStatisticsReportWidgetModal';

export const CustomerProductStatisticsReportWidget = ({
  userWidgetId,
  onWidgetDelete,
}: {
  userWidgetId: number;
  onWidgetDelete: () => void;
}) => {
  const pollingFrequency = 10000;
  const { t } = useI18n();
  const { notify } = useNotificationStore();
  const { downloadCustomerProductStatisticsExportUri } = useFileUris();

  const widget = useApiResource(
    customerProductStatisticsReportWidgetApi.getWidget,
  );
  const [isPolling, setIsPolling] = useState<boolean>(false);
  const widgetPoll = useApiResource(
    customerProductStatisticsReportWidgetApi.getWidget,
  );
  const triggerReport = useApiRequests(
    customerProductStatisticsReportWidgetApi.triggerNewReport,
  );
  const [error, setError] = useState<ApiError>();
  const [showUpdateWidgetModal, setShowUpdateWidgetModal] =
    useState<boolean>(false);

  useEffect(() => {
    widget.refresh(userWidgetId);
  }, []);

  useEffect(() => {
    if (widget.data?.existsPendingReport) {
      widgetPoll.refresh(userWidgetId);
    }
  }, [widget.data?.existsPendingReport]);

  useEffect(() => {
    if (widget.data?.existsPendingReport) {
      setIsPolling(true);
    } else {
      setIsPolling(false);
    }
  }, [widgetPoll.data?.existsPendingReport]);

  useInterval(
    () => {
      widgetPoll.refresh(userWidgetId);
      if (!widgetPoll.data?.existsPendingReport) {
        widget.refresh(userWidgetId);
      }
    },
    isPolling ? pollingFrequency : null,
  );

  function onExcelExportClick(): void {
    triggerReport
      .create({
        onSuccess: () => {
          notify({
            title: t(messages.widgets.productStatistics.newReportTriggered),
            type: 'success',
          });
          widget.refresh(userWidgetId);
        },
        onError: setError,
      })
      .send(userWidgetId);
  }

  const branchesAsTags = (branches: Branch[]): ReactElement[] =>
    branches.map((b) => <Tag key={b.id}>{b.name}</Tag>);

  function onDownloadProductStatisticsClick(id: string | undefined): void {
    if (id === undefined) return;
    FileSaver.saveAs(downloadCustomerProductStatisticsExportUri(id));
  }

  function getHeadStyle(color: string | undefined) {
    const style = {
      backgroundColor: '#ffffff',
      color: '#000000',
    };
    if (color) {
      style['backgroundColor'] = color;
      style['color'] = '#ffffff';
    }
    return style;
  }

  return (
    <>
      {widget.isLoading && (
        <Card>
          <Skeleton />
        </Card>
      )}
      {widget.hasResponse && widget.data !== undefined && (
        <Card
          title={widget.data.name}
          headStyle={getHeadStyle(widget.data.color)}
          extra={
            <>
              <Tooltip
                title={t(messages.widgets.productStatistics.triggerExport)}
              >
                <Button
                  className={styles.refreshButton}
                  key="refresh"
                  type="default"
                  size="small"
                  icon="reload"
                  loading={widget.data.existsPendingReport}
                  onClick={onExcelExportClick}
                ></Button>
              </Tooltip>
              <Button
                className={styles.editButton}
                key="edit"
                type="default"
                size="small"
                icon="edit"
                onClick={() => setShowUpdateWidgetModal(true)}
              ></Button>
              <Button
                key="delete"
                type="danger"
                size="small"
                icon="delete"
                onClick={onWidgetDelete}
              ></Button>
            </>
          }
        >
          <>
            {widget.data.lastGeneratedReportFailed && (
              <>
                <Icon
                  style={{ display: 'inline-block', marginRight: '5px' }}
                  type="warning"
                />
                {t(messages.widgets.lastGeneratedReportFailed)}
              </>
            )}
            {widget.data.existsPendingReport && (
              <>
                <Icon
                  style={{ display: 'inline-block', marginRight: '5px' }}
                  type="info-circle"
                />
                {t(messages.widgets.pendingReport)}
              </>
            )}
            {widget.data.mostRecentSuccessfullyGeneratedReport === undefined &&
              !widget.data.existsPendingReport && (
                <>{t(messages.widgets.noReportsGeneratedYet)}</>
              )}
            {widget.data.mostRecentSuccessfullyGeneratedReport !== undefined &&
              widget.data.mostRecentSuccessfullyGeneratedReport.status ===
                ReportStatus.Finished && (
                <>
                  <p className={styles.exportDate}>
                    {t(messages.widgets.generated)}{' '}
                    {Moment(
                      widget.data.mostRecentSuccessfullyGeneratedReport
                        .createdDate,
                      DateFormat.DATETIME,
                    ).format('MMMM Do YYYY, h:mm:ss a')}
                  </p>
                  <Button
                    className={styles.exportButton}
                    key="export"
                    type="primary"
                    icon="download"
                    size="small"
                    onClick={() =>
                      onDownloadProductStatisticsClick(
                        widget.data?.mostRecentSuccessfullyGeneratedReport?.id,
                      )
                    }
                  >
                    {t(messages.widgets.downloadExport)}
                  </Button>
                </>
              )}
          </>
          {error && <ApiErrorDetails error={error} />}
          <Divider className={styles.divider}></Divider>
          <div className={styles.branches}>
            {branchesAsTags(widget.data.branches)}
          </div>
        </Card>
      )}
      {widget.hasResponse && widget.data === undefined && (
        <Card
          title={messages.widgets.errorRetrievedWidget}
          extra={
            <>
              <Button
                key="delete"
                type="danger"
                size="small"
                icon="delete"
                onClick={onWidgetDelete}
              ></Button>
            </>
          }
        >
          <p>{messages.widgets.insufficientCompetence}</p>
        </Card>
      )}
      {showUpdateWidgetModal && (
        <UpdateCustomerProductStatisticsReportWidgetModal
          userWidgetId={userWidgetId}
          onCancel={() => setShowUpdateWidgetModal(false)}
          onSubmit={() => {
            setShowUpdateWidgetModal(false);
            widget.refresh(userWidgetId);
          }}
        />
      )}
    </>
  );
};
