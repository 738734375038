import { QueryStringSerializer, OptionalQueryStringSerializer } from './types';

type SerializerReturnType<T> =
  | OptionalQueryStringSerializer<T>
  | QueryStringSerializer<T>;

function numberSerializer(): OptionalQueryStringSerializer<number>;
function numberSerializer(opt: {
  defaultValue: number;
}): QueryStringSerializer<number>;
function numberSerializer(opt?: { defaultValue: number }) {
  return {
    defaultValue: opt ? opt.defaultValue : undefined,
    serialize: (v: number | undefined) =>
      v !== undefined ? v.toString() : undefined,
    deserialize: (v) => {
      const number = Number(v);
      return Number.isInteger(number) ? number : undefined;
    },
  } as SerializerReturnType<number>;
}

function stringSerializer(): OptionalQueryStringSerializer<string>;
function stringSerializer(opt: {
  defaultValue: string;
}): QueryStringSerializer<string>;
function stringSerializer(opt?: { defaultValue: string }) {
  return {
    defaultValue: opt ? opt.defaultValue : undefined,
    serialize: (v: string | undefined) => v,
    deserialize: (v) => (v ? v : undefined),
  } as SerializerReturnType<string>;
}

function booleanSerializer(): OptionalQueryStringSerializer<boolean>;
function booleanSerializer(opt: {
  defaultValue: boolean;
}): QueryStringSerializer<boolean>;
function booleanSerializer(opt?: { defaultValue: boolean }) {
  return {
    defaultValue: opt ? opt.defaultValue : undefined,
    serialize: (v: boolean | undefined) =>
      v !== undefined ? v.toString() : undefined,
    deserialize: (v) => {
      switch (v) {
        case 'true':
          return true;
        case 'false':
          return false;
      }
    },
  } as SerializerReturnType<boolean>;
}

function customSerializer<T>(opt: {
  serialize: (value: T) => string | undefined;
  deserialize: (value: string | undefined) => T | undefined;
}): OptionalQueryStringSerializer<T>;
function customSerializer<T>(opt: {
  serialize: (value: T) => string | undefined;
  deserialize: (value: string | undefined) => T | undefined;
  defaultValue: T;
}): QueryStringSerializer<T>;
function customSerializer<T>(opt: {
  defaultValue?: T;
  serialize: (value: T) => string | undefined;
  deserialize: (value: string | undefined) => T | undefined;
}) {
  return opt;
}

function stringArraySerializer(): OptionalQueryStringSerializer<string[]>;
function stringArraySerializer(opt: {
  defaultValue: string[];
}): QueryStringSerializer<string[]>;
function stringArraySerializer(opt?: { defaultValue: string[] }) {
  return {
    defaultValue: opt ? opt.defaultValue : undefined,
    serialize: (v: string[] | undefined) => v,
    deserialize: (v) => (v ? v : undefined),
  } as SerializerReturnType<string[]>;
}

function numberArraySerializer(): OptionalQueryStringSerializer<number[]>;
function numberArraySerializer(opt: {
  defaultValue: number[];
}): QueryStringSerializer<number[]>;
function numberArraySerializer(opt?: { defaultValue: number[] }) {
  return {
    defaultValue: opt ? opt.defaultValue : undefined,
    serialize: (v: number[] | undefined) => v,
    deserialize: (v) => {
      return v ? v.split(',').map(Number) : undefined;
    },
  } as SerializerReturnType<number[]>;
}

const serializers = {
  number: numberSerializer,
  string: stringSerializer,
  boolean: booleanSerializer,
  custom: customSerializer,
  stringArray: stringArraySerializer,
  numberArray: numberArraySerializer,
};

export { serializers };
