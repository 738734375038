import { createContext } from 'react';
import { HttpClient } from './httpClient';

export interface Props {
  httpClient: HttpClient;
}

const mockHttpClientRequest = () => {
  return Promise.resolve({
    hasError: true as true,
    error: {
      message: 'Error: Using default mock api implementation',
      errors: [],
      status: 418,
    },
    status: 418,
  });
};

export const HttpClientContext = createContext<HttpClient>({
  get: mockHttpClientRequest,
  put: mockHttpClientRequest,
  post: mockHttpClientRequest,
  delete: mockHttpClientRequest,
  onUnauthorized: {
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    subscribe: () => () => {},
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    unsubscribe: () => {},
  },
});
