import React, { SVGProps } from 'react';
import { CustomIconComponentProps } from 'antd/lib/icon';

export const fileDownloadIcon = (
  props: CustomIconComponentProps | SVGProps<SVGSVGElement>,
) => (
  <svg {...props} viewBox="0 0 704 896" version="1.1" focusable="false">
    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <path
        d="M401.630915,397.874162 C401.630915,384.131187 390.504713,373 376.767893,373 L327.041848,373 C313.305028,373 302.178825,384.131187 302.178825,397.874162 L302.178825,572.014023 L200.903447,572.014023 C178.7132,572.014023 167.649155,598.898846 183.395735,614.54884 L333.216165,763.317056 C343.555039,773.577648 360.233983,773.577648 370.572856,763.317056 L520.393286,614.54884 C536.139867,598.919575 525.075822,572.014023 502.885574,572.014023 L401.630915,572.014023 L401.630915,397.874162 Z M694.6,224.6 L479.4,9.4 C473.4,3.4 465.3,0 456.8,0 L32,0 C14.3,0 0,14.3 0,32 L0,864 C0,881.7 14.3,896 32,896 L672,896 C689.7,896 704,881.7 704,864 L704,247.3 C704,238.8 700.6,230.6 694.6,224.6 Z M630.2,262 L442,262 L442,73.8 L630.2,262 Z M632,824 L72,824 L72,72 L374,72 L374,288 C374,311.195959 392.804041,330 416,330 L632,330 L632,824 Z"
        id="Shape"
        fill="currentColor"
        fillRule="nonzero"
      ></path>
    </g>
  </svg>
);
