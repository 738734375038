import React, { useState, useRef, useEffect, lazy, Suspense } from 'react';
import { Router, Route, Redirect, Switch } from 'react-router-dom';
import { Shop } from '../shop/shop';
import { ShopSession, ApplicationEnvironment } from '../../types';
import { LoadingIndicator } from '../loadingIndicator/loadingIndicator';
import { Layout, Icon, Alert } from 'antd';
import styles from './app.module.scss';
import { UserCustomerPicker } from './userCustomerPicker/userCustomerPicker';
import { createBrowserHistory, History } from 'history';
import { LastLocationProvider } from 'react-router-last-location';
import {
  createHttpClient,
  HttpClient,
  HttpClientContext,
} from '../../hooks/useHttpClient';
import { I18nProvider, Locale } from '../../hooks/useI18n';
import { NotificationStoreProvider } from '../../hooks/useNotificationStore/notificationStore';
import { routes as shopRoutes } from '../shop/routes';
import { routes as internalRoutes } from '../internal/routes';
import { YupProvider } from '../../hooks/useYup';
import { createApiSettings } from '../../api/apiSettings';
import { ApiSettingsContext } from '../../hooks/useApi/apiSettingsContext';
import { enTranslations } from '../../translations/en';
import { zhCnTranslations } from '../../translations/zh-cn';

interface CurrentSession {
  userName: string;
  shopSession?: ShopSession;
  isInternal: boolean;
  applicationEnvironment: ApplicationEnvironment;
}

const shopLoginUrl = '/login';
const Internal = lazy(() => import('../internal/internal'));
const apiSettings = createApiSettings('/api');
const translations = {
  [Locale.En]: enTranslations,
  [Locale.ZhCn]: zhCnTranslations,
};

export function App() {
  const httpClient = useRef<HttpClient>(createHttpClient());
  const history = useRef<History<any>>(createBrowserHistory());
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [currentSession, setCurrentSession] = useState<CurrentSession>();
  const [error, setError] = useState<string>();
  const [isAuthorizing, setIsAuthorizing] = useState(false);

  const redirectToExternalLogin = () => {
    setIsAuthorizing(true);
    const currentUrl = window.location.href;
    window.location.href = `${apiSettings.redirectLoginUri}?returnUrl=${currentUrl}`;
  };

  const redirectToExternalLogout = () => {
    setIsAuthorizing(true);
    setCurrentSession(undefined);
    const currentOrigin = `${window.location.protocol}//${window.location.host}`;
    window.location.href = `${apiSettings.redirectLogoutUri}?returnUrl=${currentOrigin}`;
  };

  const getSession = async (redirectPath?: string) => {
    setIsLoading(true);
    const sessionResult = await httpClient.current.get(apiSettings.sessionUri);

    if (sessionResult.hasError) {
      if (sessionResult.status !== 401) {
        setError(sessionResult.error.message);
      }
    } else {
      const currentSessionResult = sessionResult.data as CurrentSession;
      setCurrentSession(currentSessionResult);

      if (redirectPath) {
        history.current.push(redirectPath);
      }
    }
    setIsLoading(false);
  };

  const setUserCustomerId = async (userCustomerId: number) => {
    setIsLoading(true);
    const sessionResult = await httpClient.current.put(
      apiSettings.sessionShopUri,
      { data: { userCustomerId } },
    );

    if (sessionResult.hasError) {
      setError(sessionResult.error.message);
      setIsLoading(false);
    } else {
      getSession(shopRoutes.dashboard);
    }
  };

  const handleEndShopSession = async () => {
    setIsLoading(true);
    const sessionResult = await httpClient.current.delete(
      apiSettings.sessionShopUri,
    );

    if (sessionResult.hasError) {
      setError(sessionResult.error.message);
      setIsLoading(false);
    } else {
      if (currentSession?.isInternal) {
        getSession(internalRoutes.userCustomers);
      } else {
        getSession(shopLoginUrl);
      }
    }
  };

  useEffect(() => {
    getSession();
  }, []);

  useEffect(() => {
    return httpClient.current.onUnauthorized.subscribe(() =>
      redirectToExternalLogin(),
    );
  }, []);

  if (error || !currentSession) {
    return (
      <AppLoader
        error={error}
        loadingMessage={
          isAuthorizing
            ? 'You are not logged in, redirecting to login page'
            : undefined
        }
      />
    );
  }

  return (
    <Suspense fallback={<LoadingIndicator />}>
      <ApiSettingsContext.Provider value={apiSettings}>
        <HttpClientContext.Provider value={httpClient.current}>
          <I18nProvider defaultLocale={Locale.En} translations={translations}>
            <YupProvider>
              <NotificationStoreProvider>
                {isLoading && <LoadingIndicator />}
                <Router history={history.current}>
                  <LastLocationProvider>
                    <Switch>
                      {!currentSession.isInternal && (
                        <Route
                          path={shopLoginUrl}
                          render={() => (
                            <UserCustomerPicker
                              onSelectUserCustomerId={setUserCustomerId}
                              onLogout={redirectToExternalLogout}
                            />
                          )}
                        />
                      )}
                      <Route
                        path="/"
                        render={() => {
                          if (currentSession.shopSession) {
                            return (
                              <Shop
                                applicationEnvironment={
                                  currentSession.applicationEnvironment
                                }
                                session={currentSession.shopSession}
                                onEndSession={handleEndShopSession}
                                onLogout={redirectToExternalLogout}
                              />
                            );
                          } else if (!currentSession.isInternal) {
                            return <Redirect to={shopLoginUrl} />;
                          } else {
                            return (
                              <Internal
                                applicationEnvironment={
                                  currentSession.applicationEnvironment
                                }
                                session={currentSession}
                                onSetUserCustomerId={setUserCustomerId}
                                onLogout={redirectToExternalLogout}
                              />
                            );
                          }
                        }}
                      />
                    </Switch>
                  </LastLocationProvider>
                </Router>
              </NotificationStoreProvider>
            </YupProvider>
          </I18nProvider>
        </HttpClientContext.Provider>
      </ApiSettingsContext.Provider>
    </Suspense>
  );
}

function AppLoader({
  error,
  loadingMessage,
}: {
  error?: string;
  loadingMessage?: string;
}) {
  return (
    <Layout>
      <Layout.Content className={styles.baseLayout}>
        <div className={styles.content}>
          {!error && <LoadingIndicator message={loadingMessage} />}
          {error && (
            <>
              <Icon type="tool" className={styles.messageIcon} />
              <Alert message="Error" description={error} type="error" />
            </>
          )}
        </div>
      </Layout.Content>
    </Layout>
  );
}
