import React, { useMemo, useEffect } from 'react';
import { Tree, Skeleton } from 'antd';
import { messages } from '../../../../messages';
import { useI18n } from '../../../../hooks/useI18n';
import { Filters } from '../../../filters/filters';
import { useDebounceValueCallback } from '../../../../hooks/useDebounceValueCallback';
import { SearchInput } from '../../../searchInput/searchInput';
import { ApiErrorDetails } from '../../../apiErrorDetails/apiErrorDetails';
import { useApiResource } from '../../../../hooks/useApi';
import { assortmentsApi, Assortment } from '../../../../api/assortmentsApi';
import { ArticleFilterValues } from '../../../../api/articlesApi';

interface Props {
  readonly values: ArticleFilterValues;
  readonly onChange: (values: ArticleFilterValues) => void;
}

const emptyAssortmentKey = 'all';

const renderTreeNodes = (assortments: Assortment[]) =>
  assortments.map((assortment) => (
    <Tree.TreeNode key={assortment.id.toString()} title={assortment.name}>
      {assortment.children && renderTreeNodes(assortment.children)}
    </Tree.TreeNode>
  ));

const ArticleFiltersComponent = ({ values, onChange }: Props) => {
  const { t } = useI18n();
  const assortments = useApiResource(assortmentsApi.getAssortments);

  const selectedAssortmentKeys = useMemo(
    () =>
      values.assortmentId !== undefined
        ? [`${values.assortmentId}`]
        : [emptyAssortmentKey],
    [values.assortmentId],
  );

  const setAssortmentValue = (assortmentKey?: string) => {
    if (!assortmentKey) {
      return;
    }

    const newAssortmentId =
      assortmentKey === emptyAssortmentKey
        ? undefined
        : parseInt(assortmentKey, 10);
    onChange({ ...values, assortmentId: newAssortmentId });
  };

  const [
    searchQuery,
    setSearchQuery,
  ] = useDebounceValueCallback(values.searchQuery, (value) =>
    onChange({ ...values, searchQuery: value }),
  );

  useEffect(() => {
    assortments.refresh();
  }, []);

  return (
    <Filters>
      <Filters.Filter title={t(messages.shared.search)}>
        <SearchInput
          value={searchQuery}
          placeholder={t(messages.shared.searchPlaceholder)}
          onChange={setSearchQuery}
        />
      </Filters.Filter>
      <Filters.Filter title={t(messages.assortment.assortment)}>
        {assortments.error && <ApiErrorDetails error={assortments.error} />}
        {!assortments.data && !assortments.error && (
          <Skeleton paragraph={{ rows: 5 }} />
        )}
        {assortments.data && (
          <Tree
            selectedKeys={selectedAssortmentKeys}
            onSelect={(e) => setAssortmentValue(e[0])}
          >
            <Tree.TreeNode
              title={t(messages.shared.all)}
              key={emptyAssortmentKey}
            />
            {renderTreeNodes(assortments.data)}
          </Tree>
        )}
      </Filters.Filter>
    </Filters>
  );
};

export const ArticleFilters = React.memo(ArticleFiltersComponent);
