import { ApiRequest } from '../hooks/useApi';
import { CustomerType } from '../types';

export const documentsApi = {
  getDocuments: (): ApiRequest<DocumentListItem[]> => async ({
    httpClient,
    apiSettings,
  }) => {
    const response = await httpClient.get(apiSettings.documentsUri);

    if (response.hasError) {
      return { error: response.error };
    } else {
      return {
        result: response.data,
      };
    }
  },
};

export interface DocumentListItem {
  id: string;
  name: string;
  description: string;
  branchId?: number;
  projectId?: number;
  customerType?: CustomerType;
}
