import { Locale } from './types';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import 'dayjs/locale/en';

dayjs.extend(utc);

export enum DateFormat {
  DATE = 'YYYY-MM-DD',
  DATETIME = 'YYYY-MM-DD HH:mm UTC',
  DATE_WEEKDAY = 'ddd DD',
}

export function createDateFormatter(locale: Locale) {
  function formatDate(
    dateString: string,
    dateFormat: DateFormat = DateFormat.DATE,
  ): string {
    return dayjs(dateString).utc().locale(locale).format(dateFormat);
  }

  return { formatDate };
}

export type DateFormatter = ReturnType<typeof createDateFormatter>;
export type FormatDateFunction = DateFormatter['formatDate'];
