import React, { useState } from 'react';
import { Filters } from '../../../filters/filters';
import { useI18n } from '../../../../hooks/useI18n';
import { messages } from '../../../../messages';
import { SearchInput } from '../../../searchInput/searchInput';
import { useDebounceValueCallback } from '../../../../hooks/useDebounceValueCallback';
import { OrderProgressPicker } from './orderProgressPicker';
import { toOrderProgresses } from '../../../../helpers/orderProgressConverter';
import { OrderListItem } from '../../../../api/ordersApi';
import { OrderTypePicker } from '../../../pickers/orderTypePicker';
import { OrderType } from '../../../../types';

export interface OrderQueryParams {
  articleNumber: string;
  customerId?: number;
  orderProgresses: number[];
  orderType?: number;
  page: number;
  pageSize: number;
  placed: boolean;
  searchQuery: string;
  sortingKey: keyof OrderListItem;
  sortingDesc: boolean;
}

export function OrderFilters({
  queryParams,
  onChangeQueryParams,
}: {
  queryParams: OrderQueryParams;
  onChangeQueryParams: (params: OrderQueryParams) => void;
}) {
  const { t } = useI18n();
  const [scope, setScope] = useState<{
    orderProgressIds: number[];
  }>({ orderProgressIds: toOrderProgresses(queryParams.orderProgresses) });
  const { orderProgressIds } = scope;

  const partialUpdateQueryParams = (
    newQueryParams: Partial<OrderQueryParams>,
  ) => {
    onChangeQueryParams({ ...queryParams, ...newQueryParams, page: 1 });
  };

  const [
    searchQuery,
    setSearchQuery,
  ] = useDebounceValueCallback(queryParams.searchQuery, (value) =>
    partialUpdateQueryParams({ searchQuery: value }),
  );

  const [
    articleNumber,
    setArticleNumberSearchQuery,
  ] = useDebounceValueCallback(queryParams.articleNumber, (value) =>
    partialUpdateQueryParams({ articleNumber: value }),
  );

  const selectableOrderTypes: OrderType[] = [
    OrderType.Standard,
    OrderType.Sample,
    OrderType.Forecast,
  ];

  function handleOrderProgressChanges(selectedOrderProgresses: number[]) {
    setScope({ orderProgressIds: selectedOrderProgresses });
    partialUpdateQueryParams({ orderProgresses: selectedOrderProgresses });
  }

  return (
    <Filters>
      <Filters.Filter title={t(messages.shared.search)}>
        <SearchInput
          value={searchQuery}
          placeholder={t(messages.shared.searchPlaceholder)}
          onChange={setSearchQuery}
        />
      </Filters.Filter>
      <Filters.Filter title={t(messages.order.progress)}>
        <OrderProgressPicker
          orderProgressIds={orderProgressIds}
          onChange={handleOrderProgressChanges}
        />
      </Filters.Filter>
      <Filters.Filter title={t(messages.order.orderType)}>
        <OrderTypePicker
          orderType={queryParams.orderType}
          selectableOrderTypes={selectableOrderTypes}
          onChange={(value) => partialUpdateQueryParams({ orderType: value })}
          allowClear={true}
        />
      </Filters.Filter>
      <Filters.Filter title={t(messages.shared.searchArticleNumber)}>
        <SearchInput
          value={articleNumber}
          placeholder={t(messages.shared.searchArticleNoPlaceholder)}
          onChange={setArticleNumberSearchQuery}
        />
      </Filters.Filter>
    </Filters>
  );
}
