export enum Locale {
  En = 'en',
  ZhCn = 'zh-cn',
}

export interface NestedPaths {
  [key: string]: NestedPaths | string;
}

export interface NestedTranslations {
  [key: string]: NestedTranslations | string;
}

export type OptionalNestedTranslations<T> = {
  [P in keyof T]: OptionalNestedTranslations<T[P]> | null | string;
};

export interface FlattenedTranslations {
  [key: string]: string;
}
