import { useI18n } from '../../../../hooks/useI18n';
import { useNotificationStore } from '../../../../hooks/useNotificationStore/notificationStore';
import React, { useState } from 'react';
import { ApiError, useApiRequests } from '../../../../hooks/useApi';
import { messages } from '../../../../messages';
import { Modal } from 'antd';
import { LoadingIndicator } from '../../../loadingIndicator/loadingIndicator';
import { ApiErrorDetails } from '../../../apiErrorDetails/apiErrorDetails';
import { customerWidgetsApi } from '../../../../api/widgets/customers/customerWidgetsApi';

export function RemoveCustomerWidgetModal(
  {
    userWidgetId,
    userWidgetName,
    onCancel,
    onSuccess,
  }: {
    userWidgetId: number;
    userWidgetName: string;
    onCancel: () => void;
    onSuccess: () => void;
  }) {
  const { t } = useI18n();
  const { notify } = useNotificationStore();

  const [error, setError] = useState<ApiError>();

  const removeWidgetRequest = useApiRequests(
    customerWidgetsApi.removeCustomerUserWidget,
  );

  const onRemove = () => {
    removeWidgetRequest
      .create({
        onSuccess: () => {
          onSuccess();
          notify({
            title: t(messages.widgets.widgetRemoved),
            type: 'success',
          });
        },
        onError: setError,
      })
      .send(userWidgetId);
  };

  return (
    <Modal
      title={t(messages.shared.deleteConfirmation, {
        name: userWidgetName,
      })}
      visible={true}
      okText={t(messages.shared.delete)}
      okType='danger'
      onOk={onRemove}
      onCancel={onCancel}
    >
      {removeWidgetRequest.isLoading && <LoadingIndicator />}
      <p>{t(messages.widgets.widgetRemovalWarning)}</p>
      {error && <ApiErrorDetails error={error} />}
    </Modal>
  );
}
