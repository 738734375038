import { Spin } from 'antd';
import styles from './loadingIndicator.module.scss';
import React from 'react';

export const LoadingIndicator = ({ message }: { message?: string }) => (
  <div className={styles.overlayClass}>
    <Spin className={styles.spinnerClass} />
    {message && <div>{message}</div>}
  </div>
);
