import React, { useEffect } from 'react';
import { Select, Skeleton } from 'antd';
import { useApiResource } from '../../hooks/useApi';
import { useI18n } from '../../hooks/useI18n';
import { ApiErrorDetails } from '../apiErrorDetails/apiErrorDetails';
import { FormLayout } from '../form/formLayout';
import { TextField } from '../form/textField';
import { messages } from '../../messages';
import { SelectField } from '../form/selectField';
import { sessionApi } from '../../api/sessionApi';

export interface CustomAddressForm {
  company: string;
  address1: string;
  address2: string;
  postalCode: string;
  city: string;
  country?: { key: number; label: string };
}

const formLayout = {
  labelCol: { span: 9 },
  wrapperCol: { span: 15 },
};

export const AddressForm = ({ formPath }: { formPath: string }) => {
  const { t } = useI18n();

  const countries = useApiResource(sessionApi.getCountries);

  useEffect(() => {
    countries.refresh();
  }, []);

  if (countries.error) {
    return <ApiErrorDetails error={countries.error} />;
  }

  if (!countries.data) {
    return <Skeleton paragraph={{ rows: 4 }} active={true} />;
  }

  return (
    <FormLayout layout={formLayout}>
      <TextField
        label={t(messages.address.company)}
        name={`${formPath}.company`}
      />
      <TextField
        label={t(messages.address.address1)}
        name={`${formPath}.address1`}
      />
      <TextField
        label={t(messages.address.address2)}
        name={`${formPath}.address2`}
      />
      <TextField
        label={t(messages.address.postalCode)}
        name={`${formPath}.postalCode`}
      />
      <TextField
        label={t(messages.address.city)}
        name={`${formPath}.city`}
      />
      <SelectField
        label={t(messages.address.country)}
        name={`${formPath}.country`}
        showSearch={true}
        style={{ width: '100%' }}
        optionFilterProp="children"
        dropdownMatchSelectWidth={false}
      >
        {countries.data.map((c) => (
          <Select.Option key={c.id}>{c.name}</Select.Option>
        ))}
      </SelectField>
    </FormLayout>
  );
};
