import { ApiConfigs } from './apiConfigs';
import { CustomerWidgetType } from './internalCustomerApi';

export const createApiSettings = (baseApiUri: string) => ({
  baseApiUri,

  checkoutVendorsUri: `${baseApiUri}/checkout/vendors`,

  checkoutSeasonsUri: `${baseApiUri}/checkout/seasons`,

  ordersUri: `${baseApiUri}/orders`,

  placeOrderUri: `${baseApiUri}/orders/place`,

  orderUri: (id: number) => `${baseApiUri}/orders/${id}`,

  orderConfirmationUri: (id: number) =>
    `${baseApiUri}/orders/${id}/confirmation`,

  deliveryNoteUri: (orderId: number, id: number) =>
    `${baseApiUri}/orders/${orderId}/deliveryNote/${id}`,

  draftOrdersCountUri: `${baseApiUri}/orders/draft/count`,

  draftOrderUri: (id: number) => `${baseApiUri}/orders/draft/${id}`,

  draftOrderLineItemsUri: (id: number) =>
    `${baseApiUri}/orders/draft/${id}/lineitems`,

  ordersVendorsUri: `${baseApiUri}/orders/vendors`,

  ordersMetaFieldsUri: `${baseApiUri}/orders/metafields`,

  deliveryDateOptionsUri: `${baseApiUri}/orders/deliverydateoptions`,

  invoicesUri: `${baseApiUri}/invoices`,

  invoicePdfUri: (id: number) => `${baseApiUri}/invoices/${id}/pdf`,

  invoiceCsvUri: `${baseApiUri}/invoices/csv`,

  dashboardInvoicesUri: `${baseApiUri}/dashboard/invoices`,

  dashboardNewsUri: `${baseApiUri}/dashboard/news`,

  articlesUri: `${baseApiUri}/articles`,

  articlesExportUri: `${baseApiUri}/articles/excel`,

  articleUri: (id: number) => `${baseApiUri}/articles/${id}`,

  articleImageUri: (id: number) => `${baseApiUri}/articles/${id}/image`,

  articlePricesUri: (id: number) => `${baseApiUri}/articles/${id}/prices`,

  assortmentsUri: `${baseApiUri}/assortments`,

  cartUri: `${baseApiUri}/cart`,

  documentsUri: `${baseApiUri}/documents`,

  documentsDownloadUri: (id: string) =>
    `${baseApiUri}/documents/download/${id}`,

  notificationsUri: `${baseApiUri}/notifications`,

  articleStatisticsReportUri: `${baseApiUri}/reports/products`,

  customerOrdersReportUri: `${baseApiUri}/reports/customerorders`,

  internalArticleStatisticsReportUri: `${baseApiUri}/internal/reports/products`,

  internalCustomerOrdersReportUri: `${baseApiUri}/internal/reports/customerorders`,

  categoryCodesUri: `${baseApiUri}/category`,

  sessionUri: `${baseApiUri}/session`,

  sessionShopUri: `${baseApiUri}/session/shop`,

  sessionCustomAddressesUri: `${baseApiUri}/session/shop/customaddresses`,

  sessionShopCountriesUri: `${baseApiUri}/session/shop/countries`,

  sessionShopCurrenciesUri: `${baseApiUri}/session/shop/currencies`,

  sessionShopUserCustomersUri: `${baseApiUri}/session/shop/usercustomers`,

  redirectLoginUri: `${baseApiUri}/redirect/login`,

  redirectLogoutUri: `${baseApiUri}/redirect/logout`,

  internalArticlesUri: `${baseApiUri}/internal/articles`,

  internalArticlesSearchUri: `${baseApiUri}/internal/articles/search`,

  internalMissingArticlesExportUri: `${baseApiUri}/internal/articles/missing/excel`,

  internalArticleUri: (id: number) => `${baseApiUri}/internal/articles/${id}`,

  internalArticleSearchUri: (id: number) =>
    `${baseApiUri}/internal/articles/search/${id}`,

  internalArticleImageUri: (id: number) =>
    `${baseApiUri}/internal/articles/${id}/image`,

  internalAssortmentsUri: `${baseApiUri}/internal/assortments`,

  internalAssortmentsArticleUri: `${baseApiUri}/internal/assortments/article`,

  internalAssortmentSummaryUri: (id: number) =>
    `${baseApiUri}/internal/assortments/${id}/summary`,

  internalAssortmentUri: (id: number) =>
    `${baseApiUri}/internal/assortments/${id}`,

  internalAssortmentArticlesUri: (assortmentId: number) =>
    `${baseApiUri}/internal/assortments/${assortmentId}/articles`,

  internalAssortmentArticleUri: (assortmentId: number, articleId: number) =>
    `${baseApiUri}/internal/assortments/${assortmentId}/articles/${articleId}`,

  internalAssortmentsExportUri: `${baseApiUri}/internal/assortments/excel`,

  internalDocumentsUri: `${baseApiUri}/internal/documents`,

  internalDocumentUri: (id: string) => `${baseApiUri}/internal/documents/${id}`,

  internalDocumentDownloadUri: (id: string) =>
    `${baseApiUri}/internal/documents/download/${id}`,

  internalNotificationsUri: `${baseApiUri}/internal/notifications`,

  internalNotificationUri: (id: string) =>
    `${baseApiUri}/internal/notifications/${id}`,

  internalCategoryCodesUri: (branchIds: number[]) => {
    const searchParams = new URLSearchParams({});
    branchIds.forEach((id) => searchParams.append('branchIds', id.toString()));

    return `${baseApiUri}/internal/category?${searchParams.toString()}`;
  },

  internalBranchesUri: `${baseApiUri}/internal/branches`,
  internalBranchUri: (id: number) => `${baseApiUri}/internal/branches/${id}`,

  internalOrderUri: (id: number) => `${baseApiUri}/internal/orders/${id}`,

  internalOrdersUri: `${baseApiUri}/internal/orders`,

  internalOrdersExportUri: `${baseApiUri}/internal/orders/excel`,

  internalReferencesUri: `${baseApiUri}/internal/references`,

  internalReferencesSearchUri: `${baseApiUri}/internal/references/search`,

  internalReferencesByBranchesUri: (branchIds: number[], searchTerm: string) =>
    `${baseApiUri}/internal/references/search/v2?branchIds=${branchIds}&searchTerm=${searchTerm}`,

  internalReferenceUri: (id: number) =>
    `${baseApiUri}/internal/references/${id}`,

  internalProjectsUri: `${baseApiUri}/internal/projects`,

  internalProjectsSearchUri: `${baseApiUri}/internal/projects/search`,

  internalProjectUri: (id: number) => `${baseApiUri}/internal/projects/${id}`,

  internalCustomersUri: `${baseApiUri}/internal/customers`,

  internalCustomersSearchUri: `${baseApiUri}/internal/customers/search`,

  internalCustomerUri: (id: number) => `${baseApiUri}/internal/customers/${id}`,

  internalCustomerDetailsUri: (id: number) =>
    `${baseApiUri}/internal/customers/${id}/details`,

  internalSimpleCustomersUri: `${baseApiUri}/internal/customers/all`,

  internalUserByEmailUri: (email: string) =>
    `${baseApiUri}/internal/users/by-email/${email}`,

  internalUsersUri: `${baseApiUri}/internal/users`,

  internalUserUri: (id: number) => `${baseApiUri}/internal/users/${id}`,

  internalUserCustomersUri: `${baseApiUri}/internal/usercustomers`,

  internalUserCustomerUri: (id: number) =>
    `${baseApiUri}/internal/usercustomers/${id}`,

  internalUserCustomerExportUri: `${baseApiUri}/internal/usercustomers/excel`,

  internalSyncErrorsUri: `${baseApiUri}/internal/sync/errors`,

  internalSyncErrorsExportUri: `${baseApiUri}/internal/sync/excel`,

  internalSyncErrorUri: (id: number) =>
    `${baseApiUri}/internal/sync/errors/${id}`,

  internalCheckoutAdaptersUri: `${baseApiUri}/internal/checkoutAdapters`,

  internalOrderMetaFieldsUri: `${baseApiUri}/internal/orderMetaField`,
  internalOrderMetaFieldUri: (id: number) =>
    `${baseApiUri}/internal/orderMetaField/${id}`,

  internalOrderMetaFieldOptionsUri: `${baseApiUri}/internal/orderMetaField/option`,
  internalOrderMetaFieldOptionUri: (id: number) =>
    `${baseApiUri}/internal/orderMetaField/option/${id}`,

  internalOrderMetaFieldsExportUri: `${baseApiUri}/internal/orderMetaField/options/excel`,

  internalWidgetsUri: `${baseApiUri}/internal/widgets`,

  internalUserWidgetsUri: `${baseApiUri}/internal/widgets/user`,

  removeInternalUserWidgetsUri: (id: number) =>
    `${baseApiUri}/internal/widgets/user/${id}`,

  internalShortageWidgetsUri: `${baseApiUri}/internal/widgets/shortage`,

  internalShortageWidgetUri: (id: number) =>
    `${baseApiUri}/internal/widgets/shortage/${id}`,

  exportInternalShortage: (
    branchIds: number[],
    categories: number[],
    itemTypes: string[],
  ) =>
    `${baseApiUri}/internal/reports/shortage?branchIds=${branchIds}&categories=${categories}&itemTypes=${itemTypes}`,

  internalWidgetCategoriesUri: `${baseApiUri}/internal/widgets/category`,

  internalWidgetCategoryUri: (id: number) =>
    `${baseApiUri}/internal/widgets/category/${id}`,

  exportInternalDelayedDeliveries: (
    branchIds: number[],
    deliveryStatuses: number[],
    referenceIds: number[],
    projectIds: number[],
  ) =>
    `${baseApiUri}/internal/reports/delayedDeliveries?branchIds=${branchIds}&deliveryStatuses=${deliveryStatuses}&referenceIds=${referenceIds}&projectIds=${projectIds}`,

  internalDelayedDeliveryWidgetUri: (id: number) =>
    `${baseApiUri}/internal/widgets/delayedDelivery/${id}`,

  internalDelayedDeliveryWidgetsUri: `${baseApiUri}/internal/widgets/delayedDelivery`,

  internalProductStatisticsReportWidgetsUri: `${baseApiUri}/internal/widgets/productStatisticsReport`,

  internalProductStatisticsReportWidgetUri: (id: number) =>
    `${baseApiUri}/internal/widgets/productStatisticsReport/${id}`,

  downloadInternalProductStatisticsExportUri: (id: string) =>
    `${baseApiUri}/internal/reports/${id}`,

  triggerNewInternalProductStatisticsExportUri: (widgetId: number) =>
    `${baseApiUri}/internal/widgets/ProductStatisticsReport/${widgetId}`,

  internalCustomerOrdersReportsWidgetUri: `${baseApiUri}/internal/widgets/customerOrdersReport`,

  internalCustomerOrdersReportWidgetUri: (id: number) =>
    `${baseApiUri}/internal/widgets/customerOrdersReport/${id}`,

  triggerNewInternalCustomerOrdersExportUri: (widgetId: number) =>
    `${baseApiUri}/internal/widgets/customerOrdersReport/${widgetId}`,

  downloadCustomerOrdersStatisticsExportUri: (id: string) =>
    `${baseApiUri}/internal/widgets/customerOrdersReport/reports/${id}`,

  internalAwaitingDeliveriesWidgetsUri: `${baseApiUri}/internal/widgets/awaitingDeliveries`,

  internalAwaitingDeliveriesWidgetUri: (id: number) =>
    `${baseApiUri}/internal/widgets/awaitingDeliveries/${id}`,

  exportInternalAwaitingDeliveries: (
    branchIds: number[],
    referenceIds: number[],
    projectIds: number[],
  ) =>
    `${baseApiUri}/internal/reports/awaitingDeliveries?branchIds=${branchIds}&referenceIds=${referenceIds}&projectIds=${projectIds}`,

  internalSlowStockWidgetsUri: `${baseApiUri}/internal/widgets/slowStock`,

  internalSlowStockWidgetUri: (id: number) =>
    `${baseApiUri}/internal/widgets/slowStock/${id}`,

  triggerNewSlowStockExportUri: (widgetId: number) =>
    `${baseApiUri}/internal/widgets/slowStock/${widgetId}`,

  downloadSlowStockExportUri: (id: string) =>
    `${baseApiUri}/internal/widgets/slowStock/reports/${id}`,

  internalSimpleCategoryCodesUri: (branchIds: number[], searchTerm: string) => {
    const searchParams = new URLSearchParams({});
    branchIds.forEach((id) => searchParams.append('branchIds', id.toString()));

    return `${baseApiUri}/internal/category/simple?${searchParams}&searchTerm=${searchTerm}`;
  },
  customerWidgetsUri: `${baseApiUri}/widgets`,

  customerUserWidgetsUri: `${baseApiUri}/widgets/user`,

  customerProductStatisticsReportWidgetsUri: `${baseApiUri}/widgets/productStatisticsReport`,

  customerProductStatisticsReportWidgetUri: (id: number) =>
    `${baseApiUri}/widgets/productStatisticsReport/${id}`,

  triggerNewCustomerProductStatisticsExportUri: (widgetId: number) =>
    `${baseApiUri}/widgets/ProductStatisticsReport/${widgetId}`,

  downloadCustomerProductStatisticsExportUri: (id: string) =>
    `${baseApiUri}/reports/${id}`,

  customerWidgetCategoriesUri: `${baseApiUri}/widgets/category`,

  customerWidgetCategoryUri: (id: number) =>
    `${baseApiUri}/widgets/category/${id}`,

  removeCustomerUserWidgetUri: (id: number) =>
    `${baseApiUri}/widgets/user/${id}`,

  disableCustomerWidget: (customerId: number, widgetType: CustomerWidgetType) =>
    `${baseApiUri}/internal/customers/${customerId}/widgets/${widgetType}/disable`,

  enableCustomerWidget: (customerId: number, widgetType: CustomerWidgetType) =>
    `${baseApiUri}/internal/customers/${customerId}/widgets/${widgetType}/enable`,

  updateCustomerGeneralSettings: (customerId: number) =>
    `${baseApiUri}/internal/customers/general/update/${customerId}`,

  customerAwaitingDeliveriesWidgetsUri: `${baseApiUri}/widgets/awaitingDeliveries`,

  customerAwaitingDeliveriesWidgetUri: (id: number) =>
    `${baseApiUri}/widgets/awaitingDeliveries/${id}`,

  exportCustomerAwaitingDeliveries: (branchIds: number[]) => {
    const query = ApiConfigs.splitCollectionIntoQuery(branchIds, 'branchIds');
    return `${baseApiUri}/widgets/awaitingDeliveries/export?${query}`;
  },

  customerBranchesUri: `${baseApiUri}/branches`,

  customerCustomerOrdersReportWidgetsUri: `${baseApiUri}/widgets/customerOrdersReport`,

  customerCustomerOrdersReportWidgetUri: (id: number) =>
    `${baseApiUri}/widgets/customerOrdersReport/${id}`,

  triggerNewCustomerCustomerOrdersReportWidgetUri: (widgetId: number) =>
    `${baseApiUri}/widgets/customerOrdersReport/${widgetId}`,

  downloadCustomerCustomerOrdersReportExportUri: (id: string) =>
    `${baseApiUri}/widgets/customerOrdersReport/reports/${id}/download`,

  internalGetUpcomingSyncs: `${baseApiUri}/internal/sync/upcoming`,
});

export type ApiSettings = ReturnType<typeof createApiSettings>;
