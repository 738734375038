import { useI18n } from '../../../../../hooks/useI18n';
import { useNotificationStore } from '../../../../../hooks/useNotificationStore/notificationStore';
import React, { useState } from 'react';
import { ApiError, useApiRequests } from '../../../../../hooks/useApi';
import { messages } from '../../../../../messages';
import { useFormik } from 'formik';
import { ColorResult, TwitterPicker } from 'react-color';
import { Input, Modal } from 'antd';
import { LoadingIndicator } from '../../../../loadingIndicator/loadingIndicator';
import { ApiErrorDetails } from '../../../../apiErrorDetails/apiErrorDetails';
import { Form } from '../../../../form/form';
import style from '../styling/widgetRowStyling.module.scss';
import { defaultWidgetColors } from '../../../../internal/widgets/widgetColors';
import {
  CreateUpdateAwaitingDeliveriesWidgetRequest,
  customerAwaitingDeliveriesWidgetApi,
} from '../../../../../api/widgets/customers/customerAwaitingDeliveriesWidgetApi';
import { CustomerWidgetCategorySelect } from '../categories/customerWidgetCategorySelect';
import { CustomerBranchPicker } from '../../../../pickers/customerBranchPicker';

export const CreateCustomerAwaitingDeliveriesModal = ({
  onClose,
}: {
  onClose: () => void;
}) => {
  const { t } = useI18n();
  const { notify } = useNotificationStore();

  const [error, setError] = useState<ApiError>();

  const [name, setName] = useState<string>('');
  const [widgetCategoryId, setWidgetCategoryId] = useState<number | undefined>(
    undefined,
  );
  const [color, setColor] = useState<string | undefined>(undefined);
  const [branchIds, setBranchIds] = useState<number[]>([]);

  const awaitingDeliveriesWidget = useApiRequests(
    customerAwaitingDeliveriesWidgetApi.createWidget,
  );

  const handleFormSubmit = () => {
    const createWidgetData: CreateUpdateAwaitingDeliveriesWidgetRequest = {
      name: name,
      widgetCategoryId: widgetCategoryId,
      color: color,
      branchIds: branchIds,
    };

    awaitingDeliveriesWidget
      .create({
        onSuccess: () => {
          notify({
            title: t(messages.widgets.widgetCreated),
            type: 'success',
          });
          onClose();
        },
        onError: setError,
      })
      .send(createWidgetData);
  };

  const formik = useFormik<FormData>({
    initialValues: {
      name: '',
      widgetCategoryId: undefined,
      color: undefined,
      branchIds: [],
    },
    onSubmit: handleFormSubmit,
  });

  function handleWidgetCategoryOnChange(widgetCategoryId: string | undefined) {
    const id =
      widgetCategoryId === undefined
        ? widgetCategoryId
        : parseInt(widgetCategoryId);
    setWidgetCategoryId(id);
  }

  function handleSelectedBranches(selectedBranchIds: number[]) {
    setBranchIds(selectedBranchIds);
  }

  function handleChangeColor(color: ColorResult) {
    setColor(color.hex);
  }

  return (
    <Modal
      title={t(messages.widgets.awaitingDeliveries.create)}
      visible={true}
      closable={false}
      onCancel={onClose}
      onOk={formik.submitForm}
      okText={t(messages.shared.save)}
      width={720}
    >
      {awaitingDeliveriesWidget.isLoading && <LoadingIndicator />}
      {error && <ApiErrorDetails error={error} />}
      <Form form={formik}>
        <div className={style.inputRow}>
          <h4>{t(messages.shared.name)}</h4>
          <Input
            value={name}
            onChange={(e) => setName(e.target.value)}
            placeholder={t(messages.widgets.widgetNamePlaceholder)}
          />
          <CustomerWidgetCategorySelect
            value={widgetCategoryId?.toString()}
            onChange={handleWidgetCategoryOnChange}
          />
        </div>
        <div className={style.inputRow}>
          <h4>{t(messages.shared.color)}</h4>
          <TwitterPicker
            width="100%"
            triangle="hide"
            colors={defaultWidgetColors}
            color={color}
            onChange={handleChangeColor}
          />
        </div>
        <div className={style.inputRow}>
          <h4>{t(messages.shared.branch)}</h4>
          <CustomerBranchPicker
            branchIds={branchIds}
            onChange={handleSelectedBranches}
          />
        </div>
      </Form>
    </Modal>
  );
};

interface FormData {
  name: string;
  widgetCategoryId: number | undefined;
  color: string | undefined;
  branchIds: number[];
}
