export type Contact = {
  id: number;
  name: string;
  telephone: string;
  fax?: string;
  email: string;
};

export const contacts: Contact[] = [
  {
    id: 1,
    name: 'Rudholm & Haak AB',
    telephone: '+46 33 1801100',
    email: 'order.se@rudholmgroup.com',
  },
  {
    id: 2,
    name: 'Rudholm & Haak (HK) Ltd',
    telephone: '(852) 2426 1782',
    email: 'order.hk@rudholmgroup.com',
  },
  {
    id: 3,
    name: 'Rudholm Bangladesh Ltd',
    telephone: '+880 (0)2 988 0070',
    email: 'order.bd@rudholmgroup.com',
  },
  {
    id: 4,
    name: 'Rudholm & Haak (PVT) LTD',
    telephone: '+91 080 4090 1154',
    email: 'order.in@rudholmgroup.com',
  },
  {
    id: 5,
    name: 'Rudholm Tekstil Aksesuarlari Sanayi Ve Tic. Ltd',
    telephone: '00 90 212 603 1357',
    email: 'order.tr@rudholmgroup.com',
  },
  {
    id: 6,
    name: 'Rudholm UK Ltd',
    telephone: '+44 (0)121 459 5800',
    email: 'order.uk@rudholmgroup.com',
  },
  {
    id: 7,
    name: 'Rudholm & Haak Vietnam',
    telephone: '84-8-3816 4271',
    email: 'order.vn@rudholmgroup.com',
  },
  {
    id: 8,
    name: 'RHG USA LLC',
    telephone: '+1 212-933-9588',
    email: 'order.usa@rudholmgroup.com',
  },
  {
    id: 9,
    name: 'Rudholm Portugal LDA',
    telephone: '+351 256 031 809',
    email: 'order.pt@rudholmgroup.com',
  },
  {
    id: 10,
    name: 'Rudholm Poland SP Z.O.O.',
    telephone: '+48 601 282 814',
    email: 'order.pl@rudholmgroup.com',
  },
  {
    id: 11,
    name: 'RH Germany',
    telephone: '+49 89 52031800',
    email: 'order.de@rudholmgroup.com',
  },
];
