import {
  NestedTranslations,
  NestedPaths,
  OptionalNestedTranslations,
  FlattenedTranslations,
} from './types';

const composePath = (key: string, parentPath?: string) =>
  parentPath ? `${parentPath}.${key}` : key;

// Returns an object referencing it self with nested path values
// Example return value:
// {
//   test1: {
//     test2: 'test1.test2',
//   },
//   test3: 'test3',
// }
export function getNestedPaths(
  translations: NestedTranslations,
  parentNamespace?: string,
) {
  const currentValues: NestedPaths = {};

  for (const key of Object.keys(translations)) {
    const value = translations[key];
    const currentPath = composePath(key, parentNamespace);
    if (typeof value === 'string') {
      currentValues[key] = currentPath;
    } else {
      currentValues[key] = getNestedPaths(value, currentPath);
    }
  }

  return currentValues;
}

// Flattens translations to a key/value object
// Example return value:
// {
//   "test1.test2": "hello2",
//   "test3": "world",
// }
export function getFlattenedTranslations(
  translations: OptionalNestedTranslations<any>,
  parentNamespace?: string,
) {
  let currentTranslations: FlattenedTranslations = {};

  for (const key of Object.keys(translations)) {
    const value = translations[key];
    if (value === null) {
      continue;
    }

    const currentPath = composePath(key, parentNamespace);
    if (typeof value === 'string') {
      currentTranslations[currentPath] = value;
    } else {
      currentTranslations = {
        ...currentTranslations,
        ...getFlattenedTranslations(value, currentPath),
      };
    }
  }

  return currentTranslations;
}
