import { useState, useRef, useEffect } from 'react';
import { ApiRequest, ApiError } from './types';
import { useHttpClient } from '../useHttpClient';
import { useApiSettings } from './useApiSettings';

export const useApiResource = <A extends any[], R, E extends R | undefined>(
  apiRequestFactory: (...args: A) => ApiRequest<R>,
  options?: { emptyValue?: E; clearDataOnLoad?: boolean },
) => {
  const clearDataOnLoad = options?.clearDataOnLoad ?? true;
  const httpClient = useHttpClient();
  const apiSettings = useApiSettings();
  const isMounted = useRef(true);
  const latestRequestIdentifier = useRef<symbol>();
  const [isLoading, setLoading] = useState(false);
  const [data, setData] = useState<E | undefined>(options?.emptyValue);
  const [error, setError] = useState<ApiError>();
  const [hasResponse, setHasResponse] = useState<boolean>();
  const emptyValue = options?.emptyValue;

  const reset = (value?: E) => {
    setData(value ?? emptyValue);
    setError(undefined);
    setLoading(false);
    setHasResponse(false);
    latestRequestIdentifier.current = undefined;
  };

  const refresh = async (...args: A) => {
    setLoading(true);

    if (clearDataOnLoad) {
      setData(emptyValue);
    }

    const currentRequestId = Symbol();
    latestRequestIdentifier.current = currentRequestId;

    const apiRequest = apiRequestFactory(...args);
    const { result, error } = await apiRequest({
      httpClient,
      apiSettings,
    });

    if (
      currentRequestId !== latestRequestIdentifier.current ||
      !isMounted.current
    ) {
      return;
    }

    setHasResponse(true);

    if (error !== undefined) {
      setError(error);
      setData(emptyValue);
    } else {
      setError(undefined);
      setData(result as any);
    }

    setLoading(false);
  };

  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  return { isLoading, data, error, hasResponse, refresh, reset } as {
    isLoading: boolean;
    data: E;
    error: ApiError | undefined;
    hasResponse: boolean;
    refresh: (...args: A) => void;
    reset: (val?: E) => void;
  };
};
