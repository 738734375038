import { ApiRequest } from '../hooks/useApi';

export const dashboardApi = {
  getOverdueInvoices: (): ApiRequest<DueInvoicesOverview> => async ({
    httpClient,
    apiSettings,
  }) => {
    const response = await httpClient.get(apiSettings.dashboardInvoicesUri);

    if (response.hasError) {
      return { error: response.error };
    } else {
      return {
        result: response.data,
      };
    }
  },
  getNews: (): ApiRequest<NewsItem[]> => async ({
    httpClient,
    apiSettings,
  }) => {
    const response = await httpClient.get(apiSettings.dashboardNewsUri);

    if (response.hasError) {
      return { error: response.error };
    } else {
      return {
        result: response.data,
      };
    }
  },
};

export interface NewsItem {
  title: string;
  link: string;
  posted: string;
}

export interface DueInvoicesOverview {
  additionalDueInvoicesAvailable: boolean;
  top: DueInvoice[];
}

export interface DueInvoice {
  id: number;
  invoiceNumber: string;
  dueDate: string;
  pdfGenerated: boolean;
  remaining: number;
  currencyCode: string;
}
