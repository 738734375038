import React from 'react';
import { Select } from 'antd';
import { useField } from 'formik';
import { LabeledValue, SelectProps } from 'antd/lib/select';
import { FormItem } from './formItem';

export function SelectField({
  name,
  label,
  children,
  ...rest
}: { name: string; label?: string; children: React.ReactNode } & SelectProps<
  LabeledValue
>) {
  const [inputProps, metaProps, helpers] = useField<LabeledValue>(name);
  const showError = metaProps.touched && metaProps.error;

  return (
    <FormItem
      label={label}
      validateStatus={showError ? 'error' : undefined}
      help={showError ? <li>{metaProps.error}</li> : undefined}
    >
      <Select<LabeledValue>
        labelInValue={true}
        {...rest}
        {...inputProps}
        onChange={(value) => {
          helpers.setValue(value);
        }}
        onBlur={() => {
          helpers.setTouched(true);
        }}
      >
        {children}
      </Select>
    </FormItem>
  );
}
