import React, { useEffect } from 'react';
import { Tabs, Badge } from 'antd';
import styles from './orders.module.scss';
import { DraftOrders } from './draftOrders/draftOrders';
import { PlacedOrders } from './placedOrders/placedOrders';
import { useI18n } from '../../../hooks/useI18n';
import { messages } from '../../../messages';

export enum OrderTabs {
  Placed = 'placed',
  Draft = 'draft',
}

export const Orders = ({
  activeTab,
  draftOrdersCount,
  onChangeActiveTab,
  onSelectOrderId,
  onRefreshDraftOrdersCount,
}: {
  activeTab: OrderTabs;
  draftOrdersCount?: number;
  onChangeActiveTab: (activeTab: OrderTabs) => void;
  onSelectOrderId: (id: number) => void;
  onRefreshDraftOrdersCount: () => void;
}) => {
  const { t } = useI18n();

  useEffect(() => {
    onRefreshDraftOrdersCount();
  }, []);

  return (
    <div className={styles.container}>
      <Tabs
        animated={false}
        activeKey={activeTab}
        onChange={(e) => onChangeActiveTab(e as OrderTabs)}
        tabBarStyle={{ margin: 0 }}
      >
        <Tabs.TabPane tab={t(messages.order.placed)} key={OrderTabs.Placed} />
        <Tabs.TabPane
          tab={
            <Badge
              className={styles.tabBadge}
              dot={(draftOrdersCount ?? 0) > 0}
            >
              {t(messages.order.unplaced)}
            </Badge>
          }
          key={OrderTabs.Draft}
        />
      </Tabs>
      {activeTab === OrderTabs.Placed && (
        <PlacedOrders onSelectOrderId={onSelectOrderId} />
      )}
      {activeTab === OrderTabs.Draft && (
        <DraftOrders onSelectOrderId={onSelectOrderId} />
      )}
    </div>
  );
};
