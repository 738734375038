import React, { useState } from 'react';
import { ContentLayout } from '../../contentLayout/contentLayout';
import { useI18n } from '../../../hooks/useI18n';
import { messages } from '../../../messages';
import { Tabs } from 'antd';
import { ProductStatisticsReport } from './productStatistics';
import { CustomerOrdersReport } from './customerOrders';

export function Reports() {
  const { t } = useI18n();
  const [activeReportTab, setActiveReportTab] = useState<number>(1);

  return (
    <ContentLayout title={t(messages.reports.reports)}>
      <Tabs
        animated={false}
        activeKey={activeReportTab.toString()}
        tabBarStyle={{ margin: 0 }}
        onChange={(id) => setActiveReportTab(parseInt(id, 10))}
      >
        <Tabs.TabPane
          tab={t(messages.reports.articleStatistics.report)}
          key="1"
        >
          <ProductStatisticsReport />
        </Tabs.TabPane>
        <Tabs.TabPane tab={t(messages.reports.customerOrders.report)} key="2">
          <CustomerOrdersReport />
        </Tabs.TabPane>
      </Tabs>
    </ContentLayout>
  );
}
