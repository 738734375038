import moment from 'moment';
import React from 'react';
import { OrderDelivery } from '../../../../api/ordersApi';
import { Radio, DatePicker, Select, Tooltip } from 'antd';
import { useField } from 'formik';
import { useI18n } from '../../../../hooks/useI18n';

export enum DeliveryDateOptionType {
  SetDate = 0,
  Upcoming = 1,
}

export const DeliveryDatePicker = ({
  name,
  upcomingDeliveries,
  earliestDate,
  nonWorkingDates: nonWorkingDays,
  showToday,
  disabled,
  disabledTooltip,
}: {
  name: string;
  upcomingDeliveries?: OrderDelivery[];
  earliestDate?: Date;
  nonWorkingDates: Date[];
  showToday?: boolean;
  disabled: boolean;
  disabledTooltip: string;
}) => {
  const [field, , helpers] = useField<{
    type: number;
    d1?: moment.Moment;
    d2?: number;
  }>(name);
  const { formatDate, t } = useI18n();

  const RadioGroupTooltip = ({ children }: { children: React.ReactNode }) =>
    disabled ? (
      <Tooltip title={t(disabledTooltip)}>
        {children}
      </Tooltip>
    ) : (
      <>{children}</>
    );

  return (
    <RadioGroupTooltip>
      <Radio.Group
        onChange={(e) => {
          helpers.setValue({ ...field.value, type: e.target.value });
        }}
        value={field.value.type}
        disabled={disabled}
      >
        <Radio value={DeliveryDateOptionType.SetDate}>
          <span>Pick a date </span>
          <DatePicker
            disabledDate={(d) => {
              if (d?.isBefore(earliestDate, 'day')) return true;

              return nonWorkingDays.some((nwd) => d?.isSame(nwd, 'day'));
            }}
            value={field.value.d1}
            onChange={(v) => {
              helpers.setValue({
                ...field.value,
                type: DeliveryDateOptionType.SetDate,
                d1: v ?? undefined,
              });
            }}
            allowClear={false}
            disabled={disabled || earliestDate === undefined}
            showToday={showToday !== undefined ? showToday : true}
          />
        </Radio>
        {!!upcomingDeliveries?.length && (
          <Radio value={DeliveryDateOptionType.Upcoming} disabled={disabled}>
            <span>Deliver together with </span>
            <Select<number>
              onChange={(v) => {
                helpers.setValue({
                  ...field.value,
                  type: DeliveryDateOptionType.Upcoming,
                  d2: v,
                });
              }}
              value={field.value.d2}
              disabled={disabled}
            >
              {upcomingDeliveries.map((d) => (
                <Select.Option key={d.orderId} value={d.orderId}>
                  {formatDate(d.deliveryDate)} ({d.orderNumber})
                </Select.Option>
              ))}
            </Select>
          </Radio>
        )}
      </Radio.Group>
    </RadioGroupTooltip>
  );
};
