import { useRef, useEffect, useCallback } from 'react';
import throttle from 'lodash.throttle';

export function useThrottleCallback<T extends (...args: any) => any>(
  setCallback: T,
  waitTimeMs: number,
) {
  const callback = useRef<T | undefined>(setCallback);

  useEffect(() => {
    callback.current = setCallback;
  }, [setCallback]);

  useEffect(() => {
    return () => {
      callback.current = undefined;
    };
  }, []);

  return useCallback(
    throttle(callback.current!, waitTimeMs, { trailing: false }),
    [],
  ) as T;
}
