import React from 'react';
import styles from './filters.module.scss';

export function Filters({ children }: { children: React.ReactNode }) {
  return <div className={styles.container}>{children}</div>;
}

Filters.Filter = Filter;

function Filter({
  title,
  children,
}: {
  title: string;
  children: React.ReactNode;
}) {
  return (
    <div className={styles.filter}>
      <div className={styles.filterHeader}>{title}</div>
      {children}
    </div>
  );
}
