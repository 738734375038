import React, { useEffect, useState } from 'react';
import { ApiError, useApiRequests } from '../../../../hooks/useApi';
import {
  CustomerWidgetCategory,
  customerWidgetsApi,
} from '../../../../api/widgets/customers/customerWidgetsApi';
import { Button } from 'antd';
import { SelectWidgetToCreateModal } from './selectWidgetToCreateModal';
import { DragDropContext, DropResult } from 'react-beautiful-dnd';
import { swap } from '../../../../helpers/swap';
import { move } from '../../../../helpers/move';
import { LoadingIndicator } from '../../../loadingIndicator/loadingIndicator';
import styles from './widgetDashboard.module.scss';
import { ApiErrorDetails } from '../../../apiErrorDetails/apiErrorDetails';
import { CustomerWidgetCategoryCard } from './customerWidgetCategoryCard';
import { CreateCustomerCategoryModal } from './categories/createCustomerCategoryModal';
import { UpdateUserWidgetsSortOrderRequest } from '../../../../api/internalWidgetsApi';
import { useI18n } from '../../../../hooks/useI18n';
import { messages } from '../../../../messages';

export const WidgetDashboard = () => {
  const { t } = useI18n();
  const categories = useApiRequests(customerWidgetsApi.getUserWidgets);

  const [createWidgetModal, setCreateWidgetModal] = useState<boolean>();
  const [showCreateCategoryModal, setShowCreateCategoryModal] = useState<boolean>(false);
  const userWidgetsSortOrder = useApiRequests(customerWidgetsApi.updateCustomerWidgetsSortOrder);

  const [categoriesMap, setCategoriesMap] = useState<Map<number, CustomerWidgetCategory>>(new Map());
  const [shouldUpdateSortOrder, setShouldUpdateSortOrder] = useState<number>();

  const [error, setError] = useState<ApiError>();

  const refreshCategories = () => {
    categories.create({
      onSuccess: (response) => {
        const result: Map<number, CustomerWidgetCategory> = response.reduce((obj, cur) => obj.set(cur.id, cur), new Map<number, CustomerWidgetCategory>());
        setCategoriesMap(result);
      },
      onError: setError,
    })
      .send();
  };

  useEffect(() => {
    refreshCategories();
  }, []);

  const updateUserWidgetsSortOrder = (categoryId: number) => {
    const category = categoriesMap.get(categoryId);
    if (!category)
      return;

    const userWidgetIds: UpdateUserWidgetsSortOrderRequest = {
      categoryId: category.id,
      userWidgetIds: category.widgets.map(widget => widget.id),
    };

    userWidgetsSortOrder.create({
        onError: setError,
      },
    ).send(userWidgetIds);
  };

  const removeWidgetFromCategory = (widgetId: number, categoryId: number) => {
    const category = categoriesMap.get(categoryId);
    if (!category) {
      return;
    }
    const widgetIndex = category.widgets.findIndex(w => w.id === widgetId);
    if (widgetIndex === -1) {
      return;
    }
    category.widgets.splice(widgetIndex, 1);
    const newMap = new Map(categoriesMap);
    newMap.set(categoryId, category);
    setCategoriesMap(newMap);
  };

  function MoveWidget(result: DropResult) {
    const { source, destination } = result;
    if (!destination) {
      return;
    }
    const sourceId = +source.droppableId;
    const destinationId = +destination.droppableId;
    const sourceCategory = categoriesMap.get(sourceId);
    const destinationCategory = categoriesMap.get(destinationId);

    if (!sourceCategory) {
      return;
    }

    if (sourceId === destinationId) {
      const result = swap(sourceCategory.widgets, source.index, destination.index);
      const newSourceCategory: CustomerWidgetCategory = {
        id: sourceCategory.id,
        name: sourceCategory.name,
        widgets: result,
      };

      setCategoriesMap(new Map(categoriesMap.set(sourceId, newSourceCategory)));
      setShouldUpdateSortOrder(sourceId);
    } else if (destinationCategory) {
      const result = move(
        sourceCategory.widgets,
        destinationCategory.widgets,
        source.index,
        destination.index,
      );

      const newSourceCategory: CustomerWidgetCategory = {
        id: sourceCategory.id,
        name: sourceCategory.name,
        widgets: result[0],
      };

      const newDestinationCategory: CustomerWidgetCategory = {
        id: destinationCategory.id,
        name: destinationCategory.name,
        widgets: result[1],
      };

      const newMap = new Map(categoriesMap);
      newMap.set(sourceId, newSourceCategory);
      newMap.set(destinationId, newDestinationCategory);
      setCategoriesMap(newMap);
      setShouldUpdateSortOrder(destinationId);
    }
  }

  useEffect(() => {
    if (shouldUpdateSortOrder !== undefined) {
      updateUserWidgetsSortOrder(shouldUpdateSortOrder);
      setShouldUpdateSortOrder(undefined);
    }
  }, [categoriesMap]);

  return (
    <>
      <Button type='default' className={styles.createNewWidget}
              onClick={() => setCreateWidgetModal(true)}>
        {t(messages.widgets.create)}
      </Button>
      <Button type='default' onClick={() => setShowCreateCategoryModal(true)}>
        {t(messages.widgets.categories.createCategory)}
      </Button>
      <div className={styles.apiError}>
        {error && <ApiErrorDetails error={error} />}
      </div>
      {categories.isLoading && <LoadingIndicator />}
      {!categories.isLoading && (
        <DragDropContext onDragEnd={MoveWidget}>
          {Array.from(categoriesMap.values()).map(category =>
            <CustomerWidgetCategoryCard
              key={category.id}
              category={category}
              onCategoryDeleted={() => refreshCategories()}
              onCategoryUpdated={() => refreshCategories()}
              onWidgetDeleted={(widgetId) => removeWidgetFromCategory(widgetId, category.id)}
            />,
          )}
        </DragDropContext>)}
      {!categories.isLoading && categoriesMap.size === 0 &&
      <div className={styles.noCategories}>
        {t(messages.widgets.categories.noCategories)}
      </div>}
      {createWidgetModal &&
      <SelectWidgetToCreateModal onClose={() => {
        setCreateWidgetModal(false);
        refreshCategories();
      }} />}
      {showCreateCategoryModal &&
      <CreateCustomerCategoryModal
        onCancel={() => setShowCreateCategoryModal(false)}
        onSuccess={() => {
          setShowCreateCategoryModal(false);
          refreshCategories();
        }} />}
      <div className={styles.bottomBox}></div>
    </>
  );
};
