import React from 'react';
import { Alert, Icon } from 'antd';
import styles from './details.module.scss';
import { messages } from '../../../../messages';
import { DescriptionList } from '../../../descriptionList/descriptionList';
import { useI18n } from '../../../../hooks/useI18n';
import { ImageZoom } from '../../../imageZoom/imageZoom';
import { formatNumber } from '../../../../helpers/formatNumber/formatNumber';
import { ArticleType, Currency } from '../../../../types';
import { ArticleDetails, ArticlePrice } from '../../../../api/articlesApi';
import { useFileUris } from '../../../../hooks/useFileUrls/useFileUrls';

export function Details({
  article,
  showPrice = true,
  displayCurrency,
  articlePrices,
}: {
  article: ArticleDetails;
  showPrice?: boolean;
  displayCurrency?: Currency;
  articlePrices?: ArticlePrice[];
}) {
  const { t } = useI18n();
  const { placeholderImageFileUri, articleImageFileUri } = useFileUris();
  const displayPrice = articlePrices?.find(
    (articlePrice) => articlePrice.articleId === article.id,
  )?.price;

  return (
    <div className={styles.container}>
      <div className={styles.imageWrapper}>
        <ImageZoom
          className={styles.image}
          imageUrl={
            article.hasImage
              ? articleImageFileUri(article.id)
              : placeholderImageFileUri
          }
        />
        <Icon type="zoom-in" className={styles.zoomIcon} />
      </div>
      <div>
        <h2>{article.articleNumber}</h2>
        {article.frozen && (
          <div className={styles.frozenAlert}>
            <Alert
              icon={<Icon type="lock" />}
              message={t(messages.article.frozenAlert)}
              showIcon={true}
              type="warning"
            />
          </div>
        )}
        {!!article.syncError && (
          <div className={styles.frozenAlert}>
            <Alert
              icon={<Icon type="warning" />}
              message={article.syncError}
              showIcon={true}
              type="error"
            />
          </div>
        )}

        <DescriptionList>
          <DescriptionList.Item title={t(messages.article.description)}>
            {article.description}
          </DescriptionList.Item>
          <DescriptionList.Item title={t(messages.article.articleNumber)}>
            {article.articleNumber}
          </DescriptionList.Item>
          {!!article.colorName && (
            <DescriptionList.Item title={t(messages.article.color)}>
              {article.colorName}
            </DescriptionList.Item>
          )}
          {!!article.sizeName && (
            <DescriptionList.Item title={t(messages.article.size)}>
              {article.sizeName}
            </DescriptionList.Item>
          )}
          {!!article.unit && (
            <DescriptionList.Item title={t(messages.article.unit)}>
              {article.unit}
            </DescriptionList.Item>
          )}
          {showPrice &&
            article.type !== ArticleType.Matrix &&
            !article.frozen && (
              <DescriptionList.Item
                title={`${t(messages.shared.price)} (${article.currencyCode})`}
              >
                {formatNumber(
                  article.displayPrice,
                  article.currencyPriceDecimalPlaces,
                )}
              </DescriptionList.Item>
            )}
          {showPrice &&
            article.type !== ArticleType.Matrix &&
            !article.frozen &&
            !!displayPrice &&
            !!displayCurrency &&
            displayCurrency.code !== article.currencyCode && (
              <DescriptionList.Item
                title={`${t(messages.shared.price)} (${displayCurrency.code})`}
              >
                {formatNumber(displayPrice, displayCurrency.priceDecimalPlaces)}
              </DescriptionList.Item>
            )}
          <DescriptionList.Item title={t(messages.shared.otherInformation)}>
            <span className={styles.extraDescription}>
              {article.extraDescription}
            </span>
          </DescriptionList.Item>
        </DescriptionList>
      </div>
    </div>
  );
}
