import { CheckoutAdapter } from './types';
import { ArticleModal } from '../components/shop/articleModal/articleModal';
import { LineItems } from '../components/shop/lineItems/lineItems';
import { useNotificationStore } from '../hooks/useNotificationStore/notificationStore';
import { Checkout } from '../components/shop/checkout/checkout';
import { UpdateLineItem } from '../types';
import { DraftOrderFields } from '../components/shop/draftOrder/draftOrderFields';

export function useCheckoutAdapterDefault(): CheckoutAdapter {
  const notificationStore = useNotificationStore();

  const onUpdateCartUpdateSuccess = (
    _item: UpdateLineItem,
    cartIsVisible: boolean,
  ) => {
    if (cartIsVisible) {
      notificationStore.notify({
        title: 'Updated cart!',
        type: 'success',
      });
    }
  };

  return {
    ArticleModal,
    Checkout,
    DraftOrderFields,
    LineItems,
    onUpdateCartUpdateSuccess,
    orderRefLimit: 50,
    orderRefRequired: false,
    schemaForMetaField: () => undefined,
    maxCartSize: null,
    showNewsFeed: true,
    showOverdueInvoices: true,
    showPrice: true,
    showQuantityInCart: true,
  };
}
