import React from 'react';
import {
  useI18n,
  TranslateFunction,
  FormatDateFunction,
} from '../../../../hooks/useI18n';
import { Table, Columns } from '../../../table';
import { messages } from '../../../../messages';
import { formatNumber } from '../../../../helpers/formatNumber/formatNumber';
import { DeliveryStatusBadge } from '../../../deliveryStatusBadge/deliveryStatusBadge';
import { DeliveryStatus, OrderRow } from '../../../../types';
import styles from './orderRows.module.scss';

export const getListColumns = (
  t: TranslateFunction,
  formatDate: FormatDateFunction,
  currencyCode: string,
  currencyPriceDecimalPlaces: number,
): Columns<OrderRow> => [
  {
    header: t(messages.article.article),
    width: 240,
    sortable: false,
    cellClassName: styles.cell,
    cell: ({ row }) => (
      <>
        <div className={styles.articleNumber}>{row.articleNumber}</div>
        <div className={styles.articleDescription}>
          {row.articleDescription}
        </div>
        <div className={styles.articleDescription}>{row.articleColor}</div>
        <div className={styles.articleDescription}>{row.articleSize}</div>
      </>
    ),
  },
  {
    header: t(messages.shared.quantity),
    accessor: 'quantityOrdered',
    sortable: false,
    align: 'right',
    width: 80,
    cellClassName: styles.cell,
    cell: ({ row }) => row.quantityOrdered,
  },
  {
    header: t(messages.order.delivered),
    sortable: false,
    align: 'right',
    width: 110,
    cellClassName: styles.cell,
    cell: ({ row }) => {
      let deliveryStatus: DeliveryStatus;

      if (row.quantityDelivered >= row.quantityOrdered) {
        deliveryStatus = DeliveryStatus.Delivered;
      } else if (row.quantityDelivered <= 0) {
        deliveryStatus = DeliveryStatus.Undelivered;
      } else {
        deliveryStatus = DeliveryStatus.PartiallyDelivered;
      }

      return (
        <div>
          <span style={{ marginRight: '10px' }}>{row.quantityDelivered}</span>
          <DeliveryStatusBadge
            deliveryStatus={deliveryStatus}
            showText={false}
          />
        </div>
      );
    },
  },
  {
    header: t(messages.order.deliveryDates),
    width: 110,
    sortable: false,
    cellClassName: styles.cell,
    cell: ({ row }) => {
      if (row.deliveryDates.length) {
        return row.deliveryDates.map((deliveryDate, i) => (
          <div key={i}>{formatDate(deliveryDate)}</div>
        ));
      }

      return '-';
    },
  },
  {
    header: <span>{`${t(messages.shared.price)} (${currencyCode})`}</span>,
    accessor: 'unitPrice',
    align: 'right',
    cellClassName: styles.cell,
    cell: ({ row }) => formatNumber(row.unitPrice, currencyPriceDecimalPlaces),
    sortable: false,
  },
  {
    header: t(messages.shared.totalPrice),
    accessor: 'totalPrice',
    align: 'right',
    cellClassName: styles.cell,
    cell: ({ row }) => formatNumber(row.totalPrice, 2),
    sortable: false,
  },
];

interface Props {
  rows: OrderRow[];
  currencyCode: string;
  currencyPriceDecimalPlaces: number;
}

const OrderRowsComponent = ({
  rows,
  currencyCode,
  currencyPriceDecimalPlaces,
}: Props) => {
  const { t, formatDate } = useI18n();
  const listColumns = getListColumns(
    t,
    formatDate,
    currencyCode,
    currencyPriceDecimalPlaces,
  );

  return <Table<OrderRow> columns={listColumns} items={rows} />;
};

export const OrderRows = React.memo(OrderRowsComponent);
