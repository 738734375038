/* eslint-disable react-hooks/rules-of-hooks */
import constate from 'constate';
import { useEffect, useMemo } from 'react';
import { useApiResource } from '../../useApi';
import { ShopSession, CustomerType, CheckoutAdapterType } from '../../../types';
import { sessionApi } from '../../../api/sessionApi';
import { useCheckoutAdapter3M } from '../../../checkoutAdapters/3M/useCheckoutAdapter3M';
import { useCheckoutAdapterDefault } from '../../../checkoutAdapters/useCheckoutAdapterDefault';
import { CheckoutAdapter } from '../../../checkoutAdapters/types';

const shopSessionStore = ({
  session,
  onEndSession,
  onLogout,
}: {
  session: ShopSession;
  onEndSession: () => void;
  onLogout: () => void;
}) => {
  const currencies = useApiResource(sessionApi.getCurrencies);
  const checkoutAdapter3M = useCheckoutAdapter3M();
  const defaultCheckoutAdapter = useCheckoutAdapterDefault();

  const adapters: Record<CheckoutAdapterType, CheckoutAdapter> = {
    [CheckoutAdapterType.Default]: defaultCheckoutAdapter,
    [CheckoutAdapterType._3M]: checkoutAdapter3M,
  };

  const checkoutAdapter = adapters[session.checkoutAdapter];

  useEffect(() => {
    if (session.customerType === CustomerType.Brand) {
      currencies.refresh();
    }
  }, [session]);

  // Memoize to only update consumers when specified deps changes
  return useMemo(() => {
    return {
      session,
      apiError: currencies.error,
      currencies: currencies.data,
      isLoadingCurrencies: currencies.isLoading,
      logout: onLogout,
      endSession: onEndSession,
      checkoutAdapter,
    };
  }, [
    session,
    currencies.error,
    currencies.data,
    currencies.isLoading,
    checkoutAdapter,
  ]);
};

const [ShopSessionStoreProvider, useShopSessionStore] = constate(
  shopSessionStore,
);

export { ShopSessionStoreProvider, useShopSessionStore };
