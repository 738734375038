import { NestedTranslations } from './types';
import { getFlattenedTranslations, getNestedPaths } from './helpers';

// Outputs flattened translations and a nested object with self referencing paths as values.
export function createMessages<T extends NestedTranslations>(
  defaultNestedTranslations: T,
) {
  const defaultTranslations = getFlattenedTranslations(
    defaultNestedTranslations,
  );
  const messageKeys: T = getNestedPaths(defaultNestedTranslations) as any;

  return { messages: messageKeys, defaultTranslations };
}
