import { useI18n } from '../../../../../hooks/useI18n';
import { useNotificationStore } from '../../../../../hooks/useNotificationStore/notificationStore';
import React, { useEffect, useState } from 'react';
import { ApiError, useApiRequests } from '../../../../../hooks/useApi';
import { messages } from '../../../../../messages';
import { useFormik } from 'formik';
import { Input, Modal } from 'antd';
import { LoadingIndicator } from '../../../../loadingIndicator/loadingIndicator';
import { ApiErrorDetails } from '../../../../apiErrorDetails/apiErrorDetails';
import { Form } from '../../../../form/form';
import {
  customerWidgetsApi,
  UpdateCategoryRequest,
} from '../../../../../api/widgets/customers/customerWidgetsApi';

export function UpdateCustomerCategoryModal(
  {
    categoryId,
    onCancel,
    onSuccess,
  }: {
    categoryId: number;
    onCancel: () => void;
    onSuccess: () => void;
  }) {
  const { t } = useI18n();
  const { notify } = useNotificationStore();

  const [error, setError] = useState<ApiError>();

  const [name, setName] = useState<string>('');

  const updateWidgetCategory = useApiRequests(
    customerWidgetsApi.updateCategory,
  );

  const category = useApiRequests(customerWidgetsApi.getCategory);

  useEffect(() => {
    category.create({
      onSuccess: (response) => {
        setName(response.name);
      },
      onError: setError,
    })
      .send(categoryId);
  }, []);

  const handleFormSubmit = () => {
    const request: UpdateCategoryRequest = {
      name: name,
    };

    updateWidgetCategory
      .create({
        onSuccess: () => {
          notify({
            title: t(messages.widgets.categories.categoryUpdated),
            type: 'success',
          });
          onSuccess();
        },
        onError: setError,
      })
      .send(categoryId, request);
  };

  const formik = useFormik<FormData>({
    initialValues: {
      name: '',
    },
    onSubmit: handleFormSubmit,
  });

  return (
    <Modal
      title={t(messages.widgets.categories.updateCategory)}
      visible={true}
      closable={false}
      onCancel={onCancel}
      onOk={formik.submitForm}
      okText={t(messages.shared.save)}
      width={720}
    >
      {updateWidgetCategory.isLoading && <LoadingIndicator />}
      {error && <ApiErrorDetails error={error} />}
      <Form form={formik}>
        <h4>{t(messages.shared.name)}</h4>
        <Input value={name}
               onChange={(e) => setName(e.target.value)} />
      </Form>
    </Modal>
  );
}

interface FormData {
  name: string;
}
