import {
  ArticleModal,
  LineItems,
  Checkout,
  DraftOrderFields,
} from './components';
import { routes } from '../../components/shop/routes';
import { UpdateLineItem, OrderMetaFieldType } from '../../types';
import { useYup, containsCjkChars } from '../../hooks/useYup';
import { useI18n } from '../../hooks/useI18n';
import { messages } from '../../messages';
import { OrderMetaFieldName } from './types';
import { useHistory } from 'react-router-dom';
import { CheckoutAdapter } from '../types';
import logo from './logo.png';
import confirmDocument from './Scotchlite_ProductBrandUsageGuide.pdf';
import { useState } from 'react';

export function useCheckoutAdapter3M(): CheckoutAdapter {
  const yup = useYup();
  const { t } = useI18n();
  const history = useHistory();
  const [hasReadConfirmDocument, setHasReadConfirmDocument] = useState<boolean>(
    false,
  );

  const onUpdateCartUpdateSuccess = (item: UpdateLineItem) => {
    if ((item?.quantity ?? 0) > 0) {
      history.push(routes.checkout);
    }
  };

  const schemaForMetaField = (
    field: OrderMetaFieldType,
    fields: OrderMetaFieldType[],
  ) => {
    switch (field.name) {
      case OrderMetaFieldName.Brand:
        return yup
          .string()
          .required()
          .max(19)
          .test(
            'forbidCJK',
            t(messages.validation.string.invalidChars),
            containsCjkChars,
          );
      case OrderMetaFieldName.Factory:
        return yup
          .string()
          .required()
          .max(19)
          .test(
            'forbidCJK',
            t(messages.validation.string.invalidChars),
            containsCjkChars,
          );
      case OrderMetaFieldName.Quantity: {
        const garmentQuantityId = fields.find(
          (mf) => mf.name === OrderMetaFieldName.GarmentQuantity,
        )?.id;
        return yup.number().when(`${garmentQuantityId}`, (qty: number) => {
          return yup
            .number()
            .required()
            .min(1)
            .max(Math.ceil(qty * 1.05));
        });
      }
      case OrderMetaFieldName.GarmentQuantity:
        return yup.number().required().min(1);
    }
  };

  return {
    ArticleModal,
    Checkout,
    confirmDocument,
    DraftOrderFields,
    hasReadConfirmDocument,
    LineItems,
    logo,
    maxCartSize: 1,
    onUpdateCartUpdateSuccess,
    orderRefLimit: 10,
    orderRefRequired: true,
    schemaForMetaField,
    setHasReadConfirmDocument,
    showNewsFeed: false,
    showOverdueInvoices: false,
    showPrice: false,
    showQuantityInCart: false,
  };
}
