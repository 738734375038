import React from 'react';
import { Icon } from 'antd';

export const OutdatedBrowser = () => (
  <div style={{ textAlign: 'center', marginTop: '50px' }}>
    <Icon
      type="warning"
      style={{ fontSize: '80px', color: '#faad14', marginBottom: '10px' }}
    />
    <h1>Your browser is out-of-date</h1>
    <p>You need to update your browser to use the site. </p>
  </div>
);
