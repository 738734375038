import React from 'react';
import styles from './descriptionList.module.scss';
import { Divider as AntDivider } from 'antd';

export function DescriptionList({ children }: { children: React.ReactNode }) {
  return (
    <div className={styles.container}>
      <table className={styles.descriptionList}>
        <tbody>{children}</tbody>
      </table>
    </div>
  );
}

DescriptionList.Item = Item;
DescriptionList.Divider = Divider;

function Item({
  title,
  children,
}: {
  title: React.ReactNode;
  children: React.ReactNode;
}) {
  return (
    <tr>
      <td className={styles.itemTitle}>{title}:</td>
      <td className={styles.itemValue}>{children}</td>
    </tr>
  );
}

function Divider() {
  return (
    <tr>
      <td colSpan={2}>
        <AntDivider className={styles.divider} />
      </td>
    </tr>
  );
}
