import { Alert } from 'antd';
import React from 'react';
import { ApiError, HttpStatusCode } from '../../hooks/useApi';
import { useI18n } from '../../hooks/useI18n';
import { messages } from '../../messages';

export function ApiErrorDetails({
  error,
  hideDetails = false,
}: {
  error: ApiError;
  hideDetails?: boolean;
}) {
  const { t } = useI18n();

  return (
    <Alert
      message={`${error.message}${
        error.status === HttpStatusCode.InternalServerError
          ? `. ${t(messages.shared.internalServerError)}`
          : ''
      }`}
      showIcon={true}
      description={
        !hideDetails && error.errors.length ? (
          <ul>
            {error.errors.map((e, i) => (
              <li key={i}>{e}</li>
            ))}
          </ul>
        ) : undefined
      }
      type="error"
    />
  );
}
