import { ApiRequest } from '../hooks/useApi';

export const categoryCodesApi = {
  getCategories: (): ApiRequest<ArticleCategory[]> => async ({
    httpClient,
    apiSettings,
  }) => {
    const response = await httpClient.get(apiSettings.categoryCodesUri);

    if (response.hasError) {
      return { error: response.error };
    } else {
      return {
        result: response.data,
      };
    }
  },
};

export interface ArticleCategory {
  id: number;
  erpId: string;
  name: string;
  branchName: string;
}
