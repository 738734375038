import React, { useMemo } from 'react';
import styles from './stock.module.scss';
import cc from 'classcat';
import { EmptyIndicator } from '../../../emptyIndicator/emptyIndicator';
import { useI18n } from '../../../../hooks/useI18n';
import { messages } from '../../../../messages';
import {
  getBalance,
  getAvailable,
  getTotalAvailable,
  getTotalBalance,
  getTotalQuantity,
  getTotalOrderOut,
  getTotalOrderIn,
} from '../../../../helpers/articleStockTransformer';
import { useShopSessionStore } from '../../../../hooks/shop/useShopSessionStore';
import { ArticleBranchStock, ArticleStock } from '../../../../api/articlesApi';

function articleBranchStocksToStockRows(
  articleBranchStocks: ArticleBranchStock[],
) {
  return articleBranchStocks.map<StockRow>(
    ({ branchNumber, branchName, articleStockLocations }) => ({
      locationNumber: branchNumber,
      locationName: branchName,
      displayName: branchName,
      orderIn: getTotalOrderIn(articleStockLocations),
      orderOut: getTotalOrderOut(articleStockLocations),
      quantity: getTotalQuantity(articleStockLocations),
      available: getTotalAvailable(articleStockLocations),
      balance: getTotalBalance(articleStockLocations),
    }),
  );
}

function articleStocksToStockRows(articleStocks: ArticleStock[]) {
  return articleStocks.map<StockRow>((articleStock) => ({
    locationNumber: articleStock.locationNumber,
    locationName: articleStock.locationName,
    displayName: getArticleStocksForStockRowsDisplayName(
      articleStock.locationNumber,
      articleStock.locationName,
    ),
    orderIn: articleStock.orderIn,
    orderOut: articleStock.orderOut,
    quantity: articleStock.quantity,
    available: getAvailable(articleStock),
    balance: getBalance(articleStock),
  }));

  function getArticleStocksForStockRowsDisplayName(
    locationNumber: string,
    locationName: string,
  ): string {
    if (
      locationNumber.trim().toLowerCase() === locationName.trim().toLowerCase()
    ) {
      return locationName;
    } else {
      return locationNumber + ' ' + locationName;
    }
  }
}

export function Stock({
  articleBranchStocks,
  showStockLocations,
  quantityDecimalPlaces,
}: {
  articleBranchStocks: ArticleBranchStock[];
  showStockLocations: boolean;
  quantityDecimalPlaces: number;
}) {
  const { session } = useShopSessionStore();

  const stockRows = useMemo(() => {
    if (showStockLocations) {
      const currentBranchStock = articleBranchStocks.find(
        (a) => a.branchId === session.branchId,
      )!;

      const hasMultiStockLocations = currentBranchStock.articleStockLocations.some(
        (stock) => stock.locationName !== undefined,
      );

      if (hasMultiStockLocations) {
        return articleStocksToStockRows(
          currentBranchStock.articleStockLocations,
        );
      }
    }

    return articleBranchStocksToStockRows(articleBranchStocks);
  }, articleBranchStocks);

  return (
    <StockTable
      stockRows={stockRows}
      quantityDecimalPlaces={quantityDecimalPlaces}
    />
  );
}

interface StockRow {
  locationNumber: string;
  locationName: string;
  displayName: string;
  quantity: number;
  orderIn: number;
  orderOut: number;
  available: number;
  balance: number;
}

function StockTable({
  stockRows,
  quantityDecimalPlaces,
}: {
  stockRows: StockRow[];
  quantityDecimalPlaces: number;
}) {
  const { t } = useI18n();

  const totalQuantity = stockRows.reduce(
    (acc, stock) => acc + stock.quantity,
    0,
  );

  const totalStockBalance = stockRows.reduce(
    (acc, stock) => acc + stock.balance,
    0,
  );

  const totalAvailable = stockRows.reduce(
    (acc, stock) => acc + stock.available,
    0,
  );

  return (
    <div className={styles.stockContainer}>
      <div className={styles.header}>
        <div className={cc([styles.headerCell, styles.titleCell])}>
          {t(messages.stock.location)}
        </div>
        <div className={cc([styles.headerCell, styles.numericCell])}>
          {t(messages.stock.inStock)}
        </div>
        <div className={cc([styles.headerCell, styles.numericCell])}>
          {t(messages.stock.inOrder)}
        </div>
        <div className={cc([styles.headerCell, styles.numericCell])}>
          {t(messages.stock.available)}
        </div>
        <div className={cc([styles.headerCell, styles.numericCell])}>
          {t(messages.stock.PO)}
        </div>
        <div className={cc([styles.headerCell, styles.numericCell])}>
          {t(messages.stock.balance)}
        </div>
      </div>

      {!!stockRows.length && (
        <div>
          {stockRows.map((stockRow) => (
            <div key={stockRow.displayName} className={styles.row}>
              <div className={cc([styles.rowCell, styles.titleCell])}>
                {stockRow.displayName}
              </div>
              <div className={cc([styles.rowCell, styles.numericCell])}>
                {stockRow.quantity.toFixed(quantityDecimalPlaces)}
              </div>
              <div className={cc([styles.rowCell, styles.numericCell])}>
                {stockRow.orderOut.toFixed(quantityDecimalPlaces)}
              </div>
              <div className={cc([styles.rowCell, styles.numericCell])}>
                {stockRow.available.toFixed(quantityDecimalPlaces)}
              </div>
              <div className={cc([styles.rowCell, styles.numericCell])}>
                {stockRow.orderIn.toFixed(quantityDecimalPlaces)}
              </div>
              <div className={cc([styles.rowCell, styles.numericCell])}>
                {stockRow.balance.toFixed(quantityDecimalPlaces)}
              </div>
            </div>
          ))}
        </div>
      )}

      {!stockRows.length && (
        <div className={styles.empty}>
          <EmptyIndicator description={t(messages.shared.noDataAvailable)} />
        </div>
      )}

      <div className={styles.footer}>
        <div className={cc([styles.footerCell, styles.titleCell])}>
          {t(messages.shared.total)}
        </div>
        <div className={cc([styles.footerCell, styles.numericCell])}>
          {totalQuantity.toFixed(quantityDecimalPlaces)}
        </div>
        <div className={cc([styles.footerCell, styles.numericCell])} />
        <div className={cc([styles.footerCell, styles.numericCell])}>
          {totalAvailable.toFixed(quantityDecimalPlaces)}
        </div>
        <div className={cc([styles.footerCell, styles.numericCell])} />
        <div className={cc([styles.footerCell, styles.numericCell])}>
          {totalStockBalance.toFixed(quantityDecimalPlaces)}
        </div>
      </div>
    </div>
  );
}
