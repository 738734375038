import React, { useRef } from 'react';
import Scrollbar from 'react-scrollbars-custom';
import { CellSize, RenderHeaderCellContent } from '../types';
import { customIEScroll } from '../helpers';
import cc from 'classcat';
import styles from './yHeaders.module.scss';
import { OverflowOverlay } from '../overflowOverlay/overflowOverlay';

export const YHeaders = React.memo(function YHeaders({
  yKeys,
  cellSize,
  selectedYKey,
  renderHeaderCellContent,
  innerScrollRef,
  overflowingTop,
  overflowingBottom,
  onScroll,
  onScrollStart,
  onScrollStop,
}: {
  yKeys: string[];
  cellSize: CellSize;
  selectedYKey?: string;
  overflowingTop: boolean;
  overflowingBottom: boolean;
  renderHeaderCellContent: RenderHeaderCellContent;
  innerScrollRef: (instance?: Scrollbar) => void;
  onScroll: () => void;
  onScrollStart: () => void;
  onScrollStop: () => void;
}) {
  const scrollRef = useRef<Scrollbar>();

  return (
    <Scrollbar
      style={{
        width: cellSize.width,
        height: '100%',
        flexShrink: 0,
      }}
      wrapperProps={{
        style: { marginRight: 0 },
        className: styles.yHeadersWrapper,
        overflowingTop,
        overflowingBottom,
      }}
      wrapperRenderer={(props: any) => {
        const {
          elementRef,
          overflowingTop,
          overflowingBottom,
          ...restProps
        } = props;
        return (
          <div {...restProps} ref={elementRef}>
            {overflowingTop && <OverflowOverlay direction="top" />}
            {overflowingBottom && <OverflowOverlay direction="bottom" />}
            {restProps.children}
          </div>
        );
      }}
      trackYProps={{ style: { width: 0 } }}
      ref={(ref) => {
        innerScrollRef(ref ? ref : undefined);
        scrollRef.current = ref ? ref : undefined;
      }}
      noScrollX={true}
      onScroll={onScroll}
      onScrollStart={onScrollStart}
      onScrollStop={onScrollStop}
      contentProps={{ onWheel: customIEScroll(scrollRef.current!) }}
    >
      <div className={styles.yHeadersColumn}>
        {yKeys.map((yKey) => (
          <div
            key={yKey}
            className={cc({
              [styles.yHeadersCell]: true,
              [styles.yHeadersCellSelected]: selectedYKey === yKey,
            })}
            style={{
              width: cellSize.width,
              height: cellSize.height,
            }}
          >
            {renderHeaderCellContent({ key: yKey })}
          </div>
        ))}
      </div>
    </Scrollbar>
  );
});
