import React from 'react';
import { OrderStatus, DeliveryStatus } from '../../types';
import { useI18n } from '../../hooks/useI18n';
import { Badge } from 'antd';
import {
  OrderProgress,
  toOrderProgress,
} from '../../helpers/orderProgressConverter';
import { orderProgressTranslator } from '../../helpers/orderProgressTranslator';

const orderProgressColor = {
  [OrderProgress.Draft]: '#d9d9d9',
  [OrderProgress.Unconfirmed]: 'blue',
  [OrderProgress.AwaitingDelivery]: 'orange',
  [OrderProgress.PartiallyDelivered]: 'yellow',
  [OrderProgress.Delivered]: 'green',
};

export function OrderProgressBadge({
  orderStatus,
  deliveryStatus,
}: {
  orderStatus: OrderStatus;
  deliveryStatus: DeliveryStatus;
}) {
  const { t } = useI18n();
  const orderProgress = toOrderProgress(orderStatus, deliveryStatus);
  if (orderProgress === undefined) {
    throw new Error('Unable to deterime order progress');
  }

  return (
    <Badge
      color={orderProgressColor[orderProgress]}
      text={orderProgressTranslator(t, orderProgress)}
    />
  );
}
