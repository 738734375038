import React from 'react';
import { Tag, Icon, Tooltip } from 'antd';
import { useI18n } from '../../../../hooks/useI18n';
import { messages } from '../../../../messages';
import { useCartStore } from '../../../../hooks/shop/useCartStore';
import { useShopSessionStore } from '../../../../hooks/shop/useShopSessionStore';

export function CartQuantity({ articleId }: { articleId: number }) {
  const { t } = useI18n();
  const cartStore = useCartStore();
  const cartItem = cartStore.items.find((i) => i.articleId === articleId);
  const {
    checkoutAdapter: { showQuantityInCart },
  } = useShopSessionStore();

  if (!cartItem) {
    return null;
  }

  return (
    <Tooltip title={t(messages.cart.quantityInCart)}>
      <Tag color="#1890ff">
        <Icon
          type="check"
          style={{ marginRight: showQuantityInCart ? '5px' : '0' }}
        />
        {showQuantityInCart && cartItem.quantity}
      </Tag>
    </Tooltip>
  );
}
