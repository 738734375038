import React, { useState } from 'react';
import { LoadingIndicator } from '../../../components/loadingIndicator/loadingIndicator';
import { useCartStore } from '../../../hooks/shop/useCartStore';
import { Checkbox, Button, Alert, Tooltip } from 'antd';
import { useI18n } from '../../../hooks/useI18n';
import { messages } from '../../../messages';
import { useShopSessionStore } from '../../../hooks/shop/useShopSessionStore';

export const UpdateArticleLineItem = ({
  articleId,
  onUpdateLineItem,
  isLoading,
}: {
  articleId: number;
  onUpdateLineItem: ({
    articleId,
    quantity,
  }: {
    articleId: number;
    quantity: number;
  }) => void;
  isLoading: boolean;
}) => {
  const cartStore = useCartStore();
  const { checkoutAdapter, session } = useShopSessionStore();
  const { t } = useI18n();

  const [isConfirmed, setIsConfirmed] = useState(false);
  const hasItems = cartStore.items.length > 0;
  const isAdded = cartStore.items.find((l) => l.articleId === articleId);

  const CheckboxTooltip = ({ children }: { children: React.ReactNode }) =>
    checkoutAdapter.hasReadConfirmDocument ? (
      <>{children}</>
    ) : (
      <Tooltip title="Please read the user guide">{children}</Tooltip>
    );

  return (
    <>
      {isLoading && <LoadingIndicator />}
      {!hasItems && (
        <>
          <CheckboxTooltip>
            <Checkbox
              style={{ marginBottom: '5px' }}
              checked={isConfirmed}
              onChange={(e) => setIsConfirmed(e.target.checked)}
              disabled={!checkoutAdapter.hasReadConfirmDocument}
            >
              {t(messages.checkoutAdapter._3M.confirmRead)}
            </Checkbox>
          </CheckboxTooltip>
          <a
            href={checkoutAdapter.confirmDocument}
            target="_blank"
            rel="noopener noreferrer"
            onClick={() =>
              checkoutAdapter.setHasReadConfirmDocument &&
              checkoutAdapter.setHasReadConfirmDocument(true)
            }
          >
            {t(messages.checkoutAdapter._3M.confirmReadLink)}
          </a>
          <Button
            type="primary"
            size="large"
            disabled={!isConfirmed || session.locked}
            onClick={() => {
              onUpdateLineItem({ articleId, quantity: 1 });
            }}
            block={true}
          >
            {t(messages.checkoutAdapter._3M.addToCart)}
          </Button>
        </>
      )}
      {hasItems && (
        <>
          {isAdded && (
            <>
              <Alert
                style={{ marginBottom: '5px' }}
                message="Added to cart"
                type="success"
                showIcon={true}
              />
              <Button
                type="primary"
                size="large"
                onClick={() => {
                  onUpdateLineItem({ articleId, quantity: 0 });
                }}
                block={true}
              >
                {t(messages.checkoutAdapter._3M.removeFromCart)}
              </Button>
            </>
          )}
          {!isAdded && (
            <>
              <Alert
                style={{ marginBottom: '5px' }}
                message={
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                    }}
                  >
                    {t(messages.checkoutAdapter._3M.cartIsFull)}
                    <Button onClick={cartStore.clearLineItems}>
                      {t(messages.checkoutAdapter._3M.clearCart)}
                    </Button>
                  </div>
                }
                type="warning"
                showIcon={false}
              />
            </>
          )}
        </>
      )}
    </>
  );
};
