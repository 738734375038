import { createTranslations } from '../hooks/useI18n/createTranslations';
import { messages, defaultTranslations } from '../messages';

export const zhCnTranslations = createTranslations<typeof messages>(
  defaultTranslations,
  {
    shared: {
      expandTable: '展開表格',
      quantity: '數量',
      price: '價格',
      update: '更新',
      search: '搜索',
      searchName: null,
      searchArticleNumber: '物料編號',
      searchPlaceholder: '搜索 ...',
      searchArticleNoPlaceholder: '物料編號 ...',
      searchErpIdNamePlaceholder: null,
      searchEmailNamePlaceholder: null,
      excludeCustomerTypePlaceholder: null,
      excludeCustomerTypeTitle: null,
      shrinkTable: '收縮表',
      login: '登入',
      logout: '登出',
      development: '開發',
      settings: '設置',
      widgetsAccess: null,
      otherInformation: '其他資訊',
      noDataAvailable: '無可用資料',
      details: '詳細資訊',
      showDetails: '顯示詳細資訊',
      hideDetails: '隱藏詳細資訊',
      hide: '隱藏',
      loadMore: '顯示更多',
      noItems: '無此項目',
      listingItemsCount: null,
      all: '所有',
      standard: '標準',
      sortBy: '排序',
      unknown: '未知',
      customer: '客戶',
      generalCustomerSettings: null,
      switchLogin: '切換登錄',
      selectLogin: '選擇登錄',
      endImpersonation: '結束瀏覽',
      country: '國家',
      brand: '品牌',
      total: '總計',
      remaining: '剩餘',
      totalPrice: '總價格',
      totalQuantity: '總數量',
      assortmentAdminCostDescription: null,
      quantityAdjusted: null,
      quantityAdjustedInfo: null,
      default: '預設',
      branch: '分行',
      email: '電子郵件',
      telephone: '電話',
      fax: '傳真',
      reference: '參考',
      references: null,
      showAll: '顯示所有',
      currency: '貨幣',
      orderCurrency: '訂單貨幣',
      status: '狀態',
      type: '類型',
      export: '輸出',
      project: '品牌',
      projects: null,
      assortment: null,
      error: '錯誤',
      internalServerError: null,
      file: '檔案',
      optional: '可選',
      download: '下載',
      save: '保存',
      show: '顯示',
      chooseFile: '選擇檔案',
      createNew: 'Create new',
      created: '已創建',
      language: '语',
      name: null,
      done: null,
      add: null,
      remove: null,
      delete: null,
      copy: null,
      clearSelection: null,
      removeConfirmation: null,
      deleteConfirmation: null,
      selectionCount: null,
      confirmation: null,
      deleteSelected: null,
      selectAll: null,
      unselectAll: null,
      categories: null,
      orderTypes: null,
      deliveryStatuses: null,
      color: null,
      syncSchedule: null,
    },

    assortment: {
      assortment: '分類',
      assortments: '分類',
      subAssortment: null,
      displayFrozenArticles: null,
      copyAssortment: null,
      copyAssortmentResult: null,
      createAssortment: null,
      createSubAssortment: null,
      addArticles: null,
      clearArticles: null,
      deleteAssortments: null,
      deleteArticleFromSelected: null,
      useStockLocations: null,
      useLocalBranchStock: null,
      allowAutomaticOrder: null,
      allowAutomaticOrderOnIncomingStock: null,
      useAutomaticQuantityAdjustment: null,
      activateAdminPriceThreshold: null,
      assortmentCurrency: null,
      adminPrice: null,
      currencyDepends: null,
      adminPriceTriggerThreshold: null,
      adminPriceCurrency: null,
      draftQuantityAdjustmentPercent: null,
      assortmentCopied: null,
      assortmentCopiedSuccess: null,
      assortmentCreated: null,
      assortmentUpdated: null,
      assortmentRemoved: null,
      assortmentsRemoved: null,
      assortmentArticlesAdded: null,
      assortmentArticlesCleared: null,
      assortmentArticleRemoved: null,
      createChildAssortmentTitle: null,
      assortmentRemovalWarning: null,
      assortmentsRemovalWarning: null,
      assortmentsRemoveConfirmation: null,
      assortmentsArticleRemoveWarning: null,
      assortmentsArticleRemoveConfirmation: null,
      assortmentArticlesMissing: null,
      assortmentArticlesMissingWarning: null,
      assortmentArticlesMissingExport: null,
    },

    customer: {
      unknown: null,
      brand: '品牌',
      vendor: '供應商',
      agent: '代理商',
      internal: null,
      customerType: '客戶類型',
      invoiceCustomer: null,
      adminCostThresholdLabel: null,
      saveGeneralCustomerSettings: null,
      customerGeneralSettingsSaved: null,
    },

    documents: {
      documents: '文件檔案',
      name: '名稱',
      description: '描述',
      generalDocuments: '一般檔案',
      branchDocuments: null,
      yourDocuments: '您的文件檔案',
      newDocument: '新文件檔案',
      confirmRemoveDocument: '您確定要刪除此文件檔案嗎?',
      removeDocument: '刪除文件檔案',
      documentRemoved: '文件檔案已刪除',
    },

    notifications: {
      notifications: null,
      name: null,
      message: null,
      newNotification: null,
      updateNotification: null,
      confirmRemoveNotification: null,
      removeNotification: null,
      notificationRemoved: null,
    },

    reports: {
      reports: '報告',
      articleStatistics: null,
      articleCategory: null,
      branchPlaceholder: null,
      categories: {
        clear: null,
        selectAll: null,
        filterPlaceholder: null,
        noMatchingCategories: null,
        selectABranch: null,
        noCategoriesSelected: null,
      },
      customerOrders: null,
      customerTypePlaceholder: null,
      downloadReport: null,
      exportArticles: null,
      exportArticleVariants: null,
      currentLocation: null,
      allLocations: null,
      includeFrozenArticles: null,
      includeImages: null,
      includeShortageList: null,
      itemType: null,
      itemTypeFilter: null,
      itemTypePlaceholder: null,
      timePeriod: null,
      timePeriodPlaceholder: null,
    },

    article: {
      article: '物料編號',
      articles: '物料編號',
      articleNumber: '物料編號',
      articleNumberOrDescription: null,
      description: '描述',
      extraDescription: null,
      kit: '套件',
      matrix: '矩陣',
      frozen: '凍結',
      aboveMaxNodesAlert: null,
      frozenAlert: null,
      size: '大小',
      unit: null,
      color: '顏色',
      showSummary: '顯示摘要',
      showTopContent: '顯示熱門內容',
      hideSummary: '隱藏摘要',
      hideTopContent: '隱藏熱門內容',
      articleInformationTitle: null,
      articleCategory: null,
    },

    stock: {
      stock: '存貨',
      location: '位置',
      inStock: '有存貨',
      inOrder: '可供落單',
      PO: '採購單',
      balance: '結餘',
      available: '可用',
    },

    cart: {
      cart: '購物車',
      quantityInCart: '購物車內數量',
      checkout: '結帳',
      updateCart: '更新購物車',
      unavailableArticle: null,
      removeUnavailableArticles: null,
    },

    order: {
      order: '訂單',
      orders: '訂單',
      placeOrder: '下訂單',
      referenceOrderNumber: '參考訂單號',
      message: '訊息',
      careLabelPos: '洗水嘜採購單',
      orderNumber: '訂單號',
      reference: '參考',
      externalReference: null,
      internalReference: '內部參考',
      orderDate: '訂購日期',
      confirmedDeliveryDate: '確認交貨日期',
      confirmedDate: '確認日期',
      latestDate: '最新的日期',
      deliveryDate: '交貨日期',
      deliveryDates: '交貨日期',
      deliveryStatus: '交貨狀態',
      progress: '狀態',
      progresses: '狀態',
      unconfirmed: '未確認',
      confirmed: '已確認',
      undelivered: '未送達',
      partiallyDelivered: '有部分已送達',
      delivered: '已送達',
      unplaced: '未落單',
      placed: '已落單',
      awaitingDelivery: '正在等待交貨',
      billedTo: '開票給',
      paymentTerms: '付款條件',
      deliveryTerms: '送貨條件',
      deliveryMethod: '送貨方式',
      noMessage: '沒有消息',
      row: '行',
      deliveryNote: '送貨單',
      invoiceNumber: '發票編號',
      placeAsVendor: '供應商下的訂單',
      orderPlacedTitle: '已下訂單',
      orderTypeForecast: null,
      orderTypeStandard: '標準',
      orderTypeSample: '取消通話/短信',
      orderTypeVariable: '洗水嘜',
      orderPlacedDescription:
        '你的訂單現已提交，將很快處理。 您可以查看訂單記錄來跟踪訂單的進度。',
      trackingNumber: '運單號',
      updateOrder: '更新訂單',
      orderType: '訂單類型',
      placeVariableOrderTitle: '洗水嘜訂單',
      placeVariableOrderDescription: '要檢查當前狀態，請遵循採購單鏈接',
      contactName: '聯繫人姓名',
      contactPhone: '聯繫電話',
      courierAccount: '快遞帳戶',
      selectAddress: null,
      origin: '起源',
      deliveryPickerDisabledTooltipOutOfStock: null,
      deliveryPickerDisabledTooltipMultiStock: null,

      additionalFields: {
        title: '其他附加',
        brandName: '品牌名稱',
        garmentType: '服裝類型',
        garmentQty: '服裝數量',
        segment: '細分',
      },
      season: null,
    },

    address: {
      address: '地址',
      addresses: '地址',
      customAddress: '自定義地址',
      shipping: '運輸',
      billing: '帳單',
      company: '公司',
      address1: '地址1',
      address2: '地址2',
      city: '城市',
      postalCode: '郵政編碼',
      country: '國家',
      shippingTypeStandard: '标准',
      shippingTypeNewCustom: '新',
      shippingTypeExistingCustom: '已保存',
    },

    invoice: {
      invoices: '發票',
      invoiceNumber: '發票編號',
      invoiceDate: '發票日期',
      dueDate: '到期日',
      paid: '已付款',
      unpaid: '未付款',
      overdue: '逾期',
    },

    login: {
      logout: '登出',
      noAvailableLogins: '沒有可用的客戶登錄名',
    },

    session: {
      lockedTitle: '您的帳戶已被鎖定',
      lockedDescription: '您可以瀏覽網頁，但不能下任何訂單',
    },

    users: {
      user: null,
      users: '用戶名稱',
      name: '姓名',
      email: '電子郵件',
      customerLogins: '客戶登錄',
      customerLoginRemoveConfirmation: null,
      customerLoginRemoved: null,
      customerLoginUpdated: null,
      customerLoginsRemoved: null,
      userUpdated: null,
      editUser: null,
      customerLoginsRemovalWarning: null,
      customerLoginsRemoveConfirmation: null,
    },

    branches: {
      branches: null,
      copyToBranches: null,
    },

    dashboard: {
      dashboard: '快速存取',
      welcome: '歡迎',
      contactRH: '聯絡我們',
      news: '最新資訊',
      invoices: {
        title: '逾期發票',
        noneDue: '沒有任何逾期發票',
        viewAll: '查看所有發票',
        viewAllUnpaid: '查看所有未付發票',
      },
    },

    validation: {
      mixed: {
        default: '無效 ',
        required: '必要欄位',
        oneOf: '必須為以下值之一: {values}',
        notOneOf: '不能是以下值之一: {values}',
        notType: '必須為 {type} 類型',
      },
      string: {
        length: '必須完全 {length} 字元',
        min: '必須至少為 {min} 個字元',
        max: '必須最多為 {max} 字元',
        matches: '必須匹配以下內容: "{regex}"',
        email: '必須是有效的電子郵件',
        url: '必須是有效的 URL(統一資源定位器)',
        trim: null,
        lowercase: null,
        uppercase: null,
        invalidChars: null,
      },
      number: {
        min: '必須大於或等於 {min}',
        max: '必須小於或等於 {max}',
        lessThan: '必須小於 {less}',
        moreThan: '必須大於 {more}',
        notEqual: '必須不得等於 {notEqual}',
        positive: '必須為正數',
        negative: '必須為負數',
        integer: null,
      },
      date: {
        min: '必須晚於 {min}',
        max: '必須早於 {max}',
      },
      object: {
        noUnknown: null,
      },
      array: {
        min: '必須至少具有{min} 項',
        max: '必須具有小於或等於 {max} 項',
      },
      file: {
        maxSizeExceeded: '檔案超過最大大小 {maxSize}',
        unsupportedFileType: '不支援檔案類型',
        multipleNotAllowed: '不允許使用多個檔案',
        multipleMaxSizeExceeded: '超過檔案的總最大大小',
        multipleMaxCountExceeded: '選擇的檔太多',
      },
    },
    checkoutAdapter: {
      checkoutAdapters: null,
      _3M: {
        addToCart: null,
        confirmRead: null,
        confirmReadLink: null,
        removeFromCart: null,
        cartIsFull: null,
        clearCart: null,
        lineItemMessage: null,
        order: {
          poNumber: null,
          brand: null,
          factory: null,
          reflectiveProduct: null,
          segment: null,
          contactName: null,
          contactPhone: null,
          courierAccount: null,
          message: null,
          producedGarment: null,
          garmentType: null,
          garmentQuantity: null,
          quantity: null,
        },
        unavailableProduct: null,
      },
    },
    orderMetaFields: {
      confirmRemoveMetaFieldOption: null,
      createOrderMetaFieldOption: null,
      editOrderMetaFields: null,
      isAlreadyParentOption: null,
      isSubOption: null,
      orderMetaField: null,
      orderMetaFieldOptionCreated: null,
      orderMetaFieldOptionRemoved: null,
      orderMetaFieldOptionUpdated: null,
      parentMetaField: null,
      parentOption: null,
      removeMetaFieldOption: null,
      updateOrderMetaFieldOption: null,
      value: null,
    },
    customers: {
      customers: null,
    },
    widgets: {
      widgets: null,
      widgetType: null,
      widgetCreated: null,
      widgetUpdated: null,
      widgetRemoved: null,
      widgetNamePlaceholder: null,
      customerMustBeBrand: null,
      widgetRemovalWarning: null,
      exportShortageList: null,
      articlesInShortage: null,
      create: null,
      insufficientCompetence: null,
      errorRetrievedWidget: null,
      categories: {
        selectCategory: null,
        createCategory: null,
        updateCategory: null,
        deleteCategory: null,
        categoryCreated: null,
        categoryUpdated: null,
        categoryRemoved: null,
        removalWarning: null,
        customerRemovalWarning: null,
        noCategories: null,
      },
      lastGeneratedReportFailed: null,
      pendingReport: null,
      noReportsGeneratedYet: null,
      downloadExport: null,
      generated: null,
      awaitingDeliveries: {
        create: null,
        update: null,
        widgetText: null,
        triggerExport: null,
      },
      shortage: {
        create: null,
        update: null,
        triggerExport: null,
        articlesInShortage: null,
      },
      delayedDeliveries: {
        create: null,
        update: null,
        widgetText: null,
        triggerExport: null,
      },
      productStatistics: {
        create: null,
        update: null,
        triggerExport: null,
        newReportTriggered: null,
      },
      customerOrdersReport: {
        triggerExport: null,
        create: null,
        update: null,
        newReportTriggered: null,
      },
      slowStock: {
        triggerExport: null,
        create: null,
        update: null,
        newReportTriggered: null,
      },
    },
    alarms: {
      addAlarm: null,
    },
  },
);
