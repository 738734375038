import React from 'react';
import { useCartStore } from '../../../hooks/shop/useCartStore';
import { useArticleCartModalStore } from '../../../hooks/shop/useArticleCartModalStore';
import { useShopSessionStore } from '../../../hooks/shop/useShopSessionStore';
import { LineItemsProps } from '../lineItems/lineItems';

function CartLineItemsComponent() {
  const cartStore = useCartStore();
  const articleCartModalStore = useArticleCartModalStore();
  const {
    session,
    checkoutAdapter: { LineItems },
  } = useShopSessionStore();

  const props: LineItemsProps = {
    isLoading: cartStore.isLoading,
    items: cartStore.items,
    currencyCode: session.currency.code,
    includeAdminCostCalculations: true,
    unavailableArticleIds: cartStore.unavailableArticleIds,
    onEdit: (articleId) => articleCartModalStore.showArticle(articleId),
    onRemove: (articleId) =>
      cartStore.updateLineItem({
        articleId,
        quantity: 0,
        variants: [],
      }),
  };

  return <LineItems {...props} />;
}

export const CartLineItems = React.memo(CartLineItemsComponent);
