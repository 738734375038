import { HttpClient } from '../useHttpClient';
import { ApiSettings } from '../../api/apiSettings';

export interface Dependencies {
  apiSettings: ApiSettings;
  httpClient: HttpClient;
}

export interface ApiError {
  readonly message: string;
  readonly errors: string[];
  readonly status: number;
}

export enum HttpStatusCode {
  InternalServerError = 500,
}

export type ApiResponse<T> =
  | {
      readonly error: ApiError;
      readonly result?: never;
    }
  | {
      readonly error?: never;
      readonly result: T;
    };

export type ApiRequest<T = undefined> = (
  dependencies: Dependencies,
) => Promise<ApiResponse<T>>;
