import moment from 'moment';
import { useFormik, yupToFormErrors } from 'formik';
import { OrderType, Address, CustomerType, LineItem } from '../../../types';
import { CustomAddressForm } from '../../../components/addressForm/addressForm';
import { useI18n } from '../../useI18n';
import { useYup, containsCjkChars } from '../../useYup';
import { useApiResource } from '../../useApi';
import { useMemo, useEffect, useState } from 'react';
import { orderTypeTranslator } from '../../../helpers/orderTypeTranslator';
import { useShopSessionStore } from '../useShopSessionStore';
import { ordersApi } from '../../../api/ordersApi';
import { checkoutApi } from '../../../api/checkoutApi';
import { DeliveryDateOptionType } from '../../../components/shop/checkout/deliveryDatePicker/deliveryDatePicker';
import { messages } from '../../../messages';

export type ChangeShippingAddressOption =
  | {
      type: ShippingAddressType.Standard | ShippingAddressType.NewCustom;
      existingCustomAddressId?: undefined;
    }
  | {
      type: ShippingAddressType.ExistingCustom;
      existingCustomAddressId: number;
    };

export enum ShippingAddressType {
  Standard = 0,
  ExistingCustom = 1,
  NewCustom = 2,
}

export interface PlaceOrderForm {
  orderMessage: string;
  externalOrderNumber: string;
  contactName: string;
  contactPhone: string;
  courierAccount: string;
  orderType: { key: OrderType; label: string };
  customAddress: CustomAddressForm | undefined;
  existingCustomAddressId: number | undefined;
  vendorCustomer: { key: string; label: string } | undefined;
  metaFields: { [key: string]: string };
  requestedDeliveryDate: {
    type: DeliveryDateOptionType;
    d1?: moment.Moment;
    d2?: number;
  };
  season: { key: string; label: string } | undefined;
}

const emptyCustomAddress: CustomAddressForm = {
  company: '',
  address1: '',
  address2: '',
  city: '',
  postalCode: '',
  country: undefined,
};

export function usePlaceOrderForm({
  standardShippingAddress,
  standardInvoiceAddress,
  cartStoreItems,
  onSubmit,
}: {
  standardShippingAddress: Address;
  standardInvoiceAddress: Address;
  cartStoreItems: LineItem[];
  onSubmit: (orderForm: PlaceOrderForm, requestedDeliveryDate?: string) => void;
}) {
  const { t } = useI18n();
  const { session, checkoutAdapter } = useShopSessionStore();
  const yup = useYup();

  const [shippingAddressType, setShippingAddressType] =
    useState<ShippingAddressType>(ShippingAddressType.Standard);

  const metaFields = useApiResource(ordersApi.getOrderMetaFields);
  const customAddresses = useApiResource(checkoutApi.getCustomAddresses);
  const vendors = useApiResource(checkoutApi.getVendors);
  const seasons = useApiResource(checkoutApi.getSeasons);
  const deliveryDateOptions = useApiResource(ordersApi.getDeliveryOptions);
  const [vendorOptionIsVisible, setVendorOptionIsVisible] = useState(false);

  const orderTypeOptions = useMemo(() => {
    const orderTypeOptions = [
      {
        key: OrderType.Standard,
        label: orderTypeTranslator(t, OrderType.Standard),
      },
      {
        key: OrderType.Sample,
        label: orderTypeTranslator(t, OrderType.Sample),
      },
    ];

    if (session.customerType === CustomerType.Brand) {
      orderTypeOptions.push({
        key: OrderType.Forecast,
        label: orderTypeTranslator(t, OrderType.Forecast),
      });
    }

    return orderTypeOptions;
  }, []);

  const validateForm = async (values: PlaceOrderForm) => {
    const schema = yup.object().shape({
      externalOrderNumber: checkoutAdapter.orderRefRequired
        ? yup
            .string()
            .max(checkoutAdapter.orderRefLimit)
            .test(
              'forbidCJK',
              t(messages.validation.string.invalidChars),
              containsCjkChars,
            )
            .required()
        : yup
            .string()
            .max(checkoutAdapter.orderRefLimit)
            .test(
              'forbidCJK',
              t(messages.validation.string.invalidChars),
              containsCjkChars,
            ),
      contactName: yup
        .string()
        .max(30)
        .test(
          'forbidCJK',
          t(messages.validation.string.invalidChars),
          containsCjkChars,
        )
        .required(),
      contactPhone: yup
        .string()
        .max(30)
        .test(
          'forbidCJK',
          t(messages.validation.string.invalidChars),
          containsCjkChars,
        )
        .required(),
      courierAccount: yup
        .string()
        .test(
          'forbidCJK',
          t(messages.validation.string.invalidChars),
          containsCjkChars,
        )
        .max(30),
      requestedDeliveryDate: yup
        .object()
        .required()
        .shape({
          type: yup.number(),
          d1: yup.object().when('type', {
            is: DeliveryDateOptionType.SetDate,
            then: yup.object().required(),
          }),
          d2: yup.number().when('type', {
            is: DeliveryDateOptionType.Upcoming,
            then: yup.number().required(),
          }),
        }),
      orderMessage: yup
        .string()
        .test(
          'forbidCJK',
          t(messages.validation.string.invalidChars),
          containsCjkChars,
        )
        .max(600),
      vendorCustomer: yup.object().when('$vendorOptionIsVisible', {
        is: true,
        then: yup.object().required(),
      }),
      customAddress: yup.object().when('$shippingAddressType', {
        is: ShippingAddressType.NewCustom,
        then: yup
          .object()
          .shape({
            company: yup
              .string()
              .required()
              .max(30)
              .test(
                'forbidCJK',
                t(messages.validation.string.invalidChars),
                containsCjkChars,
              ),
            address1: yup
              .string()
              .required()
              .max(30)
              .test(
                'forbidCJK',
                t(messages.validation.string.invalidChars),
                containsCjkChars,
              ),
            address2: yup
              .string()
              .max(30)
              .test(
                'forbidCJK',
                t(messages.validation.string.invalidChars),
                containsCjkChars,
              ),
            postalCode: yup
              .string()
              .required()
              .max(12)
              .test(
                'forbidCJK',
                t(messages.validation.string.invalidChars),
                containsCjkChars,
              ),
            city: yup
              .string()
              .required()
              .max(15)
              .test(
                'forbidCJK',
                t(messages.validation.string.invalidChars),
                containsCjkChars,
              ),
            country: yup.object().required(),
          })
          .required(),
      }),
      existingCustomAddressId: yup.number().when('$shippingAddressType', {
        is: ShippingAddressType.ExistingCustom,
        then: yup.number().required(),
      }),
      metaFields: yup.object().shape(
        (metaFields.data ?? []).reduce((metaFieldSchema, field) => {
          const adapterSchema = checkoutAdapter.schemaForMetaField(
            field,
            metaFields.data!,
          );
          const defaultSchema = field.required
            ? yup
                .string()
                .required()
                .test(
                  'forbidCJK',
                  t(messages.validation.string.invalidChars),
                  containsCjkChars,
                )
            : yup
                .string()
                .test(
                  'forbidCJK',
                  t(messages.validation.string.invalidChars),
                  containsCjkChars,
                );

          return {
            ...metaFieldSchema,
            [field.id]: adapterSchema ?? defaultSchema,
          };
        }, {}),
      ),
    });

    try {
      await schema.validate(values, {
        strict: true,
        abortEarly: false,
        context: {
          vendorOptionIsVisible,
          shippingAddressType,
        },
      });
    } catch (err) {
      return yupToFormErrors(err);
    }
  };

  const formik = useFormik<PlaceOrderForm>({
    initialValues: {
      externalOrderNumber: '',
      orderMessage: '',
      contactName: session.userName,
      contactPhone: session.customerTelephone,
      courierAccount: '',
      orderType: orderTypeOptions[0],
      vendorCustomer: undefined,
      customAddress: undefined,
      existingCustomAddressId: undefined,
      metaFields: {},
      requestedDeliveryDate: {
        type: DeliveryDateOptionType.SetDate,
        d1: undefined,
        d2: undefined,
      },
      season: undefined,
    },
    validate: validateForm,
    onSubmit: (values: PlaceOrderForm) => {
      const requestedDeliveryDate =
        values.requestedDeliveryDate.type === DeliveryDateOptionType.SetDate
          ? values.requestedDeliveryDate.d1?.toISOString()
          : deliveryDateOptions.data?.upcomingDeliveries.find(
              (d) => d.orderId === formik.values.requestedDeliveryDate.d2,
            )?.deliveryDate;
      onSubmit(values, requestedDeliveryDate);
    },
  });

  const shippingAddress = useMemo<Address | undefined>(() => {
    if (shippingAddressType === ShippingAddressType.NewCustom) {
      return undefined;
    }

    if (shippingAddressType === ShippingAddressType.ExistingCustom) {
      if (!customAddresses.data) {
        return undefined;
      }
      const address = customAddresses.data.find(
        (ca) => ca.id === formik.values.existingCustomAddressId,
      )!;

      return address;
    }

    if (formik.values.vendorCustomer) {
      const vendor = vendors.data!.find(
        (v) => v.id.toString() === formik.values.vendorCustomer!.key,
      )!;
      return vendor.address;
    }

    return standardShippingAddress;
  }, [
    shippingAddressType,
    formik.values.existingCustomAddressId,
    formik.values.vendorCustomer,
    vendors.data,
    customAddresses.data,
  ]);

  const invoiceAddress = useMemo<Address>(() => {
    if (formik.values.vendorCustomer) {
      const vendor = vendors.data!.find(
        (v) => v.id.toString() === formik.values.vendorCustomer!.key,
      )!;
      return vendor.invoiceCustomerAddress ?? vendor.address;
    }

    return standardInvoiceAddress;
  }, [standardInvoiceAddress, formik.values.vendorCustomer, vendors.data]);

  useEffect(() => {
    metaFields.refresh();
    seasons.refresh();
  }, []);

  useEffect(() => {
    if (cartStoreItems.length > 0) {
      deliveryDateOptions.refresh(formik.values.existingCustomAddressId);
    }
  }, [formik.values.existingCustomAddressId, cartStoreItems]);

  useEffect(() => {
    if (metaFields.data) {
      const initalMetaFieldValues = metaFields.data.reduce(
        (metaFieldValues, field) => ({
          ...metaFieldValues,
          [field.id]: '',
        }),
        {},
      );

      formik.setValues({
        ...formik.values,
        metaFields: initalMetaFieldValues,
      });
    }
  }, [metaFields.data]);

  useEffect(() => {
    if (deliveryDateOptions.data) {
      formik.setValues({
        ...formik.values,
        requestedDeliveryDate: {
          type: DeliveryDateOptionType.SetDate,
          d1: moment(deliveryDateOptions.data.earliestDeliveryDate),
          d2: deliveryDateOptions.data.upcomingDeliveries[0]?.orderId,
        },
      });
    }
  }, [deliveryDateOptions.data]);

  const toggleVendorOption = (isVisible: boolean) => {
    if (isVisible) {
      vendors.refresh();
      setVendorOptionIsVisible(true);
    } else {
      setVendorOptionIsVisible(false);
      formik.setFieldValue('vendorCustomer', undefined);
    }
  };

  const enableCustomAddress = () => {
    formik.setFieldValue('contactName', '', false);
    formik.setFieldValue('contactPhone', '', false);
    formik.setFieldValue('customAddress', emptyCustomAddress, false);
  };

  const shippingAddressOptions = [
    {
      value: 'standard',
      label: 'Standard',
    },
    {
      value: 'saved',
      label: 'Saved',
      isLeaf: false,
    },
    {
      value: 'new',
      label: 'New',
    },
  ];

  const loadCustomAddresses = () => {
    customAddresses.refresh();
  };

  const changeShippingAddressType = ({
    type,
    existingCustomAddressId,
  }: ChangeShippingAddressOption) => {
    setShippingAddressType(type);
    formik.setFieldValue('contactName', '', false);
    formik.setFieldValue('contactPhone', '', false);

    if (type === ShippingAddressType.Standard) {
      formik.setFieldValue('customAddress', undefined, false);
      formik.setFieldValue('existingCustomAddressId', undefined, false);
    }

    if (type === ShippingAddressType.NewCustom) {
      formik.setFieldValue('customAddress', emptyCustomAddress, false);
      formik.setFieldValue('existingCustomAddressId', undefined, false);
    }

    if (type === ShippingAddressType.ExistingCustom) {
      formik.setFieldValue('customAddress', undefined, false);
      formik.setFieldValue(
        'existingCustomAddressId',
        existingCustomAddressId,
        false,
      );
    }
  };

  const disableCustomAddress = () => {
    formik.setFieldValue('customAddress', undefined, false);
  };

  return {
    formik,
    error: metaFields.error,
    isInitializing: metaFields.isLoading,
    metaFields: metaFields.data,
    enableCustomAddress,
    disableCustomAddress,
    orderTypeOptions,
    shippingAddressType,
    changeShippingAddressType,
    customAddresses: customAddresses.data,
    customAddressesError: customAddresses.error,
    shippingAddress,
    invoiceAddress,
    vendorOptionIsVisible,
    toggleVendorOption,
    seasons: seasons,
    vendors: vendors.data,
    vendorsError: vendors.error,
    shippingAddressOptions,
    loadCustomAddresses,
    loadingCustomAddresses: customAddresses.isLoading,
    deliveryDatesOptions: deliveryDateOptions.data,
  };
}
