import { Select } from 'antd';
import React, { useEffect, useMemo } from 'react';
import { DraftOrderFieldsProps } from '../../../checkoutAdapters/types';
import { useI18n } from '../../../hooks/useI18n';
import { messages } from '../../../messages';
import { OrderType, OrderMetaFieldOption } from '../../../types';
import { TextField } from '../../../components/form/textField';
import { TextAreaField } from '../../../components/form/textAreaField';
import { SelectField } from '../../../components/form/selectField';
import { OrderMetaFieldName } from '../types';

export function DraftOrderFields({
  formik,
  orderTypeOptions,
  order,
  metaFields,
}: DraftOrderFieldsProps) {
  const { t } = useI18n();

  useEffect(() => {
    const garmId = metaFields?.find(
      (mf) => mf.name === OrderMetaFieldName.GarmentQuantity,
    )?.id;
    const qtyId = metaFields?.find(
      (mf) => mf.name === OrderMetaFieldName.Quantity,
    )?.id;

    const garmentQuantity = parseInt(formik.values.metaFields[`${garmId}`]);
    const quantity = parseInt(formik.values.metaFields[`${qtyId}`]);

    if (!garmentQuantity && !quantity) return;

    if (!garmentQuantity) {
      formik.setFieldValue(`metaFields.${qtyId}`, undefined);
      return;
    }

    formik.setFieldValue(
      `metaFields.${qtyId}`,
      Math.ceil(garmentQuantity * 1.05),
    );
  }, [
    formik.values.metaFields[
      `${
        metaFields?.find((mf) => mf.name === OrderMetaFieldName.GarmentQuantity)
          ?.id
      }`
    ],
  ]);

  const filterReflectiveProducts = (option: OrderMetaFieldOption) => {
    const parentOptionId =
      parseInt(
        formik.values.metaFields[
          `${
            metaFields?.find((mf) => mf.name === OrderMetaFieldName.Segment)?.id
          }`
        ],
      ) || undefined;

    return (
      option.parentOptionId === 0 ||
      (parentOptionId && option.parentOptionId === parentOptionId)
    );
  };

  const sortOptions = (a: OrderMetaFieldOption, b: OrderMetaFieldOption) => {
    if (a.sortOrder === b.sortOrder) {
      return a.value.toLowerCase() < b.value.toLowerCase()
        ? -1
        : a.value.toLowerCase() > b.value.toLowerCase()
        ? 1
        : 0;
    } else {
      return a.sortOrder > b.sortOrder ? -1 : 1;
    }
  };

  useEffect(() => {
    const reflectiveProduct = metaFields?.find(
      (mf) => mf.name === OrderMetaFieldName.ReflectiveProduct,
    )?.id;
    formik.setFieldValue(`metaFields.${reflectiveProduct}`, undefined);
  }, [
    formik.values.metaFields[
      `${metaFields?.find((mf) => mf.name === OrderMetaFieldName.Segment)?.id}`
    ],
  ]);

  const reflectiveProducts = useMemo(() => {
    return metaFields
      ?.find((mf) => mf.name === OrderMetaFieldName.ReflectiveProduct)
      ?.options.filter(filterReflectiveProducts)
      .sort(sortOptions);
  }, [
    formik.values.metaFields[
      `${metaFields?.find((mf) => mf.name === OrderMetaFieldName.Segment)?.id}`
    ],
  ]);

  return (
    <>
      <SelectField
        label={t(messages.order.orderType)}
        name="orderType"
        style={{ width: '100%' }}
        optionFilterProp="children"
        dropdownMatchSelectWidth={false}
        disabled={order.type === OrderType.Variable}
      >
        {orderTypeOptions.map((orderTypeOption: any) => (
          <Select.Option key={orderTypeOption.key}>
            {orderTypeOption.label}
          </Select.Option>
        ))}
      </SelectField>
      <TextField
        name="externalOrderNumber"
        label={t(messages.checkoutAdapter._3M.order.poNumber)}
        disabled={order.type === OrderType.Variable}
      />
      <TextField
        name={`metaFields.${
          metaFields?.find((mf) => mf.name === OrderMetaFieldName.Brand)?.id
        }`}
        label={t(messages.checkoutAdapter._3M.order.brand)}
      ></TextField>
      <TextField
        name={`metaFields.${
          metaFields?.find((mf) => mf.name === OrderMetaFieldName.Factory)?.id
        }`}
        label={t(messages.checkoutAdapter._3M.order.factory)}
      ></TextField>
      <SelectField
        label={t(messages.checkoutAdapter._3M.order.segment)}
        style={{ width: '100%' }}
        name={`metaFields.${
          metaFields?.find((mf) => mf.name === OrderMetaFieldName.Segment)?.id
        }`}
        labelInValue={false}
      >
        {metaFields
          ?.find((mf) => mf.name === OrderMetaFieldName.Segment)
          ?.options.sort(sortOptions)
          .map((o) => (
            <Select.Option key={o.id} value={o.id.toString()}>
              {o.value}
            </Select.Option>
          ))}
      </SelectField>
      <SelectField
        label={t(messages.checkoutAdapter._3M.order.reflectiveProduct)}
        style={{ width: '100%' }}
        name={`metaFields.${
          metaFields?.find(
            (mf) => mf.name === OrderMetaFieldName.ReflectiveProduct,
          )?.id
        }`}
        labelInValue={false}
      >
        {reflectiveProducts?.map((o) => (
          <Select.Option key={o.id} value={o.id.toString()}>
            {o.value}
          </Select.Option>
        ))}
      </SelectField>
      <TextField name="contactName" label={t(messages.order.contactName)} />
      <TextField name="contactPhone" label={t(messages.order.contactPhone)} />
      <TextField
        name="courierAccount"
        label={t(messages.order.courierAccount)}
      />
      <TextAreaField
        rows={5}
        name="orderMessage"
        label={t(messages.order.message)}
        disabled={order.type === OrderType.Variable}
      />
      <SelectField
        label={t(messages.checkoutAdapter._3M.order.garmentType)}
        style={{ width: '100%' }}
        name={`metaFields.${
          metaFields?.find((mf) => mf.name === OrderMetaFieldName.GarmentType)
            ?.id
        }`}
        labelInValue={false}
      >
        {metaFields
          ?.find((mf) => mf.name === OrderMetaFieldName.GarmentType)
          ?.options.sort(sortOptions)
          .map((o) => (
            <Select.Option key={o.id} value={o.id.toString()}>
              {o.value}
            </Select.Option>
          ))}
      </SelectField>
      <TextField
        name={`metaFields.${
          metaFields?.find(
            (mf) => mf.name === OrderMetaFieldName.GarmentQuantity,
          )?.id
        }`}
        type="number"
        label={t(messages.checkoutAdapter._3M.order.garmentQuantity)}
        min={1}
      />
      <TextField
        name={`metaFields.${
          metaFields?.find((mf) => mf.name === OrderMetaFieldName.Quantity)?.id
        }`}
        type="number"
        label={t(messages.checkoutAdapter._3M.order.quantity)}
        min={1}
      />
    </>
  );
}
