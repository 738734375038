import React from 'react';
import { OrderMetaFieldType } from '../../types';
import { TextField } from './textField';
import { SelectField } from './selectField';
import { Select } from 'antd';

export function OrderMetaField({
  formPath,
  field,
  type,
  label,
}: {
  formPath: string;
  field: OrderMetaFieldType;
  type?: 'text' | 'number';
  label?: string;
}) {
  if (!field.useOptions) {
    return (
      <TextField
        key={field.id}
        name={`${formPath}.${field.id}`}
        label={label ?? field.name}
        type={type ?? 'text'}
      />
    );
  } else {
    return (
      <SelectField
        key={field.id}
        style={{ width: '100%' }}
        name={`${formPath}.${field.id}`}
        label={label ?? field.name}
        labelInValue={false}
      >
        {field.options.map((o) => (
          <Select.Option key={o.id} value={o.id.toString()}>
            {o.value}
          </Select.Option>
        ))}
      </SelectField>
    );
  }
}
