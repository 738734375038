import React from 'react';
import styles from './articlesList.module.scss';
import {
  List,
  Row,
  Col,
  Input,
  Select,
  Button,
  Icon,
  Radio,
  Pagination,
  Divider,
  Menu,
  Dropdown,
  Tooltip,
} from 'antd';
import { ListCardItem } from '../listCardItem/listCardItem';
import { ArticleListType } from '../types';
import { ArticleType, CustomerType, Currency } from '../../../../types';
import { CartQuantity } from '../cartQuantity/cartQuantity';
import { useI18n, TranslateFunction } from '../../../../hooks/useI18n';
import { messages } from '../../../../messages';
import { Table, Columns } from '../../../table';
import { ListLayout } from '../../../listLayout/listLayout';
import { useShopSessionStore } from '../../../../hooks/shop/useShopSessionStore';
import { CurrencyPicker } from '../currencyPicker/currencyPicker';
import { formatNumber } from '../../../../helpers/formatNumber/formatNumber';
import { fileDownloadIcon } from '../../../customIcons/fileDownloadIcon';
import { ArticleListItem } from '../../../../api/articlesApi';

export const getListColumns = (
  t: TranslateFunction,
  currency: Currency,
  showPrice = true,
): Columns<ArticleListItem> => [
  {
    header: t(messages.article.articleNumber),
    accessor: 'articleNumber',
    sortable: true,
    width: 150,
  },
  {
    header: t(messages.article.description),
    accessor: 'description',
    sortable: true,
    width: 220,
  },
  {
    header: t(messages.article.extraDescription),
    accessor: 'erpText1',
    sortable: true,
    width: 220,
  },
  {
    header: t(messages.article.unit),
    sortable: false,
    width: 50,
    cell: ({ row }) => {
      return row.unit;
    },
  },
  {
    header: showPrice ? (
      <span>
        {t(messages.shared.price)} ({currency.code})
      </span>
    ) : (
      <></>
    ),
    accessor: 'displayPrice',
    sortable: false,
    align: 'right',
    cell: ({ row }) => {
      return showPrice && row.type !== ArticleType.Matrix
        ? formatNumber(row.displayPrice, currency.priceDecimalPlaces)
        : '';
    },
  },
  {
    header: t(messages.stock.inStock),
    sortable: false,
    align: 'right',
    width: 100,
    cell: ({ row }) => {
      return row.type !== ArticleType.Matrix
        ? row.inStock.toFixed(row.quantityDecimalPlaces)
        : '';
    },
  },
  {
    header: t(messages.stock.inOrder),
    sortable: false,
    align: 'right',
    width: 100,
    cell: ({ row }) => {
      return row.type !== ArticleType.Matrix
        ? row.inOrder.toFixed(row.quantityDecimalPlaces)
        : '';
    },
  },
  {
    header: t(messages.stock.available),
    sortable: false,
    align: 'right',
    width: 100,
    cell: ({ row }) => {
      return row.type !== ArticleType.Matrix
        ? row.available.toFixed(row.quantityDecimalPlaces)
        : '';
    },
  },
  {
    header: '',
    sortable: false,
    width: 130,
    cell: ({ row }) => {
      return (
        <div className={styles.actionsCell}>
          <Button type="primary" ghost={true} size="small">
            <Icon type="shopping-cart" />
          </Button>
          <CartQuantity articleId={row.id} />
        </div>
      );
    },
  },
];

interface Props {
  isLoading: boolean;
  displayCurrency: Currency;
  totalArticles?: number;
  page: number;
  pageSize: number;
  sortingKey: keyof ArticleListItem;
  sortingDesc: boolean;
  articles: ArticleListItem[];
  listType: ArticleListType;
  exportArticlesCurrentBranchUrl: string;
  exportArticlesAllBranchesUrl: string;
  exportArticleVariantsCurrentBranchUrl: string;
  exportArticleVariantsAllBranchesUrl: string;
  onChangeDisplayCurrency: (currency: Currency) => void;
  onPageChange: (page: number) => void;
  onChangeListType: (listType: ArticleListType) => void;
  onSelectItem: (item: ArticleListItem) => void;
  onSortedChange: (sorting: {
    key: keyof ArticleListItem;
    desc: boolean;
  }) => void;
}

const ArticlesListComponent = ({
  isLoading,
  displayCurrency,
  totalArticles,
  page,
  pageSize,
  sortingKey,
  sortingDesc,
  listType,
  articles,
  exportArticlesCurrentBranchUrl,
  exportArticlesAllBranchesUrl,
  exportArticleVariantsCurrentBranchUrl,
  exportArticleVariantsAllBranchesUrl,
  onChangeDisplayCurrency,
  onPageChange,
  onSortedChange,
  onChangeListType,
  onSelectItem,
}: Props) => {
  const { t } = useI18n();
  const { session, checkoutAdapter } = useShopSessionStore();

  const listColumns = getListColumns(
    t,
    displayCurrency,
    checkoutAdapter.showPrice,
  );

  return (
    <>
      <ListLayout.ContentHeader
        title={
          <span>
            {t(messages.shared.listingItemsCount, {
              count: totalArticles ?? 0,
            })}
            <Divider type="vertical" />
            <Tooltip title={t(messages.reports.exportArticles)}>
              <Dropdown
                overlay={
                  <Menu>
                    <Menu.Item>
                      <a
                        href={exportArticlesCurrentBranchUrl}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <span>
                          {t(messages.reports.exportArticles)}{' '}
                          {!session.useLocalBranchStock &&
                            t(messages.reports.currentLocation)}
                        </span>
                      </a>
                    </Menu.Item>
                    {!session.useLocalBranchStock && (
                      <Menu.Item>
                        <a
                          href={exportArticlesAllBranchesUrl}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <span>
                            {t(messages.reports.exportArticles)}{' '}
                            {t(messages.reports.allLocations)}
                          </span>
                        </a>
                      </Menu.Item>
                    )}
                    <Menu.Item>
                      <a
                        href={exportArticleVariantsCurrentBranchUrl}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <span>
                          {t(messages.reports.exportArticleVariants)}{' '}
                          {!session.useLocalBranchStock &&
                            t(messages.reports.currentLocation)}
                        </span>
                      </a>
                    </Menu.Item>
                    {!session.useLocalBranchStock && (
                      <Menu.Item>
                        <a
                          href={exportArticleVariantsAllBranchesUrl}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <span>
                            {t(messages.reports.exportArticleVariants)}{' '}
                            {t(messages.reports.allLocations)}
                          </span>
                        </a>
                      </Menu.Item>
                    )}
                  </Menu>
                }
                trigger={['click']}
                placement="bottomLeft"
              >
                <Icon component={fileDownloadIcon} />
              </Dropdown>
            </Tooltip>
          </span>
        }
        extra={
          <Row type="flex" align="middle" gutter={12}>
            {session.customerType === CustomerType.Brand && (
              <Col>
                <Row type="flex" align="middle" gutter={4}>
                  <Col>{t(messages.shared.currency)}:</Col>
                  <Col>
                    <CurrencyPicker
                      currency={displayCurrency}
                      onChange={onChangeDisplayCurrency}
                    />
                  </Col>
                </Row>
              </Col>
            )}
            <Col>
              <Row type="flex" align="middle" gutter={4}>
                <Col>{t(messages.shared.sortBy)}:</Col>
                <Col>
                  <Input.Group compact={true}>
                    <Select
                      size="large"
                      value={sortingKey}
                      onChange={(key: keyof ArticleListItem) => {
                        onSortedChange({ key, desc: false });
                      }}
                      className={styles.sortBySelect}
                    >
                      {listColumns
                        .filter((c) => c.sortable)
                        .map((c) => (
                          <Select.Option value={c.accessor} key={c.accessor}>
                            {c.header}
                          </Select.Option>
                        ))}
                    </Select>
                    <Button
                      size="large"
                      onClick={() =>
                        onSortedChange({ key: sortingKey, desc: !sortingDesc })
                      }
                    >
                      <Icon
                        type={`sort-${
                          // Sortorder is inverted b/c ant-design shows icon in wrong direction.
                          sortingDesc ? 'ascending' : 'descending'
                        }`}
                      />
                    </Button>
                  </Input.Group>
                </Col>
              </Row>
            </Col>
            <Col>
              <Radio.Group
                value={listType}
                onChange={(e) => onChangeListType(e.target.value)}
                buttonStyle="solid"
                size="large"
              >
                <Radio.Button value={ArticleListType.Rows}>
                  <Icon type="bars" />
                </Radio.Button>
                <Radio.Button value={ArticleListType.Cards}>
                  <Icon type="table" />
                </Radio.Button>
              </Radio.Group>
            </Col>
          </Row>
        }
      />
      <div>
        {listType === ArticleListType.Cards ? (
          <List
            loading={isLoading}
            grid={{
              gutter: 16,
              xs: 1,
              sm: 2,
              md: 3,
              xl: 4,
            }}
            dataSource={articles}
            renderItem={(item: ArticleListItem) => (
              <List.Item className={styles.listItem}>
                <ListCardItem
                  item={item}
                  displayCurrency={displayCurrency}
                  onSelectItem={onSelectItem}
                  showPrice={checkoutAdapter.showPrice}
                />
              </List.Item>
            )}
          />
        ) : (
          <Table<ArticleListItem>
            isLoading={isLoading}
            columns={listColumns}
            sorted={{ key: sortingKey, desc: sortingDesc, onSortedChange }}
            items={articles}
            onSelectItem={onSelectItem}
          />
        )}
        {articles.length > 0 && (
          <div className={styles.paginationWrapper}>
            <Pagination
              onChange={onPageChange}
              current={page}
              total={totalArticles}
              pageSize={pageSize}
            />
          </div>
        )}
      </div>
    </>
  );
};

export const ArticlesList = React.memo(ArticlesListComponent);
