import { useI18n } from '../../../../../hooks/useI18n';
import { useNotificationStore } from '../../../../../hooks/useNotificationStore/notificationStore';
import React, { useState } from 'react';
import { ApiError, useApiRequests } from '../../../../../hooks/useApi';
import { messages } from '../../../../../messages';
import { Modal } from 'antd';
import { LoadingIndicator } from '../../../../loadingIndicator/loadingIndicator';
import { ApiErrorDetails } from '../../../../apiErrorDetails/apiErrorDetails';
import { customerWidgetsApi } from '../../../../../api/widgets/customers/customerWidgetsApi';

export function RemoveCustomerCategoryModal(
  {
    categoryId,
    categoryName,
    onCancel,
    onSuccess,
  }: {
    categoryId: number;
    categoryName: string;
    onCancel: () => void;
    onSuccess: () => void;
  }) {
  const { t } = useI18n();
  const { notify } = useNotificationStore();

  const [error, setError] = useState<ApiError>();

  const removeCategory = useApiRequests(
    customerWidgetsApi.removeWidgetCategory,
  );

  const handleRemove = () => {
    removeCategory
      .create({
        onSuccess: () => {
          onSuccess();
          notify({
            title: t(messages.widgets.categories.categoryRemoved),
            type: 'success',
          });
        },
        onError: setError,
      })
      .send(categoryId);
  };

  return (
    <Modal
      title={t(messages.shared.deleteConfirmation, {
        name: categoryName,
      })}
      visible={true}
      okText={t(messages.shared.delete)}
      okType='danger'
      onOk={handleRemove}
      onCancel={onCancel}
    >
      {removeCategory.isLoading && <LoadingIndicator />}
      <p>{t(messages.widgets.categories.customerRemovalWarning)}</p>
      {error && <ApiErrorDetails error={error} />}
    </Modal>
  );
}
