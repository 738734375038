import Decimal from 'decimal.js';
import toFormatDecorator from 'toformat';

const DecimalWithFormat = toFormatDecorator(Decimal);
const format = {
  groupSeparator: ',',
  groupSize: 3,
};

export function formatNumber(number: number, decimalPlaces?: number) {
  if (decimalPlaces !== undefined) {
    return new DecimalWithFormat(number).toFormat(decimalPlaces, format);
  }

  return new DecimalWithFormat(number).toFormat(format);
}
