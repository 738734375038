import React, { useCallback, useReducer, useMemo } from 'react';
import { matrixOrderReducer } from './matrixOrderReducer';
import { MatrixOrderSummary } from './matrixOrderSummary/matrixOrderSummary';
import { LoadingIndicator } from '../../../loadingIndicator/loadingIndicator';
import { LineItem, UpdateLineItem, Currency } from '../../../../types';
import {
  MatrixVariantSummary,
  ArticlePrice,
} from '../../../../api/articlesApi';
import { VariantOrderItem } from './types';
import { MatrixOrderTable } from './matrixOrderTable/matrixOrderTable';
import styles from './matrixOrder.module.scss';

export function MatrixOrder({
  articleId,
  currencyCode,
  currencyPriceDecimalPlaces,
  displayCurrency,
  variants,
  selectedVariant,
  lineItem,
  isLoading,
  articlePrices,
  showSummary,
  lineItemQuantityError,
  draftOrder,
  draftQuantityAdjustmentPercent,
  onVariantSelect,
  onUpdateLineItem,
}: {
  articleId: number;
  currencyCode: string;
  currencyPriceDecimalPlaces: number;
  displayCurrency?: Currency;
  variants: MatrixVariantSummary[];
  selectedVariant?: MatrixVariantSummary;
  lineItem?: LineItem;
  isLoading: boolean;
  articlePrices?: ArticlePrice[];
  showSummary: boolean;
  lineItemQuantityError?: string;
  draftOrder: boolean;
  draftQuantityAdjustmentPercent: number;
  onVariantSelect: (variant: MatrixVariantSummary) => void;
  onUpdateLineItem: (lineItem: UpdateLineItem) => void;
}) {
  const [variantOrderItems, variantOrderItemsDispatch] = useReducer(
    matrixOrderReducer,
    getVariantOrderItems(variants, lineItem?.variants ?? []),
  );

  const variantOrderItemsWithQty = useMemo(
    () => variantOrderItems.filter((item) => !!item.quantity),
    [variantOrderItems],
  );

  const handleRemoveItem = useCallback(({ variant }: VariantOrderItem) => {
    variantOrderItemsDispatch({
      type: 'setQuantity',
      payload: { variantId: variant.id, quantity: undefined },
    });
  }, []);

  const handleUpdateQuantity = useCallback(
    ({ variant, quantity }: VariantOrderItem) => {
      variantOrderItemsDispatch({
        type: 'setQuantity',
        payload: { variantId: variant.id, quantity },
      });
    },
    [],
  );

  const handleUpdateCart = useCallback(() => {
    const variants = variantOrderItemsWithQty.map(({ variant, quantity }) => ({
      articleId: variant.id,
      quantity: quantity!,
    }));

    onUpdateLineItem({ articleId, variants });
  }, [variantOrderItems]);

  return (
    <>
      <div className={styles.container}>
        <div className={styles.matrixTableWrapper}>
          <MatrixOrderTable
            currencyCode={currencyCode}
            currencyPriceDecimalPlaces={currencyPriceDecimalPlaces}
            items={variantOrderItems}
            onSelectItem={({ variant }) => onVariantSelect(variant)}
            onUpdateItem={handleUpdateQuantity}
            selectedVariant={selectedVariant}
            displayCurrency={displayCurrency}
            articlePrices={articlePrices}
            draftOrder={draftOrder}
            draftQuantityAdjustmentPercent={draftQuantityAdjustmentPercent}
          />
        </div>
        {showSummary ? (
          <div className={styles.matrixOrderSummary}>
            <MatrixOrderSummary
              items={variantOrderItemsWithQty}
              currencyCode={currencyCode}
              lineItemQuantityError={lineItemQuantityError}
              onUpdateCart={handleUpdateCart}
              onRemoveItem={draftOrder ? undefined : handleRemoveItem}
            />
          </div>
        ) : null}
      </div>
      {isLoading && <LoadingIndicator />}
    </>
  );
}

function getVariantOrderItems(
  variants: MatrixVariantSummary[],
  lineItemVariants: LineItem[],
) {
  const items = variants.map((variant) => {
    const lineItemVariant = lineItemVariants.find(
      (i) => i.articleId === variant.id,
    );

    return {
      variant,
      quantity: lineItemVariant?.quantity,
      originalQuantity: lineItemVariant?.originalQuantity,
    };
  });

  return items;
}
