import React, { useState, useRef, useEffect, useMemo } from 'react';
import styles from './checkout.module.scss';
import {
  Row,
  Col,
  Divider,
  Button,
  Select,
  Checkbox,
  Skeleton,
  Alert,
  Icon,
} from 'antd';
import { useI18n } from '../../../hooks/useI18n';
import { messages } from '../../../messages';
import { CustomerType, OrderType } from '../../../types';
import { AddressDetails } from '../../addressDetails/addressDetails';
import { ApiErrorDetails } from '../../apiErrorDetails/apiErrorDetails';
import { useApiRequests, ApiError } from '../../../hooks/useApi';
import { useCartStore } from '../../../hooks/shop/useCartStore';
import { useShopSessionStore } from '../../../hooks/shop/useShopSessionStore';
import { LoadingIndicator } from '../../loadingIndicator/loadingIndicator';
import { TextField } from '../../form/textField';
import { TextAreaField } from '../../form/textAreaField';
import { Form } from '../../form/form';
import { SelectField } from '../../form/selectField';
import { AddressForm } from '../../addressForm/addressForm';
import {
  usePlaceOrderForm,
  PlaceOrderForm,
  ShippingAddressType,
} from '../../../hooks/shop/usePlaceOrderForm/usePlaceOrderForm';
import { OrderMetaField } from '../../form/orderMetaField';
import { CartLineItems } from '../cartLineItems/cartLineItems';
import { Vendor } from '../../../api/checkoutApi';
import { ordersApi, PlacedOrder, PlaceOrderData } from '../../../api/ordersApi';
import { AddressSelect } from '../../addressSelect/addressSelect';
import { DeliveryDatePicker } from './deliveryDatePicker/deliveryDatePicker';
import { FormItem } from '../../form/formItem';
import { NONE_VALUE } from '../../../helpers/general';

const formLayout = {
  labelCol: { span: 7 },
  wrapperCol: { span: 17 },
};

export const Checkout = ({
  onPlacedOrder,
}: {
  onPlacedOrder: (id: number) => void;
}) => {
  const { t } = useI18n();

  const isSubmitting = useRef<boolean>(false);
  const cartStore = useCartStore();
  const { session } = useShopSessionStore();
  const placeOrderRequest = useApiRequests(ordersApi.placeOrder);

  const [placeOrderError, setPlaceOrderError] = useState<ApiError>();
  const [disableDatePicker, setDisableDatePicker] = useState(false);
  const [disableShippingAddress, setDisableShippingAddress] = useState(false);

  const handlePlaceOrderSuccess = (placedOrder: PlacedOrder) => {
    cartStore.refreshItems();
    onPlacedOrder(placedOrder.id);
  };

  const handleFormSubmit = (
    values: PlaceOrderForm,
    requestedDeliveryDate?: string,
  ) => {
    if (isSubmitting.current) {
      return;
    }
    isSubmitting.current = true;

    setPlaceOrderError(undefined);

    const seasonId = values.season ? parseInt(values.season.key) : undefined;

    const orderData: PlaceOrderData = {
      externalOrderNumber: values.externalOrderNumber,
      orderMessage: values.orderMessage,
      orderType: values.orderType.key,
      contactName: values.contactName,
      contactPhone: values.contactPhone,
      courierAccount: values.courierAccount,
      customOrderAddress: values.customAddress
        ? {
            company: values.customAddress.company,
            address1: values.customAddress.address1,
            address2: values.customAddress.address2,
            postalCode: values.customAddress.postalCode,
            city: values.customAddress.city,
            countryId: values.customAddress.country!.key,
          }
        : undefined,
      vendorCustomerId: values.vendorCustomer
        ? parseInt(values.vendorCustomer.key, 10)
        : undefined,
      existingCustomAddressId: values.existingCustomAddressId,
      metaFieldValues: values.metaFields,
      requestedDeliveryDate: disableDatePicker ? '' : requestedDeliveryDate,
      seasonId: seasonId === NONE_VALUE ? undefined : seasonId,
    };

    placeOrderRequest
      .create({
        onSuccess: handlePlaceOrderSuccess,
        onError: setPlaceOrderError,
        onResponse: () => {
          isSubmitting.current = false;
        },
      })
      .send(orderData);
  };

  const itemsAreInStock = useMemo(() => {
    return cartStore.items.every((i) => {
      return i.variants && i.variants.length > 0
        ? i.variants.every((v) => v.stockBalance >= v.quantity)
        : i.stockBalance >= i.quantity;
    });
  }, [cartStore.items]);

  const anyItemHasMultistock = useMemo(() => {
    return cartStore.items.some((i) => i.hasMultiStock);
  }, [cartStore.items]);

  const placeOrderForm = usePlaceOrderForm({
    standardShippingAddress: session.customerAddress,
    standardInvoiceAddress:
      session.invoiceCustomerAddress ?? session.customerAddress,
    cartStoreItems: cartStore.items,
    onSubmit: handleFormSubmit,
  });

  useEffect(() => {
    cartStore.refreshItems();
  }, []);

  useEffect(() => {
    setDisableDatePicker(
      (!itemsAreInStock && !session.allowAutomaticOrderOnIncomingStock) ||
        !placeOrderForm.deliveryDatesOptions?.deliveryDateAvailable ||
        anyItemHasMultistock ||
        session.customerType === CustomerType.Internal ||
        !session.allowAutomaticOrder ||
        session.useStockLocations ||
        (OrderType[placeOrderForm.formik.values.orderType.key] !==
          OrderType[OrderType.Standard] &&
          OrderType[placeOrderForm.formik.values.orderType.key] !==
            OrderType[OrderType.Sample]),
    );
  }, [
    itemsAreInStock,
    anyItemHasMultistock,
    placeOrderForm.formik.values.orderType,
    placeOrderForm.deliveryDatesOptions?.deliveryDateAvailable,
  ]);

  useEffect(() => {
    setDisableShippingAddress(
      OrderType[placeOrderForm.formik.values.orderType.key] ===
        OrderType[OrderType.Forecast],
    );
  }, [placeOrderForm.formik.values.orderType]);

  if (placeOrderForm.error) {
    return <ApiErrorDetails error={placeOrderForm.error} />;
  }

  if (placeOrderForm.isInitializing) {
    return <Skeleton paragraph={{ rows: 4 }} active={true} />;
  }

  const DeliveryDatePickerContainer = ({
    children,
  }: {
    children: React.ReactNode;
  }) =>
    session.customerType === CustomerType.Internal ||
    !session.allowAutomaticOrder ||
    session.useStockLocations ||
    OrderType[placeOrderForm.formik.values.orderType.key] ===
      OrderType[OrderType.Forecast] ? (
      <></>
    ) : (
      <>{children}</>
    );

  return (
    <div className={styles.content}>
      {placeOrderError && <ApiErrorDetails error={placeOrderError} />}
      {placeOrderRequest.isLoading && <LoadingIndicator />}
      <Form form={placeOrderForm.formik} layout={formLayout}>
        <Row gutter={36}>
          <Col span={11}>
            <Divider orientation="left">{t(messages.shared.details)}</Divider>
            <SelectField
              label="Order type"
              name="orderType"
              style={{ width: '100%' }}
              optionFilterProp="children"
              dropdownMatchSelectWidth={false}
              className={styles.formInputElement}
            >
              {placeOrderForm.orderTypeOptions.map((orderTypeOption) => (
                <Select.Option key={orderTypeOption.key}>
                  {orderTypeOption.label}
                </Select.Option>
              ))}
            </SelectField>
            <SelectField
              loading={placeOrderForm.seasons.isLoading}
              label={t(messages.order.season)}
              name="season"
              style={{ width: '100%' }}
              optionFilterProp="children"
              dropdownMatchSelectWidth={false}
              className={styles.formInputElement}
            >
              <Select.Option key={NONE_VALUE} style={{ fontWeight: 'bold' }}>
                None of the listed
              </Select.Option>
              {placeOrderForm.seasons.data?.map((s) => (
                <Select.Option key={s.id}>{s.name}</Select.Option>
              ))}
            </SelectField>
            <TextField
              name="externalOrderNumber"
              label={t(messages.order.referenceOrderNumber)}
            />
            <TextField
              name="contactName"
              label={t(messages.order.contactName)}
            />
            <TextField
              name="contactPhone"
              label={t(messages.order.contactPhone)}
            />
            <TextField
              name="courierAccount"
              label={t(messages.order.courierAccount)}
            />
            <TextAreaField
              rows={3}
              name="orderMessage"
              label={t(messages.order.message)}
            />

            {placeOrderForm.metaFields &&
              placeOrderForm.metaFields
                .sort((field1, field2) => field1.sortOrder - field2.sortOrder)
                .map((field) => (
                  <OrderMetaField
                    formPath="metaFields"
                    key={field.id}
                    field={field}
                  />
                ))}
            <Divider orientation="left">{t(messages.address.address)}</Divider>
            {session.customerType === CustomerType.Brand && (
              <div className={styles.vendorOptionContainer}>
                <Checkbox
                  checked={placeOrderForm.vendorOptionIsVisible}
                  onChange={(e) =>
                    placeOrderForm.toggleVendorOption(e.target.checked)
                  }
                >
                  {t(messages.order.placeAsVendor)}
                </Checkbox>
                {placeOrderForm.vendorOptionIsVisible && (
                  <div className={styles.vendorOptionPickerWrapper}>
                    <VendorOptionPicker
                      error={placeOrderForm.vendorsError}
                      vendors={placeOrderForm.vendors}
                    />
                  </div>
                )}
              </div>
            )}
            <Row gutter={18}>
              {!disableShippingAddress && (
                <Col span={12}>
                  <div className={styles.addressTitleContainer}>
                    <span className={styles.addressTitle}>
                      {t(messages.address.shipping)}
                    </span>
                    <Divider type="vertical" />
                    <AddressSelect
                      existingCustomAddressId={
                        placeOrderForm.formik.values.existingCustomAddressId
                      }
                      addresses={placeOrderForm.customAddresses}
                      addressesError={placeOrderForm.customAddressesError}
                      loadingAddresses={placeOrderForm.loadingCustomAddresses}
                      loadAddresses={placeOrderForm.loadCustomAddresses}
                      onChangeAddressType={
                        placeOrderForm.changeShippingAddressType
                      }
                      shippingAddressType={placeOrderForm.shippingAddressType}
                    />
                  </div>
                  {placeOrderForm.shippingAddressType ===
                    ShippingAddressType.NewCustom && (
                    <AddressForm formPath="customAddress" />
                  )}
                  {!!placeOrderForm.shippingAddress && (
                    <AddressDetails address={placeOrderForm.shippingAddress} />
                  )}
                </Col>
              )}

              <Col span={12}>
                <div className={styles.addressTitleContainer}>
                  <span className={styles.addressTitle}>
                    {t(messages.address.billing)}
                  </span>
                </div>
                <AddressDetails address={placeOrderForm.invoiceAddress} />
              </Col>
            </Row>
          </Col>
          <Col span={13}>
            <Divider orientation="left">{t(messages.cart.cart)}</Divider>
            {cartStore.items.some((v) => v.quantityIsAdjusted) && (
              <Alert
                icon={<Icon type="info-circle" />}
                message={t(messages.shared.quantityAdjustedInfo)}
                showIcon={true}
                type="info"
              />
            )}
            <CartLineItems />
            <div className={styles.placeOrderContainer}>
              <DeliveryDatePickerContainer>
                <Divider orientation="left">
                  {t(messages.order.deliveryDate)}
                </Divider>
                <FormItem>
                  <DeliveryDatePicker
                    nonWorkingDates={
                      placeOrderForm.deliveryDatesOptions?.nonWorkingDates ?? []
                    }
                    earliestDate={
                      placeOrderForm.deliveryDatesOptions?.earliestDeliveryDate
                    }
                    name="requestedDeliveryDate"
                    upcomingDeliveries={
                      placeOrderForm.shippingAddress &&
                      placeOrderForm.deliveryDatesOptions?.upcomingDeliveries
                        ? placeOrderForm.deliveryDatesOptions.upcomingDeliveries
                        : []
                    }
                    showToday={false}
                    disabled={disableDatePicker}
                    disabledTooltip={
                      anyItemHasMultistock
                        ? messages.order.deliveryPickerDisabledTooltipMultiStock
                        : messages.order.deliveryPickerDisabledTooltipOutOfStock
                    }
                  />
                </FormItem>
              </DeliveryDatePickerContainer>
              <Button
                type="primary"
                size="large"
                block={true}
                disabled={
                  session.locked ||
                  cartStore.isLoading ||
                  !cartStore.items.length ||
                  !!cartStore.unavailableArticleIds.length
                }
                onClick={placeOrderForm.formik.submitForm}
              >
                {t(messages.order.placeOrder)}
              </Button>
            </div>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

function VendorOptionPicker({
  error,
  vendors,
}: {
  error?: ApiError;
  vendors?: Vendor[];
}) {
  if (error) {
    return <ApiErrorDetails error={error} />;
  }

  if (vendors === undefined) {
    return <Skeleton paragraph={false} active={true} />;
  }

  return (
    <SelectField
      name="vendorCustomer"
      showSearch={true}
      style={{ width: '100%' }}
      optionFilterProp="children"
      dropdownMatchSelectWidth={false}
      placeholder="Select vendor"
      className={styles.formInputElement}
    >
      {vendors.map((v) => (
        <Select.Option key={v.id}>{v.name}</Select.Option>
      ))}
    </SelectField>
  );
}
