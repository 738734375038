import React, { useRef } from 'react';
import Scrollbar from 'react-scrollbars-custom';
import styles from './xHeaders.module.scss';
import cc from 'classcat';
import { CellSize, RenderHeaderCellContent } from '../types';
import { customIEScroll } from '../helpers';
import { OverflowOverlay } from '../overflowOverlay/overflowOverlay';

export const XHeaders = React.memo(function XHeaders({
  xKeys,
  cellSize,
  selectedXKey,
  renderHeaderCellContent,
  innerScrollRef,
  overflowingLeft,
  overflowingRight,
  onScroll,
  onScrollStart,
  onScrollStop,
}: {
  xKeys: string[];
  cellSize: CellSize;
  selectedXKey?: string;
  renderHeaderCellContent: RenderHeaderCellContent;
  overflowingLeft: boolean;
  overflowingRight: boolean;
  innerScrollRef: (instance?: Scrollbar) => void;
  onScroll: () => void;
  onScrollStart: () => void;
  onScrollStop: () => void;
}) {
  const scrollRef = useRef<Scrollbar>();

  return (
    <Scrollbar
      style={{
        width: 'auto',
        height: cellSize.height,
        marginLeft: cellSize.width,
      }}
      wrapperProps={{
        style: { marginBottom: 0 },
        className: styles.xHeadersWrapper,
        overflowingLeft,
        overflowingRight,
      }}
      wrapperRenderer={(props: any) => {
        const {
          elementRef,
          overflowingLeft,
          overflowingRight,
          ...restProps
        } = props;
        return (
          <div {...restProps} ref={elementRef}>
            {overflowingLeft && <OverflowOverlay direction="left" />}
            {overflowingRight && <OverflowOverlay direction="right" />}
            {restProps.children}
          </div>
        );
      }}
      trackXProps={{ style: { height: 0 } }}
      ref={(ref) => {
        innerScrollRef(ref ? ref : undefined);
        scrollRef.current = ref ? ref : undefined;
      }}
      noScrollY={true}
      onScroll={onScroll}
      onScrollStart={onScrollStart}
      onScrollStop={onScrollStop}
      contentProps={{ onWheel: customIEScroll(scrollRef.current!) }}
    >
      <div className={styles.xHeadersRow}>
        {xKeys.map((xKey) => (
          <div
            key={xKey}
            className={cc({
              [styles.xHeadersCell]: true,
              [styles.xHeadersCellSelected]: selectedXKey === xKey,
            })}
            style={{
              width: cellSize.width,
              height: cellSize.height,
            }}
          >
            {renderHeaderCellContent({ key: xKey })}
          </div>
        ))}
      </div>
    </Scrollbar>
  );
});
