import React from 'react';
import { Form } from 'antd';

export function FormItem({
  label,
  help,
  className,
  validateStatus,
  children,
}: {
  label?: React.ReactNode;
  help?: React.ReactNode;
  className?: string;
  validateStatus?: 'error' | 'success' | 'warning' | 'validating';
  children: React.ReactNode;
}) {
  return (
    <Form.Item
      label={label}
      validateStatus={validateStatus}
      help={help}
      className={className}
    >
      {children}
    </Form.Item>
  );
}
