import MessageFormat from 'messageformat';
import { Locale, FlattenedTranslations } from './types';

export function createMessageTranslator(
  locale: Locale,
  translations: FlattenedTranslations,
) {
  const mf = new MessageFormat(locale);
  const messageFormatters: any = mf.compile({ translations });

  function translate(
    messageKey: string,
    translationArguments: { [key: string]: string | number } = {},
  ): string {
    const messageFormatter = messageFormatters.translations[messageKey];
    if (!messageFormatter) {
      throw Error(`No translation found for key ${messageKey}`);
    }

    return messageFormatter(translationArguments);
  }

  return { translate };
}

export type MessageTranslator = ReturnType<typeof createMessageTranslator>;
export type TranslateFunction = MessageTranslator['translate'];
