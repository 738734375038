export interface Stock {
  orderIn: number;
  orderOut: number;
  quantity: number;
}

export function getAvailable(stock: Stock) {
  return stock.quantity - stock.orderOut;
}

export function getBalance(stock: Stock) {
  return getAvailable(stock) + stock.orderIn;
}

export function getTotalOrderIn(stocks: Stock[]) {
  return stocks.reduce((total, { orderIn }) => total + orderIn, 0);
}

export function getTotalOrderOut(stocks: Stock[]) {
  return stocks.reduce((total, { orderOut }) => total + orderOut, 0);
}

export function getTotalQuantity(stocks: Stock[]) {
  return stocks.reduce((total, { quantity }) => total + quantity, 0);
}

export function getTotalAvailable(stocks: Stock[]) {
  return stocks.reduce((total, stock) => total + getAvailable(stock), 0);
}

export function getTotalBalance(stocks: Stock[]) {
  return stocks.reduce((total, stock) => total + getBalance(stock), 0);
}
