import { ApiRequest } from '../hooks/useApi';

export const assortmentsApi = {
  getAssortments: (): ApiRequest<Assortment[]> => async ({
    httpClient,
    apiSettings,
  }) => {
    const response = await httpClient.get(apiSettings.assortmentsUri);

    if (response.hasError) {
      return { error: response.error };
    } else {
      return {
        result: response.data,
      };
    }
  },
};

export interface Assortment {
  id: number;
  name: string;
  checkoutAdapterId?: number;
  children?: Assortment[];
}
