import React, { useReducer, useRef } from 'react';
import { Icon } from 'antd';
import styles from './quickSearch.module.scss';
import Downshift from 'downshift';
import cc from 'classcat';
import { reducer, initialState } from './quickSearchState';
import { MenuItem } from './types';
import { SearchMenu } from './quickSearchMenu/quickSearchMenu';
import { useI18n } from '../../../hooks/useI18n';
import { messages } from '../../../messages';
import { useDebounceValueCallback } from '../../../hooks/useDebounceValueCallback';
import { useApiRequests } from '../../../hooks/useApi';
import { useArticleCartModalStore } from '../../../hooks/shop/useArticleCartModalStore';
import { articlesApi } from '../../../api/articlesApi';

export function QuickSearch() {
  const { t } = useI18n();
  const inputRef = useRef<HTMLInputElement>(null);
  const [state, dispatch] = useReducer(reducer, initialState);
  const searchRequest = useApiRequests(articlesApi.getArticles);
  const articleCartModalStore = useArticleCartModalStore();

  const [
    debouncedSearchQuery,
    setDebouncedSearchQuery,
  ] = useDebounceValueCallback(state.searchQuery, (value) =>
    handleSearchQueryChange(value),
  );

  function search(searchQuery: string, page: number) {
    searchRequest
      .create({
        onSuccess: (result) => {
          dispatch({
            type: 'searchSuccess',
            payload: { result, page },
          });
        },
        onError: (error) => {
          dispatch({ type: 'searchFailure', payload: error.message });
        },
      })
      .send({
        filterValues: {
          searchQuery,
        },
        page,
        pageSize: 10,
        sortDesc: false,
        sortBy: 'articleNumber',
      });
  }

  function handleSearchQueryChange(newSearchQuery: string) {
    dispatch({ type: 'updatedSearchQuery', payload: newSearchQuery });
    search(newSearchQuery, 1);
  }

  function handleLoadMore() {
    dispatch({ type: 'loadingMore' });
    search(state.searchQuery, state.currentPage + 1);
  }

  function handleMenuMouseUp() {
    // Catch end of menu interactions like scrolling, and focus
    // the input, so that we capture keyboard inputs (up/down arrows)
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }

  function handleOuterClick() {
    dispatch({ type: 'reset' });
  }

  function handleSelectItem(item: MenuItem | null, controller: any) {
    if (!item) {
      dispatch({ type: 'reset' });
      return;
    }

    if (item.type === 'article') {
      if (inputRef.current) {
        inputRef.current.blur();
      }
      dispatch({ type: 'reset' });
      articleCartModalStore.showArticle(item.id);
    }

    if (item.type === 'loadMore') {
      if (state.list) {
        controller.setHighlightedIndex(state.list.items.length - 1);
      }
      handleLoadMore();
    }
  }

  function handleInputChange(e: React.ChangeEvent<HTMLInputElement>) {
    setDebouncedSearchQuery(e.target.value);
  }

  return (
    <Downshift
      selectedItem={null}
      inputValue={debouncedSearchQuery}
      itemToString={() => ''}
      isOpen={state.isOpen}
      onOuterClick={handleOuterClick}
      onSelect={handleSelectItem}
    >
      {({ getInputProps, getMenuProps, getItemProps, highlightedIndex }) => (
        <div className={styles.wrapper}>
          <div
            className={cc({
              [styles.searchInputWrapper]: true,
              [styles.searchInputWrapperActive]: state.isOpen,
            })}
          >
            <Icon type="search" className={styles.searchIcon} />
            <input
              ref={inputRef}
              className={styles.searchInput}
              placeholder={t(messages.shared.search)}
              {...getInputProps({
                onChange: handleInputChange,
              })}
            />
          </div>
          {state.isOpen && (
            <div onMouseUp={handleMenuMouseUp}>
              <SearchMenu
                loading={state.loading}
                items={state.list ? state.list.items : undefined}
                isLastPage={state.isLastPage}
                error={state.error}
                highlightedIndex={
                  highlightedIndex ? highlightedIndex : undefined
                }
                getMenuProps={getMenuProps}
                getItemProps={getItemProps}
              />
            </div>
          )}
        </div>
      )}
    </Downshift>
  );
}
