import { useRef, useEffect, useCallback, useState } from 'react';
import debounce from 'lodash.debounce';

type SetCallback<T> = (newValue: T) => void;

export function useDebounceValueCallback<T>(
  originalValue: T,
  setCallback: SetCallback<T>,
  debounceTimeout = 350,
) {
  const [value, setValue] = useState<T>(originalValue);
  const callback = useRef<SetCallback<T> | undefined>(setCallback);
  const isRunning = useRef<boolean>(false);

  useEffect(() => {
    callback.current = setCallback;
  }, [setCallback]);

  useEffect(() => {
    setValue(originalValue);
  }, [originalValue]);

  useEffect(() => {
    return () => {
      isRunning.current = false;
      callback.current = undefined;
    };
  }, []);

  const debounceSet = useCallback(
    debounce((newValue: T) => {
      if (!isRunning.current || !callback.current) {
        return;
      }
      callback.current(newValue);
    }, debounceTimeout),
    [],
  );

  const debouncedSetValue = useCallback((newValue: T) => {
    isRunning.current = true;
    setValue(newValue);
    debounceSet(newValue);
  }, []);

  return [value, debouncedSetValue] as [T, SetCallback<T>];
}
