import React, { useEffect } from 'react';
import styles from './userCustomerPicker.module.scss';
import { Layout, Card, Icon, Alert, Button, Divider } from 'antd';
import { useI18n } from '../../../hooks/useI18n';
import { messages } from '../../../messages';
import { ApiErrorDetails } from '../../apiErrorDetails/apiErrorDetails';
import { LoadingIndicator } from '../../loadingIndicator/loadingIndicator';
import { useApiResource } from '../../../hooks/useApi';
import { sessionApi } from '../../../api/sessionApi';
import { UserCustomer } from '../../../types';

export function UserCustomerPicker({
  onSelectUserCustomerId,
  onLogout,
}: {
  onSelectUserCustomerId: (id: number) => void;
  onLogout: () => void;
}) {
  const { t } = useI18n();

  const userCustomers = useApiResource(sessionApi.getUserCustomers);

  useEffect(() => {
    userCustomers.refresh();
  }, []);

  function sortUserCustomers(a: UserCustomer, b: UserCustomer): number {
    const result = sort(a.projectName, b.projectName);
    if (result !== 0) {
      return result;
    }
    return sort(a.branchName, b.branchName);
  }

  function sort(a: string, b: string): number {
    a = a.toLowerCase();
    b = b.toLowerCase();

    return a === b ? 0 : a > b ? 1 : -1;
  }

  const getContent = () => {
    if (userCustomers.error) {
      return <ApiErrorDetails error={userCustomers.error} />;
    }

    if (!userCustomers.data) {
      return <LoadingIndicator />;
    }

    return (
      <>
        {!userCustomers.data.length && (
          <Alert message={t(messages.login.noAvailableLogins)} type="warning" />
        )}
        {!!userCustomers.data.length && (
          <>
            <h2>{t(messages.shared.selectLogin)}</h2>
            {userCustomers.data
              .sort((a, b) => sortUserCustomers(a, b))
              .map((userCustomer) => (
                <Card
                  onClick={() => onSelectUserCustomerId(userCustomer.id)}
                  key={userCustomer.id}
                  className={styles.card}
                >
                  <div className={styles.cardContent}>
                    <div className={styles.cardTitles}>
                      <div className={styles.cardTitle}>
                        {t(messages.shared.country)}: {userCustomer.branchName}
                      </div>
                      <div className={styles.cardTitle}>
                        {t(messages.shared.project)}: {userCustomer.projectName}
                      </div>
                      <div className={styles.cardTitle}>
                        {t(messages.shared.assortment)}: {userCustomer.assortmentName}
                      </div>
                      <div className={styles.cardSubTitle}>
                        {userCustomer.customerErpId} -{' '}
                        {userCustomer.customerName}
                      </div>
                    </div>
                    <div className={styles.iconContainer}>
                      <Icon type="right" />
                    </div>
                  </div>
                </Card>
              ))}
          </>
        )}
        <Divider />
        <Button onClick={onLogout}>{t(messages.login.logout)}</Button>
      </>
    );
  };

  return (
    <Layout>
      <Layout.Content className={styles.baseLayout}>
        <div className={styles.content}>{getContent()}</div>
      </Layout.Content>
    </Layout>
  );
}
