import { ApiRequest } from '../hooks/useApi';

export interface StrippedBranch {
  id: number;
  name: string;
}

export const customerBranchesApi = {
  getBranches:
    (): ApiRequest<StrippedBranch[]> =>
    async ({ httpClient, apiSettings }) => {
      const response = await httpClient.get(apiSettings.customerBranchesUri);

      if (response.hasError) {
        return { error: response.error };
      } else {
        return {
          result: response.data,
        };
      }
    },
};
