import React, { useState } from 'react';
import { messages } from '../../../../../messages';
import { Input, Modal } from 'antd';
import { useI18n } from '../../../../../hooks/useI18n';
import { useFormik } from 'formik';
import { TimePeriodType } from '../../../../../types';
import { ApiError, useApiRequests } from '../../../../../hooks/useApi';
import { Form } from '../../../../form/form';
import {
  CreateUpdateCustomerProductStatisticsReportWidgetRequest,
  customerProductStatisticsReportWidgetApi,
} from '../../../../../api/widgets/customers/customerProductStatisticsReportWidgetApi';
import { useNotificationStore } from '../../../../../hooks/useNotificationStore/notificationStore';
import { ApiErrorDetails } from '../../../../apiErrorDetails/apiErrorDetails';
import style from '../styling/widgetRowStyling.module.scss';
import { TimePeriodPicker } from '../../../../pickers/timePeriodPicker';
import { ArticleCategoryPicker } from '../../../../pickers/articleCategoryPicker';
import { categoryCodesApi } from '../../../../../api/categoryCodesApi';
import { TwitterPicker } from 'react-color';
import { defaultWidgetColors } from '../../../../internal/widgets/widgetColors';
import { CustomerWidgetCategorySelect } from '../categories/customerWidgetCategorySelect';

export const CreateCustomerProductStatisticsReportWidgetModal = ({
  onClose,
}: {
  onClose: () => void;
}) => {
  const { t } = useI18n();
  const { notify } = useNotificationStore();

  const [name, setName] = useState<string>('');
  const [widgetCategoryId, setWidgetCategoryId] = useState<number | undefined>(
    undefined,
  );
  const [color, setColor] = useState<string | undefined>(undefined);
  const [categoryIds, setCategoryIds] = useState<number[]>([]);
  const [timePeriods, setTimePeriods] = useState<{
    type: TimePeriodType;
    periods: string[];
  }>({
    type: TimePeriodType.Yearly,
    periods: [],
  });

  const [error, setError] = useState<ApiError>();

  const createWidget = useApiRequests(
    customerProductStatisticsReportWidgetApi.createWidget,
  );

  const handleFormSubmit = () => {
    const createWidgetData: CreateUpdateCustomerProductStatisticsReportWidgetRequest =
      {
        name: name,
        widgetCategoryId: widgetCategoryId,
        color: color,
        categoryIds: categoryIds,
        timePeriods: timePeriods.periods,
        timePeriodType: timePeriods.type,
      };

    createWidget
      .create({
        onSuccess: () => {
          notify({
            title: t(messages.widgets.widgetCreated),
            type: 'success',
          });
          onClose();
        },
        onError: setError,
      })
      .send(createWidgetData);
  };

  const formik = useFormik<FormData>({
    initialValues: {
      name: '',
      categoryIds: [],
    },
    onSubmit: handleFormSubmit,
  });

  function handleWidgetCategoryOnChange(widgetCategoryId: string | undefined) {
    const id =
      widgetCategoryId === undefined
        ? widgetCategoryId
        : parseInt(widgetCategoryId);
    setWidgetCategoryId(id);
  }

  return (
    <Modal
      title={t(messages.widgets.productStatistics.create)}
      visible={true}
      closable={false}
      onCancel={onClose}
      onOk={formik.submitForm}
      okText={t(messages.shared.save)}
      width={720}
    >
      {error && <ApiErrorDetails error={error} />}
      <Form form={formik}>
        <div className={style.inputRow}>
          <h4>{t(messages.shared.name)}</h4>
          <Input
            value={name}
            onChange={(e) => setName(e.target.value)}
            placeholder={t(messages.widgets.widgetNamePlaceholder)}
          />
          <CustomerWidgetCategorySelect
            value={widgetCategoryId?.toString()}
            onChange={handleWidgetCategoryOnChange}
          />
        </div>
        <div className={style.inputRow}>
          <h4>{t(messages.shared.color)}</h4>
          <TwitterPicker
            width="100%"
            triangle="hide"
            colors={defaultWidgetColors}
            color={color}
            onChange={(color) => setColor(color.hex)}
          />
        </div>
        <div className={style.inputRow}>
          <h4>{t(messages.reports.articleCategory)}</h4>
          <ArticleCategoryPicker
            categoryIds={categoryIds}
            onChange={setCategoryIds}
            categoryRequestFactory={(_) => categoryCodesApi.getCategories}
          />
        </div>
        <div className={style.inputRow}>
          <h4>{t(messages.reports.timePeriod)}</h4>
          <TimePeriodPicker onChange={setTimePeriods} />
        </div>
      </Form>
    </Modal>
  );
};

interface FormData {
  name: string;
  categoryIds: number[];
}
