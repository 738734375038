import { Button, Card, Divider, Skeleton, Tag, Typography } from 'antd';
import { useI18n } from '../../../../../hooks/useI18n';
import { useFileUris } from '../../../../../hooks/useFileUrls/useFileUrls';
import { useApiResource } from '../../../../../hooks/useApi';
import React, { ReactElement, useEffect, useState } from 'react';
import FileSaver from 'file-saver';
import styles from '../../../../internal/dashboard/widgets/awaitingDeliveriesWidget/awaitingDeliveriesWidget.module.scss';
import { messages } from '../../../../../messages';
import {
  Branch,
  customerAwaitingDeliveriesWidgetApi,
} from '../../../../../api/widgets/customers/customerAwaitingDeliveriesWidgetApi';
import { UpdateCustomerAwaitingDeliveriesWidgetModal } from './updateCustomerAwaitingDeliveriesModal';

const { Title } = Typography;

export function CustomerAwaitingDeliveriesWidget({
  userWidgetId,
  onWidgetDelete,
}: {
  userWidgetId: number;
  onWidgetDelete: () => void;
}) {
  const { t } = useI18n();
  const { customerAwaitingDeliveriesExportUri } = useFileUris();

  const widget = useApiResource(customerAwaitingDeliveriesWidgetApi.getWidget);
  const [showUpdateWidgetModal, setShowUpdateWidgetModal] = useState<boolean>();

  const branchesAsTags = (branches: Branch[]): ReactElement[] =>
    branches.map((b) => <Tag key={b.id}>{b.name}</Tag>);

  useEffect(() => {
    widget.refresh(userWidgetId);
  }, []);

  function onExcelExportClick(): void {
    if (widget.data === undefined) return;

    const branchIds = widget.data.branches.map((b) => b.id);
    FileSaver.saveAs(customerAwaitingDeliveriesExportUri(branchIds));
  }

  function getHeadStyle(color: string | undefined) {
    const style = {
      backgroundColor: '#ffffff',
      color: '#000000',
    };
    if (color) {
      style['backgroundColor'] = color;
      style['color'] = '#ffffff';
    }
    return style;
  }

  return (
    <>
      {widget.isLoading && (
        <Card>
          <Skeleton />
        </Card>
      )}
      {widget.hasResponse && widget.data !== undefined && (
        <Card
          title={widget.data.name}
          headStyle={getHeadStyle(widget.data.color)}
          extra={
            <>
              <Button
                className={styles.editButton}
                key="edit"
                type="default"
                size="small"
                icon="edit"
                onClick={() => setShowUpdateWidgetModal(true)}
              ></Button>
              <Button
                key="delete"
                type="danger"
                size="small"
                icon="delete"
                onClick={() => onWidgetDelete()}
              ></Button>
            </>
          }
        >
          <Title className={styles.awaitingDeliveriesCount} level={2}>
            {widget.data.numberOfOrders}
          </Title>
          <p className={styles.awaitingDeliveriesCountText}>
            {t(messages.widgets.awaitingDeliveries.widgetText)}
          </p>
          <Button
            className={styles.exportButton}
            key="export"
            type="primary"
            icon="download"
            size="small"
            onClick={onExcelExportClick}
          >
            {t(messages.widgets.awaitingDeliveries.triggerExport)}
          </Button>
          <Divider className={styles.divider}></Divider>
          <div className={styles.branches}>
            {branchesAsTags(widget.data.branches)}
          </div>
        </Card>
      )}
      {showUpdateWidgetModal && (
        <UpdateCustomerAwaitingDeliveriesWidgetModal
          userWidgetId={userWidgetId}
          onSubmit={() => {
            setShowUpdateWidgetModal(false);
            widget.refresh(userWidgetId);
          }}
          onCancel={() => setShowUpdateWidgetModal(false)}
        />
      )}
    </>
  );
}
