import React from 'react';
import { PropGetters } from 'downshift';
import { Avatar } from 'antd';
import cc from 'classcat';
import { QuickSearchArticle, MenuItem } from '../types';
import { LoadingIndicator } from '../../../loadingIndicator/loadingIndicator';
import { EmptyIndicator } from '../../../emptyIndicator/emptyIndicator';
import styles from './quickSearchMenu.module.scss';
import { useI18n } from '../../../../hooks/useI18n';
import { messages } from '../../../../messages';
import { useFileUris } from '../../../../hooks/useFileUrls/useFileUrls';

export function SearchMenu({
  highlightedIndex,
  loading,
  items,
  isLastPage,
  error,
  getMenuProps,
  getItemProps,
}: {
  highlightedIndex?: number;
  loading: boolean;
  items?: QuickSearchArticle[];
  isLastPage: boolean;
  error?: string;
  getMenuProps: PropGetters<MenuItem>['getMenuProps'];
  getItemProps: PropGetters<MenuItem>['getItemProps'];
}) {
  const { t } = useI18n();
  const { placeholderImageFileUri, articleImageFileUri } = useFileUris();

  return (
    <div {...getMenuProps()} className={styles.menu}>
      {error && <div className={styles.errorLabel}>{error}</div>}
      {loading && <LoadingIndicator />}
      <div className={styles.menuItems}>
        {!!items?.length && (
          <>
            {items.map((item, index) => (
              <div
                key={item.id}
                className={cc({
                  [styles.entry]: true,
                  [styles.entryActive]: highlightedIndex === index,
                })}
                {...getItemProps({
                  item: { type: 'article', id: item.id },
                  index,
                })}
              >
                <Avatar
                  shape="square"
                  src={
                    item.hasImage
                      ? articleImageFileUri(item.id)
                      : placeholderImageFileUri
                  }
                  className={styles.entryImage}
                />
                <div className={styles.entryDetails}>
                  <div className={styles.entryLabel}>{item.articleNumber}</div>
                  <div className={styles.entryLabel}>{item.description}</div>
                </div>
              </div>
            ))}
            {!isLastPage && (
              <div
                className={cc({
                  [styles.loadMoreEntry]: true,
                  [styles.entry]: true,
                  [styles.entryActive]: items.length === highlightedIndex,
                })}
                {...getItemProps({
                  item: { type: 'loadMore' },
                  index: items.length,
                })}
              >
                {t(messages.shared.loadMore)}
              </div>
            )}
          </>
        )}
        {items?.length === 0 && (
          <div className={styles.noMenuItemsLabel}>
            <EmptyIndicator description={t(messages.shared.noItems)} />
          </div>
        )}
      </div>
    </div>
  );
}
