import React from 'react';
import { FormLayoutOptions } from './types';
import { FormLayout } from './formLayout';
import { FormikProvider } from 'formik';

export function Form({
  form,
  children,
  layout,
}: {
  form: any;
  layout?: FormLayoutOptions;
  children: React.ReactNode;
}) {
  return (
    <FormLayout layout={layout}>
      <FormikProvider value={form}>{children}</FormikProvider>
    </FormLayout>
  );
}
