import { ApiRequest } from '../hooks/useApi';
import { ArticleType, PagedResult } from '../types';

export const articlesApi = {
  getArticle:
    (id: number, includeFrozen?: boolean): ApiRequest<ArticleDetails> =>
    async ({ httpClient, apiSettings }) => {
      const response = await httpClient.get(apiSettings.articleUri(id), {
        params: { includeFrozen },
      });

      if (response.hasError) {
        return { error: response.error };
      } else {
        return {
          result: {
            ...response.data,
            syncError: response.headers.syncerror,
          },
        };
      }
    },

  getArticles:
    (options: GetArticlesOptions): ApiRequest<PagedResult<ArticleListItem>> =>
    async ({ httpClient, apiSettings }) => {
      const response = await httpClient.get(apiSettings.articlesUri, {
        params: {
          searchQuery: options.filterValues.searchQuery,
          assortmentId: options.filterValues.assortmentId,
          sortBy: options.sortBy,
          sortDesc: options.sortDesc,
          limit: options.pageSize,
          offset: (options.page - 1) * options.pageSize,
          displayCurrencyId: options.displayCurrencyId,
        },
      });

      if (response.hasError) {
        return { error: response.error };
      } else {
        return {
          result: {
            items: response.data.result,
            totalItems: response.data.total,
          },
        };
      }
    },

  getArticlePrices:
    (id: number, displayCurrency: number): ApiRequest<ArticlePrice[]> =>
    async ({ httpClient, apiSettings }) => {
      const response = await httpClient.get(apiSettings.articlePricesUri(id), {
        params: { displayCurrency },
      });

      if (response.hasError) {
        return { error: response.error };
      } else {
        return {
          result: response.data,
        };
      }
    },
};

export interface ArticleDetails {
  id: number;
  articleNumber: string;
  articleStocks: ArticleBranchStock[];
  colorName?: string;
  currencyCode: string;
  currencyPriceDecimalPlaces: number;
  description: string;
  extraDescription: string;
  frozen: boolean;
  hasImage: boolean;
  displayPrice: number;
  quantityDecimalPlaces: number;
  quantityPerUnit: string;
  sizeName?: string;
  unit: string;
  syncError: string;
  type: ArticleType;
  useStockLocations: boolean;
  variants?: MatrixVariantSummary[];
}

export interface ArticleBranchStock {
  articleStockLocations: ArticleStock[];
  branchId: number;
  branchName: string;
  branchNumber: string;
}

export interface ArticleStock {
  locationName: string;
  locationNumber: string;
  orderIn: number;
  orderOut: number;
  quantity: number;
}

export interface MatrixVariantSummary {
  id: number;
  articleNumber: string;
  colorName: string;
  colorCode: string;
  frozen: boolean;
  hasMultipleLocationStock: boolean;
  sizeName: string;
  sizeCode: string;
  stockLocations: ArticleStock[];
  displayPrice: number;
  priceDecimals: number;
  quantityDecimals: number;
}

export interface ArticleListItem {
  id: number;
  articleNumber: string;
  description: string;
  erpText1: string;
  type: ArticleType;
  displayPrice: number;
  unit: string;
  inStock: number;
  inOrder: number;
  available: number;
  quantityDecimalPlaces: number;
  hasImage: boolean;
}

interface GetArticlesOptions {
  page: number;
  pageSize: number;
  sortDesc: boolean;
  sortBy: keyof ArticleListItem;
  filterValues: ArticleFilterValues;
  displayCurrencyId?: number;
}

export interface ArticleFilterValues {
  searchQuery: string;
  assortmentId?: number;
}

export interface ArticlePrice {
  articleId: number;
  price: number;
}
