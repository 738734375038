import React from 'react';
import { Card, Button, Icon } from 'antd';
import styles from './listCardItem.module.scss';
import { CartQuantity } from '../cartQuantity/cartQuantity';
import { useI18n } from '../../../../hooks/useI18n';
import { messages } from '../../../../messages';
import { Currency, ArticleType } from '../../../../types';
import { formatNumber } from '../../../../helpers/formatNumber/formatNumber';
import { ArticleListItem } from '../../../../api/articlesApi';
import { useFileUris } from '../../../../hooks/useFileUrls/useFileUrls';

interface Props {
  item: ArticleListItem;
  displayCurrency: Currency;
  onSelectItem: (item: ArticleListItem) => void;
  showPrice?: boolean;
}

export const ListCardItem = ({
  item,
  displayCurrency,
  onSelectItem,
  showPrice = true,
}: Props) => {
  const { t } = useI18n();
  const { placeholderImageFileUri, articleImageFileUri } = useFileUris();

  return (
    <Card
      key={item.id}
      onClick={() => onSelectItem(item)}
      className={styles.card}
      cover={
        <div className={styles.imageWrapper}>
          <img
            src={
              item.hasImage
                ? articleImageFileUri(item.id)
                : placeholderImageFileUri
            }
            alt="product"
            onError={({ currentTarget }) => {
              currentTarget.onerror = null;
              currentTarget.src = placeholderImageFileUri;
            }}
          />
        </div>
      }
    >
      <Card.Meta
        title={item.articleNumber}
        description={
          <div className={styles.description}>
            <div>{item.description}</div>
            {showPrice && item.type !== ArticleType.Matrix && (
              <>
                <div className={styles.stock}>
                  {`${t(messages.stock.stock)}: ${item.available.toFixed(
                    item.quantityDecimalPlaces,
                  )}`}
                </div>
                <div>
                  {`${t(messages.article.unit)}: `}
                  {`${item.unit}`}
                </div>
                <div>
                  {`${t(messages.shared.price)}: `}
                  {`${formatNumber(
                    item.displayPrice,
                    displayCurrency.priceDecimalPlaces,
                  )} ${displayCurrency.code}`}
                </div>
              </>
            )}
            <div className={styles.cartQuantityWrapper}>
              <CartQuantity articleId={item.id} />
            </div>
            <div className={styles.actions}>
              <Button type="primary" ghost={true} block={true}>
                <Icon type="shopping-cart" />
              </Button>
            </div>
          </div>
        }
      />
    </Card>
  );
};
