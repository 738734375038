import React, { useRef, useCallback, useReducer, useEffect } from 'react';
import styles from './matrixTable.module.scss';
import Scrollbar from 'react-scrollbars-custom';
import {
  CellSize,
  BaseCellValue,
  RenderCellContent,
  CellLocation,
  RenderHeaderCellContent,
} from './types';
import { XHeaders } from './xHeaders/xHeaders';
import { YHeaders } from './yHeaders/yHeaders';
import { Cells } from './cells/cells';
import debounce from 'lodash.debounce';
import { overflowStateReducer } from './overflowState';

export function MatrixTable<T extends BaseCellValue>({
  xAxisLabel,
  yAxisLabel,
  xKeys,
  yKeys,
  selectedCell,
  cellSize,
  cellValues,
  renderXHeaderCellContent,
  renderYHeaderCellContent,
  renderCellContent,
  onCellSelect,
}: {
  xAxisLabel: string;
  yAxisLabel: string;
  xKeys: string[];
  yKeys: string[];
  selectedCell?: CellLocation;
  cellSize: CellSize;
  cellValues: T[];
  renderXHeaderCellContent: RenderHeaderCellContent;
  renderYHeaderCellContent: RenderHeaderCellContent;
  renderCellContent: RenderCellContent<T>;
  onCellSelect: (cell: CellLocation) => void;
}) {
  const currentScrollTarget = useRef<'cells' | 'headers' | 'none'>('none');
  const xHeadersScrollRef = useRef<Scrollbar>();
  const yHeadersScrollRef = useRef<Scrollbar>();
  const cellsScrollRef = useRef<Scrollbar>();

  const [overflowState, dispatchOverflowAction] = useReducer(
    overflowStateReducer,
    {
      left: false,
      right: false,
      top: false,
      bottom: false,
    },
  );

  const checkOverflow = useCallback(
    debounce(
      () => {
        if (cellsScrollRef.current !== undefined) {
          const scrollValues = cellsScrollRef.current.getScrollValues();
          dispatchOverflowAction({
            type: 'scroll',
            payload: {
              scrollWidth: scrollValues.scrollWidth ?? 0,
              clientWidth: scrollValues.clientWidth ?? 0,
              scrollHeight: scrollValues.scrollHeight ?? 0,
              clientHeight: scrollValues.clientHeight ?? 0,
              scrollTop: scrollValues.scrollTop ?? 0,
              scrollLeft: scrollValues.scrollLeft ?? 0,
            },
          });
        }
      },
      50,
      { maxWait: 100 },
    ),
    [],
  );

  const handleHeadersScrollStart = useCallback(() => {
    if (currentScrollTarget.current === 'none') {
      currentScrollTarget.current = 'headers';
    }
  }, []);

  const handleCellsScrollStart = useCallback(() => {
    if (currentScrollTarget.current === 'none') {
      currentScrollTarget.current = 'cells';
    }
  }, []);

  const handleHeadersScroll = useCallback(() => {
    if (currentScrollTarget.current === 'headers') {
      cellsScrollRef.current!.scrollLeft = xHeadersScrollRef.current!.scrollLeft;
      cellsScrollRef.current!.scrollTop = yHeadersScrollRef.current!.scrollTop;
    }
  }, []);

  const handleCellsScroll = useCallback(() => {
    if (currentScrollTarget.current === 'cells') {
      xHeadersScrollRef.current!.scrollLeft = cellsScrollRef.current!.scrollLeft;
      yHeadersScrollRef.current!.scrollTop = cellsScrollRef.current!.scrollTop;
    }
    checkOverflow();
  }, []);

  const handleHeadersScrollStop = useCallback(() => {
    if (currentScrollTarget.current === 'headers') {
      currentScrollTarget.current = 'none';
    }
  }, []);

  const handleCellsScrollStop = useCallback(() => {
    if (currentScrollTarget.current === 'cells') {
      currentScrollTarget.current = 'none';
    }
    checkOverflow();
  }, []);

  const setXHeadersScrollRef = useCallback((ref?: Scrollbar) => {
    xHeadersScrollRef.current = ref;
  }, []);

  const setYHeadersScrollRef = useCallback((ref?: Scrollbar) => {
    yHeadersScrollRef.current = ref;
  }, []);

  const setCellsScrollRef = useCallback((ref?: Scrollbar) => {
    cellsScrollRef.current = ref;
  }, []);

  useEffect(() => {
    checkOverflow();
  }, []);

  return (
    <div className={styles.baseWrapper}>
      <div
        className={styles.axisLabels}
        style={{ width: cellSize.width, height: cellSize.height }}
      >
        <svg className={styles.svgLine} focusable="false">
          <line x1="0" y1="0" x2="100%" y2="100%" />
        </svg>
        <span className={styles.xAxisLabel}>{xAxisLabel}</span>
        <span className={styles.yAxisLabel}>{yAxisLabel}</span>
      </div>
      <XHeaders
        xKeys={xKeys}
        cellSize={cellSize}
        selectedXKey={selectedCell ? selectedCell.xKey : undefined}
        innerScrollRef={setXHeadersScrollRef}
        renderHeaderCellContent={renderXHeaderCellContent}
        overflowingLeft={overflowState.left}
        overflowingRight={overflowState.right}
        onScroll={handleHeadersScroll}
        onScrollStart={handleHeadersScrollStart}
        onScrollStop={handleHeadersScrollStop}
      />
      <div
        className={styles.rowsContainer}
        style={{ height: `calc(100% - ${cellSize.height}px)` }}
      >
        <YHeaders
          yKeys={yKeys}
          cellSize={cellSize}
          selectedYKey={selectedCell ? selectedCell.yKey : undefined}
          renderHeaderCellContent={renderYHeaderCellContent}
          overflowingTop={overflowState.top}
          overflowingBottom={overflowState.bottom}
          innerScrollRef={setYHeadersScrollRef}
          onScroll={handleHeadersScroll}
          onScrollStart={handleHeadersScrollStart}
          onScrollStop={handleHeadersScrollStop}
        />
        <Cells
          xKeys={xKeys}
          yKeys={yKeys}
          selectedCell={selectedCell}
          cellSize={cellSize}
          cellValues={cellValues}
          renderCellContent={renderCellContent}
          overflowingLeft={overflowState.left}
          overflowingRight={overflowState.right}
          overflowingTop={overflowState.top}
          overflowingBottom={overflowState.bottom}
          innerScrollRef={setCellsScrollRef}
          onScroll={handleCellsScroll}
          onScrollStart={handleCellsScrollStart}
          onScrollStop={handleCellsScrollStop}
          onCellSelect={onCellSelect}
        />
      </div>
    </div>
  );
}
