import { OrderStatus, DeliveryStatus } from '../types';

export enum OrderProgress {
  Draft = 1,
  Unconfirmed = 2,
  AwaitingDelivery = 3,
  PartiallyDelivered = 4,
  Delivered = 5,
}

export interface OrderProgressModel {
  orderStatus: OrderStatus | undefined;
  deliveryStatus: DeliveryStatus | undefined;
}

export function fromOrderProgress(progress: OrderProgress) {
  switch (progress) {
    case OrderProgress.Draft:
      return { orderStatus: OrderStatus.Draft, deliveryStatus: undefined };
    case OrderProgress.Unconfirmed:
      return { orderStatus: OrderStatus.Placed, deliveryStatus: undefined };
    case OrderProgress.AwaitingDelivery:
      return {
        orderStatus: OrderStatus.Confirmed,
        deliveryStatus: DeliveryStatus.Undelivered,
      };
    case OrderProgress.PartiallyDelivered:
      return {
        orderStatus: OrderStatus.Confirmed,
        deliveryStatus: DeliveryStatus.PartiallyDelivered,
      };
    case OrderProgress.Delivered:
      return {
        orderStatus: OrderStatus.Confirmed,
        deliveryStatus: DeliveryStatus.Delivered,
      };
  }
}
export function fromOrderProgresses(
  progresses: number[],
): OrderProgressModel[] {
  return progresses.flatMap((progress) => {
    switch (progress) {
      case OrderProgress.AwaitingDelivery:
        return {
          orderStatus: OrderStatus.Confirmed,
          deliveryStatus: DeliveryStatus.Undelivered,
        };
      case OrderProgress.Delivered:
        return {
          orderStatus: OrderStatus.Confirmed,
          deliveryStatus: DeliveryStatus.Delivered,
        };
      case OrderProgress.Draft:
        return {
          orderStatus: OrderStatus.Draft,
          deliveryStatus: undefined,
        };
      case OrderProgress.PartiallyDelivered:
        return {
          orderStatus: OrderStatus.Confirmed,
          deliveryStatus: DeliveryStatus.PartiallyDelivered,
        };
      case OrderProgress.Unconfirmed:
        return {
          orderStatus: OrderStatus.Placed,
          deliveryStatus: undefined,
        };
      default:
        return [];
    }
  }) as OrderProgressModel[];
}

export function toOrderProgresses(progresses: number[]): OrderProgress[] {
  return progresses.flatMap((progress) => {
    switch (progress) {
      case 1:
        return OrderProgress.Draft;
      case 2:
        return OrderProgress.Unconfirmed;
      case 3:
        return OrderProgress.AwaitingDelivery;
      case 4:
        return OrderProgress.PartiallyDelivered;
      case 5:
        return OrderProgress.Delivered;
      default:
        return [];
    }
  });
}

export function toOrderProgress(
  orderStatus: OrderStatus,
  deliveryStatus: DeliveryStatus,
) {
  switch (orderStatus) {
    case OrderStatus.Draft:
      return OrderProgress.Draft;
    case OrderStatus.Placed:
      return OrderProgress.Unconfirmed;
    case OrderStatus.Confirmed:
      switch (deliveryStatus) {
        case DeliveryStatus.Undelivered:
          return OrderProgress.AwaitingDelivery;
        case DeliveryStatus.PartiallyDelivered:
          return OrderProgress.PartiallyDelivered;
        case DeliveryStatus.Delivered:
          return OrderProgress.Delivered;
      }
  }
}
