/* eslint-disable react-hooks/rules-of-hooks */
import { useState, useEffect, useMemo, useRef } from 'react';
import constate from 'constate';
import { SimpleEventDispatcher } from 'strongly-typed-events';
import { useApiRequests, useApiResource, ApiError } from '../../useApi';
import { useShopSessionStore } from '../useShopSessionStore';
import { useNotificationStore } from '../../useNotificationStore/notificationStore';
import { UpdateLineItem, LineItem } from '../../../types';
import { cartApi } from '../../../api/cartApi';

const cartStore = () => {
  const articleUpdateEvent = useRef(new SimpleEventDispatcher<number>());
  const notificationStore = useNotificationStore();
  const {
    session,
    checkoutAdapter: { onUpdateCartUpdateSuccess, maxCartSize },
  } = useShopSessionStore();

  const [isVisible, setIsVisible] = useState<boolean>(false);
  const cart = useApiResource(cartApi.getItems, {
    emptyValue: {
      items: [] as LineItem[],
      unavailableArticleIds: [] as number[],
    },
    clearDataOnLoad: false,
  });

  const updateLineItemRequest = useApiRequests(cartApi.updateLineItem);
  const clearLineItemsRequest = useApiRequests(cartApi.clearLineItems);

  const handleCartUpdateSuccess = (item: UpdateLineItem) => {
    articleUpdateEvent.current.dispatch(item.articleId);
    onUpdateCartUpdateSuccess(item, isVisible);
  };

  const handleCartUpdateError = (error: ApiError) => {
    notificationStore.notify({
      title: error.message,
      type: 'danger',
      errors: error.errors,
    });
  };

  const validateCartSize = (
    update: UpdateLineItem,
    currentCartItems: LineItem[],
    maxSize: number | null,
  ) => {
    if (!maxSize || !update.quantity) return true;

    const currentCount = currentCartItems.reduce(
      (acc, curr) => acc + curr.quantity,
      0,
    );

    return currentCount + update.quantity <= maxSize;
  };

  const updateLineItem = (lineItem: UpdateLineItem) => {
    if (!validateCartSize(lineItem, cart.data.items, maxCartSize)) {
      notificationStore.notify({
        title: `Cart can have ${maxCartSize} article${
          maxCartSize === 1 ? '' : 's'
        } at most`,
        type: 'danger',
      });

      return;
    }

    updateLineItemRequest
      .create({
        onSuccess: () => {
          handleCartUpdateSuccess(lineItem);
          cart.refresh();
        },
        onError: handleCartUpdateError,
      })
      .send(lineItem);
  };

  const clearLineItems = () => {
    clearLineItemsRequest
      .create({
        onSuccess: () => {
          cart.refresh();
        },
        onError: handleCartUpdateError,
      })
      .send();
  };

  useEffect(() => {
    cart.refresh();
  }, [session]);

  const isLoading = useMemo(() => {
    return updateLineItemRequest.isLoading || cart.isLoading;
  }, [updateLineItemRequest.isLoading, cart.isLoading]);

  const showCart = () => {
    setIsVisible(true);
  };

  const hideCart = () => {
    setIsVisible(false);
  };

  const refreshItems = () => {
    cart.refresh();
  };

  // Memoize to only update consumers when specified deps changes
  return useMemo(
    () => ({
      items: cart.data.items,
      unavailableArticleIds: cart.data.unavailableArticleIds,
      isLoading,
      isVisible,
      updateLineItem,
      clearLineItems,
      showCart,
      hideCart,
      refreshItems,
      articleUpdateEvent: articleUpdateEvent.current.asEvent(),
    }),
    [cart.data, isLoading, isVisible],
  );
};

const [CartStoreProvider, useCartStore] = constate(cartStore);
export { CartStoreProvider, useCartStore };
