import React, { useEffect } from 'react';
import { Route, Link, withRouter, RouteComponentProps } from 'react-router-dom';
import { matchPath, Switch, Redirect, generatePath } from 'react-router';
import {
  Alert,
  Layout,
  Menu,
  Icon,
  Dropdown,
  Button,
  Badge,
  Tooltip,
} from 'antd';
import styles from './shop.module.scss';
import { Dashboard } from './dashboard/dashboard';
import { Documents } from './documents/documents';
import { Articles } from './articles/articles';
import { QuickSearch } from './quickSearch/quickSearch';
import { ArticleCartModal } from './articleCartModal/articleCartModal';
import { ShopSession, ApplicationEnvironment, CustomerType } from '../../types';
import { useI18n } from '../../hooks/useI18n';
import { messages } from '../../messages';
import { Orders, OrderTabs } from './orders/orders';
import { Order } from './order/order';
import { DescriptionList } from '../descriptionList/descriptionList';
import { ApiErrorDetails } from '../apiErrorDetails/apiErrorDetails';
import { useLastLocation } from 'react-router-last-location';
import { routes } from './routes';
import { CartStoreProvider, useCartStore } from '../../hooks/shop/useCartStore';
import {
  ShopSessionStoreProvider,
  useShopSessionStore,
} from '../../hooks/shop/useShopSessionStore';
import { CartDrawer } from './cartDrawer/cartDrawer';
import { ArticleCartModalStoreProvider } from '../../hooks/shop/useArticleCartModalStore';
import { Invoices } from './invoices/invoices';
import querystringify from 'querystringify';
import { DraftOrder } from './draftOrder/draftOrder';
import { Logo } from '../logo/logo';
import cc from 'classcat';
import { draftOrderApi } from '../../api/draftOrderApi';
import { useApiResource } from '../../hooks/useApi';
import { useThrottleCallback } from '../../hooks/useThrottleCallback';
import { LocalePicker } from '../localePicker/localePicker';
import { Reports } from './reports/reports';

export const Shop = ({
  session,
  applicationEnvironment,
  onEndSession,
  onLogout,
}: {
  applicationEnvironment: ApplicationEnvironment;
  session: ShopSession;
  onEndSession: () => void;
  onLogout: () => void;
}) => {
  return (
    <ShopSessionStoreProvider
      session={session}
      onEndSession={onEndSession}
      onLogout={onLogout}
    >
      <ArticleCartModalStoreProvider>
        <CartStoreProvider>
          <ShopContent applicationEnvironment={applicationEnvironment} />
        </CartStoreProvider>
      </ArticleCartModalStoreProvider>
    </ShopSessionStoreProvider>
  );
};

const menuItems = {
  dashboard: { path: routes.dashboard, exact: true },
  articles: { path: routes.articles, exact: false },
  orders: { path: routes.orders, exact: false },
  invoices: { path: routes.invoices, exact: false },
  reports: { path: routes.reports, exact: false },
  development: { path: routes.development, exact: false },
  documents: { path: routes.documents, exact: false },
};

const getSelectedMenuItem = (path: string) => {
  for (const key in menuItems) {
    const menuItem = menuItems[key as keyof typeof menuItems];

    if (matchPath(path, menuItem)) {
      return menuItem.path;
    }
  }
  return '';
};

const ShopContent = withRouter(
  ({
    location,
    history,
    applicationEnvironment,
  }: {
    applicationEnvironment: ApplicationEnvironment;
  } & RouteComponentProps) => {
    const lastLocation = useLastLocation();
    const cartStore = useCartStore();
    const shopSessionStore = useShopSessionStore();
    const Checkout = shopSessionStore.checkoutAdapter.Checkout;
    const draftOrdersCount = useApiResource(draftOrderApi.getDraftOrdersCount, {
      clearDataOnLoad: false,
    });
    const { t, locale, changeLocale } = useI18n();

    const handleCheckout = () => {
      cartStore.hideCart();
      history.push(routes.checkout);
    };

    const goToInvoices = (options?: { paid?: boolean }) => {
      history.push({
        pathname: routes.invoices,
        search: options ? querystringify.stringify(options) : undefined,
      });
    };

    const goToOrder = (orderId: number, options?: { isNew?: boolean }) => {
      history.push({
        pathname: generatePath(routes.order, { id: orderId }),
        search: options ? querystringify.stringify(options) : undefined,
      });
    };

    const goToDraftOrder = (orderId: number) => {
      history.push({
        pathname: generatePath(routes.orderDraft, { id: orderId }),
      });
    };

    const refreshOrdersCountThrottled = useThrottleCallback(
      draftOrdersCount.refresh,
      5000,
    );

    useEffect(() => {
      refreshOrdersCountThrottled();
    }, []);

    const selectedMenuPath = getSelectedMenuItem(location.pathname);

    return (
      <Layout className={styles.baseLayout}>
        <Layout.Header className={styles.header}>
          <div className={styles.topMenuWrapper}>
            <div className={styles.navMenuWrapper}>
              <Logo applicationEnvironment={applicationEnvironment} />
              <Menu
                theme="dark"
                mode="horizontal"
                selectedKeys={[selectedMenuPath]}
                className={cc([styles.topMenu, styles.navMenu])}
              >
                <Menu.Item
                  key={menuItems.dashboard.path}
                  className={styles.topMenuItem}
                >
                  <Link to={menuItems.dashboard.path}>
                    {t(messages.dashboard.dashboard)}
                  </Link>
                </Menu.Item>
                <Menu.Item
                  key={menuItems.articles.path}
                  className={styles.topMenuItem}
                >
                  <Link to={menuItems.articles.path}>
                    {t(messages.article.articles)}
                  </Link>
                </Menu.Item>
                <Menu.Item
                  key={menuItems.orders.path}
                  className={styles.topMenuItem}
                >
                  <Link to={menuItems.orders.path}>
                    <Badge
                      dot={(draftOrdersCount.data ?? 0) > 0}
                      className={styles.notificationBadge}
                    >
                      {t(messages.order.orders)}
                    </Badge>
                  </Link>
                </Menu.Item>
                {shopSessionStore.session.invoicesEnabled && (
                  <Menu.Item
                    key={menuItems.invoices.path}
                    className={styles.topMenuItem}
                  >
                    <Link to={menuItems.invoices.path}>
                      {t(messages.invoice.invoices)}
                    </Link>
                  </Menu.Item>
                )}
              </Menu>
            </div>
            <div className={styles.quickSearchMenuItem}>
              <QuickSearch />
            </div>
            <div>
              <Menu
                theme="dark"
                mode="horizontal"
                selectable={false}
                className={styles.topMenu}
              >
                <Menu.Item className={styles.topRightMenuItem}>
                  <Dropdown
                    overlay={
                      <Menu className={styles.userMenu}>
                        <div className={styles.userDetails}>
                          <DescriptionList>
                            <DescriptionList.Item
                              title={t(messages.shared.customer)}
                            >
                              {shopSessionStore.session.customerName}
                            </DescriptionList.Item>
                            <DescriptionList.Item
                              title={t(messages.users.name)}
                            >
                              {shopSessionStore.session.userName}
                            </DescriptionList.Item>
                            <DescriptionList.Item
                              title={t(messages.users.email)}
                            >
                              {shopSessionStore.session.email}
                            </DescriptionList.Item>
                          </DescriptionList>
                          <Button
                            ghost={true}
                            type="primary"
                            className={styles.endShopSessionButton}
                            onClick={() => shopSessionStore.endSession()}
                          >
                            {shopSessionStore.session.isImpersonating
                              ? t(messages.shared.endImpersonation)
                              : t(messages.shared.switchLogin)}
                          </Button>
                        </div>
                        <Menu.Divider />
                        <div className={styles.localeMenuItem}>
                          <DescriptionList>
                            <DescriptionList.Item
                              title={t(messages.shared.language)}
                            >
                              <LocalePicker
                                locale={locale}
                                onChange={changeLocale}
                              />
                            </DescriptionList.Item>
                          </DescriptionList>
                        </div>
                        <Menu.Divider />
                        <Menu.Item key="1">
                          <Link to={menuItems.documents.path}>
                            {t(messages.documents.documents)}
                          </Link>
                        </Menu.Item>
                        <Menu.Item
                          key="2"
                          disabled={
                            shopSessionStore.session.customerType !==
                            CustomerType.Brand
                          }
                        >
                          {shopSessionStore.session.customerType ===
                          CustomerType.Brand ? (
                            <Link to={menuItems.reports.path}>
                              {t(messages.reports.reports)}
                            </Link>
                          ) : (
                            <Tooltip placement="left" title="Coming soon">
                              <div>{t(messages.reports.reports)}</div>
                            </Tooltip>
                          )}
                        </Menu.Item>
                        <Menu.Item key="3" disabled={true}>
                          <Tooltip placement="left" title="Coming soon">
                            {t(messages.shared.settings)}
                          </Tooltip>
                        </Menu.Item>
                        <Menu.Item
                          key="4"
                          onClick={() => shopSessionStore.logout()}
                        >
                          {t(messages.shared.logout)}
                        </Menu.Item>
                      </Menu>
                    }
                    trigger={['click']}
                    placement="bottomRight"
                  >
                    <div className={styles.userMenuItem}>
                      <div className={styles.userMenuItemSection}>
                        {t(messages.shared.country)}:
                        <span className={styles.userMenuItemValue}>
                          RH {shopSessionStore.session.branchName}
                        </span>
                      </div>
                      <div className={styles.userMenuItemSection}>
                        {t(messages.shared.project)}:
                        <span className={styles.userMenuItemValue}>
                          {shopSessionStore.session.projectName}
                        </span>
                      </div>
                      <Icon
                        type="caret-down"
                        className={styles.userMenuItemCaret}
                      />
                    </div>
                  </Dropdown>
                </Menu.Item>
                <Menu.Item
                  className={styles.topRightMenuItem}
                  onClick={() => cartStore.showCart()}
                >
                  <div className={styles.menuItem}>
                    <Badge
                      className={styles.notificationBadge}
                      dot={!!cartStore.items.length}
                    >
                      <Icon
                        type="shopping-cart"
                        className={styles.menuItemIcon}
                      />
                    </Badge>
                    <span>{t(messages.cart.cart)}</span>
                  </div>
                </Menu.Item>
              </Menu>
            </div>
          </div>
        </Layout.Header>
        <Layout.Content className={styles.contentWrapper}>
          <div className={styles.content} id="shopContent">
            {shopSessionStore.session.locked && menuItems.dashboard.path !== selectedMenuPath && (
              <Alert
                className={styles.warningBox}
                message={t(messages.session.lockedTitle)}
                showIcon={true}
                description={t(messages.session.lockedDescription)}
                type="warning"
              />
            )}
            {shopSessionStore.apiError && (
              <ApiErrorDetails error={shopSessionStore.apiError} />
            )}
            <Switch>
              <Route
                path={routes.dashboard}
                exact={true}
                render={() => <Dashboard goToInvoices={goToInvoices} />}
              />
              <Route
                path={routes.documents}
                exact={true}
                render={() => <Documents />}
              />
              <Route
                path={routes.reports}
                exact={true}
                render={() => <Reports />}
              />
              <Route path={routes.articles} component={Articles} />
              <Route
                path={routes.checkout}
                render={() => (
                  <Checkout
                    onPlacedOrder={(orderId) =>
                      goToOrder(orderId, { isNew: true })
                    }
                  />
                )}
              />
              <Route
                path={routes.orderDraft}
                render={({ match }) => (
                  <DraftOrder
                    id={match.params.id}
                    onPlaceOrder={() => {
                      draftOrdersCount.refresh();
                      goToOrder(match.params.id);
                    }}
                    onGoBack={() => {
                      if (
                        lastLocation &&
                        pathIsOrderList(lastLocation.pathname)
                      ) {
                        history.goBack();
                      } else {
                        history.push(routes.draftOrders);
                      }
                    }}
                  />
                )}
              />
              <Route
                path={routes.draftOrders}
                render={() => (
                  <Orders
                    onSelectOrderId={goToDraftOrder}
                    activeTab={OrderTabs.Draft}
                    draftOrdersCount={draftOrdersCount.data}
                    onRefreshDraftOrdersCount={refreshOrdersCountThrottled}
                    onChangeActiveTab={(tab) => {
                      history.push(
                        tab === OrderTabs.Draft
                          ? routes.draftOrders
                          : routes.ordersPlaced,
                      );
                    }}
                  />
                )}
              />
              <Route
                path={routes.ordersPlaced}
                render={() => (
                  <Orders
                    onSelectOrderId={goToOrder}
                    activeTab={OrderTabs.Placed}
                    draftOrdersCount={draftOrdersCount.data}
                    onRefreshDraftOrdersCount={refreshOrdersCountThrottled}
                    onChangeActiveTab={(tab) => {
                      history.push(
                        tab === OrderTabs.Draft
                          ? routes.draftOrders
                          : routes.ordersPlaced,
                      );
                    }}
                  />
                )}
              />
              <Route
                path={routes.order}
                render={({ match }) => (
                  <Order
                    id={match.params.id}
                    onGoBack={() => {
                      if (
                        lastLocation &&
                        pathIsOrderList(lastLocation.pathname)
                      ) {
                        history.goBack();
                      } else {
                        history.push(routes.ordersPlaced);
                      }
                    }}
                  />
                )}
              />
              <Route
                path={routes.invoices}
                render={() => <Invoices onSelectOrderId={goToOrder} />}
              />
              <Route
                path={routes.orders}
                exact={true}
                render={() => <Redirect to={routes.ordersPlaced} />}
              />
              <Route render={() => <Redirect to={routes.dashboard} />} />
            </Switch>
          </div>
        </Layout.Content>
        <CartDrawer
          visible={cartStore.isVisible}
          onCheckout={handleCheckout}
          onClose={() => cartStore.hideCart()}
        />
        <ArticleCartModal />
      </Layout>
    );
  },
);

function pathIsOrderList(path: string) {
  return (
    !!matchPath(path, {
      path: routes.draftOrders,
      exact: true,
    }) ||
    !!matchPath(path, {
      path: routes.ordersPlaced,
      exact: true,
    })
  );
}
