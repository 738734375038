import React, { SVGProps } from 'react';
import { CustomIconComponentProps } from 'antd/lib/icon';

export const boxIcon = (
  props: CustomIconComponentProps | SVGProps<SVGSVGElement>,
) => (
  <svg {...props} viewBox="0 0 500 500" version="1.1" focusable="false">
    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="SVG-Layer" fill="currentColor" fillRule="nonzero">
        <path
          d="M487.750863,218.754605 L382.250863,218.754605 L382.250863,15.629605 C382.250863,7.03585497 376.625863,0.00460497345 369.750863,0.00460497345 L129.750863,0.00460497345 C122.875863,0.00460497345 117.250863,7.03585497 117.250863,15.629605 L117.250863,218.754605 L12.7508634,218.754605 C5.87586343,218.754605 0.250863433,225.785855 0.250863433,234.379605 L0.250863433,484.379605 C0.250863433,492.973355 5.87586343,500.004605 12.7508634,500.004605 L487.750863,500.004605 C494.625863,500.004605 500.250863,492.973355 500.250863,484.379605 L500.250863,234.379605 C500.250863,225.785855 494.625863,218.754605 487.750863,218.754605 Z M236.250863,474.004605 L26.2508634,474.004605 L26.2508634,250.004605 L74.6975546,250.004605 L188.412246,250.004605 L236.250863,250.004605 L236.250863,474.004605 Z M355.250863,219.004605 L145.250863,219.004605 L145.250863,23.004605 L193.697555,23.004605 L307.412246,23.004605 L355.250863,23.004605 L355.250863,219.004605 Z M478.250863,474.004605 L268.250863,474.004605 L268.250863,250.004605 L316.697555,250.004605 L430.412246,250.004605 L478.250863,250.004605 L478.250863,474.004605 Z"
          id="Shape"
        />
      </g>
    </g>
  </svg>
);
