import { ApiRequest } from '../hooks/useApi';
import {
  Address,
  OrderType,
  OrderStatus,
  DeliveryStatus,
  OrderMetaFieldType,
  DeliveryNote,
  OrderCareLabelPo,
  OrderMetaFieldValue,
  OrderRow,
} from '../types';
import {
  OrderProgressModel,
  OrderProgress,
} from '../helpers/orderProgressConverter';

export const ordersApi = {
  getOrder: (id: number): ApiRequest<OrderDetails> => async ({
    httpClient,
    apiSettings,
  }) => {
    const response = await httpClient.get(apiSettings.orderUri(id));

    if (response.hasError) {
      return { error: response.error };
    } else {
      return { result: response.data };
    }
  },

  placeOrder: (data: PlaceOrderData): ApiRequest<PlacedOrder> => async ({
    httpClient,
    apiSettings,
  }) => {
    const response = await httpClient.post(apiSettings.placeOrderUri, { data });

    if (response.hasError) {
      return { error: response.error };
    } else {
      return {
        result: response.data,
      };
    }
  },

  getOrders: (options: GetOrdersOptions): ApiRequest<OrderList> => async ({
    httpClient,
    apiSettings,
  }) => {
    const response = await httpClient.post(apiSettings.ordersUri, {
      data: {
        articleNumber: options.articleNumber,
        customerId: options.customerId,
        orderProgresses: options.orderProgresses,
        orderType: options.orderType,
        page: (options.page - 1) * options.pageSize,
        pageSize: options.pageSize,
        placed: options.placed,
        searchQuery: options.searchQuery,
        sortingDesc: options.sortingDesc,
        sortingKey: options.sortingKey,
      },
    });

    if (response.hasError) {
      return { error: response.error };
    } else {
      return {
        result: {
          items: response.data.result,
          totalItems: response.data.total,
        },
      };
    }
  },

  getVendors: (): ApiRequest<OrderViewVendor[]> => async ({
    httpClient,
    apiSettings,
  }) => {
    const response = await httpClient.get(apiSettings.ordersVendorsUri);

    if (response.hasError) {
      return { error: response.error };
    } else {
      return {
        result: response.data,
      };
    }
  },

  getOrderMetaFields: (): ApiRequest<OrderMetaFieldType[]> => async ({
    httpClient,
    apiSettings,
  }) => {
    const response = await httpClient.get(apiSettings.ordersMetaFieldsUri);

    if (response.hasError) {
      return { error: response.error };
    } else {
      return {
        result: response.data,
      };
    }
  },

  getDeliveryOptions: (
    customAddressId?: number,
  ): ApiRequest<DeliveryDateOptions> => async ({ httpClient, apiSettings }) => {
    const response = await httpClient.get(apiSettings.deliveryDateOptionsUri, {
      params: { customAddressId },
    });

    if (response.hasError) {
      return { error: response.error };
    } else {
      return {
        result: {
          ...response.data,
          syncError: response.headers.syncerror,
        },
      };
    }
  },

  getOrderProgresses: (): OrderProgress[] => {
    const initialOrderProgressIds: OrderProgress[] = [
      OrderProgress.Unconfirmed,
      OrderProgress.AwaitingDelivery,
      OrderProgress.PartiallyDelivered,
      OrderProgress.Delivered,
    ];
    return initialOrderProgressIds;
  },
};

export interface OrderDetails {
  id: number;
  customerName: string;
  customerErpId: string;
  projectName: string;
  invoiceCustomerName: string;
  invoiceCustomerErpId: string;
  invoiceCustomerAddress: Address;
  orderNumber: string;
  externalOrderNumber: string;
  reference: string;
  externalReference: string;
  orderDate: string;
  deliveryDate?: string;
  deliveryDateNote: string;
  paymentTermName: string;
  deliveryTermName: string;
  deliveryMethodName: string;
  shippingAddress: Address;
  rows: OrderRow[];
  deliveryNotes: DeliveryNote[];
  orderSum: number;
  orderMessage?: string;
  careLabelPos: OrderCareLabelPo[];
  metaFields: OrderMetaFieldValue[];
  type: OrderType;
  status: OrderStatus;
  deliveryStatus: DeliveryStatus;
  currencyCode: string;
  currencyPriceDecimalPlaces: number;
  contactPhone: string;
  season?: string;
}

interface PlaceOrderDataCustomAddress {
  company: string;
  address1: string;
  address2: string;
  postalCode: string;
  city: string;
  countryId: number;
}

export interface PlaceOrderData {
  externalOrderNumber?: string;
  contactName?: string;
  contactPhone?: string;
  courierAccount?: string;
  orderMessage?: string;
  orderType: OrderType;
  customOrderAddress?: PlaceOrderDataCustomAddress;
  existingCustomAddressId?: number;
  vendorCustomerId?: number;
  metaFieldValues: { [key: number]: string };
  requestedDeliveryDate?: string;
  seasonId?: number;
}

export interface PlacedOrder {
  id: number;
}

export interface OrderList {
  items: OrderListItem[];
  totalItems: number;
}

export interface OrderListItem {
  currencyCode: string;
  customerName: string;
  deliveryDate?: string;
  deliveryDateNote: string;
  deliveryStatus: DeliveryStatus;
  externalOrderNumber: string;
  id: number;
  latestDeliveryDate?: string;
  orderDate: string;
  orderNumber: string;
  orderProgress: string;
  orderSum: number;
  status: OrderStatus;
}

interface GetOrdersOptions {
  articleNumber: string;
  customerId?: number;
  orderProgresses: OrderProgressModel[];
  orderType?: number;
  page: number;
  pageSize: number;
  placed: boolean;
  searchQuery: string;
  sortingKey: keyof OrderListItem;
  sortingDesc: boolean;
}

interface OrderViewVendor {
  id: number;
  name: string;
}

export interface DeliveryDateOptions {
  deliveryDateAvailable: boolean;
  earliestDeliveryDate: Date;
  upcomingDeliveries: OrderDelivery[];
  nonWorkingDates: Date[];
}

export interface OrderDelivery {
  orderId: number;
  orderNumber: string;
  deliveryDate: string;
}
