import { QuickSearchResult } from './types';

export type Actions =
  | {
      type: 'reset';
    }
  | {
      type: 'focused';
    }
  | {
      type: 'updatedSearchQuery';
      payload: string;
    }
  | {
      type: 'loadingMore';
    }
  | {
      type: 'searchSuccess';
      payload: { result: QuickSearchResult; page: number };
    }
  | {
      type: 'searchFailure';
      payload: string;
    };

interface State {
  isFocused: boolean;
  isOpen: boolean;
  loading: boolean;
  searchQuery: string;
  isLastPage: boolean;
  currentPage: number;
  error?: string;
  list?: QuickSearchResult;
}

export const initialState: State = {
  isFocused: false,
  isOpen: false,
  loading: false,
  searchQuery: '',
  isLastPage: false,
  currentPage: 1,
  error: undefined,
  list: undefined,
};

export function reducer(state: State, action: Actions): State {
  switch (action.type) {
    case 'reset': {
      return initialState;
    }

    case 'focused': {
      return {
        ...state,
        isFocused: true,
      };
    }

    case 'updatedSearchQuery': {
      return {
        ...state,
        searchQuery: action.payload,
        loading: true,
        list: undefined,
        error: undefined,
        isOpen: !!action.payload.length,
      };
    }

    case 'loadingMore': {
      return {
        ...state,
        error: undefined,
        loading: true,
      };
    }

    case 'searchSuccess': {
      if (state.loading === false) {
        return state;
      }

      return {
        ...state,
        loading: false,
        list: state.list
          ? {
              ...state.list,
              items: [...state.list.items, ...action.payload.result.items],
            }
          : action.payload.result,
        error: undefined,
        currentPage: action.payload.page,
        isLastPage: isLastPage(
          action.payload.page,
          action.payload.result.totalItems,
          15,
        ),
      };
    }

    case 'searchFailure': {
      if (state.loading === false) {
        return state;
      }

      return {
        ...state,
        loading: false,
        list: state.list,
        error: action.payload,
      };
    }
  }
}

function isLastPage(currentPage: number, totalItems: number, pageSize: number) {
  const lastPage = Math.max(Math.ceil((totalItems || 0) / pageSize), 1);
  return currentPage >= lastPage;
}
