import { VariantOrderItem } from './types';

function clampQuantity(quantity: number) {
  const minValue = 0;

  return quantity < minValue ? minValue : quantity;
}

export type Actions = {
  type: 'setQuantity';
  payload: {
    variantId: number;
    quantity?: number;
  };
};

export function matrixOrderReducer(
  state: VariantOrderItem[],
  action: Actions,
): VariantOrderItem[] {
  switch (action.type) {
    case 'setQuantity': {
      const { quantity, variantId } = action.payload;

      return state.map((variantData) => {
        const isCurrentItem = variantData.variant.id === variantId;

        if (!isCurrentItem) {
          return variantData;
        }

        return {
          ...variantData,
          quantity:
            quantity !== undefined ? clampQuantity(quantity) : undefined,
        };
      });
    }
  }

  return state;
}
