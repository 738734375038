/* eslint-disable react-hooks/rules-of-hooks */
import constate from 'constate';
import { Locale, FlattenedTranslations } from './types';
import { createMessageTranslator } from './messageTranslator';
import { createDateFormatter } from './dateFormatter';
import { useMemo, useState } from 'react';

const localeKey = 'locale';

const i18nStore = ({
  defaultLocale,
  translations,
}: {
  defaultLocale: Locale;
  translations: Record<Locale, FlattenedTranslations>;
}) => {
  const [locale, setLocale] = useState(() => {
    const storedLocale = localStorage.getItem(localeKey);

    if (
      storedLocale &&
      Object.values(Locale).some((val) => val === storedLocale)
    ) {
      return storedLocale as Locale;
    } else {
      return defaultLocale;
    }
  });

  const messageTranslator = useMemo(
    () => createMessageTranslator(locale, translations[locale]),
    [locale],
  );

  const formatDate = useMemo(() => createDateFormatter(locale).formatDate, [
    locale,
  ]);

  const changeLocale = (locale: Locale) => {
    localStorage.setItem(localeKey, locale);
    setLocale(locale);
  };

  // Memoize to only update consumers when specified deps changes
  return useMemo(
    () => ({
      locale,
      t: messageTranslator.translate,
      formatDate,
      changeLocale,
    }),
    [locale],
  );
};

const [I18nProvider, useI18n] = constate(i18nStore);
export { I18nProvider, useI18n };
