import React, { useState, useEffect, useCallback } from 'react';
import {
  LineItem,
  UpdateLineItem,
  ArticleType,
  Currency,
  CustomerType,
} from '../../../types';
import { useI18n } from '../../../hooks/useI18n';
import { useApiResource, ApiError } from '../../../hooks/useApi';
import { articlesApi, MatrixVariantSummary } from '../../../api/articlesApi';
import { Modal, Skeleton, Divider, Tooltip, Button, Icon } from 'antd';
import { ApiErrorDetails } from '../../apiErrorDetails/apiErrorDetails';
import { Details } from './details/details';
import { Stock } from './stock/stock';
import { messages } from '../../../messages';
import { UpdateArticleLineItem } from './updateArticleLineItem/updateArticleLineItem';
import { MatrixOrder } from './matrixOrder/matrixOrder';
import styles from './article.module.scss';
import cc from 'classcat';
import { CurrencyPicker } from '../articles/currencyPicker/currencyPicker';
import { useShopSessionStore } from '../../../hooks/shop/useShopSessionStore';

export function ArticleModal({
  articleId,
  displayCurrency,
  lineItemMessage,
  lineItem,
  isUpdatingLineItem,
  updateLineItemError,
  lineItemQuantityError,
  draftOrder,
  onUpdateLineItem,
  onClose,
  onChangeDisplayCurrency,
}: {
  articleId: number;
  displayCurrency?: Currency;
  lineItemMessage: string;
  lineItem?: LineItem;
  isUpdatingLineItem: boolean;
  updateLineItemError?: ApiError;
  lineItemQuantityError?: string;
  draftOrder: boolean;
  onUpdateLineItem: (lineItem: UpdateLineItem) => void;
  onClose: () => void;
  onChangeDisplayCurrency?: (currency: Currency) => void;
}) {
  const { t } = useI18n();
  const article = useApiResource(articlesApi.getArticle);
  const articleVariant = useApiResource(articlesApi.getArticle);
  const articlePrices = useApiResource(articlesApi.getArticlePrices);
  const { session } = useShopSessionStore();
  const [expandTable, setExpandTable] = React.useState(false);
  const [showSummary, setShowSummary] = React.useState(true);
  const [showTopContent, setShowTopContent] = React.useState(true);

  const [
    selectedVariant,
    setSelectedVariant,
  ] = useState<MatrixVariantSummary>();

  useEffect(() => {
    article.refresh(articleId, false);
  }, [articleId]);

  useEffect(() => {
    if (!displayCurrency) return;
    articlePrices.refresh(articleId, displayCurrency.id);
  }, [displayCurrency]);

  const handleVariantSelect = useCallback((variant: MatrixVariantSummary) => {
    if (selectedVariant && selectedVariant.id === variant.id) {
      return;
    }

    articleVariant.refresh(variant.id);
    setSelectedVariant(variant);
  }, []);

  const activeArticle = selectedVariant ? articleVariant.data : article.data;

  const TopContent = ({ showTopContent }: { showTopContent: boolean }) => {
    return (
      <>
        {showTopContent ? (
          <div className={styles.topContent}>
            {activeArticle && (
              <Details
                article={activeArticle}
                displayCurrency={displayCurrency}
                articlePrices={articlePrices.data}
              />
            )}
            {session.customerType === CustomerType.Brand &&
              !!displayCurrency &&
              onChangeDisplayCurrency && (
                <div>
                  <span>{t(messages.shared.currency)}: </span>
                  <CurrencyPicker
                    currency={displayCurrency}
                    onChange={onChangeDisplayCurrency}
                  />
                </div>
              )}
            {articleVariant.data && !articleVariant.data.frozen && (
              <div className={styles.topStockWrapper}>
                <Stock
                  articleBranchStocks={articleVariant.data.articleStocks}
                  showStockLocations={article.data?.useStockLocations ?? false}
                  quantityDecimalPlaces={
                    articleVariant.data.quantityDecimalPlaces
                  }
                />
              </div>
            )}
            {articleVariant.error && (
              <ApiErrorDetails error={articleVariant.error} />
            )}
            {!activeArticle && !articleVariant.error && (
              <Skeleton paragraph={{ rows: 5 }} active={true} />
            )}
          </div>
        ) : null}
      </>
    );
  };

  const MinimizeMatrixOrderSummaryButton = () => {
    const onClick = () => {
      setShowSummary(!showSummary);

      if (showSummary === false && showTopContent === false) {
        setExpandTable(false);
      }
    };
    return (
      <div className={styles.minimizeOrderSummaryButton}>
        <Tooltip
          title={
            showSummary
              ? t(messages.article.hideSummary)
              : t(messages.article.showSummary)
          }
        >
          <Button type="default" size="small" block={false} onClick={onClick}>
            <Icon type={showSummary ? 'right-square' : 'left-square'} />
          </Button>
        </Tooltip>
      </div>
    );
  };
  const MinimizeTopContentButton = () => {
    const onClick = () => {
      setShowTopContent(!showTopContent);

      if (showSummary === false && showTopContent === false) {
        setExpandTable(false);
      }
    };
    return (
      <div className={styles.minimizeTopContentButton}>
        <Tooltip
          title={
            showTopContent
              ? t(messages.article.hideTopContent)
              : t(messages.article.showTopContent)
          }
        >
          <Button type="default" size="small" block={false} onClick={onClick}>
            <Icon type={showTopContent ? 'up-square' : 'down-square'} />
          </Button>
        </Tooltip>
      </div>
    );
  };
  const ExpandMatrixButton = () => {
    const onClick = () => {
      setExpandTable(!expandTable);

      if (showSummary === false && showTopContent === false) {
        setShowSummary(true);
        setShowTopContent(true);
      } else {
        setShowSummary(false);
        setShowTopContent(false);
      }
    };
    return (
      <div className={styles.minimizeTopContentButton}>
        <Tooltip
          title={
            showSummary || showTopContent
              ? t(messages.shared.expandTable)
              : t(messages.shared.shrinkTable)
          }
        >
          <Button type="default" size="small" block={false} onClick={onClick}>
            <Icon
              type={showSummary || showTopContent ? 'arrows-alt' : 'shrink'}
            />
          </Button>
        </Tooltip>
      </div>
    );
  };

  return (
    <Modal
      className={styles.modal}
      footer={null}
      visible={true}
      onCancel={onClose}
      width="100%"
    >
      <div
        className={cc({
          [styles.page]: true,
          [styles.pageMatrixArticle]:
            article.data && article.data.type === ArticleType.Matrix,
        })}
      >
        {article.error && <ApiErrorDetails error={article.error} />}
        {updateLineItemError && <ApiErrorDetails error={updateLineItemError} />}
        {!article.data && !article.error && (
          <Skeleton paragraph={{ rows: 15 }} active={true} />
        )}

        {!!article.data && (
          <>
            <TopContent showTopContent={showTopContent} />
            <div className={styles.bottomContent}>
              {article.data.type !== ArticleType.Matrix &&
                article.data.type !== ArticleType.Kit &&
                !article.data.frozen && (
                  <div className={styles.bottomStockWrapper}>
                    <Divider className={styles.blockDivider} orientation="left">
                      {t(messages.stock.stock)}
                    </Divider>
                    <Stock
                      articleBranchStocks={article.data.articleStocks}
                      showStockLocations={article.data.useStockLocations}
                      quantityDecimalPlaces={article.data.quantityDecimalPlaces}
                    />
                  </div>
                )}
              {article.data.type !== ArticleType.Matrix &&
                !article.data.frozen && (
                  <div className={styles.articleOrderWrapper}>
                    <Divider className={styles.blockDivider} orientation="left">
                      {lineItemMessage}
                    </Divider>
                    <UpdateArticleLineItem
                      price={article.data.displayPrice}
                      currencyCode={article.data.currencyCode}
                      quantityPerUnit={article.data.quantityPerUnit}
                      articleId={articleId}
                      lineItem={lineItem}
                      lineItemQuantityError={lineItemQuantityError}
                      draftOrder={draftOrder}
                      draftQuantityAdjustmentPercent={
                        session.draftQuantityAdjustmentPercent
                      }
                      isLoading={isUpdatingLineItem}
                      onUpdateLineItem={onUpdateLineItem}
                    />
                  </div>
                )}
              {article.data.type === ArticleType.Matrix &&
                article.data.variants && (
                  <div className={styles.variantOrderWrapper}>
                    {showTopContent ? (
                      <div>
                        <Divider
                          className={styles.blockDivider}
                          orientation="left"
                        >
                          {lineItemMessage}
                        </Divider>
                      </div>
                    ) : null}
                    <div
                      className={
                        !showTopContent
                          ? styles.minimizeFieldsWrapper
                          : undefined
                      }
                    >
                      <ExpandMatrixButton />
                      <MinimizeMatrixOrderSummaryButton />
                      <MinimizeTopContentButton />
                      <div style={{ clear: 'both', height: '1px' }}>&nbsp;</div>
                    </div>
                    <MatrixOrder
                      articleId={articleId}
                      currencyCode={article.data.currencyCode}
                      currencyPriceDecimalPlaces={
                        article.data.currencyPriceDecimalPlaces
                      }
                      displayCurrency={displayCurrency}
                      variants={article.data.variants}
                      selectedVariant={selectedVariant}
                      lineItem={lineItem}
                      isLoading={isUpdatingLineItem}
                      articlePrices={articlePrices.data}
                      showSummary={showSummary}
                      lineItemQuantityError={lineItemQuantityError}
                      draftOrder={draftOrder}
                      draftQuantityAdjustmentPercent={
                        session.draftQuantityAdjustmentPercent
                      }
                      onUpdateLineItem={onUpdateLineItem}
                      onVariantSelect={handleVariantSelect}
                    />
                  </div>
                )}
            </div>
          </>
        )}
      </div>
    </Modal>
  );
}
