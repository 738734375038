import { AssortmentAdminCost } from './api/internalAssortmentsApi';

export enum UserRole {
  Internal = 0,
  Customer = 1,
  SuperCustomer = 2,
  Vendor = 3,
  Agent = 4,
}

export enum ArticleType {
  Standard = 0,
  Matrix = 1,
  MatrixVariant = 2,
  Kit = 3,
}

export enum OrderStatus {
  Unknown = 0,
  Draft = 1,
  Placed = 2,
  Confirmed = 3,
}

export enum OrderType {
  Unknown = 0,
  Standard = 1,
  Sample = 2,
  Variable = 3,
  Forecast = 5,
}

export enum DeliveryStatus {
  Undelivered = 0,
  PartiallyDelivered = 1,
  Delivered = 2,
}

export enum CustomerType {
  Unknown = 0,
  Brand = 1,
  Agent = 2,
  Vendor = 3,
  Internal = 4,
}

export enum TimePeriodType {
  Yearly = 0,
  Quarterly = 1,
  Monthly = 2,
}

export enum ApplicationEnvironment {
  Development = 1,
  Staging = 2,
  Production = 3,
}

export enum CheckoutAdapterType {
  Default = 0,
  _3M = 1,
}

export interface UserCustomer {
  id: number;
  customerId: number;
  customerName: string;
  customerType: CustomerType;
  customerErpId: string;
  projectId: number;
  projectName: string;
  assortmentName: string;
  branchId: number;
  branchName: string;
}

export interface User {
  id: number;
  email: string;
  userName: string;
}

export interface InternalSession {
  userName: string;
}

export interface ShopSession {
  userName: string;
  email: string;
  customerId: number;
  customerName: string;
  customerType: CustomerType;
  customerAddress: Address;
  customerTelephone: string;
  invoiceCustomerId?: number;
  invoiceCustomerAddress?: Address;
  projectId: number;
  projectName: string;
  assortmentName: string;
  branchId: number;
  branchName: string;
  branchDeliveryNotePdfDisabled: boolean;
  currency: Currency;
  isImpersonating: boolean;
  locked: boolean;
  invoicesEnabled: boolean;
  checkoutAdapter: CheckoutAdapterType;
  allowAutomaticOrder: boolean;
  allowAutomaticOrderOnIncomingStock: boolean;
  useStockLocations: boolean;
  useLocalBranchStock: boolean;
  useAutomaticQuantityAdjustment: boolean;
  draftQuantityAdjustmentPercent: number;
  notifications: Notification[];
  assortmentAdminCost: AssortmentAdminCost;
}

export interface Notification {
  id: string;
  name: string;
  message: string;
  branchName: string;
  projectId?: number;
  projectName: string;
  customerType?: CustomerType;
  createdDate: string;
}

export interface Country {
  id: number;
  name: string;
}

export interface Currency {
  id: number;
  name: string;
  code: string;
  priceDecimalPlaces: number;
}

export interface Branch {
  identifier: string;
  name: string;
}

export interface Address {
  id: number;
  company: string;
  address1: string;
  address2: string;
  cityAndPostalCode: string;
  countryName: string;
}

export interface LineItem {
  articleId: number;
  articleNumber: string;
  description: string;
  type: ArticleType;
  colorName?: string;
  colorCode?: string;
  sizeName?: string;
  sizeCode?: string;
  unit: string;
  hasImage: boolean;
  quantity: number;
  quantityIsAdjusted: boolean;
  quantityPerUnit: number;
  originalQuantity?: number;
  totalPrice: number;
  variants?: LineItem[];
  stockBalance: number;
  hasMultiStock: boolean;
}

export interface UpdateLineItem {
  articleId: number;
  quantity?: number;
  quantityIsAdjusted?: boolean;
  variants?: { articleId: number; quantity: number }[];
  lineItemQuantityError?: string;
}

export interface PagedResult<T> {
  items: T[];
  totalItems: number;
}

export interface Sorting<T> {
  key: keyof T;
  desc: boolean;
}

export interface OrderRow {
  rowNumber: number;
  articleColor: string;
  articleDescription: string;
  articleExtraDescription: string;
  articleId: number;
  articleNumber: string;
  articleSize: string;
  articleUnit: string;
  deliveryDate: string;
  deliveryDates: string[];
  deliveryDateNote: string;
  quantityDelivered: number;
  quantityOrdered: number;
  unitPrice: number;
  totalPrice: number;
}

export interface DeliveryNote {
  id: number;
  erpId: number;
  trackingNumber: string;
  invoiceNumber: string;
  deliveryDate?: string;
  deliveryDateNote: string;
  rows: DeliveryNoteRow[];
}

export interface DeliveryNoteRow {
  rowNumber: number;
  articleColor: string;
  articleDescription: string;
  articleExtraDescription: string;
  articleId: string;
  articleNumber: string;
  articleSize: string;
  articleUnit: string;
  quantityDelivered: number;
  quantityOrdered: number;
}

export interface OrderMetaFieldType {
  id: number;
  name: string;
  options: OrderMetaFieldOption[];
  required: boolean;
  sortOrder: number;
  useOptions: boolean;
}

export interface OrderMetaFieldValue {
  id: number;
  name: string;
  value: string;
  sortOrder: number;
}

export interface OrderMetaFieldOption {
  id: number;
  orderMetaField: OrderMetaFieldType;
  orderMetaFieldId: number;
  parentOptionId: number;
  sortOrder: number;
  value: string;
}

export interface OrderCareLabelPo {
  po: string;
  uri: string;
}
