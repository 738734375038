export const routes = {
  dashboard: '/',
  articles: '/articles',
  orders: '/orders',
  order: '/orders/:id',
  ordersPlaced: '/orders/placed',
  draftOrders: '/orders/draft',
  orderDraft: '/orders/draft/:id',
  invoices: '/invoices',
  reports: '/reports',
  development: '/development',
  documents: '/documents',
  checkout: '/checkout',
};
