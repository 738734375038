import React, { SVGProps } from 'react';
import { CustomIconComponentProps } from 'antd/lib/icon';

export const coinsIcon = (
  props: CustomIconComponentProps | SVGProps<SVGSVGElement>,
) => (
  <svg {...props} viewBox="0 0 348 352" version="1.1" focusable="false">
    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <path
        d="M174,0.5 C76.2360603,0.5 0,34.4 0,79 L0,271 C0,315.2 76.8,352 174,352 C271.2,352 348,313.7 348,272 L348,80 C348,43 287.9,0.5 174,0.5 Z M325,272.102446 C325,286.696738 272.045139,322.5 174,322.5 C75.9548611,322.5 23,286.696738 23,272.102446 L23,219.5 C56.3458333,241.12895 111.502778,255.303262 174,255.303262 C236.497222,255.303262 291.654167,241.12895 325,219.5 L325,272.102446 Z M325,176.102446 C325,190.696738 272.045139,226.5 174,226.5 C75.9548611,226.5 23,190.696738 23,176.102446 L23,123.5 C56.3458333,145.12895 111.502778,159.303262 174,159.303262 C236.497222,159.303262 291.654167,145.12895 325,123.5 L325,176.102446 Z M174,130.5 C88.9693252,130.5 20,107.880208 20,80 C20,52.1197917 88.9693252,29.5 174,29.5 C259.030675,29.5 328,52.1197917 328,80 C328,107.880208 259.030675,130.5 174,130.5 Z"
        id="Shape"
        fill="currentColor"
        fillRule="nonzero"
      />
    </g>
  </svg>
);
