import axios, { AxiosError } from 'axios';
import {
  OkResponse,
  Method,
  RequestOptions,
  RequestOptionsWithData,
  ErrorResponse,
} from './types';
import { SimpleEventDispatcher } from 'ste-simple-events';

export function createHttpClient() {
  const onUnauthorizedEvent = new SimpleEventDispatcher<undefined>();
  const axiosClient = axios.create({ timeout: 60000 });
  axiosClient.interceptors.response.use(
    (response) => response,
    errorResponseHandler,
  );

  function errorResponseHandler(error: AxiosError<any>) {
    if (error.response && error.response.status === 401) {
      onUnauthorizedEvent.dispatch(undefined);
    }

    return Promise.reject(error);
  }

  async function request(
    method: Method,
    url: string,
    options?: RequestOptionsWithData,
  ): Promise<OkResponse | ErrorResponse> {
    try {
      const response = await axiosClient.request({ url, method, ...options });
      return {
        hasError: false,
        status: response.status,
        data: response.data,
        headers: response.headers,
      };
    } catch (error) {
      const status = error.response ? error.response.status : 0;
      const message =
        error.response && error.response.data && error.response.data.message
          ? error.response.data.message
          : `Unexpected network error (Status ${status})`;
      const errors =
        error.response && error.response.data && error.response.data.errors
          ? error.response.data.errors
          : [];

      return {
        hasError: true,
        status,
        error: { message, errors, status },
      };
    }
  }

  return {
    get: (url: string, options?: RequestOptions) =>
      request('GET', url, options),
    put: (url: string, options?: RequestOptionsWithData) =>
      request('PUT', url, options),
    post: (url: string, options?: RequestOptionsWithData) =>
      request('POST', url, options),
    delete: (url: string, options?: RequestOptions) =>
      request('DELETE', url, options),
    onUnauthorized: {
      subscribe: (fn: () => void) => onUnauthorizedEvent.subscribe(fn),
      unsubscribe: (fn: () => void) => onUnauthorizedEvent.unsubscribe(fn),
    },
  };
}

export type HttpClient = ReturnType<typeof createHttpClient>;
