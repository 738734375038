import { ApiRequest } from '../hooks/useApi';
import { Country, UserCustomer, Currency } from '../types';

export const sessionApi = {
  getCountries: (): ApiRequest<Country[]> => async ({
    httpClient,
    apiSettings,
  }) => {
    const response = await httpClient.get(apiSettings.sessionShopCountriesUri);

    if (response.hasError) {
      return { error: response.error };
    } else {
      return {
        result: response.data,
      };
    }
  },

  getUserCustomers: (): ApiRequest<UserCustomer[]> => async ({
    httpClient,
    apiSettings,
  }) => {
    const response = await httpClient.get(
      apiSettings.sessionShopUserCustomersUri,
    );

    if (response.hasError) {
      return { error: response.error };
    } else {
      return {
        result: response.data,
      };
    }
  },

  getCurrencies: (): ApiRequest<Currency[]> => async ({
    httpClient,
    apiSettings,
  }) => {
    const response = await httpClient.get(apiSettings.sessionShopCurrenciesUri);

    if (response.hasError) {
      return { error: response.error };
    } else {
      return {
        result: response.data,
      };
    }
  },
};
