import React, { useState, useMemo, useEffect, useRef } from 'react';
import { Row, Col, Card, Divider, Button, Skeleton, Icon, Alert } from 'antd';
import { useI18n } from '../../../hooks/useI18n';
import { messages } from '../../../messages';
import { DescriptionList } from '../../descriptionList/descriptionList';
import { OrderType, UpdateLineItem, CustomerType } from '../../../types';
import { Form } from '../../form/form';

import {
  useApiRequests,
  ApiError,
  useApiResource,
} from '../../../hooks/useApi';
import { AddressDetails } from '../../addressDetails/addressDetails';
import { LoadingIndicator } from '../../loadingIndicator/loadingIndicator';
import { AddressForm } from '../../addressForm/addressForm';
import { ApiErrorDetails } from '../../apiErrorDetails/apiErrorDetails';
import styles from './draftOrder.module.scss';
import {
  usePlaceOrderForm,
  PlaceOrderForm,
  ShippingAddressType,
} from '../../../hooks/shop/usePlaceOrderForm/usePlaceOrderForm';
import {
  draftOrderApi,
  DraftOrderDetails,
  PlaceDraftOrderData,
} from '../../../api/draftOrderApi';
import { AddressSelect } from '../../addressSelect/addressSelect';
import { useShopSessionStore } from '../../../hooks/shop/useShopSessionStore';
import { DeliveryDatePicker } from '../checkout/deliveryDatePicker/deliveryDatePicker';
import FormItem from 'antd/lib/form/FormItem';

const formLayout = {
  labelCol: { span: 7 },
  wrapperCol: { span: 17 },
};

export const MemoizedLineItems = React.memo(DraftOrderLineItems);
export const MemoizedDetailsList = React.memo(DraftOrderDetailsList);

export function DraftOrder({
  id,
  onGoBack,
  onPlaceOrder,
}: {
  id: number;
  onGoBack: () => void;
  onPlaceOrder: () => void;
}) {
  const { t } = useI18n();
  const order = useApiResource(draftOrderApi.getDraftOrder, {
    clearDataOnLoad: false,
  });

  useEffect(() => {
    order.refresh(id);
  }, [id]);

  return (
    <div className={styles.page}>
      <div className={styles.navigation} onClick={onGoBack}>
        <Icon type="left" /> {t(messages.order.orders)}
      </div>
      {!order.data && !order.error && (
        <Skeleton paragraph={{ rows: 15 }} active={true} />
      )}

      {order.error && <ApiErrorDetails error={order.error} />}

      {!!order.data && order.isLoading && <LoadingIndicator />}

      {!!order.data && (
        <DraftOrderView
          order={order.data}
          onPlacedOrder={onPlaceOrder}
          onRefreshOrder={() => order.refresh(id)}
        />
      )}
    </div>
  );
}

function DraftOrderView({
  order,
  onRefreshOrder,
  onPlacedOrder,
}: {
  order: DraftOrderDetails;
  onRefreshOrder: () => void;
  onPlacedOrder: () => void;
}) {
  const { t } = useI18n();
  const {
    checkoutAdapter: { ArticleModal, DraftOrderFields },
  } = useShopSessionStore();

  const isSubmitting = useRef<boolean>(false);
  const { session } = useShopSessionStore();
  const [placeOrderError, setPlaceOrderError] = useState<ApiError>();
  const [updateLineItemError, setUpdateLineItemError] = useState<ApiError>();
  const [lineItemQuantityError, setLineItemQuantityError] = useState<string>();
  const [selectedArticleId, setSelectedArticleId] = useState<number>();
  const [disableDatePicker, setDisableDatePicker] = useState(false);

  const placeDraftOrderRequest = useApiRequests(draftOrderApi.placeDraftOrder);
  const updateLineItemRequest = useApiRequests(draftOrderApi.updateLineItem);

  const closeArticleModal = () => {
    setSelectedArticleId(undefined);
    setUpdateLineItemError(undefined);
    setLineItemQuantityError(undefined);
  };

  const updateLineItem = (lineItem: UpdateLineItem) => {
    updateLineItemRequest
      .create({
        onSuccess: (response) => {
          if (response.quantityError !== undefined) {
            setLineItemQuantityError(response.quantityError);
            return;
          }

          closeArticleModal();
          onRefreshOrder();
        },
        onError: (response) => {
          setUpdateLineItemError(response);
        },
      })
      .send(order.id, lineItem);
  };

  const handleFormSubmit = (
    values: PlaceOrderForm,
    requestedDeliveryDate?: string,
  ) => {
    if (isSubmitting.current) {
      return;
    }
    isSubmitting.current = true;

    setPlaceOrderError(undefined);
    const orderData: PlaceDraftOrderData = {
      externalOrderNumber: values.externalOrderNumber,
      orderMessage: values.orderMessage,
      orderType: values.orderType.key,
      contactName: values.contactName,
      contactPhone: values.contactPhone,
      courierAccount: values.courierAccount,
      customOrderAddress: values.customAddress
        ? {
            company: values.customAddress.company,
            address1: values.customAddress.address1,
            address2: values.customAddress.address2,
            postalCode: values.customAddress.postalCode,
            city: values.customAddress.city,
            countryId: values.customAddress.country!.key,
          }
        : undefined,
      metaFieldValues: values.metaFields,
      existingCustomAddressId: values.existingCustomAddressId,
      requestedDeliveryDate: disableDatePicker ? '' : requestedDeliveryDate,
    };

    placeDraftOrderRequest
      .create({
        onSuccess: onPlacedOrder,
        onError: setPlaceOrderError,
        onResponse: () => {
          isSubmitting.current = false;
        },
      })
      .send(order.id, orderData);
  };

  const placeOrderForm = usePlaceOrderForm({
    standardShippingAddress: order.shippingAddress,
    standardInvoiceAddress: order.invoiceCustomerAddress,
    cartStoreItems: order.rows,
    onSubmit: handleFormSubmit,
  });

  const selectedLineItem = useMemo(
    () => order.rows.find((r) => r.articleId === selectedArticleId),
    [order.rows, selectedArticleId],
  );

  const itemsAreInStock = useMemo(() => {
    return order.rows.every((i) => {
      return i.variants && i.variants.length > 0
        ? i.variants.every((v) => v.stockBalance >= v.quantity)
        : i.stockBalance >= i.quantity;
    });
  }, [order.rows]);

  const anyItemHasMultistock = useMemo(() => {
    return order.rows.some((r) => r.hasMultiStock);
  }, [order.rows]);

  useEffect(() => {
    setDisableDatePicker(
      (!itemsAreInStock && !session.allowAutomaticOrderOnIncomingStock) ||
      !placeOrderForm.deliveryDatesOptions?.deliveryDateAvailable ||
      anyItemHasMultistock ||
      session.customerType === CustomerType.Internal ||
      !session.allowAutomaticOrder ||
      OrderType[placeOrderForm.formik.values.orderType.key] !==
        OrderType[OrderType.Standard],
    );
  }, [
    itemsAreInStock,
    anyItemHasMultistock,
    placeOrderForm.formik.values.orderType,
    placeOrderForm.deliveryDatesOptions?.deliveryDateAvailable,
  ]);

  if (placeOrderForm.error) {
    return <ApiErrorDetails error={placeOrderForm.error} />;
  }

  if (placeOrderForm.isInitializing) {
    return <Skeleton paragraph={{ rows: 4 }} active={true} />;
  }

  const DeliveryDatePickerContainer = ({
    children,
  }: {
    children: React.ReactNode;
  }) =>
    session.customerType === CustomerType.Internal ||
    !session.allowAutomaticOrder ? (
      <></>
    ) : (
      <>{children}</>
    );

  return (
    <>
      {placeDraftOrderRequest.isLoading && <LoadingIndicator />}
      {selectedArticleId && (
        <ArticleModal
          articleId={selectedArticleId}
          lineItemMessage={t(messages.order.updateOrder)}
          lineItem={selectedLineItem}
          isUpdatingLineItem={updateLineItemRequest.isLoading}
          updateLineItemError={updateLineItemError}
          lineItemQuantityError={lineItemQuantityError}
          draftOrder={true}
          onClose={closeArticleModal}
          onUpdateLineItem={updateLineItem}
        />
      )}
      <div className={styles.header}>
        <div className={styles.headerTitle}>
          <span>{order.orderNumber}</span>
        </div>
        <div>
          <Button type="primary" onClick={placeOrderForm.formik.submitForm}>
            {t(messages.order.placeOrder)}
          </Button>
        </div>
      </div>
      {order.type === OrderType.Variable && (
        <Alert
          className={styles.messageAlert}
          message={t(messages.order.placeVariableOrderTitle)}
          description={t(messages.order.placeVariableOrderDescription)}
          type="info"
        />
      )}
      <Form form={placeOrderForm.formik} layout={formLayout}>
        <Row gutter={15}>
          <Col span={8}>
            <Card
              className={styles.contentBlock}
              title={t(messages.shared.details)}
            >
              <MemoizedDetailsList order={order} />
            </Card>
            <Card
              className={styles.contentBlock}
              title={t(messages.address.address)}
            >
              <div className={styles.contentBlock}>
                <div className={styles.addressTitleContainer}>
                  <span className={styles.addressTitle}>
                    {t(messages.address.shipping)}
                  </span>
                  <Divider type="vertical" />
                  <AddressSelect
                    existingCustomAddressId={
                      placeOrderForm.formik.values.existingCustomAddressId
                    }
                    addresses={placeOrderForm.customAddresses}
                    addressesError={placeOrderForm.customAddressesError}
                    loadingAddresses={placeOrderForm.loadingCustomAddresses}
                    loadAddresses={placeOrderForm.loadCustomAddresses}
                    onChangeAddressType={
                      placeOrderForm.changeShippingAddressType
                    }
                    shippingAddressType={placeOrderForm.shippingAddressType}
                  />
                </div>
                {placeOrderForm.shippingAddressType ===
                  ShippingAddressType.NewCustom && (
                  <AddressForm formPath="customAddress" />
                )}

                {!!placeOrderForm.shippingAddress && (
                  <AddressDetails address={placeOrderForm.shippingAddress} />
                )}
              </div>
              <div className={styles.contentBlock}>
                <div className={styles.addressTitleContainer}>
                  <span className={styles.addressTitle}>
                    {t(messages.address.billing)}
                  </span>
                </div>
                <AddressDetails address={placeOrderForm.invoiceAddress} />
              </div>
            </Card>
          </Col>
          <Col span={16}>
            {placeOrderError && <ApiErrorDetails error={placeOrderError} />}
            <Card
              className={styles.contentBlock}
              title={t(messages.order.placeOrder)}
            >
              <DeliveryDatePickerContainer>
                <Row>
                  <Col span={24}>
                    <FormItem style={{ float: 'right' }}>
                      <DeliveryDatePicker
                        nonWorkingDates={
                          placeOrderForm.deliveryDatesOptions
                            ?.nonWorkingDates ?? []
                        }
                        earliestDate={
                          placeOrderForm.deliveryDatesOptions
                            ?.earliestDeliveryDate
                        }
                        name="requestedDeliveryDate"
                        upcomingDeliveries={
                          placeOrderForm.shippingAddress &&
                          placeOrderForm.deliveryDatesOptions
                            ?.upcomingDeliveries
                            ? placeOrderForm.deliveryDatesOptions
                                .upcomingDeliveries
                            : []
                        }
                        showToday={false}
                        disabled={disableDatePicker}
                        disabledTooltip={anyItemHasMultistock ?
                          messages.order.deliveryPickerDisabledTooltipMultiStock :
                          messages.order.deliveryPickerDisabledTooltipOutOfStock
                        }
                      />
                    </FormItem>
                  </Col>
                </Row>
              </DeliveryDatePickerContainer>
              <DraftOrderFields
                formik={placeOrderForm.formik}
                orderTypeOptions={placeOrderForm.orderTypeOptions}
                order={order}
                metaFields={placeOrderForm.metaFields}
              />
            </Card>
            {order.careLabelPos.length > 0 && (
              <Card
                className={styles.contentBlock}
                title={t(messages.order.careLabelPos)}
              >
                <ul className={styles.careLabelPoList}>
                  {order.careLabelPos.map((careLabelPo) => (
                    <li key={careLabelPo.po}>
                      <a
                        href={careLabelPo.uri}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {careLabelPo.po}
                      </a>
                    </li>
                  ))}
                </ul>
              </Card>
            )}
            <Card
              title={t(messages.article.articles)}
              className={styles.contentBlock}
            >
              <MemoizedLineItems order={order} onEdit={setSelectedArticleId} />
            </Card>
          </Col>
        </Row>
      </Form>
    </>
  );
}

function DraftOrderDetailsList({ order }: { order: DraftOrderDetails }) {
  const { t, formatDate } = useI18n();

  return (
    <DescriptionList>
      <DescriptionList.Item title={t(messages.order.orderNumber)}>
        {order.orderNumber}
      </DescriptionList.Item>
      <DescriptionList.Item title={t(messages.order.orderDate)}>
        {formatDate(order.orderDate)}
      </DescriptionList.Item>
      <DescriptionList.Divider />
      <DescriptionList.Item title={t(messages.shared.customer)}>
        {order.customerName}
      </DescriptionList.Item>
      <DescriptionList.Item title={t(messages.order.billedTo)}>
        {order.invoiceCustomerName}
      </DescriptionList.Item>
      <DescriptionList.Item title={t(messages.shared.project)}>
        {order.projectName}
      </DescriptionList.Item>
      <DescriptionList.Item title={t(messages.order.reference)}>
        {order.externalReference}
      </DescriptionList.Item>
      <DescriptionList.Item title={t(messages.order.paymentTerms)}>
        {order.paymentTermName}
      </DescriptionList.Item>
      <DescriptionList.Item title={t(messages.order.deliveryMethod)}>
        {order.deliveryMethodName}
      </DescriptionList.Item>
      <DescriptionList.Item title={t(messages.order.deliveryTerms)}>
        {order.deliveryTermName}
      </DescriptionList.Item>
    </DescriptionList>
  );
}

function DraftOrderLineItems({
  order,
  onEdit,
}: {
  order: DraftOrderDetails;
  onEdit: (articleId: number) => void;
}) {
  const {
    checkoutAdapter: { LineItems },
  } = useShopSessionStore();

  return (
    <LineItems
      includeAdminCostCalculations={true}
      isLoading={false}
      currencyCode={order.currencyCode}
      items={order.rows}
      onEdit={onEdit}
    />
  );
}
