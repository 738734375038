import React, { useMemo } from 'react';
import FormContext from 'antd/es/form/context';
import { FormLayoutOptions } from './types';

const defaultLayout = {
  labelCol: {
    span: 24,
  },
  wrapperCol: {
    span: 24,
  },
};

export function FormLayout({
  children,
  layout = defaultLayout,
}: {
  layout?: FormLayoutOptions;
  children: React.ReactNode;
}) {
  const formLayoutValue = useMemo(() => {
    return { vertical: false, ...layout };
  }, [layout]);

  return (
    <FormContext.Provider value={formLayoutValue}>
      {children}
    </FormContext.Provider>
  );
}
