import React, { useEffect } from 'react';
import {
  TranslateFunction,
  useI18n,
  FormatDateFunction,
} from '../../../../hooks/useI18n';
import { Columns, Table } from '../../../table';
import { CustomerType } from '../../../../types';
import { Filters } from '../../../filters/filters';
import { SearchInput } from '../../../searchInput/searchInput';
import { useDebounceValueCallback } from '../../../../hooks/useDebounceValueCallback';
import { messages } from '../../../../messages';
import { ApiErrorDetails } from '../../../apiErrorDetails/apiErrorDetails';
import { useApiResource } from '../../../../hooks/useApi';
import { ListLayout } from '../../../listLayout/listLayout';
import { useQueryParams, serializers } from '../../../../hooks/useQueryParams';
import { useShopSessionStore } from '../../../../hooks/shop/useShopSessionStore';
import { CustomerFilter } from '../customerFilter/customerFilter';
import { OrderListItem, ordersApi } from '../../../../api/ordersApi';

const getColumns = (
  t: TranslateFunction,
  formatDate: FormatDateFunction,
  customerIsBrand: boolean,
): Columns<OrderListItem> => [
  {
    header: t(messages.order.orderNumber),
    accessor: 'orderNumber',
    sortable: true,
    width: 130,
  },
  {
    header: t(messages.shared.customer),
    accessor: 'customerName',
    sortable: true,
    show: customerIsBrand,
  },
  {
    header: t(messages.order.referenceOrderNumber),
    accessor: 'externalOrderNumber',
    sortable: true,
  },
  {
    header: t(messages.order.orderDate),
    accessor: 'orderDate',
    sortable: true,
    cell: ({ row }) => formatDate(row.orderDate),
  },
];

const pageSize = 30;

export function DraftOrders({
  onSelectOrderId,
}: {
  onSelectOrderId: (id: number) => void;
}) {
  const { t, formatDate } = useI18n();
  const shopSessionStore = useShopSessionStore();
  const customerIsBrand =
    shopSessionStore.session.customerType === CustomerType.Brand;
  const columns = getColumns(t, formatDate, customerIsBrand);

  const [queryParams, setQueryParams] = useQueryParams({
    page: serializers.number({ defaultValue: 1 }),
    searchQuery: serializers.string({ defaultValue: '' }),
    articleNumber: serializers.string({ defaultValue: '' }),
    sortingDesc: serializers.boolean({ defaultValue: true }),
    sortingKey: serializers.custom<keyof OrderListItem>({
      defaultValue: 'orderDate',
      serialize: (v) => v,
      deserialize: (v) => {
        if (v && columns.some((c) => c.accessor === v)) {
          return v as keyof OrderListItem;
        }
      },
    }),
    customerId: serializers.number(),
  });

  const partialupdateFilterQueryParams = (
    newQueryParams: Partial<typeof queryParams>,
  ) => {
    setQueryParams({ ...queryParams, ...newQueryParams, page: 1 });
  };

  const [
    searchQuery,
    setSearchQuery,
  ] = useDebounceValueCallback(queryParams.searchQuery, (value) =>
    partialupdateFilterQueryParams({ searchQuery: value }),
  );

  const [
    articleNumber,
    setArticleNumberSearchQuery,
  ] = useDebounceValueCallback(queryParams.articleNumber, (value) =>
    partialupdateFilterQueryParams({ articleNumber: value }),
  );

  const orders = useApiResource(ordersApi.getOrders, {
    emptyValue: {
      totalItems: 0,
      items: [],
    },
    clearDataOnLoad: false,
  });

  useEffect(() => {
    orders.refresh({
      articleNumber: queryParams.articleNumber,
      customerId: customerIsBrand ? queryParams.customerId : undefined,
      orderProgresses: [],
      page: queryParams.page,
      pageSize,
      placed: false,
      searchQuery: queryParams.searchQuery,
      sortingKey: queryParams.sortingKey,
      sortingDesc: queryParams.sortingDesc,
    });
  }, [
    queryParams.articleNumber,
    queryParams.customerId,
    queryParams.page,
    queryParams.searchQuery,
    queryParams.sortingDesc,
    queryParams.sortingKey,
  ]);

  return (
    <ListLayout>
      <ListLayout.Sider>
        <Filters>
          <Filters.Filter title={t(messages.shared.search)}>
            <SearchInput
              value={searchQuery}
              placeholder={t(messages.shared.searchPlaceholder)}
              onChange={setSearchQuery}
            />
          </Filters.Filter>
          {customerIsBrand && (
            <CustomerFilter
              customerId={queryParams.customerId}
              onChange={(customerId) =>
                partialupdateFilterQueryParams({
                  customerId,
                })
              }
            />
          )}
          <Filters.Filter title={t(messages.shared.searchArticleNumber)}>
            <SearchInput
              value={articleNumber}
              placeholder={t(messages.shared.searchArticleNoPlaceholder)}
              onChange={setArticleNumberSearchQuery}
            />
          </Filters.Filter>
        </Filters>
      </ListLayout.Sider>
      <ListLayout.Content>
        <ListLayout.ContentHeader
          title={t(messages.shared.listingItemsCount, {
            count: orders.data.totalItems || 0,
          })}
        />
        {orders.error && (
          <ListLayout.ContentSection>
            <ApiErrorDetails error={orders.error} />
          </ListLayout.ContentSection>
        )}
        <Table<OrderListItem>
          columns={columns}
          items={orders.data.items}
          isLoading={orders.isLoading}
          onSelectItem={(order) => onSelectOrderId(order.id)}
          paged={{
            page: queryParams.page,
            pageSize,
            onPageChange: (page) => {
              setQueryParams({ ...queryParams, page });
            },
            totalItems: orders.data.totalItems || 0,
          }}
          sorted={{
            desc: queryParams.sortingDesc,
            key: queryParams.sortingKey,
            onSortedChange: (sorting) =>
              setQueryParams({
                ...queryParams,
                sortingDesc: sorting.desc,
                sortingKey: sorting.key,
              }),
          }}
        />
      </ListLayout.Content>
    </ListLayout>
  );
}
