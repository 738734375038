export type OverflowActions = {
  type: 'scroll';
  payload: {
    clientHeight: number;
    clientWidth: number;
    scrollHeight: number;
    scrollWidth: number;
    scrollTop: number;
    scrollLeft: number;
  };
};

export interface OverflowState {
  left: boolean;
  right: boolean;
  top: boolean;
  bottom: boolean;
}

const boundsBuffer = 5;

export function overflowStateReducer(
  state: OverflowState,
  action: OverflowActions,
): OverflowState {
  const {
    scrollWidth,
    clientWidth,
    scrollHeight,
    clientHeight,
    scrollTop,
    scrollLeft,
  } = action.payload;

  const maxRightScrollBounds = scrollWidth - clientWidth - boundsBuffer;
  const maxBottomScrollBounds = scrollHeight - clientHeight - boundsBuffer;

  const newState = {
    left: scrollLeft >= boundsBuffer,
    right: scrollLeft < maxRightScrollBounds,
    top: scrollTop >= boundsBuffer,
    bottom: scrollTop < maxBottomScrollBounds,
  };

  if (
    newState.left !== state.left ||
    newState.right !== state.right ||
    newState.top !== state.top ||
    newState.bottom !== state.bottom
  ) {
    return newState;
  }

  return state;
}
