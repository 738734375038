import React, { useEffect } from 'react';
import { Modal, Skeleton, Divider } from 'antd';
import styles from './articleModal.module.scss';
import { Details } from '../../../components/shop/articleModal/details/details';
import { Stock } from '../../../components/shop/articleModal/stock/stock';
import { useI18n } from '../../../hooks/useI18n';
import { messages } from '../../../messages';
import { ApiErrorDetails } from '../../../components/apiErrorDetails/apiErrorDetails';
import { useApiResource } from '../../../hooks/useApi';
import { articlesApi } from '../../../api/articlesApi';
import { UpdateArticleLineItem } from './updateArticleLineItem';
import { LineItem, UpdateLineItem, ArticleType } from '../../../types';

export function ArticleModal({
  articleId,
  isUpdatingLineItem,
  onUpdateLineItem,
  onClose,
}: {
  articleId: number;
  lineItemMessage: string;
  lineItem?: LineItem;
  isUpdatingLineItem: boolean;
  onUpdateLineItem: (lineItem: UpdateLineItem) => void;
  onClose: () => void;
}) {
  const { t } = useI18n();
  const article = useApiResource(articlesApi.getArticle);

  useEffect(() => {
    article.refresh(articleId);
  }, [articleId]);

  const activeArticle = article.data;

  return (
    <Modal
      className={styles.modal}
      footer={null}
      visible={true}
      onCancel={onClose}
      width="100%"
    >
      <div className={styles.page}>
        {article.error && <ApiErrorDetails error={article.error} />}

        {!article.data && !article.error && (
          <Skeleton paragraph={{ rows: 15 }} active={true} />
        )}

        {!!article.data && (
          <>
            <div className={styles.topContent}>
              {activeArticle && (
                <Details article={activeArticle} showPrice={false} />
              )}
              {!activeArticle && (
                <Skeleton paragraph={{ rows: 5 }} active={true} />
              )}
            </div>
            <div className={styles.bottomContent}>
              {article.data.type !== ArticleType.Matrix &&
                article.data.type !== ArticleType.Kit &&
                !article.data.frozen && (
                  <div className={styles.bottomStockWrapper}>
                    <Divider className={styles.blockDivider} orientation="left">
                      {t(messages.stock.stock)}
                    </Divider>
                    <Stock
                      articleBranchStocks={article.data.articleStocks}
                      showStockLocations={article.data.useStockLocations}
                      quantityDecimalPlaces={article.data.quantityDecimalPlaces}
                    />
                  </div>
                )}
              {article.data.type !== ArticleType.Matrix &&
                !article.data.frozen && (
                  <div className={styles.articleOrderWrapper}>
                    <Divider className={styles.blockDivider} orientation="left">
                      {t(messages.checkoutAdapter._3M.lineItemMessage)}
                    </Divider>
                    <UpdateArticleLineItem
                      articleId={articleId}
                      onUpdateLineItem={onUpdateLineItem}
                      isLoading={isUpdatingLineItem}
                    />
                  </div>
                )}
            </div>
          </>
        )}
      </div>
    </Modal>
  );
}
