import React from 'react';
import { useI18n, TranslateFunction } from '../../../../hooks/useI18n';
import { Table, Columns } from '../../../table';
import { messages } from '../../../../messages';
import styles from './deliveryNoteRows.module.scss';
import { DeliveryNoteRow } from '../../../../types';

export const getListColumns = (
  t: TranslateFunction,
): Columns<DeliveryNoteRow> => [
  {
    header: t(messages.order.row),
    accessor: 'rowNumber',
    sortable: false,
    width: 50,
    cellClassName: styles.cell,
  },
  {
    header: t(messages.article.articleNumber),
    sortable: false,
    cellClassName: styles.cell,
    cell: ({ row }) => (
      <>
        <div className={styles.articleNumber}>{row.articleNumber}</div>
        <div className={styles.articleDescription}>
          {row.articleDescription}
        </div>
        <div className={styles.articleDescription}>{row.articleColor}</div>
        <div className={styles.articleDescription}>{row.articleSize}</div>
      </>
    ),
  },
  {
    header: t(messages.order.delivered),
    accessor: 'quantityDelivered',
    align: 'right',
    cellClassName: styles.cell,
    width: 150,
    cell: ({ row }) => row.quantityDelivered,
    sortable: false,
  },
];

interface Props {
  rows: DeliveryNoteRow[];
}

const DeliveryNoteRowComponent = ({ rows }: Props) => {
  const { t } = useI18n();
  const listColumns = getListColumns(t);

  return <Table<DeliveryNoteRow> columns={listColumns} items={rows} />;
};

export const DeliveryNoteRows = React.memo(DeliveryNoteRowComponent);
