import React, { useMemo, useState } from 'react';
import { LoadingIndicator } from '../../loadingIndicator/loadingIndicator';
import styles from './lineItems.module.scss';
import { Avatar, Button, Empty, Alert, Icon, Tooltip } from 'antd';
import cc from 'classcat';
import emptyBoxIcon from './emptyBoxIcon.svg';
import { LineItem } from '../../../types';
import { useI18n } from '../../../hooks/useI18n';
import { messages } from '../../../messages';
import { useFileUris } from '../../../hooks/useFileUrls/useFileUrls';
import { formatNumber } from '../../../helpers/formatNumber/formatNumber';
import Decimal from 'decimal.js';
import { useShopSessionStore } from '../../../hooks/shop/useShopSessionStore';

export interface LineItemsProps {
  isLoading: boolean;
  items: LineItem[];
  currencyCode: string;
  unavailableArticleIds?: number[];
  includeAdminCostCalculations: boolean;
  onEdit?: (articleId: number) => void;
  onRemove?: (articleId: number) => void;
}

export const LineItems = (props: LineItemsProps) => {
  const { t } = useI18n();
  const { session } = useShopSessionStore();

  const [addAdminPrice, setAddAdminPrice] = useState<boolean>(false);
  const totalPrice = useMemo(() => {
    let price = new Decimal(0);

    for (const { totalPrice } of props.items) {
      price = price.add(totalPrice);
    }

    if (
      props.includeAdminCostCalculations &&
      session.assortmentAdminCost.isActive
    ) {
      const adminThresholdNotReached =
        price.toNumber() <
        (session.assortmentAdminCost.priceTriggerThreshold ?? 0);
      setAddAdminPrice(adminThresholdNotReached);
      if (adminThresholdNotReached) {
        price = price.add(session.assortmentAdminCost.price ?? 0);
      }
    }

    return price.toNumber();
  }, [props.items]);

  return (
    <div>
      {props.isLoading && <LoadingIndicator />}
      {!!props.unavailableArticleIds?.length && (
        <Alert
          message={t(messages.cart.removeUnavailableArticles)}
          type="error"
          showIcon={true}
        />
      )}
      <div>
        {!!props.items.length && (
          <div>
            <div className={styles.header}>
              <div
                className={cc([styles.headerCell, styles.articleDetailsCell])}
              >
                {t(messages.article.article)}
              </div>
              <div className={cc([styles.headerCell, styles.numberCell])}>
                {t(messages.shared.quantity)}
              </div>
              <div className={cc([styles.headerCell, styles.numberCell])}>
                {t(messages.shared.totalPrice)} ({props.currencyCode})
              </div>
              <div className={cc([styles.headerCell, styles.actionCell])} />
            </div>

            <div>
              {props.items.map((item) => (
                <LineItemRow
                  key={item.articleId}
                  item={item}
                  unavailableArticleIds={props.unavailableArticleIds}
                  onEdit={props.onEdit}
                  onRemove={props.onRemove}
                />
              ))}
            </div>

            <div className={styles.summary}>
              {props.includeAdminCostCalculations &&
                session.assortmentAdminCost.isActive && (
                  <div
                    className={`
                    ${styles.adminCostDescription} ${
                      !addAdminPrice ? styles.reached : ''
                    }
                      `}
                  >
                    <p>
                      {t(messages.shared.assortmentAdminCostDescription)}{' '}
                      {formatNumber(
                        session.assortmentAdminCost.priceTriggerThreshold ?? 0,
                        2,
                      )}{' '}
                      {props.currencyCode} :{' '}
                      {formatNumber(session.assortmentAdminCost.price ?? 0, 2)}{' '}
                      {props.currencyCode}
                    </p>
                  </div>
                )}

              <div className={styles.totalPrice}>
                <div className={styles.totalPriceLabel}>
                  {t(messages.shared.totalPrice)}:
                </div>
                <div>{`${formatNumber(totalPrice, 2)} ${
                  props.currencyCode
                }`}</div>
              </div>
            </div>
          </div>
        )}

        {!props.items.length && (
          <Empty
            description={t(messages.shared.noItems)}
            image={<img src={emptyBoxIcon} />}
          />
        )}
      </div>
    </div>
  );
};

function LineItemRow({
  item,
  unavailableArticleIds,
  onEdit,
  onRemove,
}: {
  item: LineItem;
  unavailableArticleIds?: number[];
  onEdit?: (articleId: number) => void;
  onRemove?: (articleId: number) => void;
}) {
  const { t } = useI18n();
  const { placeholderImageFileUri, articleImageFileUri } = useFileUris();

  const isAvailable = useMemo(() => {
    if (!unavailableArticleIds) {
      return true;
    }

    if (unavailableArticleIds.includes(item.articleId)) {
      return false;
    }

    if (item.variants && item.variants.length) {
      const hasUnavailableVariant = item.variants.some((v) =>
        unavailableArticleIds.includes(v.articleId),
      );

      if (hasUnavailableVariant) {
        return false;
      }
    }

    return true;
  }, [unavailableArticleIds]);

  const handleRowClick = (item: LineItem) => {
    if (onEdit && isAvailable) {
      onEdit(item.articleId);
    }
  };

  return (
    <div className={styles.row} onClick={() => handleRowClick(item)}>
      <div className={styles.rowContent}>
        <div className={cc([styles.rowCell, styles.articleDetailsCell])}>
          <div
            className={cc([styles.rowCellContent, styles.rowTitleCellContent])}
          >
            <Avatar
              src={
                item.hasImage
                  ? articleImageFileUri(item.articleId)
                  : placeholderImageFileUri
              }
              shape="square"
              className={styles.articleImage}
            />
            <div className={styles.articleDetails}>
              <div className={cc([styles.articleDetail, styles.articleNumber])}>
                {item.articleNumber}
              </div>
              <div
                className={cc([
                  styles.articleDetail,
                  styles.articleDescription,
                ])}
              >
                {item.description}
              </div>
            </div>
          </div>
        </div>
        {isAvailable && (
          <div className={styles.rowContent}>
            <div className={cc([styles.rowCell, styles.numberCell])}>
              <div className={styles.rowCellContent}>
                {item.quantityIsAdjusted && (
                  <Tooltip
                    title={t(messages.shared.quantityAdjusted, {
                      qtyPerUnit: item.quantityPerUnit,
                    })}
                  >
                    <Icon style={{ color: '#40a9ff' }} type="info-circle" />
                  </Tooltip>
                )}{' '}
                {item.quantity} {item.unit}
              </div>
            </div>
            <div className={cc([styles.rowCell, styles.numberCell])}>
              <div className={styles.rowCellContent}>
                {formatNumber(item.totalPrice, 2)}
              </div>
            </div>
          </div>
        )}
        {!isAvailable && (
          <div className={cc([styles.rowCell])}>
            <div className={styles.rowCellContent}>
              <Alert
                message={t(messages.cart.unavailableArticle)}
                type="error"
              />
            </div>
          </div>
        )}
        <div
          className={cc([styles.rowCell, styles.actionCell])}
          onClick={(e) => e.stopPropagation()}
        >
          {onEdit && isAvailable && (
            <Button
              shape="circle"
              icon="edit"
              onClick={() => onEdit(item.articleId)}
            />
          )}
          {onRemove && (
            <Button
              shape="circle"
              icon="delete"
              onClick={() => onRemove(item.articleId)}
            />
          )}
        </div>
      </div>
      {!!item.variants?.length && (
        <div className={styles.nestedRow}>
          <div className={styles.nestedRowContent}>
            <div className={styles.header}>
              <div
                className={cc([
                  styles.nestedHeaderCell,
                  styles.variantNameCell,
                ])}
              >
                {t(messages.article.color)}
              </div>
              <div
                className={cc([
                  styles.nestedHeaderCell,
                  styles.variantNameCell,
                ])}
              >
                {t(messages.article.size)}
              </div>
              <div
                className={cc([
                  styles.nestedHeaderCell,
                  styles.variantNumberCell,
                ])}
              >
                {t(messages.shared.quantity)}
              </div>
              <div
                className={cc([
                  styles.nestedHeaderCell,
                  styles.variantNumberCell,
                ])}
              >
                {t(messages.shared.totalPrice)}
              </div>
            </div>
            {item.variants.map((variant) => (
              <div key={variant.articleId} className={styles.row}>
                <div className={styles.rowContent}>
                  <div
                    className={cc([
                      styles.nestedRowCell,
                      styles.variantNameCell,
                    ])}
                  >
                    <div className={styles.rowCellContent}>
                      {variant.colorName}
                    </div>
                  </div>
                  <div
                    className={cc([
                      styles.nestedRowCell,
                      styles.variantNameCell,
                    ])}
                  >
                    <div className={styles.rowCellContent}>
                      {variant.sizeName}
                    </div>
                  </div>
                  <div
                    className={cc([
                      styles.nestedRowCell,
                      styles.variantNumberCell,
                    ])}
                  >
                    <div className={styles.rowCellContent}>
                      {variant.quantity} {variant.unit}
                    </div>
                  </div>
                  <div
                    className={cc([
                      styles.nestedRowCell,
                      styles.variantNumberCell,
                    ])}
                  >
                    <div className={styles.rowCellContent}>
                      {formatNumber(variant.totalPrice, 2)}
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
}
