import React from 'react';
import cc from 'classcat';
import {
  BaseCellValue,
  CellSize,
  RenderCellContent,
  CellLocation,
} from '../types';
import styles from './cell.module.scss';

interface CellProps<T extends BaseCellValue> {
  isSelected: boolean;
  cellSize: CellSize;
  cellValue?: T;
  xKey: string;
  yKey: string;
  renderCellContent: RenderCellContent<T>;
  onSelect: (cell: CellLocation) => void;
}

function CellComponent<T extends BaseCellValue>({
  xKey,
  yKey,
  cellSize,
  isSelected,
  onSelect,
  renderCellContent,
  cellValue,
}: CellProps<T>) {
  return (
    <div
      tabIndex={-1}
      style={{ width: cellSize.width, height: cellSize.height }}
      className={cc({
        [styles.cell]: true,
        [styles.cellSelected]: isSelected,
      })}
      onFocus={() => {
        if (!isSelected) {
          onSelect({ xKey, yKey });
        }
      }}
    >
      {renderCellContent({
        xKey,
        yKey,
        cellValue,
      })}
    </div>
  );
}

// Manually typed because of troubles with React.memo and
// generic functions
export const Cell: <T extends BaseCellValue>(
  props: CellProps<T>,
) => JSX.Element = React.memo(CellComponent) as any;
