import React, { useEffect, useMemo, useState } from 'react';
import { Filters } from '../../../filters/filters';
import { Radio, Select, Skeleton } from 'antd';
import { messages } from '../../../../messages';
import { useI18n } from '../../../../hooks/useI18n';
import { useShopSessionStore } from '../../../../hooks/shop/useShopSessionStore';
import { LabeledValue } from 'antd/lib/select';
import { useApiResource } from '../../../../hooks/useApi';
import { ApiErrorDetails } from '../../../apiErrorDetails/apiErrorDetails';
import { ordersApi } from '../../../../api/ordersApi';

enum FilterState {
  All,
  CurrentCustomer,
  Vendor,
}

export function CustomerFilter({
  customerId,
  onChange,
}: {
  customerId?: number;
  onChange: (customerId?: number) => void;
}) {
  const { t } = useI18n();
  const shopSessionStore = useShopSessionStore();
  const vendors = useApiResource(ordersApi.getVendors);

  // Filter state that will be used if customerId prop is undefined
  const [emptyFilterState, setEmptyFilterState] = useState<
    FilterState.All | FilterState.Vendor
  >(FilterState.All);

  // We derive the active filter state based on the customerId prop
  const activeFilterState = useMemo(() => {
    if (customerId === shopSessionStore.session.customerId) {
      return FilterState.CurrentCustomer;
    }

    if (customerId !== undefined) {
      return FilterState.Vendor;
    }

    return emptyFilterState;
  }, [customerId, emptyFilterState]);

  useEffect(() => {
    if (activeFilterState === FilterState.Vendor) {
      vendors.refresh();
    }
  }, [activeFilterState]);

  const selectedVendor = vendors.data
    ? vendors.data.find((d) => d.id === customerId)
    : undefined;

  return (
    <Filters.Filter title="Customer">
      <Radio
        checked={activeFilterState === FilterState.All}
        onChange={() => {
          onChange(undefined);
          setEmptyFilterState(FilterState.All);
        }}
        style={{ display: 'block' }}
      >
        {t(messages.shared.all)}
      </Radio>
      <Radio
        checked={activeFilterState === FilterState.CurrentCustomer}
        style={{ display: 'block' }}
        onChange={() => onChange(shopSessionStore.session.customerId)}
      >
        {shopSessionStore.session.customerName}
      </Radio>
      <Radio
        checked={activeFilterState === FilterState.Vendor}
        style={{ display: 'block' }}
        onChange={() => {
          onChange(undefined);
          setEmptyFilterState(FilterState.Vendor);
        }}
      >
        Vendor
      </Radio>
      {activeFilterState === FilterState.Vendor && (
        <>
          {vendors.error && <ApiErrorDetails error={vendors.error} />}
          {vendors.isLoading && (
            <Skeleton title={true} paragraph={false} active={true} />
          )}
          {!vendors.error && !vendors.isLoading && (
            <Select<LabeledValue | undefined>
              value={
                selectedVendor
                  ? {
                      key: selectedVendor.id.toString(),
                      label: selectedVendor.name,
                    }
                  : undefined
              }
              labelInValue={true}
              dropdownMatchSelectWidth={false}
              showSearch={true}
              allowClear={true}
              optionFilterProp="children"
              placeholder="Select vendor"
              style={{ width: '100%', marginTop: '10px' }}
              onChange={(val) => {
                if (!vendors.data) {
                  return;
                }

                if (!val) {
                  onChange(undefined);
                  return;
                }

                onChange(parseInt(val.key, 10));
              }}
            >
              {vendors.data &&
                vendors.data.map((v) => (
                  <Select.Option key={v.id}>{v.name}</Select.Option>
                ))}
            </Select>
          )}
        </>
      )}
    </Filters.Filter>
  );
}
