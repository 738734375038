import React from 'react';
import devLogo from '../../images/logo-dev.png';
import stagingLogo from '../../images/logo-staging.png';
import productionLogo from '../../images/logo-production.png';
import styles from './logo.module.scss';
import { ApplicationEnvironment } from '../../types';
import { useShopSessionStore } from '../../hooks/shop/useShopSessionStore';

export function Logo({
  applicationEnvironment,
}: {
  applicationEnvironment: ApplicationEnvironment;
}) {
  const { checkoutAdapter } = useShopSessionStore();
  return (
    <>
      <img className={styles.logo} src={GetLogoSrc(applicationEnvironment)} />
      {checkoutAdapter?.logo && (
        <img
          className={styles.logo}
          style={{ marginLeft: '15px' }}
          src={checkoutAdapter.logo}
        />
      )}
    </>
  );
}

function GetLogoSrc(applicationEnvironment: ApplicationEnvironment) {
  switch (applicationEnvironment) {
    case ApplicationEnvironment.Production:
      return productionLogo;
    case ApplicationEnvironment.Staging:
      return stagingLogo;
    case ApplicationEnvironment.Development:
      return devLogo;
  }
}
