import React from 'react';
import { Alert, Button, Drawer, Icon } from 'antd';
import styles from './cartDrawer.module.scss';
import { useI18n } from '../../../hooks/useI18n';
import { messages } from '../../../messages';
import { useCartStore } from '../../../hooks/shop/useCartStore';
import { CartLineItems } from '../cartLineItems/cartLineItems';

interface Props {
  visible: boolean;
  onCheckout: () => void;
  onClose: () => void;
}

export const CartDrawer = ({ visible, onClose, onCheckout }: Props) => {
  const { t } = useI18n();
  const cartStore = useCartStore();

  return (
    <Drawer
      bodyStyle={{ height: 'calc(100vh - 55px)', position: 'relative' }}
      width={700}
      title={t(messages.cart.cart)}
      placement="right"
      closable={true}
      visible={visible}
      onClose={onClose}
    >
      <div className={styles.content}>
        {cartStore.items.some(v => v.quantityIsAdjusted) && (
          <Alert
            icon={<Icon type="info-circle" />}
            message={t(messages.shared.quantityAdjustedInfo)}
            showIcon={true}
            type="info"
          />
        )}
        <div className={styles.items}>
          <CartLineItems />
        </div>
        <div className={styles.footer}>
          <Button
            block={true}
            type="primary"
            size="large"
            disabled={!cartStore.items.length || cartStore.isLoading}
            onClick={onCheckout}
          >
            {t(messages.cart.checkout)}
          </Button>
        </div>
      </div>
    </Drawer>
  );
};
