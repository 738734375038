import { ApiRequest } from '../../../hooks/useApi';
import { TimePeriodType } from '../../../types';
import {
  Branch,
  Category,
  ProductStatisticsReport,
  Project,
} from '../internalProductStatisticsReportWidgetApi';

export const customerProductStatisticsReportWidgetApi = {
  createWidget:
    (
      request: CreateUpdateCustomerProductStatisticsReportWidgetRequest,
    ): ApiRequest =>
    async ({ httpClient, apiSettings }) => {
      const response = await httpClient.post(
        apiSettings.customerProductStatisticsReportWidgetsUri,
        {
          data: request,
        },
      );

      if (response.hasError) {
        return { error: response.error };
      }
      return {
        result: response.data,
      };
    },
  updateWidget:
    (
      id: number,
      request: CreateUpdateCustomerProductStatisticsReportWidgetRequest,
    ): ApiRequest =>
    async ({ httpClient, apiSettings }) => {
      const response = await httpClient.put(
        apiSettings.customerProductStatisticsReportWidgetUri(id),
        {
          data: request,
        },
      );

      if (response.hasError) {
        return { error: response.error };
      }
      return {
        result: response.data,
      };
    },
  getWidget:
    (id: number): ApiRequest<CustomerProductStatisticsReportWidget> =>
    async ({ httpClient, apiSettings }) => {
      const response = await httpClient.get(
        apiSettings.customerProductStatisticsReportWidgetUri(id),
      );

      if (response.hasError) {
        return { error: response.error };
      }
      return {
        result: response.data,
      };
    },
  triggerNewReport:
    (widgetId: number): ApiRequest<void> =>
    async ({ httpClient, apiSettings }) => {
      const response = await httpClient.post(
        apiSettings.triggerNewCustomerProductStatisticsExportUri(widgetId),
      );

      if (response.hasError) {
        return { error: response.error };
      }
      return {
        result: response.data,
      };
    },
};

export interface CustomerProductStatisticsReportWidget {
  name: string;
  widgetCategoryId: number;
  color: string | undefined;
  branches: Branch[];
  categories: Category[];
  projects: Project[];
  itemTypes: string[];
  timePeriods: string[];
  timePeriodType: TimePeriodType;
  includeFrozenArticles: boolean;
  includeImages: boolean;
  mostRecentSuccessfullyGeneratedReport: ProductStatisticsReport | undefined;
  existsPendingReport: boolean;
  lastGeneratedReportFailed: boolean;
}

export interface CreateUpdateCustomerProductStatisticsReportWidgetRequest {
  name: string;
  widgetCategoryId: number | undefined;
  color: string | undefined;
  categoryIds: number[];
  timePeriods: string[];
  timePeriodType: TimePeriodType;
}
