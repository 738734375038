import { useState, useRef, useEffect } from 'react';
import { ApiRequest, ApiError, ApiResponse } from './types';
import { useHttpClient } from '../useHttpClient';
import { useApiSettings } from './useApiSettings';

export const useApiRequests = <A extends any[], R>(
  apiRequestFactory: (...args: A) => ApiRequest<R>,
) => {
  const httpClient = useHttpClient();
  const apiSettings = useApiSettings();
  const isMounted = useRef(true);
  const latestRequestIdentifier = useRef<symbol>();
  const [isLoading, setLoading] = useState(false);

  const sendRequest = async (
    args: any,
    onSuccess?: (result: R) => void,
    onError?: (error: ApiError) => void,
    onResponse?: (response: ApiResponse<R>) => void,
  ) => {
    const requestId = Symbol();
    latestRequestIdentifier.current = requestId;

    setLoading(true);

    const apiRequest = apiRequestFactory(...args);
    const response = await apiRequest({
      httpClient,
      apiSettings,
    });

    if (!isMounted.current) {
      return;
    }

    if (onResponse) {
      onResponse(response);
    }

    if (response.error !== undefined) {
      if (onError) {
        onError(response.error);
      }
    } else if (onSuccess) {
      onSuccess(response.result);
    }

    if (requestId === latestRequestIdentifier.current && isMounted.current) {
      setLoading(false);
    }
  };

  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  const create = ({
    onSuccess,
    onError,
    onResponse,
  }: {
    onSuccess?: (result: R) => void;
    onError?: (error: ApiError) => void;
    onResponse?: (response: ApiResponse<R>) => void;
  }) => ({
    send: (...args: A) => {
      sendRequest(args, onSuccess, onError, onResponse);
    },
  });

  const update = ({
    onSuccess,
    onError,
    onResponse,
  }: {
    onSuccess?: (result: R) => void;
    onError?: (error: ApiError) => void;
    onResponse?: (response: ApiResponse<R>) => void;
  }) => ({
    send: (...args: A) => {
      sendRequest(args, onSuccess, onError, onResponse);
    },
  });

  return { isLoading, create, update };
};
