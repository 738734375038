import {
  OptionalNestedTranslations,
  FlattenedTranslations,
  NestedPaths,
} from './types';
import { getFlattenedTranslations } from './helpers';

// Overrides translations, a null value will use the default value
export function createTranslations<T extends NestedPaths>(
  defaultFlattenedTranslations: FlattenedTranslations,
  translations: OptionalNestedTranslations<T>,
) {
  const flattenedTranslations = getFlattenedTranslations(translations);

  return { ...defaultFlattenedTranslations, ...flattenedTranslations };
}
