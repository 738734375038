import React from 'react';
import { useEffect, PropsWithChildren } from 'react';
import { useLocation } from 'react-router-dom';

export default function ScrollToTop({ children }: PropsWithChildren<{}>) {
  const { pathname } = useLocation();

  useEffect(() => {
    const shopContentElement = document.querySelector('#shopContent');
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const _ = shopContentElement?.scrollIntoView();
  }, [pathname]);

  return <>{children}</>;
}
