import React from 'react';
import MediumImageZoom from 'react-medium-image-zoom';

// Currently wrapping react-medium-image-zoom with type declarations
// found at './react-medium-image-zoom.d.ts'
export function ImageZoom({
  imageUrl,
  className,
}: {
  imageUrl: string;
  className?: string;
}) {
  return (
    <MediumImageZoom
      image={{ src: imageUrl, className }}
      zoomMargin={100}
      defaultStyles={{
        zoomContainer: {
          zIndex: 99999,
          cursor: 'zoom-out',
        },
        image: {
          cursor: 'zoom-in',
        },
      }}
    />
  );
}
