/* eslint-disable react-hooks/rules-of-hooks */
import constate from 'constate';
import { useI18n } from '../useI18n';
import { useEffect, useMemo } from 'react';
import { getLocale } from './locale';
import * as yup from 'yup';

const containsCjkChars = function (value: string) {
  const regex = RegExp(
    /[\u{3400}-\u{4DBF}]|[\u{4E00}-\u{9FFC}]|[\u{20000}-\u{2A6DD}]|[\u{2A700}-\u{2B734}]|[\u{2B740}-\u{2B81D}]|[\u{2B820}-\u{2CEA1}]|[\u{2CEB0}-\u{2EBE0}]|[\u{30000}-\u{3134A}]/u,
    'u',
  );
  const match = regex.exec(value);
  return match ? false : true;
};

const yupStore = () => {
  const { t } = useI18n();

  useEffect(() => {
    yup.setLocale(getLocale(t));
  }, [t]);

  // Memoize to only update consumers when specified deps changes
  return useMemo(() => yup, []);
};

const [YupProvider, useYup] = constate(yupStore);
export { YupProvider, useYup, containsCjkChars };
