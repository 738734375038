import { Button, Select, Tooltip } from 'antd';
import React, { useEffect, useState } from 'react';
import { useApiResource } from '../../../../../hooks/useApi';
import {
  customerWidgetsApi,
  WidgetCategory,
} from '../../../../../api/widgets/customers/customerWidgetsApi';
import { CreateCustomerCategoryModal } from './createCustomerCategoryModal';
import { useI18n } from '../../../../../hooks/useI18n';
import { messages } from '../../../../../messages';

const { Option } = Select;

export function CustomerWidgetCategorySelect(
  {
    value,
    onChange,
  }: {
    value: string | undefined;
    onChange: (name: string) => void;
  }) {
  const { t } = useI18n();
  const [showCreateCategoryModal, setShowCreateCategoryModal] = useState<boolean>(false);

  const widgetCategories = useApiResource(customerWidgetsApi.getCategories, {
    emptyValue: [] as WidgetCategory[],
    clearDataOnLoad: true,
  });

  useEffect(() => {
    widgetCategories.refresh();
  }, []);

  function onCategoryCreated() {
    setShowCreateCategoryModal(false);
    widgetCategories.refresh();
  }

  return (
    <>
      <Select showSearch={true} allowClear={true}
              value={value}
              onChange={onChange}
              style={{ width: 250, marginTop: 10, marginRight: 10 }}
              placeholder={t(messages.widgets.categories.selectCategory)}>
        {widgetCategories.hasResponse && widgetCategories.data.map(category =>
          <Option key={category.id}>{category.name}</Option>)}
      </Select>
      <Tooltip placement='topLeft'
               title={t(messages.widgets.categories.createCategory)}>
        <Button shape='circle' icon='plus'
                onClick={() => setShowCreateCategoryModal(true)}></Button>
      </Tooltip>
      {showCreateCategoryModal &&
      <CreateCustomerCategoryModal
        onCancel={() => setShowCreateCategoryModal(false)}
        onSuccess={onCategoryCreated} />}
    </>
  );
}
