import React from 'react';
import styles from './dashboard.module.scss';
import { UserInformation } from './userInformation/userInformation';
import { Row, Col, Alert } from 'antd';
import { ContactCard } from './contactCard/contactCard';
import { NewsCard } from './newsCard/newsCard';
import { InvoicesCard } from './invoicesCard/invoicesCard';
import { useShopSessionStore } from '../../../hooks/shop/useShopSessionStore';
import { useI18n } from '../../../hooks/useI18n';
import { messages } from '../../../messages';
import { WidgetDashboard } from './widgets/widgetDashboard';
import { CustomerType } from '../../../types';

export const Dashboard = ({
  goToInvoices,
}: {
  goToInvoices: (options?: { paid?: boolean }) => void;
}) => {
  const { t } = useI18n();
  const { session, checkoutAdapter } = useShopSessionStore();

  return (
    <div>
      <div className={styles.header}>
        <div className={styles.headerContent}>
          <UserInformation />
        </div>
      </div>
      <div className={styles.pageContent}>
        {session.notifications !== undefined &&
          session.notifications.length > 0 &&
          session.notifications.map((notification) => (
            <div key={notification.id} className={styles.extraMargin}>
              <Row gutter={15}>
                <Col span={24}>
                  <Alert
                    message={notification.name}
                    showIcon={true}
                    description={notification.message}
                    type="info"
                  />
                </Col>
              </Row>
            </div>
          ))}
        {session.locked && (
          <div className={styles.extraMargin}>
            <Row gutter={15}>
              <Col span={24}>
                <Alert
                  message={t(messages.session.lockedTitle)}
                  showIcon={true}
                  description={t(messages.session.lockedDescription)}
                  type="warning"
                />
              </Col>
            </Row>
          </div>
        )}
        <div>
          <Row gutter={15}>
            <Col span={12} className={styles.widgetContainer}>
              {checkoutAdapter.showNewsFeed && <NewsCard />}
            </Col>
            <Col span={12} className={styles.widgetContainer}>
              {session.invoicesEnabled &&
                checkoutAdapter.showOverdueInvoices && (
                  <InvoicesCard goToInvoices={goToInvoices} />
                )}
              <ContactCard />
            </Col>
          </Row>
        </div>
      </div>
      {session.customerType === CustomerType.Brand && <WidgetDashboard />}
    </div>
  );
};
