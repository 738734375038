import React from 'react';
import emptySvg from './empty.svg';
import styles from './emptyIndicator.module.scss';

interface EmptyIndicatorProps {
  description: string;
}

export const EmptyIndicator = ({ description }: EmptyIndicatorProps) => (
  <div className={styles.content}>
    <img src={emptySvg} />
    <div className={styles.label}>{description}</div>
  </div>
);
