import Scrollbar from 'react-scrollbars-custom';

const ieUserAgentRegex = /Trident\/7\./;

// This custom scroll is used to disable smooth scrolling in IE
export const customIEScroll = (scrollBar: Scrollbar) => (
  event: React.WheelEvent<HTMLDivElement>,
) => {
  // Only use custom scroll for IE
  if (!ieUserAgentRegex.exec(window.navigator.userAgent)) {
    return;
  }
  event.preventDefault();

  const currentScrollTop = scrollBar.scrollTop;
  const currentScrollLeft = scrollBar.scrollLeft;

  const scrollSpeed = 0.5;

  scrollBar.scrollTo(
    currentScrollTop + event.deltaY * scrollSpeed,
    currentScrollLeft + event.deltaX * scrollSpeed,
  );
};
