import { PagedResult } from '../types';
import { ApiRequest } from '../hooks/useApi';

export const invoicesApi = {
  getInvoices: (
    options: GetInvoicesOptions,
  ): ApiRequest<PagedResult<InvoiceListItem>> => async ({
    httpClient,
    apiSettings,
  }) => {
    const response = await httpClient.get(apiSettings.invoicesUri, {
      params: {
        searchQuery: options.searchQuery,
        paid: options.paid,
        sortBy: options.sortBy,
        sortDesc: options.sortDesc,
        limit: options.pageSize,
        offset: (options.page - 1) * options.pageSize,
      },
    });

    if (response.hasError) {
      return { error: response.error };
    } else {
      return {
        result: {
          items: response.data.result,
          totalItems: response.data.total,
        },
      };
    }
  },
};

interface GetInvoicesOptions {
  page: number;
  pageSize: number;
  sortDesc: boolean;
  sortBy: keyof InvoiceListItem;
  searchQuery: string;
  paid?: boolean;
}

export interface InvoiceListItem {
  id: number;
  invoiceNumber: string;
  invoiceDate: string;
  dueDate: string;
  total: number;
  remaining: number;
  pdfGenerated: boolean;
  currencyCode: string;
  paid: boolean;
  overdue: boolean;
  orders: { id: number; orderNumber: string }[];
}
