import { ApiRequest } from '../../../hooks/useApi';
import { CustomerWidgetType } from '../../internalCustomerApi';

export const customerWidgetsApi = {
  getAllWidgets:
    (): ApiRequest<CustomerWidget[]> =>
    async ({ httpClient, apiSettings }) => {
      const response = await httpClient.get(apiSettings.customerWidgetsUri);
      if (response.hasError) {
        return { error: response.error };
      }
      return {
        result: response.data,
      };
    },

  getUserWidgets:
    (): ApiRequest<CustomerWidgetCategory[]> =>
    async ({ httpClient, apiSettings }) => {
      const response = await httpClient.get(apiSettings.customerUserWidgetsUri);
      if (response.hasError) {
        return { error: response.error };
      }
      return {
        result: response.data,
      };
    },
  removeCustomerUserWidget:
    (id: number): ApiRequest =>
    async ({ httpClient, apiSettings }) => {
      const response = await httpClient.delete(
        apiSettings.removeCustomerUserWidgetUri(id),
      );

      if (response.hasError) {
        return { error: response.error };
      }
      return {
        result: response.data,
      };
    },
  updateCustomerWidgetsSortOrder:
    (request: UpdateCustomerWidgetsSortOrderRequest): ApiRequest =>
    async ({ httpClient, apiSettings }) => {
      const response = await httpClient.post(
        apiSettings.customerUserWidgetsUri,
        {
          data: request,
        },
      );

      if (response.hasError) {
        return { error: response.error };
      }
      return {
        result: response.data,
      };
    },
  getCategories:
    (): ApiRequest<WidgetCategory[]> =>
    async ({ httpClient, apiSettings }) => {
      const response = await httpClient.get(
        apiSettings.customerWidgetCategoriesUri,
      );

      if (response.hasError) {
        return { error: response.error };
      }
      return {
        result: response.data,
      };
    },
  getCategory:
    (id: number): ApiRequest<WidgetCategory> =>
    async ({ httpClient, apiSettings }) => {
      const response = await httpClient.get(
        apiSettings.customerWidgetCategoryUri(id),
      );

      if (response.hasError) {
        return { error: response.error };
      }
      return {
        result: response.data,
      };
    },
  createCategory:
    (request: CreateCategoryRequest): ApiRequest =>
    async ({ httpClient, apiSettings }) => {
      const response = await httpClient.post(
        apiSettings.customerWidgetCategoriesUri,
        {
          data: request,
        },
      );

      if (response.hasError) {
        return { error: response.error };
      }
      return {
        result: response.data,
      };
    },
  updateCategory:
    (id: number, request: UpdateCategoryRequest): ApiRequest =>
    async ({ httpClient, apiSettings }) => {
      const response = await httpClient.put(
        apiSettings.customerWidgetCategoryUri(id),
        {
          data: request,
        },
      );

      if (response.hasError) {
        return { error: response.error };
      }
      return {
        result: response.data,
      };
    },
  removeWidgetCategory:
    (id: number): ApiRequest =>
    async ({ httpClient, apiSettings }) => {
      const response = await httpClient.delete(
        apiSettings.customerWidgetCategoryUri(id),
      );

      if (response.hasError) {
        return { error: response.error };
      }
      return {
        result: response.data,
      };
    },
};

export interface CustomerWidget {
  type: CustomerWidgetType;
}

export interface CustomerWidgetCategory {
  id: number;
  name: string;
  widgets: CustomerUserWidget[];
}

export interface CustomerUserWidget {
  id: number;
  name: string;
  widgetType: CustomerWidgetType;
}

export interface WidgetCategory {
  id: number;
  name: string;
}

export interface CreateCategoryRequest {
  name: string;
}

export interface UpdateCategoryRequest {
  name: string;
}

export interface UpdateCustomerWidgetsSortOrderRequest {
  categoryId: number;
  userWidgetIds: number[];
}
