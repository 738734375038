import React, { useState } from 'react';
import { Card, Dropdown, Icon, Menu } from 'antd';
import styles from './customerWidgetCategoryCard.module.scss';
import {
  CustomerUserWidget,
  CustomerWidgetCategory,
} from '../../../../api/widgets/customers/customerWidgetsApi';
import { messages } from '../../../../messages';
import { useI18n } from '../../../../hooks/useI18n';
import { Draggable, Droppable } from 'react-beautiful-dnd';
import { CustomerProductStatisticsReportWidget } from './productStatisticsReport/customerProductStatisticsReportWidget';
import { RemoveCustomerCategoryModal } from './categories/removeCustomerCategoryModal';
import { UpdateCustomerCategoryModal } from './categories/updateCustomerCategoryModal';
import { RemoveCustomerWidgetModal } from './removeCustomerWidgetModal';
import { CustomerWidgetType } from '../../../../api/internalCustomerApi';
import { CustomerAwaitingDeliveriesWidget } from './awaitingDeliveries/customerAwaitingDeliveriesWidget';
import { CustomerOrdersReportWidget } from './customerOrdersReport/customerOrdersReportWidget';

export const CustomerWidgetCategoryCard = (
  {
    category,
    onWidgetDeleted,
    onCategoryDeleted,
    onCategoryUpdated,
  }: {
    category: CustomerWidgetCategory;
    onWidgetDeleted: (widgetId: number) => void;
    onCategoryDeleted: () => void;
    onCategoryUpdated: () => void;
  },
) => {
  const { t } = useI18n();
  const [deleteWidgetModal, setDeleteWidgetModal] = useState<CustomerUserWidget>();
  const [showDeleteCategoryModal, setShowDeleteCategoryModal] = useState<boolean>();
  const [showUpdateCategoryModal, setShowUpdateCategoryModal] = useState<boolean>();

  const moreMenu = (
    <Menu>
      <Menu.Item key='update' onClick={() => setShowUpdateCategoryModal(true)}>
        <Icon type='edit' />
        {t(messages.widgets.categories.updateCategory)}
      </Menu.Item>
      <Menu.Item key='delete' onClick={() => setShowDeleteCategoryModal(true)}>
        <Icon type='delete' />
        {t(messages.widgets.categories.deleteCategory)}
      </Menu.Item>
    </Menu>
  );

  const renderWidgetByType = (widget: CustomerUserWidget) => {
    switch (widget.widgetType) {
      case CustomerWidgetType.ProductStatisticsReport:
        return <CustomerProductStatisticsReportWidget
          userWidgetId={widget.id}
          onWidgetDelete={() => setDeleteWidgetModal(widget)}
        />;
      case CustomerWidgetType.AwaitingDeliveries:
        return <CustomerAwaitingDeliveriesWidget
          userWidgetId={widget.id}
          onWidgetDelete={() => setDeleteWidgetModal(widget)}
        />;
      case CustomerWidgetType.CustomerOrdersReport:
        return <CustomerOrdersReportWidget
          userWidgetId={widget.id}
          onWidgetDelete={() => setDeleteWidgetModal(widget)}
        />;
    }
  };

  return (
    <>
      <Card className={styles.categoryCard}
            key={category.id}
            title={category.name}
            extra={
              <Dropdown overlay={moreMenu} trigger={['click']}>
                <Icon className={styles.moreIcon} type='more' />
              </Dropdown>}>
        <Droppable ignoreContainerClipping={true} key={category.id}
                   droppableId={`${category.id}`}
                   direction='horizontal'>
          {provided => (
            <div
              ref={provided.innerRef}
              {...provided.droppableProps}
              className={styles.divProvided}
            >
              {category.widgets.map((userWidget: CustomerUserWidget, index: number) => (
                <Draggable
                  key={userWidget.id}
                  draggableId={userWidget.id.toString()}
                  index={index}
                >
                  {provided => (
                    <div
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      className={styles.widgetCard}
                    >
                      {renderWidgetByType(userWidget)}
                    </div>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </Card>
      {deleteWidgetModal &&
      <RemoveCustomerWidgetModal userWidgetId={deleteWidgetModal.id}
                                 userWidgetName={deleteWidgetModal.name}
                                 onSuccess={() => {
                                   onWidgetDeleted(deleteWidgetModal.id);
                                   setDeleteWidgetModal(undefined);
                                 }}
                                 onCancel={() => setDeleteWidgetModal(undefined)} />}
      {showDeleteCategoryModal &&
      <RemoveCustomerCategoryModal categoryId={category.id}
                                   categoryName={category.name}
                                   onCancel={() => setShowDeleteCategoryModal(false)}
                                   onSuccess={onCategoryDeleted} />}
      {showUpdateCategoryModal &&
      <UpdateCustomerCategoryModal categoryId={category.id}
                                   onCancel={() => setShowUpdateCategoryModal(false)}
                                   onSuccess={onCategoryUpdated} />}
    </>
  );
};
