import { OrderType } from '../types';
import { TranslateFunction } from '../hooks/useI18n';
import { messages } from '../messages';

export function orderTypeTranslator(t: TranslateFunction, type: OrderType) {
  switch (type) {
    case OrderType.Forecast:
      return t(messages.order.orderTypeForecast);
    case OrderType.Standard:
      return t(messages.order.orderTypeStandard);
    case OrderType.Sample:
      return t(messages.order.orderTypeSample);
    case OrderType.Variable:
      return t(messages.order.orderTypeVariable);
    default:
      return t(messages.shared.unknown);
  }
}
