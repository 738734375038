import React, { useEffect } from 'react';
import { Card, Skeleton, Icon, Button } from 'antd';
import { Columns, Table } from '../../../table';
import { messages } from '../../../../messages';
import { useI18n } from '../../../../hooks/useI18n';
import { useApiResource } from '../../../../hooks/useApi';
import { ApiErrorDetails } from '../../../apiErrorDetails/apiErrorDetails';
import { DueInvoice, DueInvoicesOverview } from '../types';
import { formatNumber } from '../../../../helpers/formatNumber/formatNumber';
import { EmptyIndicator } from '../../../emptyIndicator/emptyIndicator';
import styles from './invoicesCard.module.scss';
import { dashboardApi } from '../../../../api/dashboardApi';
import { useApiSettings } from '../../../../hooks/useApi/useApiSettings';

export function InvoicesCard({
  goToInvoices,
}: {
  goToInvoices: (options?: { paid?: boolean }) => void;
}) {
  const { t } = useI18n();
  const invoices = useApiResource(dashboardApi.getOverdueInvoices);

  useEffect(() => {
    invoices.refresh();
  }, []);

  return (
    <Card
      title={t(messages.dashboard.invoices.title)}
      extra={
        <Button type="link" size="small" onClick={() => goToInvoices()}>
          {t(messages.dashboard.invoices.viewAll)}
        </Button>
      }
    >
      {invoices.error && <ApiErrorDetails error={invoices.error} />}
      {invoices.isLoading && (
        <Skeleton title={false} paragraph={{ rows: 5 }} active={true} />
      )}
      {invoices.data && (
        <InvoicesTable
          invoicesOverview={invoices.data}
          onViewAll={() => goToInvoices({ paid: false })}
        />
      )}
    </Card>
  );
}

function InvoicesTable({
  invoicesOverview,
  onViewAll,
}: {
  invoicesOverview: DueInvoicesOverview;
  onViewAll: () => void;
}) {
  const { t, formatDate } = useI18n();
  const apiSettings = useApiSettings();

  const tableColumns: Columns<DueInvoice> = [
    {
      header: t(messages.invoice.invoiceNumber),
      accessor: 'invoiceNumber',
    },
    {
      header: t(messages.invoice.dueDate),
      accessor: 'dueDate',
      cell: ({ value }) => formatDate(value),
    },
    {
      header: t(messages.shared.remaining),
      cell: ({ row }) =>
        `${formatNumber(row.remaining, 2)} ${row.currencyCode}`,
    },
    {
      header: '',
      width: 40,
      cell: ({ row }) => {
        if (!row.pdfGenerated) return null;
        return (
          <a
            href={apiSettings.invoicePdfUri(row.id)}
            target="_blank"
            rel="noopener noreferrer"
          >
            <Icon type="file-pdf" />
          </a>
        );
      },
    },
  ];

  if (!invoicesOverview.top.length) {
    return (
      <EmptyIndicator description={t(messages.dashboard.invoices.noneDue)} />
    );
  }

  return (
    <>
      <Table<DueInvoice> items={invoicesOverview.top} columns={tableColumns} />
      {invoicesOverview.additionalDueInvoicesAvailable && (
        <Button
          type="link"
          size="small"
          block={true}
          onClick={onViewAll}
          className={styles.viewAllButton}
        >
          {t(messages.dashboard.invoices.viewAllUnpaid)}
        </Button>
      )}
    </>
  );
}
