import React from 'react';
import { Radio } from 'antd';
import { Locale } from '../../hooks/useI18n';
import { localeTranslator } from '../../helpers/localeTranslator';
import styles from './localePicker.module.scss';

export function LocalePicker({
  locale,
  onChange,
}: {
  locale: Locale;
  onChange: (locale: Locale) => void;
}) {
  return (
    <Radio.Group
      className={styles.radioGroup}
      size="small"
      onChange={(e) => onChange(e.target.value)}
      value={locale}
    >
      <Radio.Button className={styles.radioOption} value={Locale.En} data-testid={Locale.En}>
        {localeTranslator(Locale.En)}
      </Radio.Button>
      <Radio.Button className={styles.radioOption} value={Locale.ZhCn} data-testid={Locale.ZhCn}>
        {localeTranslator(Locale.ZhCn)}
      </Radio.Button>
    </Radio.Group>
  );
}
