/* eslint-disable react-hooks/rules-of-hooks */
import constate from 'constate';
import { notification } from 'antd';
import { ArgsProps as AntdNotifictionConfig } from 'antd/lib/notification';
import { useMemo } from 'react';

interface NotificationOptions {
  title: string;
  errors?: string[];
  type: 'info' | 'success' | 'danger';
}

notification.config({
  duration: 3,
});

const notificationStore = () => {
  const notify = (options: NotificationOptions) => {
    const antdNotifictionConfig: AntdNotifictionConfig = {
      message: options.title,
      description: options.errors?.map((e) => e),
    };

    switch (options.type) {
      case 'info':
        notification.info(antdNotifictionConfig);
        break;
      case 'success':
        notification.success(antdNotifictionConfig);
        break;
      case 'danger':
        notification.error(antdNotifictionConfig);
    }
  };

  // Memoize to only update consumers when specified deps changes
  return useMemo(
    () => ({
      notify,
    }),
    [],
  );
};

const [NotificationStoreProvider, useNotificationStore] =
  constate(notificationStore);
export { NotificationStoreProvider, useNotificationStore };
