import React, { useEffect, useMemo } from 'react';
import { TranslateFunction, FormatDateFunction } from '../../../hooks/useI18n';
import { Columns, Table } from '../../table';
import { messages } from '../../../messages';
import { useI18n } from '../../../hooks/useI18n';
import { useQueryParams, serializers } from '../../../hooks/useQueryParams';
import { useDebounceValueCallback } from '../../../hooks/useDebounceValueCallback';
import { useApiResource } from '../../../hooks/useApi';
import { ListLayout } from '../../listLayout/listLayout';
import { Filters } from '../../filters/filters';
import { SearchInput } from '../../searchInput/searchInput';
import { ApiErrorDetails } from '../../apiErrorDetails/apiErrorDetails';
import { InvoiceListItem } from './types';
import {
  Button,
  Menu,
  Dropdown,
  Icon,
  Tag,
  Radio,
  Divider,
  Tooltip,
} from 'antd';
import styles from './invoices.module.scss';
import { formatNumber } from '../../../helpers/formatNumber/formatNumber';
import { invoicesApi } from '../../../api/invoicesApi';
import { ApiSettings } from '../../../api/apiSettings';
import { useApiSettings } from '../../../hooks/useApi/useApiSettings';
import { getExportCsvUrl } from './csvExport';
import { fileDownloadIcon } from '../../customIcons/fileDownloadIcon';

const getColumns = (
  t: TranslateFunction,
  formatDate: FormatDateFunction,
  onSelectOrderId: (id: number) => void,
  apiSettings: ApiSettings,
): Columns<InvoiceListItem> => [
  {
    header: t(messages.invoice.invoiceNumber),
    accessor: 'invoiceNumber',
    sortable: true,
    cell: ({ row }) => {
      return (
        <>
          {row.invoiceNumber}{' '}
          {row.paid && <Tag color="green">{t(messages.invoice.paid)}</Tag>}
          {!row.paid && row.overdue && (
            <Tag color="red">{t(messages.invoice.overdue)}</Tag>
          )}
        </>
      );
    },
  },
  {
    header: t(messages.order.orderNumber),
    sortable: false,
    cell: ({ row }) => {
      if (row.orders.length === 1) {
        return (
          <Button
            type="link"
            size="small"
            onClick={() => onSelectOrderId(row.orders[0].id)}
          >
            {row.orders[0].orderNumber}
          </Button>
        );
      } else if (row.orders.length > 1) {
        return (
          <div>
            <Dropdown
              trigger={['click']}
              overlay={
                <Menu>
                  {row.orders.map(({ id, orderNumber }) => (
                    <Menu.Item key={id}>
                      <Button
                        type="link"
                        size="small"
                        onClick={() => onSelectOrderId(id)}
                      >
                        {orderNumber}
                      </Button>
                    </Menu.Item>
                  ))}
                </Menu>
              }
            >
              <Button size="small" className={styles.smallButtonText}>
                {`${row.orders.length} ${t(messages.order.orders)}`}
                <Icon type="caret-down" className={styles.smallDropdownIcon} />
              </Button>
            </Dropdown>
          </div>
        );
      } else {
        return null;
      }
    },
  },
  {
    header: t(messages.invoice.invoiceDate),
    accessor: 'invoiceDate',
    sortable: true,
    cell: ({ row }) => formatDate(row.invoiceDate),
  },
  {
    header: t(messages.invoice.dueDate),
    accessor: 'dueDate',
    sortable: true,
    cell: ({ row }) => formatDate(row.dueDate),
  },
  {
    header: t(messages.shared.total),
    accessor: 'total',
    align: 'right',
    cell: ({ row }) => `${formatNumber(row.total, 2)} ${row.currencyCode}`,
    sortable: true,
  },
  {
    header: t(messages.shared.remaining),
    accessor: 'remaining',
    align: 'right',
    cell: ({ row }) => `${formatNumber(row.remaining, 2)} ${row.currencyCode}`,
    sortable: false,
  },
  {
    header: '',
    width: 32,
    cell: ({ row }) => {
      if (!row.pdfGenerated) return null;
      return (
        <a
          href={apiSettings.invoicePdfUri(row.id)}
          target="_blank"
          rel="noopener noreferrer"
        >
          <Icon type="file-pdf" />
        </a>
      );
    },
  },
];

const pageSize = 30;

export function Invoices({
  onSelectOrderId,
}: {
  onSelectOrderId: (id: number) => void;
}) {
  const { t, formatDate } = useI18n();
  const apiSettings = useApiSettings();
  const columns = getColumns(t, formatDate, onSelectOrderId, apiSettings);

  const [queryParams, setQueryParams] = useQueryParams({
    page: serializers.number({ defaultValue: 1 }),
    searchQuery: serializers.string({ defaultValue: '' }),
    sortingDesc: serializers.boolean({ defaultValue: true }),
    sortingKey: serializers.custom<keyof InvoiceListItem>({
      defaultValue: 'invoiceDate',
      serialize: (v) => v,
      deserialize: (v) => {
        if (v && columns.some((c) => c.accessor === v)) {
          return v as keyof InvoiceListItem;
        }
      },
    }),
    paid: serializers.boolean(),
  });

  const partialupdateFilterQueryParams = (
    newQueryParams: Partial<typeof queryParams>,
  ) => {
    setQueryParams({ ...queryParams, ...newQueryParams, page: 1 });
  };

  const [
    searchQuery,
    setSearchQuery,
  ] = useDebounceValueCallback(queryParams.searchQuery, (value) =>
    partialupdateFilterQueryParams({ searchQuery: value }),
  );

  const invoices = useApiResource(invoicesApi.getInvoices, {
    emptyValue: {
      totalItems: 0,
      items: [],
    },
    clearDataOnLoad: false,
  });

  const exportCsvUrl = useMemo<string>(
    () =>
      getExportCsvUrl(apiSettings, {
        searchQuery: queryParams.searchQuery,
        sortBy: queryParams.sortingKey,
        sortDesc: queryParams.sortingDesc,
        paid: queryParams.paid,
      }),
    [
      apiSettings,
      queryParams.searchQuery,
      queryParams.sortingKey,
      queryParams.sortingDesc,
      queryParams.paid,
    ],
  );

  useEffect(() => {
    invoices.refresh({
      searchQuery: queryParams.searchQuery,
      page: queryParams.page,
      pageSize,
      sortBy: queryParams.sortingKey,
      sortDesc: queryParams.sortingDesc,
      paid: queryParams.paid,
    });
  }, [
    queryParams.searchQuery,
    queryParams.page,
    queryParams.sortingKey,
    queryParams.sortingDesc,
    queryParams.paid,
  ]);

  return (
    <ListLayout>
      <ListLayout.Sider>
        <Filters>
          <Filters.Filter title={t(messages.shared.search)}>
            <SearchInput
              value={searchQuery}
              placeholder={t(messages.shared.searchPlaceholder)}
              onChange={setSearchQuery}
            />
          </Filters.Filter>
          <Filters.Filter title={t(messages.shared.status)}>
            <Radio.Group
              value={queryParams.paid}
              onChange={(e) =>
                partialupdateFilterQueryParams({
                  paid: e.target.value,
                })
              }
            >
              <Radio value={undefined} style={{ display: 'block' }}>
                {t(messages.shared.all)}
              </Radio>
              <Radio value={false} style={{ display: 'block' }}>
                {t(messages.invoice.unpaid)}
              </Radio>
              <Radio value={true} style={{ display: 'block' }}>
                {t(messages.invoice.paid)}
              </Radio>
            </Radio.Group>
          </Filters.Filter>
        </Filters>
      </ListLayout.Sider>
      <ListLayout.Content>
        <ListLayout.ContentHeader
          title={
            <span>
              {t(messages.shared.listingItemsCount, {
                count: invoices.data.totalItems || 0,
              })}
              <Divider type="vertical" />
              <Tooltip title={t(messages.shared.export)}>
                <a
                  href={exportCsvUrl}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Icon component={fileDownloadIcon} />
                </a>
              </Tooltip>
            </span>
          }
        />
        {invoices.error && (
          <ListLayout.ContentSection>
            <ApiErrorDetails error={invoices.error} />
          </ListLayout.ContentSection>
        )}
        <Table<InvoiceListItem>
          columns={columns}
          items={invoices.data.items}
          isLoading={invoices.isLoading}
          paged={{
            page: queryParams.page,
            pageSize,
            onPageChange: (page) => {
              setQueryParams({ ...queryParams, page });
            },
            totalItems: invoices.data.totalItems || 0,
          }}
          sorted={{
            desc: queryParams.sortingDesc,
            key: queryParams.sortingKey,
            onSortedChange: (sorting) =>
              setQueryParams({
                ...queryParams,
                sortingDesc: sorting.desc,
                sortingKey: sorting.key,
              }),
          }}
        />
      </ListLayout.Content>
    </ListLayout>
  );
}
