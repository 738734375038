import React, { useState } from 'react';
import { Checkbox, Button, Input } from 'antd';
import { Table, Column } from 'react-virtualized';
import { useEffect } from 'react';
import { useApiResource, ApiRequest } from '../../hooks/useApi';
import { LoadingIndicator } from '../loadingIndicator/loadingIndicator';
import { ApiErrorDetails } from '../apiErrorDetails/apiErrorDetails';
import { useI18n } from '../../hooks/useI18n';
import { messages } from '../../messages';

type CategoryOption = {
  id: number;
  erpId: string;
  name: string;
  branchName: string;
};

export function ArticleCategoryPicker({
  categoryIds,
  branchIds,
  onChange,
  categoryRequestFactory,
}: {
  categoryIds: number[];
  branchIds?: number[];
  onChange: (selectedCategoryIds: number[]) => void;
  categoryRequestFactory: (
    branchIds: number[],
  ) => () => ApiRequest<CategoryOption[]>;
}) {
  const { t } = useI18n();
  const [filter, setFilter] = useState('');
  const availableCategories = useApiResource(
    categoryRequestFactory(branchIds ?? []),
  );

  useEffect(() => {
    if (branchIds !== undefined && !branchIds.length) {
      availableCategories.reset();
    } else {
      availableCategories.refresh();
    }
  }, [branchIds]);
  const selectableCategories =
    availableCategories.data === undefined
      ? []
      : availableCategories.data
          .filter(
            (c) =>
              c.name.toLowerCase().includes(filter.toLowerCase()) ||
              c.erpId.toLowerCase().includes(filter.toLowerCase()),
          )
          .map((c) => {
            return {
              id: c.id,
              name: c.name,
              erpId: c.erpId,
              branch: c.branchName,
              selected: categoryIds.includes(c.id),
            };
          });

  const selectedCategoryIds =
    availableCategories.data === undefined
      ? []
      : availableCategories.data
          .filter((c) => categoryIds.includes(c.id))
          .map((c) => {
            return {
              id: c.id,
              name: c.name,
              erpId: c.erpId,
              branch: c.branchName,
              selected: categoryIds.includes(c.id),
            };
          });

  function handleSelectCategory(catId: number) {
    if (categoryIds.includes(catId)) {
      onChange(categoryIds.filter((c) => c !== catId));
    } else {
      onChange([...categoryIds, catId]);
    }
  }

  return (
    <>
      <Input
        placeholder={t(messages.reports.categories.filterPlaceholder)}
        disabled={!availableCategories.data?.length}
        size="small"
        onChange={(e) => setFilter(e.target.value)}
        style={{ width: '275px', marginBottom: '10px' }}
        allowClear={true}
      />
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <div>
          <strong>Available</strong>
          <Table
            width={325}
            height={300}
            headerHeight={20}
            rowHeight={30}
            rowCount={selectableCategories.length}
            rowGetter={({ index }) => selectableCategories[index]}
            rowStyle={{ display: 'flex' }}
            headerStyle={{ display: 'flex' }}
            noRowsRenderer={() => (
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  height: '100%',
                  color: 'gray',
                }}
              >
                {availableCategories.error && (
                  <ApiErrorDetails error={availableCategories.error} />
                )}
                {!availableCategories.error &&
                  availableCategories.isLoading && <LoadingIndicator />}
                {!availableCategories.error && availableCategories.hasResponse
                  ? t(messages.reports.categories.noMatchingCategories)
                  : t(messages.reports.categories.selectABranch)}
              </div>
            )}
            rowRenderer={({ style, rowData }) => {
              return (
                <div key={rowData.id} style={style}>
                  <span style={{ width: '25px' }}>
                    <Checkbox
                      onChange={() => handleSelectCategory(rowData.id)}
                      checked={rowData.selected}
                    ></Checkbox>
                  </span>
                  <span style={{ width: '50px' }}>{rowData.erpId}</span>
                  <span
                    style={{
                      width: '150px',
                      whiteSpace: 'nowrap',
                      textOverflow: 'ellipsis',
                      overflow: 'hidden',
                      paddingRight: '5px',
                    }}
                    title={rowData.name}
                  >
                    {rowData.name}
                  </span>
                  <span style={{ width: '75px' }}>{rowData.branch}</span>
                </div>
              );
            }}
          >
            <Column width={25} label="" dataKey="" />
            <Column width={50} label="Erp Id" dataKey="erpId" />
            <Column label="Name" dataKey="name" width={150} />
            <Column label="Branch" dataKey="branch" width={75} />
          </Table>
          <Button
            type="link"
            size="small"
            disabled={!selectableCategories.length}
            onClick={() => onChange(selectableCategories.map((c) => c.id))}
          >
            {t(messages.reports.categories.selectAll)}
          </Button>
        </div>
        <div>
          <strong>Selected</strong>
          <Table
            width={325}
            height={300}
            headerHeight={20}
            rowHeight={30}
            rowCount={selectedCategoryIds.length}
            rowGetter={({ index }) => selectedCategoryIds[index]}
            rowStyle={{ display: 'flex' }}
            headerStyle={{ display: 'flex' }}
            noRowsRenderer={() => (
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  height: '100%',
                  color: 'gray',
                }}
              >
                {t(messages.reports.categories.noCategoriesSelected)}
              </div>
            )}
            rowRenderer={({ style, rowData }) => {
              return (
                <div key={rowData.id} style={style}>
                  <span style={{ width: '25px' }}>
                    <Checkbox
                      onChange={() => handleSelectCategory(rowData.id)}
                      checked={rowData.selected}
                    ></Checkbox>
                  </span>
                  <span style={{ width: '50px' }}>{rowData.erpId}</span>
                  <span
                    style={{
                      width: '150px',
                      whiteSpace: 'nowrap',
                      textOverflow: 'ellipsis',
                      overflow: 'hidden',
                      paddingRight: '5px',
                    }}
                    title={rowData.name}
                  >
                    {rowData.name}
                  </span>
                  <span style={{ width: '75px' }}>{rowData.branch}</span>
                </div>
              );
            }}
          >
            <Column width={25} label="" dataKey="" />
            <Column width={50} label="Erp Id" dataKey="erpId" />
            <Column label="Name" dataKey="name" width={150} />
            <Column label="Branch" dataKey="branch" width={75} />
          </Table>
          <Button
            type="link"
            size="small"
            disabled={!selectedCategoryIds.length}
            onClick={() => {
              onChange([]);
            }}
          >
            {t(messages.reports.categories.clear)}
          </Button>
        </div>
      </div>
    </>
  );
}
