import React, { useMemo } from 'react';
import { useI18n } from '../../hooks/useI18n';
import { messages } from '../../messages';
import { OrderType } from '../../types';
import { Select } from 'antd';
import style from './picker.module.scss';

export function OrderTypePicker({
  orderType,
  selectableOrderTypes,
  allowClear,
  onChange,
}: {
  orderType?: number;
  selectableOrderTypes: OrderType[];
  allowClear: boolean;
  onChange: (selectedOrderType: number) => void;
}) {
  const { t } = useI18n();

  const orderTypeOptions = useMemo(() => {
    const orderTypes = [];

    if (selectableOrderTypes.includes(OrderType.Standard)) {
      orderTypes.push({
        key: OrderType.Standard,
        label: t(messages.order.orderTypeStandard),
      });
    }

    if (selectableOrderTypes.includes(OrderType.Sample)) {
      orderTypes.push({
        key: OrderType.Sample,
        label: t(messages.order.orderTypeSample),
      });
    }

    if (selectableOrderTypes.includes(OrderType.Forecast)) {
      orderTypes.push({
        key: OrderType.Forecast,
        label: t(messages.order.orderTypeForecast),
      });
    }

    if (selectableOrderTypes.includes(OrderType.Variable)) {
      orderTypes.push({
        key: OrderType.Variable,
        label: t(messages.order.orderTypeVariable),
      });
    }

    return orderTypes;
  }, [t]);

  return (
    <Select
      className={style.width100}
      value={orderType}
      onChange={onChange}
      notFoundContent={false}
      placeholder={t(messages.order.orderType)}
      allowClear={allowClear}
    >
      {orderTypeOptions.map((o) => (
        <Select.Option key={o.key} value={o.key}>
          {o.label}
        </Select.Option>
      ))}
    </Select>
  );
}
