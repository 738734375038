import { useI18n } from '../../../../../hooks/useI18n';
import { useNotificationStore } from '../../../../../hooks/useNotificationStore/notificationStore';
import React, { useState } from 'react';
import { ApiError, useApiRequests } from '../../../../../hooks/useApi';
import { CreateCategoryRequest } from '../../../../../api/internalWidgetsApi';
import { messages } from '../../../../../messages';
import { useFormik } from 'formik';
import { Input, Modal } from 'antd';
import { ApiErrorDetails } from '../../../../apiErrorDetails/apiErrorDetails';
import { Form } from '../../../../form/form';
import { customerWidgetsApi } from '../../../../../api/widgets/customers/customerWidgetsApi';

export function CreateCustomerCategoryModal(
  {
    onCancel,
    onSuccess,
  }: {
    onCancel: () => void;
    onSuccess: () => void;
  }) {
  const { t } = useI18n();
  const { notify } = useNotificationStore();

  const createWidgetCategory = useApiRequests(customerWidgetsApi.createCategory);

  const [name, setName] = useState<string>('');
  const [error, setError] = useState<ApiError>();

  const handleFormSubmit = () => {
    const createCategoryRequest: CreateCategoryRequest = {
      name: name,
    };

    createWidgetCategory
      .create({
        onSuccess: () => {
          notify({
            title: t(messages.widgets.categories.categoryCreated),
            type: 'success',
          });
          onSuccess();
        },
        onError: setError,
      })
      .send(createCategoryRequest);
  };

  const formik = useFormik<FormData>({
    initialValues: {
      name: '',
    },
    onSubmit: handleFormSubmit,
  });

  return (
    <Modal
      title={t(messages.widgets.categories.createCategory)}
      visible={true}
      closable={false}
      onCancel={onCancel}
      onOk={formik.submitForm}
      okText={t(messages.shared.save)}
      width={720}
    >
      {error && <ApiErrorDetails error={error} />}
      <Form form={formik}>
        <h4>{t(messages.shared.name)}</h4>
        <Input value={name}
               onChange={(e) => setName(e.target.value)} />
      </Form>
    </Modal>
  );
}

interface FormData {
  name: string;
}
