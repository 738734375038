import { UpdateLineItem, LineItem } from '../types';
import { ApiRequest } from '../hooks/useApi';

export const cartApi = {
  getItems: (): ApiRequest<Cart> => async ({ httpClient, apiSettings }) => {
    const response = await httpClient.get(apiSettings.cartUri);

    if (response.hasError) {
      return { error: response.error };
    } else {
      return {
        result: response.data,
      };
    }
  },

  updateLineItem: (lineItem: UpdateLineItem): ApiRequest => async ({
    httpClient,
    apiSettings,
  }) => {
    const response = await httpClient.post(apiSettings.cartUri, {
      data: lineItem,
    });

    if (response.hasError) {
      return { error: response.error };
    } else {
      return {
        result: undefined,
      };
    }
  },
  clearLineItems: (): ApiRequest => async ({ httpClient, apiSettings }) => {
    const response = await httpClient.delete(apiSettings.cartUri);

    if (response.hasError) {
      return { error: response.error };
    } else {
      return {
        result: undefined,
      };
    }
  },
};

interface Cart {
  items: LineItem[];
  unavailableArticleIds: number[];
}
