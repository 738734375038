import React from 'react';
import { DeliveryStatus } from '../../types';
import { useI18n } from '../../hooks/useI18n';
import { Badge, Tooltip } from 'antd';
import { deliveryStatusTranslator } from '../../helpers/deliveryStatusTranslator';

const deliveryStatusColor = {
  [DeliveryStatus.Undelivered]: 'orange',
  [DeliveryStatus.PartiallyDelivered]: 'yellow',
  [DeliveryStatus.Delivered]: 'green',
};

export function DeliveryStatusBadge({
  deliveryStatus,
  showText,
}: {
  deliveryStatus: DeliveryStatus;
  showText: boolean;
}) {
  const { t } = useI18n();

  const color = deliveryStatusColor[deliveryStatus];
  const text = deliveryStatusTranslator(t, deliveryStatus);

  if (showText) {
    return <Badge color={color} text={text} />;
  } else {
    return (
      <Tooltip title={text}>
        <Badge color={color} />
      </Tooltip>
    );
  }
}
