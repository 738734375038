import { ApiRequest } from '../../hooks/useApi';
import { TimePeriodType } from '../../types';

export const internalProductStatisticsReportWidgetApi = {
  createWidget:
    (request: CreateUpdateProductStatisticsReportWidgetRequest): ApiRequest =>
    async ({ httpClient, apiSettings }) => {
      const response = await httpClient.post(
        apiSettings.internalProductStatisticsReportWidgetsUri,
        {
          data: request,
        },
      );

      if (response.hasError) {
        return { error: response.error };
      }
      return {
        result: response.data,
      };
    },

  updateWidget:
    (
      id: number,
      request: CreateUpdateProductStatisticsReportWidgetRequest,
    ): ApiRequest =>
    async ({ httpClient, apiSettings }) => {
      const response = await httpClient.put(
        apiSettings.internalProductStatisticsReportWidgetUri(id),
        {
          data: request,
        },
      );

      if (response.hasError) {
        return { error: response.error };
      }
      return {
        result: response.data,
      };
    },

  getWidget:
    (id: number): ApiRequest<ProductStatisticsReportWidget> =>
    async ({ httpClient, apiSettings }) => {
      const response = await httpClient.get(
        apiSettings.internalProductStatisticsReportWidgetUri(id),
      );

      if (response.hasError) {
        return { error: response.error };
      }
      return {
        result: response.data,
      };
    },
  triggerNewReport:
    (widgetId: number): ApiRequest<void> =>
    async ({ httpClient, apiSettings }) => {
      const response = await httpClient.post(
        apiSettings.triggerNewInternalProductStatisticsExportUri(widgetId),
      );

      if (response.hasError) {
        return { error: response.error };
      }
      return {
        result: response.data,
      };
    },
};

export interface ProductStatisticsReportWidget {
  name: string;
  widgetCategoryId: number | undefined;
  color: string | undefined;
  branches: Branch[];
  categories: Category[];
  projects: Project[];
  itemTypes: string[];
  timePeriods: string[];
  timePeriodType: TimePeriodType;
  includeFrozenArticles: boolean;
  includeImages: boolean;
  mostRecentSuccessfullyGeneratedReport: ProductStatisticsReport | undefined;
  existsPendingReport: boolean;
  lastGeneratedReportFailed: boolean;
}

export interface Branch {
  id: number;
  name: string;
}

export interface Category {
  id: number;
  name: string;
}

export interface Project {
  id: number;
  name: string;
  erpId: string;
  branch: Branch;
}

export interface CreateUpdateProductStatisticsReportWidgetRequest {
  name: string;
  widgetCategoryId: number | undefined;
  color: string | undefined;
  branchIds: number[];
  categoryIds: number[];
  projectIds: number[];
  itemTypes: string[];
  timePeriods: string[];
  timePeriodType: TimePeriodType;
  includeFrozenArticles: boolean;
  includeImages: boolean;
}

export interface ProductStatisticsReport {
  id: string;
  createdDate: string;
  status: ReportStatus;
}

export enum ReportStatus {
  Pending,
  Finished,
  Failed,
}
