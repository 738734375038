import { ApiRequest } from '../hooks/useApi';
import { Address } from '../types';

export const checkoutApi = {
  getVendors: (): ApiRequest<Vendor[]> => async ({
    httpClient,
    apiSettings,
  }) => {
    const response = await httpClient.get(apiSettings.checkoutVendorsUri);

    if (response.hasError) {
      return { error: response.error };
    } else {
      return {
        result: response.data,
      };
    }
  },
  getSeasons:
    (): ApiRequest<Season[]> =>
    async ({ httpClient, apiSettings }) => {
      const response = await httpClient.get(apiSettings.checkoutSeasonsUri);
      if (response.hasError) {
        return { error: response.error };
      } else {
        return {
          result: response.data,
        };
      }
    },

  getCustomAddresses: (): ApiRequest<Address[]> => async ({
    httpClient,
    apiSettings,
  }) => {
    const response = await httpClient.get(
      apiSettings.sessionCustomAddressesUri,
    );

    if (response.hasError) {
      return { error: response.error };
    } else {
      return {
        result: response.data,
      };
    }
  },
};

export interface Vendor {
  id: number;
  name: string;
  address: Address;
  invoiceCustomerId: number;
  invoiceCustomerAddress?: Address;
}

export interface Season {
  id: number;
  name: string;
}
