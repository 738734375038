import { ApiRequest } from '../../../hooks/useApi';

export const customerAwaitingDeliveriesWidgetApi = {
  createWidget:
    (request: CreateUpdateAwaitingDeliveriesWidgetRequest): ApiRequest =>
    async ({ httpClient, apiSettings }) => {
      const response = await httpClient.post(
        apiSettings.customerAwaitingDeliveriesWidgetsUri,
        {
          data: request,
        },
      );

      if (response.hasError) {
        return { error: response.error };
      }
      return {
        result: response.data,
      };
    },

  updateWidget:
    (
      id: number,
      request: CreateUpdateAwaitingDeliveriesWidgetRequest,
    ): ApiRequest =>
    async ({ httpClient, apiSettings }) => {
      const response = await httpClient.put(
        apiSettings.customerAwaitingDeliveriesWidgetUri(id),
        {
          data: request,
        },
      );

      if (response.hasError) {
        return { error: response.error };
      }
      return {
        result: response.data,
      };
    },

  getWidget:
    (id: number): ApiRequest<AwaitingDeliveriesWidget> =>
    async ({ httpClient, apiSettings }) => {
      const response = await httpClient.get(
        apiSettings.customerAwaitingDeliveriesWidgetUri(id),
      );

      if (response.hasError) {
        return { error: response.error };
      }
      return {
        result: response.data,
      };
    },
};

export interface Branch {
  id: number;
  name: string;
}

export interface AwaitingDeliveriesWidget {
  name: string;
  widgetCategoryId: number | undefined;
  color: string | undefined;
  branch: Branch;
  numberOfOrders: number;
  branches: Branch[];
}

export interface CreateUpdateAwaitingDeliveriesWidgetRequest {
  name: string;
  widgetCategoryId: number | undefined;
  color: string | undefined;
  branchIds: number[];
}
