import querystringify from 'querystringify';
import { ApiSettings } from '../../../api/apiSettings';
import { InvoiceListItem } from './types';

export interface ExportOptions {
  searchQuery: string;
  sortDesc: boolean;
  sortBy: keyof InvoiceListItem;
  displayCurrencyId?: number;
  paid: boolean | undefined;
}

export function getExportCsvUrl(
  apiSettings: ApiSettings,
  options: ExportOptions,
) {
  const queryString = querystringify.stringify({
    searchQuery: options.searchQuery,
    sortBy: options.sortBy,
    sortDesc: options.sortDesc,
    displayCurrencyId: options.displayCurrencyId,
    paid: options.paid
  });

  return `${apiSettings.invoiceCsvUri}?${queryString}`;
}
